import { navigate as navigateGatsby } from "gatsby";
// Services
import intl from "./intl";

const navigate = (path, options, ev = null) => {

  // allow open with cmd/ctrl + click
  if(ev?.metaKey || ev?.ctrlKey) {
    window.open(intl.localizePath(path), "_blank");
  } else {
    navigateGatsby(intl.localizePath(path), options);
  }
}
export { navigate };
