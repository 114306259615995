import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import {
  CaptionTitle,
  EditorialBlock,
  RButton,
} from "@ritual/essentials-for-react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import styled from "styled-components";
import { navigate } from "../../services/navigation";
import metrics from "../../utils/metrics";

const HeadlineElement = styled.span`
  font-weight: 500 !important;

  p {
    margin: 0;
  }

  i {
    font-family: var(--font-dutch);
    font-weight: 400 !important;
  }
`;

const DescriptionElement = styled.span`
  p {
    margin: 0;
  }
`;

const CaptionTitleEB = styled(CaptionTitle)`
  white-space: break-spaces;
`

const ImageContainer = styled.div`
  display: block;
  height: 100%;
  width: 100%;
`;

type Content = {
  eyebrow?: string;
  headline?: {
    raw: string;
  };
  description?: {
    raw: string;
  };
  footnote?: string;
  backgroundImage?: {
    description: string;
    gatsbyImageData: IGatsbyImageData;
  };
  ctaText?: string;
  ctaSlug?: string;
  backgroundColor?: string;
};

type Props = {
  item: {
    content: Content;
  };
};

type EditorialBlocksProps = (props: Props) => JSX.Element | null;

const EditorialBlocks: EditorialBlocksProps = ({ item }) => {
  const { content } = item;
  const {
    eyebrow,
    headline,
    description,
    footnote,
    backgroundImage,
    ctaText,
    ctaSlug,
    backgroundColor,
  } = content;
  const imageOnly = !!backgroundImage;
  const showCta = !!ctaText && !!ctaSlug;

  const onClickHandler = (e: any) => {
    metrics.track("CTA Clicked", {
      location: "Product List Page Editorial Block",
      destination: ctaSlug,
    });

    if (ctaSlug) navigate(ctaSlug, undefined, e);
  };

  return (
    <EditorialBlock
      style={{
        height: "100%",
      }}
      imageOnly={imageOnly}
      backgroundColor={backgroundColor}
    >
      {eyebrow && (
        <span slot="editorial-eyebrow" className="typography-eyebrow2">
          {eyebrow}
        </span>
      )}
      {headline && headline.raw && (
        <HeadlineElement
          slot="editorial-headline"
          className="typography-lead2"
          dangerouslySetInnerHTML={{
            __html: documentToHtmlString(JSON.parse(headline.raw)),
          }}
        />
      )}
      {description && (
        <DescriptionElement
          slot="editorial-description"
          className="typography-body3"
          dangerouslySetInnerHTML={{
            __html: documentToHtmlString(JSON.parse(description.raw)),
          }}
        />
      )}
      {footnote && (
        <CaptionTitleEB slot="editorial-block-caption">{footnote}</CaptionTitleEB>
      )}
      {backgroundImage && (
        <ImageContainer slot="editorial-block-image">
          <GatsbyImage
            image={backgroundImage?.gatsbyImageData}
            alt={backgroundImage?.description}
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </ImageContainer>
      )}
      {showCta && (
        <RButton
          buttonClass="primary"
          onClick={onClickHandler}
          slot="editorial-block-cta-button"
        >
          {ctaText}
        </RButton>
      )}
    </EditorialBlock>
  );
};

export default EditorialBlocks;
