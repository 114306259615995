import makeAsyncScript from "react-async-script";
import intl from "../../services/intl";
import locales from "../../../config/locales";

let scripts = {};

// https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/incorporating_sign_in_with_apple_into_other_platforms
export function createAppleAuthScript() {
  const currentLocale = locales[intl.locale].appleAuthLocale;
  if (scripts[currentLocale]) {
    return scripts[currentLocale];
  }

  const scriptURL = `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${currentLocale}/appleid.auth.js`;
  const script = makeAsyncScript(scriptURL)(() => null);
  scripts[currentLocale] = script;
  return script;
}
