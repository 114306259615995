import { Component } from "react";
import styled from "styled-components";

import { Color, rem, rgba, responsive, Opacity } from "../../utils/style";

// Components
import MagicModal from "../MagicModal";
import Text from "../Text";

const Title = styled.h3`
  margin: 0;
  font-weight: 500;

  padding: 0 0 32px;
  letter-spacing: -0.2px;
  font-size: ${rem(22)};
  line-height: ${rem(32)};

  ${responsive.sm`
    padding: 0 0 40px;
    letter-spacing: -0.4px;
    font-size: ${rem(30)};
    line-height: ${rem(36)};
  `}

  border-bottom: 2px solid ${Color.ritualBlue};
`;

const IngredientContainer = styled.div`
  padding: 32px 0;
  border-bottom: 1px solid ${Color.fadedGrey};
`;

const Name = styled.h4`
  margin: 0 0 8px;
  font-weight: 500;
  font-size: ${rem(18)};
  line-height: ${rem(28)};
`;

const Category = styled.p`
  margin: 24px 0 8px;
  color: ${rgba(Color.ritualBlue, Opacity.light)};
  font-weight: 300;
  font-size: ${rem(16)};
  line-height: ${rem(22)};
`;

const Content = styled.p`
  margin: 0;
  font-weight: 300;
  font-size: ${rem(16)};
  line-height: ${rem(22)};
`;

export default class OtherIngredientsModal extends Component {
  render() {
    const { isOpen, onRequestClose, title, ingredients } = this.props;

    return (
      <MagicModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        slideOut={true}
      >
        <Title>{title}</Title>
        {ingredients.map((ingredient, i) => {
          const { name, location, manufacturingLocation, supplier, source } =
            ingredient;
          return (
            <IngredientContainer key={i}>
              <Name>{name}</Name>
              <Content>{location}</Content>
              {manufacturingLocation && (
                <>
                  <Category>
                    <Text
                      id="ingredients.info.location"
                      defaultMessage="Final Location of Manufacturing"
                    />
                  </Category>
                  <Content>{manufacturingLocation}</Content>
                </>
              )}
              {supplier && (
                <>
                  <Category>
                    <Text
                      id="ingredients.info.supplier"
                      defaultMessage="Supplier"
                    />
                  </Category>
                  <Content>{supplier}</Content>
                </>
              )}
              {ingredient.function && (
                <>
                  <Category>
                    <Text
                      id="ingredients.info.function"
                      defaultMessage="Function"
                    />
                  </Category>
                  <Content>{ingredient.function}</Content>
                </>
              )}
              {source && (
                <>
                  <Category>
                    <Text
                      id="ingredients.info.source"
                      defaultMessage="Source"
                    />
                  </Category>
                  <Content>{source}</Content>
                </>
              )}
            </IngredientContainer>
          );
        })}
      </MagicModal>
    );
  }
}
