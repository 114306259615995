import intl from "../services/intl";
import { getCartTraits } from "./cart";
import { getUserTraits } from "./currentUser";

function identify(id, traits, options = {}) {
  const attributes = _getIdentifyTraits(traits);
  if (!window.analytics || !typeof window.analytics.identify === "function") {
    return;
  }

  if (typeof id !== "string") {
    window.analytics.identify(attributes, options);
  } else {
    window.analytics.identify(id, attributes, options);
  }
}

function page(pageLabel, properties = {}) {
  if (!window.analytics || !typeof window.analytics.page === "function") {
    return;
  }

  const traits = getUserTraits();

  // Create a new properties object with TikTok Event ID and Facebook Pixel data
  const enhancedProperties = { ...properties };

  const options = {
    context: {
      traits,
    },
  };

  window.analytics.page(pageLabel, enhancedProperties, options);
}

function track(event, properties = {}, options, callback) {
  let trackOptions = _parseOptions(properties, options);

  if (!window.analytics || !typeof window.analytics.track === "function") {
    return;
  }

  const trackArgs = [event, properties, trackOptions];
  if (callback) {
    trackArgs.push(callback);
  }

  window.analytics.track(...trackArgs);
}

function _getIdentifyTraits(attrs = {}) {
  const userTraits = getUserTraits() || {};
  const traits = { ...attrs };

  if (!traits.first_name && userTraits.firstName) {
    traits.first_name = userTraits.firstName;
  }
  if (!traits.last_name && userTraits.lastName) {
    traits.last_name = userTraits.lastName;
  }
  if (!traits.email && userTraits.email) {
    traits.email = userTraits.email;
  }

  traits.locale = intl.locale;

  return traits;
}

function _parseOptions(properties, options = {}) {
  let traits = getUserTraits();
  let trackOptions = {};

  // Initialize context with traits if needed
  const hasTraits = traits.email && (!options || !options.noTraits);
  if (hasTraits) {
    trackOptions.context = {
      traits: traits,
    };
  }

  if (options.addMarketingPreference) {
    // Pass in marketing preference to traits for User Signup
    traits.marketingPreference =
      properties.marketingPreference || options.marketingPreference;
    trackOptions.context = trackOptions.context || {};
    trackOptions.context.traits = traits;
  }

  if (options.addEmail) {
    // Only add email to the top level properties if it is not already present, when the addEmail option is true.
    if (traits.email) {
      properties.email = traits.email;
    }
  }

  // If the segmentOptions property is passed, merge them carefully preserving context
  if (options.segmentOptions) {
    const { context: segmentContext, ...restSegmentOptions } =
      options.segmentOptions;
    if (segmentContext) {
      trackOptions.context = {
        ...trackOptions.context,
        ...segmentContext,
      };
    }
    trackOptions = { ...trackOptions, ...restSegmentOptions };
  }

  if (options.cartTraits) {
    const cartOptions = getCartTraits();
    trackOptions.context = trackOptions.context || {};
    trackOptions.context.traits = {
      ...(trackOptions.context.traits || {}),
      ...cartOptions,
    };
  }

  _parseIntegrations(trackOptions);

  // Return trackOptions only if it has properties
  return Object.keys(trackOptions).length ? trackOptions : undefined;
}

function _parseIntegrations(trackOptions) {
  const { integrations } = trackOptions;

  if (!window.analytics || !integrations) {
    return;
  }

  const userIntegrations = { ...window.analytics.integrations };

  // If All is false, all existing integrations preferences should be
  // overwritten to false. This only applies to the current event being tracked,
  // and does not change the persisted integration preferences for the user.
  const allValue = integrations.All;

  Object.keys(userIntegrations).forEach((key) => {
    const userValue = userIntegrations[key];
    const optionsValue = integrations[key];
    let mergedValue = true;

    // Do not override the Segment.io integration.
    if (key === "Segment.io") return;

    if (userValue === false) {
      mergedValue = false;
    } else if (optionsValue !== undefined) {
      mergedValue = optionsValue;
    } else {
      mergedValue = allValue !== undefined ? allValue : userValue;
    }

    trackOptions.integrations[key] = mergedValue;
  });
}

const metrics = {
  identify,
  page,
  track,
};

export default metrics;
