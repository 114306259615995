import styled from "styled-components";
import "url-search-params-polyfill";

// Services
import intl from "../../services/intl";

// Redux

// Components
import DiscountButtonCopy from "./DiscountButtonCopy";
import RitualButton from "../global/RitualButton";

// Utils
import { responsive } from "../../utils/style";
import { navigateTo } from "../../utils/navigate";

// Styled Components
const QuickAddButtonWrapper = styled.div`
  width: 100%;

  ${responsive.md`
    width: auto;
  `}
`;

const Button = styled(RitualButton)`
  width: 100%;

  ${responsive.sm`
    width: ${(props) => (props.showPricing ? "auto" : "100%")};
    padding-left: 37px;
    padding-right: 37px;
  `};

  ${responsive.md`
    padding-left: 26px;
    padding-right: 26px;
  `};
`;

export default function QuickAddButton(props) {
  const {
    plan,
    trackCTAClick,
    promotion,
    product,
    subscriptionId,
    showPricing,
  } = props;

  const quickAddSearchParams = new URLSearchParams();
  quickAddSearchParams.append("pid", plan.id);
  quickAddSearchParams.append("close", "3");

  const urlSearchParams = new URLSearchParams(window.location.search);
  if (urlSearchParams.has("scope") && urlSearchParams.has("token")) {
    quickAddSearchParams.append("token", urlSearchParams.get("token"));
    quickAddSearchParams.append("scope", urlSearchParams.get("scope"));
  }

  const quickAddUrl = `${
    process.env.GATSBY_ACCOUNT_URL
  }/subscription/${subscriptionId}/add/confirm?${quickAddSearchParams.toString()}`;
  const price = plan.amount / 100;

  const handleClick = async (event) => {
    event.preventDefault();
    await trackCTAClick(event);
    await navigateTo(quickAddUrl);
  };

  const copyPrefix = showPricing
    ? intl.t("general.discount-button-prefix", "Add")
    : intl.t("general.button-add-cart", "Add to Cart");

  return (
    <QuickAddButtonWrapper>
      <Button
        onClick={handleClick}
        href={quickAddUrl}
        rel="noreferrer"
        title={`Quick Add ${plan.id}`}
        target="_self"
        {...props}
      >
        <DiscountButtonCopy
          prefix={copyPrefix}
          price={showPricing ? price : null}
          promotion={showPricing && promotion}
          product={product}
          showDash={showPricing}
        />
      </Button>
    </QuickAddButtonWrapper>
  );
}
