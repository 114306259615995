import { Component } from "react";
import styled from "styled-components";

// Utils
import { Color, responsive } from "../../utils/style";

// Components
import Container from "../Container";

// Styled Elements
const AuthContainer = styled(Container).attrs({
  className: "col-sm-6 offset-sm-3 ",
})`
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  padding: 24px 24px;
  margin: 40px auto;
  background-color: ${Color.white};
  max-width: 280px;

  ${responsive.sm`
    width: unset;
    margin: 40px auto;
    padding: 24px 60px;
    max-width: 340px;
  `}

  ${responsive.md`
    margin: 56px auto;
    padding: 56px 80px;
    max-width: 454px;
  `}

  ${responsive.lg`
    padding: 56px 100px;
    max-width: 570px;
  `}
`;

export default class AuthenticateContainer extends Component {
  render() {
    const { children } = this.props;
    return <AuthContainer>{children}</AuthContainer>;
  }
}
