import styled, { keyframes } from "styled-components";
import AsyncRitualButton from "../global/AsyncRitualButton";
import Currency from "../Currency";
import StrikeText from "../global/StrikeText";
import { getPromotionDetails } from "../../utils/promotion";

type Props = {
  className?: string;
  prefix: string;
  price?: number;
  showPricing?: boolean;
  discountPrice?: number;
  isVisible?: boolean;
  promotion?: any;
  basePrice?: number;
};

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

const PrefixAnimation = keyframes`
  0% {
    left: 50%;
    transform: translateX(-50%);
  }
  100% {
    left: 0;
    transform: translateX(0);
  }
`;

const PrefixText = styled.span`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  &.animate {
    animation: ${PrefixAnimation} 0.5s 0.5s forwards;
  }
`;

const PricingAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const PricingText = styled.span`
  opacity: 0;

  &.animate {
    animation: ${PricingAnimation} 0.5s 0.5s forwards;
  }
`;

const DiscountButtonAnimated = (props: Props) => {
  const {
    className,
    prefix,
    price,
    discountPrice,
    isVisible,
    promotion,
    basePrice,
  } = props;
  const promotionDetails = getPromotionDetails(promotion, price);

  let promotionPrice = discountPrice || price;
  if (promotion && discountPrice) {
    promotionPrice = discountPrice * (1 - promotion.percentOff / 100);
  } else if (promotionDetails?.discountPrice) {
    promotionPrice = promotionDetails.discountPrice;
  }

  return (
    <AsyncRitualButton {...props} className={className}>
      <ButtonWrapper>
        <PrefixText className={isVisible ? "animate" : ""}>{prefix}</PrefixText>
        <PricingText className={isVisible ? "animate" : ""}>
          {basePrice && basePrice !== price && (
            <StrikeText>
              {Currency({ value: basePrice, round: true })}
            </StrikeText>
          )}
          {!basePrice && price && price !== promotionPrice && (
            <StrikeText>{Currency({ value: price, round: true })}</StrikeText>
          )}
          {typeof promotionPrice === "number"
            ? Currency({ value: promotionPrice, round: true })
            : promotionPrice}
        </PricingText>
      </ButtonWrapper>
    </AsyncRitualButton>
  );
};

export default DiscountButtonAnimated;
