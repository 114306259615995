import { Component } from "react";
import styled, { keyframes } from "styled-components";
import intl from "../../services/intl";
import { Icons } from "../../utils/react-svg";
import { Stickers } from "../../utils/svg";
import sanitizeHtml from "isomorphic-html-sanitize";
import { marked } from "marked";
import $ from "jquery";
import {
  Font,
  Color,
  rem,
  media,
  capitalize,
  Opacity,
} from "../../utils/style";
import Row from "../Row";
import Text from "../Text";
import DateTimeFormat from "../DateTimeFormat";
import ReviewSchema from "./ReviewSchema";
import ReviewStars from "./ReviewStars";
import { MagicSVGImage } from "../MagicSVGImage";

const MAX_COMMENT_LENGTH = 350;

const animateIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ReviewRow = styled(Row)`
  position: relative;
  ${Font.circular};
  padding: 56px 0;
  animation: ${animateIn} 1.2s ease-out forwards;

  ${media.tablet`
    padding: 40px 0px;
  `};

  ${media.tablet`
    padding: 40px 0px;
  `};

  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    border-top: 1px solid #ccc;

    ${media.mobile`
      left: 15px;
      right: 15px;
    `};
  }

  &::before {
    top: 0;
    ${(p) => (p.isFirst ? "" : "display: none")};
  }

  &::after {
    bottom: 0;
  }
`;

const UserColumn = styled.div.attrs({
  className: "col-12 col-sm-5 col-md-3 d-flex pl-sm-0 mb-md-0",
})`
  margin-bottom: 40px;

  p {
    margin: 0;
  }
`;

const ReviewColumn = styled.div.attrs({
  className: "col-12 col-sm-7 col-md-9 d-flex pr-sm-0 flex-column",
})``;

const StarWrapper = styled.div.attrs({
  className: "d-flex",
})`
  .ReviewStars--star {
    width: 17px;
    height: 16px;
    margin-right: 0;

    ${media.tablet`
      width: 17px;
      height: 16px;
    `};
  }
`;

const ReviewDetails = styled.div.attrs({
  className: "d-flex flex-column flex-md-row justify-content-between",
})``;

const UserSection = styled.div.attrs({
  className: "d-flex flex-column ml-3",
})`
  p {
    margin: 0;
    font-size: ${rem(14)};
    line-height: ${rem(24)};
    font-weight: 500;
  }
`;

const ReviewTitle = styled.h2.attrs({
  className: "mt-3 mb-2",
})`
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  letter-spacing: -0.2px;

  ${media.tablet`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
    letter-spacing: 0;
  `};
`;

const ReviewDate = styled.p.attrs({
  className: "m-0 mb-3",
})`
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-weight: 300;
`;

const ReviewActionItemLabel = styled.p.attrs({
  className: "mb-2 mb-md-0",
})`
  font-size: ${rem(14)};
  line-height: ${rem(20)};
`;

const ReviewSticker = styled.div.attrs({
  className: "position-relative",
})`
  width: 72px;
  height: 72px;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const ReviewComment = styled.div.attrs({
  className: "mb-4",
})`
  padding: 40px;
  background: rgba(233, 238, 241, ${Opacity.light});

  ${media.tablet`
    padding: 24px;
  `} h2 {
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  }
`;

const ReviewContentWrapper = styled.div.attrs({
  className: "mb-3",
})``;

const ReviewContent = styled.div`
  ${Font.dutch};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: normal;
  display: inline;
`;

const ReadMoreButton = styled.a`
  ${Font.dutch};
  display: inline;
  line-height: ${rem(26)};
  border-bottom: 1px solid ${Color.ritualBlue};
  cursor: pointer;
  white-space: nowrap;
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: ${Opacity.light};
  }

  > span {
    display: inline;
    border-bottom: 1px solid ${Color.white};
  }
`;

const ReviewCommentContent = styled.p.attrs({
  className: "m-0",
})`
  ${Font.dutch};
  font-size: ${rem(16)};
  line-height: ${rem(26)};

  a {
    font-weight: 500;
    border-bottom: 2px solid ${Color.ritualBlue};
    text-decoration: none;
    cursor: pointer;
    font-size: inherit;
    line-height: 1em;
    transition: opacity 200ms ease-in-out;

    &:hover {
      opacity: ${Opacity.light}s;
    }
  }
`;

const ReviewVotes = styled.span.attrs({
  className: "d-flex align-items-center justify-content-center",
})`
  margin-right: 24px;
  cursor: default;

  &:last-child {
    margin-right: 0;
  }
`;

const VoteButton = styled.div.attrs({
  className: "position-relative",
})`
  width: 24px;
  height: 24px;
  padding: 3px;
  margin-right: 5px;
  margin-left: -3px;
  user-select: none;
  ${(p) => (p.voted ? `cursor: default;` : `cursor: pointer;`)} .isvg {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;

    ${(p) =>
    p.voted
      ? `
          svg {
            path {
              fill: ${Color.ritualDisabledBlue};
            }
          }
        `
      : `
          &:hover {
            svg {
              path {
                fill: ${Color.ritualDisabledBlue};
              }
            }
          }
        `};

    svg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      path {
        transition: all 0.2s ease-in-out;
      }
    }
  }
`;

const VoteCount = styled.span`
  font-size: ${rem(16)};
  line-height: ${rem(22)};
  font-weight: 300;
  user-select: none;
  margin-top: -1px;
`;

const WouldRecommend = styled.p.attrs({
  className: "mb-4",
})`
  ${Font.dutch};

  span {
    ${Font.circular};
    font-weight: 500;
  }
`;

const ProductGifted = styled.p.attrs({
  className: "m-0",
})`
  color: rgba(20, 43, 111, ${Opacity.light});
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-weight: 300;
`;

const VerifiedBuyer = styled.span.attrs({
  className:
    "position-absolute d-flex align-items-center justify-content-center",
})`
  width: 20px;
  height: 20px;
  background-color: ${Color.ritualBlue};
  border-radius: 50%;
  right: 0;
  bottom: 0;
  cursor: pointer;

  .isvg {
    position: relative;
    width: 10px;
    height: 8px;

    svg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      g {
        fill: ${Color.white};
      }
    }
  }
`;

const VerifiedTooltip = styled.div.attrs({
  className: "position-absolute p-3",
})`
  top: calc(100% + 13px);
  left: 0;
  width: 200px;
  border: 1px solid ${Color.ritualBlue};
  background-color: ${Color.white};
  z-index: 100;

  &::before {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: ${Color.white};
    border-top: 1px solid ${Color.ritualBlue};
    border-right: 1px solid ${Color.ritualBlue};
    transform: rotate(-45deg);
    top: -9px;
    left: 54px;
  }

  p {
    font-size: ${rem(12)};
    line-height: ${rem(18)};
  }
`;

const SubscriptionAge = styled.p.attrs({
  className: "p-0",
})`
  span {
    display: inline-block;
  }
`;

export default class UserReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayVerifiedTooltip: false,
      displayLongContent: props.content.length < MAX_COMMENT_LENGTH,
      votes_up: props.votes_up,
      votes_down: props.votes_down,
      voted: false,
    };
  }

  renderUserSection() {
    let { subscription_age, user, productName, age_range } = this.props;
    let months = subscription_age % 12;
    let years = (subscription_age - months) / 12;

    return (
      <UserSection>
        <p>{user.display_name}</p>
        {age_range && (
          <p>
            <Text
              id="reviews.user.age-range"
              defaultMessage="Age {range}"
              values={{ range: age_range }}
            />
          </p>
        )}
        <SubscriptionAge>
          {subscription_age && subscription_age !== "0" ? (
            <Text
              id="reviews.user.subscription-age-copy"
              defaultMessage={`{age} on {productName}`}
              values={{
                age: (
                  <span>
                    <Text
                      id="reviews.user.subscription-age-format"
                      defaultMessage={`
                      {years, plural, =0 {} one {1 year } other {{years} years }
                      }{months, plural, =0 {} one {1 month} other {{months} months}}`}
                      values={{ years, months }}
                    />
                  </span>
                ),
                productName,
              }}
            />
          ) : (
            productName
          )}
        </SubscriptionAge>
      </UserSection>
    );
  }

  renderSticker() {
    const { sticker, verified_buyer } = this.props;
    const stickerName = (sticker && sticker.replace(/-/gi, "")) || "";

    // Check for sticker name, otherwise pick a random sticker from Stickers
    const stickerSVG =
      Stickers?.[capitalize(stickerName)] ||
      Stickers?.[
        capitalize(
          Object.keys(Stickers)[
            Math.floor(Math.random() * Object.keys(Stickers).length)
          ],
        )
      ];

    return (
      <div>
        <ReviewSticker>
          {stickerSVG && (
            <MagicSVGImage
              width={72}
              height={72}
              alt={stickerName}
              src={stickerSVG}
            />
          )}
          {verified_buyer && this.renderVerifiedIcon()}
        </ReviewSticker>
      </div>
    );
  }

  renderVerifiedIcon() {
    let { productName } = this.props;
    let { displayVerifiedTooltip } = this.state;
    return (
      <div>
        <VerifiedBuyer
          onMouseEnter={this.handleTooltip.bind(this, true)}
          onMouseLeave={this.handleTooltip.bind(this, false)}
        >
          <span className="isvg">
            <Icons.Check />
          </span>
        </VerifiedBuyer>
        {displayVerifiedTooltip && (
          <VerifiedTooltip role="tooltip" aria-label={`This subscriber purchased ${productName} — we checked.`}>
            <p>
              <Text
                id="reviews.verified-purchase-copy"
                defaultMessage="This subscriber purchased {productName} — we checked."
                values={{
                  productName,
                }}
              />
            </p>
          </VerifiedTooltip>
        )}
      </div>
    );
  }

  handleTooltip(show) {
    this.setState({
      displayVerifiedTooltip: show,
    });
  }

  handleReadMoreClick() {
    this.setState({
      displayLongContent: true,
    });
  }

  handleUpvote() {
    let { votes_up } = this.props;
    if (votes_up === this.state.votes_up && !this.state.voted) {
      this.setState({ voted: true });
      this.sendVote("up")
        .then(() => {
          this.setState({
            votes_up: this.state.votes_up + 1,
          });
        })
        .catch((err) => {
          /* eslint no-console: ["error", { allow: ["error"] }] */
          console.error(err);
          this.setState({
            voted: false,
          });
        });
    }
  }

  handleDownvote() {
    let { votes_down } = this.props;
    if (votes_down === this.state.votes_down && !this.state.voted) {
      this.setState({ voted: true });
      this.sendVote("down")
        .then(() => {
          this.setState({
            votes_down: this.state.votes_down + 1,
          });
        })
        .catch((err) => {
          /* eslint no-console: ["error", { allow: ["error"] }] */
          console.error(err);
          this.setState({
            voted: false,
          });
        });
    }
  }

  sendVote(type) {
    let { id } = this.props;
    return $.post(`https://api.yotpo.com/reviews/${id}/vote/${type}`);
  }

  formatCommentContent(content) {
    let sanitizedContent = sanitizeHtml(content, { allowedTags: [] });
    let markdownConvertedContent = marked.parseInline(sanitizedContent);
    return markdownConvertedContent;
  }

  renderComment() {
    let { comment } = this.props;
    if (!comment || (comment && !comment.content)) {
      return null;
    }

    let content = this.formatCommentContent(comment.content);

    return (
      <ReviewComment>
        <h2 className="mb-1">
          <Text
            id="reviews.comment-ritual-response"
            defaultMessage="Ritual Response"
          />
        </h2>
        <ReviewDate>
          <DateTimeFormat value={comment.created_at} />
        </ReviewDate>
        <ReviewCommentContent dangerouslySetInnerHTML={{ __html: content }} />
      </ReviewComment>
    );
  }

  renderContent() {
    let content = this.props.content;
    if (!this.state.displayLongContent) {
      content = content.slice(0, 250).concat("&#8230; ");
    }

    return (
      <ReviewContentWrapper>
        <ReviewContent dangerouslySetInnerHTML={{ __html: content }} />
        {!this.state.displayLongContent && this.renderReadMoreButton()}
      </ReviewContentWrapper>
    );
  }

  renderReadMoreButton() {
    return (
      <ReadMoreButton onClick={this.handleReadMoreClick.bind(this)}>
        <Text id="general.read-more" defaultMessage="Read More" />
        <span>.</span>
      </ReadMoreButton>
    );
  }

  render() {
    let {
      created_at,
      score,
      title,
      would_recommend,
      received_product,
      index,
      sticker,
    } = this.props;
    let { votes_up, votes_down, voted } = this.state;

    title = sanitizeHtml(title, { allowedTags: [] });
    const wouldRecommend = would_recommend?.toLowerCase() || false;
    const thirdPartyReview = !sticker; // Imported reviews do not have associated sticker
    const gifted = received_product === "Yes";

    return (
      <ReviewRow isFirst={index === 0}>
        <ReviewSchema {...this.props} />
        <UserColumn>
          {this.renderSticker()}
          {this.renderUserSection()}
        </UserColumn>

        <ReviewColumn>
          <StarWrapper>
            <ReviewStars score={score} />
          </StarWrapper>

          <ReviewTitle dangerouslySetInnerHTML={{ __html: title }} />

          <ReviewDate>
            <DateTimeFormat value={created_at} />
          </ReviewDate>

          {this.renderContent()}

          {wouldRecommend && (
            <WouldRecommend>
              <Text
                id="reviews.would-recommend"
                defaultMessage={`{recommendation}, {wouldRecommend, select, yes {I'd} other {I wouldn't}} tell a friend to take this product.`}
                values={{
                  wouldRecommend,
                  recommendation: (
                    <span>
                      <Text
                        id="reviews.recommendation"
                        defaultMessage={`{wouldRecommend, select, yes {Yes} other {No}}`}
                        values={{
                          wouldRecommend,
                        }}
                      />
                    </span>
                  ),
                }}
              />
            </WouldRecommend>
          )}

          {this.renderComment()}

          <ReviewDetails>
            <div className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
              <ReviewActionItemLabel
                aria-label={intl.t(
                  "reviews.review-helpful",
                  "Is this review helpful?",
                )}
                role="label"
              >
                <Text
                  id="reviews.review-helpful"
                  defaultMessage="Is this review helpful?"
                />
              </ReviewActionItemLabel>
              <span className="d-flex justify-content-start ml-0 ml-sm-4 mb-2 mb-md-0">
                <ReviewVotes>
                  <VoteButton
                    voted={voted}
                    onClick={this.handleUpvote.bind(this)}
                    aria-label="Upvote this user review"
                  >
                    <span className="isvg">
                      <Icons.Upvote alt={`is this helpful upvote button`} />
                    </span>
                  </VoteButton>
                  <VoteCount>{votes_up}</VoteCount>
                </ReviewVotes>

                <ReviewVotes>
                  <VoteButton
                    voted={voted}
                    onClick={this.handleDownvote.bind(this)}
                    aria-label="Downvote this user review"
                  >
                    <span className="isvg">
                      <Icons.Downvote alt={`is this helpful downvote button`} />
                    </span>
                  </VoteButton>
                  <VoteCount>{votes_down}</VoteCount>
                </ReviewVotes>
              </span>
            </div>
            {gifted && (
              <ProductGifted>
                <Text
                  id="reviews.product-gifted"
                  defaultMessage="This customer received a benefit in exchange for a review."
                />
              </ProductGifted>
            )}
            {thirdPartyReview && !gifted && (
              <ProductGifted>
                Originally shared on a verified site.
              </ProductGifted>
            )}
          </ReviewDetails>
        </ReviewColumn>
      </ReviewRow>
    );
  }
}
