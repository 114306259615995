import $ from "jquery";
import { Component } from "react";
import styled from "styled-components";

// Services
import intl from "../../services/intl";

// Components
import Container from "../Container";
import MagicSelect from "../MagicSelect";
import CompactAggregatedStarRating from "../reviews/CompactAggregatedStarRating";
import ReviewCTA from "../reviews/ReviewCTA";
import ReviewPagination from "../reviews/ReviewPagination";
import Row from "../Row";

// Utils
import { getStoredUser } from "../../utils/currentUser";
import metrics from "../../utils/metrics";
import { atMost, responsive } from "../../utils/style";
import translateReview from "../../utils/translateReview";
import UserReview from "../reviews/UserReview";

const ReviewsContainer = styled(Container)`
  overflow: unset !important;
`;

const ReviewsSection = styled.section.attrs((p) => ({
  id: "reviews",
  className: `${p.bottomPadding ? "" : "pb-0"}`,
  "aria-label": "Reviews",
}))`
  padding: 80px 0;

  .dropdown-container {
    justify-content: flex-end;
  }
`;

const ProductDetails = styled.div.attrs({
  className: "col-12",
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin-bottom: 40px;

  ${responsive.sm`
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  `}

  ${responsive.md`
    margin-bottom: 56px;
  `}
`;

const ProductSelectorWrapper = styled.div`
  flex: 1 1 100%;
  display: ${(p) => (p.shouldDisplay ? "flex" : "none")};
  width: 100%;
  flex-direction: column;

  ${responsive.sm`
    display: flex;
    flex: 1 1 50%;
    justify-content: flex-end;
    padding-right: 16px;
  `}

  ${responsive.md`
    flex: 1 1 33%;
    justify-content: flex-start;
    padding-right: 0;
  `}
`;

const ProductSelector = styled.div`
  width: 100%;
  padding: 0 15px;

  ${responsive.sm`
    padding: 0;
    width: 270px;
  `}
`;

const StarRatingWrapper = styled.div`
  flex: 1 1 100%;
  order: -1;
  margin-bottom: 32px;
  display: ${(p) => (p.shouldDisplay ? "flex" : "none")};

  ${responsive.sm`
    display: unset;
  `};

  ${responsive.md`
    flex: 1 1 33%;
    order: 0;
    margin-bottom: 0;
  `}
`;

const SortAreaWrapper = styled.div`
  flex: 1 1 100%;
  display: flex;
  width: 100%;

  ${responsive.sm`
    flex: 1 1 50%;
    padding-left: 16px;
  `}

  ${responsive.md`
    flex: 1 1 33%;
    justify-content: flex-end;
    padding-left: 0;
  `}
`;

const SortArea = styled.div`
  text-align: right;
  width: 100%;
  padding: 0 15px;

  ${responsive.sm`
    width: 270px;
    padding: unset;
  `}
`;

export default class Reviews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalPages: null,
      reviews: props.reviews,
      sort: "rating",
      direction: "desc",
    };

    this.productSkus = {};
    // The products prop is not defined on the PDPs.
    if (props.products) {
      props.products.forEach((product, i) => {
        this.productSkus[product.sku] = i;
      });
    }
  }

  changeProduct(event) {
    if (this.productSkus[event] > -1) {
      this.props.onProductSelect(this.productSkus[event]);
    }
  }

  changeSort(value) {
    const [sort, direction] = value.split("-");
    this.setState({ sort, direction });
    setTimeout(() => {
      this.pagination.reset();
      this.getReviews(false, 1);
    }, 0);
    this.trackChangeReviewSort(value);
  }

  trackChangeReviewSort(option) {
    const event = {
      option,
    };
    metrics.track("Review Sorting Toggled", event);
  }

  getReviews(scrollToTop, page) {
    let { productId } = this.props;
    let { sort, direction } = this.state;
    let yotpoDomain = process.env.GATSBY_YOTPO_DOMAIN;
    let yotpoAppKey = process.env.GATSBY_YOTPO_APP_KEY;

    $.get(
      `https://api.yotpo.com/v1/widget/${yotpoAppKey}/products/${productId}/reviews.json?page=${page}&sort=${sort}&direction=${direction}`,
      (data) => {
        let totalPages = Math.ceil(
          data.response.pagination.total / data.response.pagination.per_page,
        );
        let reviews = data.response.reviews.map((r) =>
          translateReview(yotpoDomain, r),
        );
        this.setState({
          totalPages: totalPages,
          reviews: reviews,
        });
        if (scrollToTop) {
          this.scrollToTop();
        }
      },
    );
  }

  scrollToTop() {
    let navOffset = atMost.tablet() ? 52 : 128;
    $("html,body").animate(
      {
        scrollTop: this.reviewsContainer.offsetTop - navOffset,
      },
      1000,
    );
  }

  componentDidMount() {
    this.getReviews(false, 1);
    // TODO: Update to isLoggedIn selector from users store.
    this.setState({ loggedIn: !!getStoredUser() });
  }

  optionGroupsData() {
    return this.props.categoryMap.map((category) => {
      return {
        label: category.name,
        options: category.products.map((product) => {
          return {
            value: product.sku,
            title: product.simpleName,
          };
        }),
      };
    });
  }

  getCurrentOption() {
    // return the option from optionGroupsData that matches the activeProduct
    const activeProduct = this.props.products[this.props.selectedProductIndex];
    const optionGroupsData = this.optionGroupsData();

    let matches = [];

    optionGroupsData.forEach(function (group) {
      matches = matches.concat(
        group.options.filter(function (option) {
          return option.value === activeProduct.sku;
        }),
      );
    });
    return matches[0];
  }

  render() {
    let {
      productId,
      productUrl,
      productName,
      reviewScore,
      reviewCount,
      bottomPadding = true,
      seeAllReviewsCta = false,
      isProductPage = false,
    } = this.props;

    let { reviews, totalPages, loggedIn } = this.state;

    const sortByOptions = [
      {
        value: "rating-desc",
        title: intl.t("product.review.rating-desc", "Highest Rated"),
      },
      {
        value: "rating-asc",
        title: intl.t("product.review.rating-asc", "Lowest Rated"),
      },
      {
        value: "date-desc",
        title: intl.t("product.review.date-desc", "Most Recent"),
      },
      {
        value: "votes_up-desc",
        title: intl.t("product.review.votes-up-desc", "Most Helpful"),
      },
      {
        value: "votes_down-desc",
        title: intl.t("product.review.votes-down-desc", "Least Helpful"),
      },
    ];

    const currentSortDirection = [this.state.sort, this.state.direction].join(
      "-",
    );
    const currentOption = sortByOptions.find(
      (o) => o.value === currentSortDirection,
    );

    return (
      <ReviewsSection
        bottomPadding={bottomPadding}
        isProductPage={isProductPage}
        ref={(r) => {
          this.reviewsContainer = r;
        }}
      >
        {this.props.children}

        <ReviewsContainer>
          <Row className={"dropdown-container"}>
            <ProductDetails>
              <ProductSelectorWrapper shouldDisplay={!isProductPage}>
                {!isProductPage && (
                  <>
                    <ProductSelector>
                      <MagicSelect
                        label={intl.t(
                          "product.reviews.select-label",
                          "Reviews for",
                        )}
                        optionGroups={this.optionGroupsData()}
                        currentOption={this.getCurrentOption()}
                        onSelectionMade={this.changeProduct.bind(this)}
                      />
                    </ProductSelector>
                    <ReviewCTA
                      productId={productId}
                      loggedIn={loggedIn}
                      className={"mt-3 px-3 px-sm-0"}
                    />
                  </>
                )}
              </ProductSelectorWrapper>
              <StarRatingWrapper shouldDisplay={!isProductPage}>
                {!isProductPage && (
                  <CompactAggregatedStarRating
                    score={reviewScore}
                    totalReviews={reviewCount}
                    productId={productId}
                  />
                )}
              </StarRatingWrapper>
              <SortAreaWrapper>
                <SortArea className="mt-4 mt-sm-0">
                  <MagicSelect
                    label={intl.t("product.reviews.sort-by", "Sort by")}
                    options={sortByOptions}
                    currentOption={currentOption}
                    onSelectionMade={this.changeSort.bind(this)}
                  />
                </SortArea>
              </SortAreaWrapper>
            </ProductDetails>
          </Row>

          {reviews.map((review, i) => {
            return (
              <UserReview
                key={"review-" + review.id}
                index={i}
                productName={productName}
                productUrl={productUrl}
                {...review}
              />
            );
          })}
          <ReviewPagination
            ref={(r) => {
              this.pagination = r;
            }}
            onPageSelect={this.getReviews.bind(this, true)}
            totalPages={totalPages}
            loggedIn={loggedIn}
            productId={this.props.productId}
            seeAllReviewsCta={seeAllReviewsCta}
          />
        </ReviewsContainer>
      </ReviewsSection>
    );
  }
}
