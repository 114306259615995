import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import classNames from "classnames";
import { Icons } from "../../utils/react-svg";
import { Font, Opacity, rem, responsive, rgba } from "../../utils/style";
import { Color } from "../../utils/styleDesignSystem";
import { IGatsbyImageData } from "gatsby-plugin-image";
import {
  ContentfulImage,
  StudyResult,
  SupplementFactsType,
  ValuePropType,
  HowToUse,
  ProductStudy,
} from "../../utils/productTypes";

const ModalContentWrapper = styled.div`
  hr {
    border-top-width: 1px;
    align-self: stretch;
    background: ${Color.indigoBlue20};
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  &.single-ingredient {
    margin-bottom: unset;
    text-wrap: balance;

    ${responsive.sm`
      text-wrap: pretty;
    `}
  }
`;

const Content = styled.div`
  margin-bottom: var(--spacing-2, 32px);

  p {
    ${Font.dutch};
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  }

  a,
  a:hover {
    text-decoration: underline;
  }

  .light {
    color: rgba(20, 43, 111, ${Opacity.light});
    font-weight: 300;
  }
`;

const StudyResultsContentContainer = styled.div`
  margin: var(--spacing-2) 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
`;

const StudySummaryContentContainer = styled.div`
  margin-top: var(--spacing-2);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
`;

const StudyDescription = styled.div.attrs({
  className: "typography-body1"
})`

  p {
    margin: var(--spacing-2, 32px) 0;
  }

  p.withImage {
    margin-bottom: 12px;
  }

  &.single-ingredient {
    margin-bottom: unset;

    p {
      font-size: ${rem(16)};
      font-weight: 400;
      line-height: 26px;
    }
  }

  ${responsive.md`
    p.withImage {
      font-size: ${rem(18)};
    }
  `};
`;

const StudyResults = styled.div`
  h2 {
    font-size: ${rem(14)};
    line-height: ${rem(22)};
    text-transform: uppercase;
    margin: 0 0 8px 0;
    color: ${rgba(Color.indigoBlue, Opacity.light)};

    ${responsive.md`
      margin-bottom: 16px;
    `};
  }

  ul {
    list-style: none;
    padding: 0;
    margin-bottom: unset;

    li {
      margin: 0 0 24px 0;
      display: flex;

      &:last-child {
        margin-bottom: 0;
      }

      svg {
        margin-right: 10px;
        width: 15px;
        flex: 0 0 auto;
      }

      p {
        ${Font.circular};
        font-size: ${rem(16)};
        line-height: ${rem(22)};
        margin: 0;
      }
    }
  }
`;

const StudySummary = styled.div`
  h3 {
    font-size: 1rem;
    line-height: 1.625rem;
    margin: 0 0 8px;
  }

  p {
    margin: unset;

    &.protein {
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    }
  }
`;

const ImageWrapper = styled.div`
  flex-shrink: 0;

  width: 84px;
  height: 96px;
  margin-left: 24px;

  ${responsive.md`
    width: 108px;
    height: 124px;
    margin-left: 40px;
  `}
`;

const IconWrapper = styled.div`
  margin-right: 8px;
`;

const DesktopImage = styled(GatsbyImage)`
  display: none !important;

  ${responsive.sm`
    width: 100%;
    height: auto;
    display: block !important;
    margin: var(--spacing-2, 32px) 0;
  `};
`;

const MobileImage = styled(GatsbyImage)`
  margin: var(--spacing-2) 0 0;

  ${responsive.sm`
    display: none !important;
  `};
`;

const Disclaimer = styled.div.attrs({
  className: "typography-caption"
})`
  margin: var(--spacing-2, 32px) 0 var(--spacing-3, 48px);
  li {
    color: ${Color.indigoBlue40};
    list-style-type: none;
  }

  a {
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }
`;

type ContentfulProduct = {
  sku: string;
  name: { name: string };
  pageDescription: string;
  category: string;
  heroZoomImages: ContentfulImage[];
  heroFeaturedImages: ContentfulImage[];
  timelineHeading: string;
  timelineSubhead: string;
  timelinePhoto: IGatsbyImageData;
  timelineVideo: IGatsbyImageData;
  timelineData: {
    childMarkdownRemark: {
      rawMarkdownBody: string;
    };
  };
  supplementFacts: SupplementFactsType;
  valueProps: ValuePropType[];
  howToUse: HowToUse;
  productStudyModal: ProductStudy;
};

type Props = {
  productData: ContentfulProduct;
};

const ProductStudyModal = ({ productData }: Props) => {
  const { productStudyModal, category } = productData;

  const {
    headerImage,
    studyDescriptionImage,
    mobileStudyDescriptionImage,
    content,
  } = productStudyModal;

  const {
    studyDescription,
    studyResults,
    secondaryStudyResults,
    studySummary,
    disclaimer,
  } = content;

  const headerImageData = headerImage && {
    image: getImage(headerImage),
    description: headerImage.description,
  };
  const studyDescriptionImageData = studyDescriptionImage && {
    image: getImage(studyDescriptionImage),
    description: studyDescriptionImage.description,
  };
  const mobileStudyDescriptionImageData = mobileStudyDescriptionImage && {
    image: getImage(mobileStudyDescriptionImage),
    description: mobileStudyDescriptionImage.description,
  };
  const isProteinProduct = category.includes("Protein");
  const hasStudyResults = studyResults && studyResults.length > 0;
  const hasSecondaryStudyResults =
    secondaryStudyResults && secondaryStudyResults.length > 0;
  
  const footnotes = disclaimer?.split("<br/>");
  const showStudyResults = hasStudyResults || hasSecondaryStudyResults;

  return (
    <>
      <TitleWrapper
        data-test-title-wrapper
        className={classNames({
          useSingleIngredientStyle: "single-ingredient",
        })}
      >
        {headerImageData && (
          <ImageWrapper>
            <GatsbyImage
              image={headerImageData.image}
              alt={headerImageData.description}
              style={{
                userSelect: "none",
                userDrag: "none",
                pointerEvents: "none",
                touchCallout: "none",
                width: "100%",
                height: "100%",
              }}
              imgStyle={{ objectFit: "contain" }}
            />
          </ImageWrapper>
        )}
      </TitleWrapper>

      <ModalContentWrapper>
        <Content>
          {(studyDescription || studyDescriptionImage) && (
            <StudyDescription
              className={classNames({
                useSingleIngredientStyle: "single-ingredient",
              })}
            >
              {studyDescription && (
                <p
                  className={classNames({ "withImage": studyDescriptionImage })}
                  dangerouslySetInnerHTML={{ __html: studyDescription }}
                />
              )}
              {mobileStudyDescriptionImage && (
                <MobileImage
                  id="clinical-study_study-description_mobile-image"
                  image={mobileStudyDescriptionImageData.image}
                  alt={mobileStudyDescriptionImageData.description}
                  imgStyle={{ objectFit: "contain" }}
                  style={{
                    userSelect: "none",
                    userDrag: "none",
                    pointerEvents: "none",
                    touchCallout: "none",
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
              {studyDescriptionImage && (
                <DesktopImage
                  id="clinical-study_study-description_desktop-image"
                  image={studyDescriptionImageData.image}
                  alt={studyDescriptionImageData.description}
                  imgStyle={{ objectFit: "contain" }}
                  style={{
                    userSelect: "none",
                    userDrag: "none",
                    pointerEvents: "none",
                    touchCallout: "none",
                    width: "100%",
                    height: "100%",
                  }}
                />
              )}
              {hasStudyResults && <hr />}
            </StudyDescription>
          )}

          {showStudyResults && (
            <StudyResultsContentContainer>
              {studyResults &&
                studyResults.map((studyResult: StudyResult, i: number) => {
                  return (
                    <StudyResults key={i}>
                      <h2>{studyResult?.title}</h2>
                      <ul>
                        {studyResult.results.map(
                          (result: string, i: number) => {
                            return (
                              <li key={i}>
                                <IconWrapper>
                                  <Icons.ArrowRoundedUp />
                                </IconWrapper>
                                <p
                                  dangerouslySetInnerHTML={{ __html: result }}
                                ></p>
                              </li>
                            );
                          },
                        )}
                      </ul>
                    </StudyResults>
                  );
                })}
              {hasSecondaryStudyResults &&
                secondaryStudyResults.map(
                  (studyResult: StudyResult, i: number) => {
                    return (
                      <StudyResults key={i}>
                        <h2>{studyResult.title}</h2>
                        <ul>
                          {studyResult.results.map(
                            (result: string, i: number) => {
                              return (
                                <li key={i}>
                                  <IconWrapper>
                                    <Icons.CheckRounded />
                                  </IconWrapper>
                                  <p>{result}</p>
                                </li>
                              );
                            },
                          )}
                        </ul>
                      </StudyResults>
                    );
                  },
                )}
            </StudyResultsContentContainer>
          )}
          {showStudyResults && <hr className="results-block"/>}

          {studySummary && (
            <StudySummaryContentContainer>
              {studySummary.map((block: any, i: number) => {
                return (
                  <StudySummary key={i}>
                    <h3>{block?.title}</h3>
                    <p
                      className={classNames({"protein": isProteinProduct})}
                      dangerouslySetInnerHTML={{ __html: block.content }}
                    ></p>
                  </StudySummary>
                );
              })}
            </StudySummaryContentContainer>
          )}
        </Content>
        {disclaimer && <hr className="disclaimer-block"/>}

        {footnotes && (
          <Disclaimer>
            {footnotes.map((footnote, index) => {
              return (
                <>
                  <li key={index}>{footnote}</li>
                  <br/>
                </>
              )
            })}
          </Disclaimer>
        )}
      </ModalContentWrapper>
    </>
  );
};

export default ProductStudyModal;
