/**
 * Structure
 * ---------
 *
 * stockStatus: // "preorder", "out of stock"
 *  product.sku: // "E50PLUS60"
 *    modal:
 *      title: {String},
 *      copy: {Array},
 *    completeModal:
 *      title: {String},
 *      copy: {Array},
 *      ctaModule: {Object}:
 *        title: {String}
 *        copy: {Array}
 *        cta: {String}
 *        url: {String}
 *    iterable: // Used for iterable email lists
 *      optIn: {String},
 *      optOut: {String},
 */
const ModalCopy = {
  "out of stock": {
    EPRE60: {
      modal: {
        title: "stock-status.EPRE60.modal.title",
        copy: {
          copy: "stock-status.EPRE60.modal.copy",
          emoji: "Peace",
        },
        cta: "stock-status.EPRE60.cta",
      },
      completeModal: {
        title: "stock-status.EPRE60.complete-modal.title",
        copy: "stock-status.EPRE60.complete-modal.copy",
        ctaModule: {
          title: "stock-status.EPRE60.complete-modal.cta-module.title",
          copy: "stock-status.EPRE60.complete-modal.cta-module.copy",
          cta: "stock-status.EPRE60.complete-modal.cta-module.cta",
          url: "/products/essential-for-women-multivitamin",
        },
      },
      iterable: {
        optIn: "5c8620f9-56e2-42ae-bca3-cfbea0996efd",
        optOut: "50b5bd29-2444-49df-b80e-7eb2941d351e",
      },
    },
    EFKIDS90: {
      modal: {
        title: "stock-status.EFKIDS90.modal.title",
        copy: {
          copy: "stock-status.EFKIDS90.modal.copy",
          emoji: "Peace",
        },
        cta: "stock-status.EFKIDS90.cta",
      },
      completeModal: {
        title: "stock-status.EFKIDS90.complete-modal.title",
        copy: "stock-status.EFKIDS90.complete-modal.copy",
        ctaModule: {
          title: "stock-status.EFKIDS90.complete-modal.cta-module.title",
          copy: "stock-status.EFKIDS90.complete-modal.cta-module.copy",
          cta: "stock-status.EFKIDS90.complete-modal.cta-module.cta",
          url: "/vitamins-for-kids",
        },
      },
      iterable: {
        optIn: "d94b799a-ae0e-4444-b5d0-a9fa4a073453",
        optOut: "aad17e4d-c5ae-4fea-88fa-f67749d7ab07",
      },
    },
    EFM50PLUS60: {
      modal: {
        title: "stock-status.EFM50PLUS60.modal.title",
        copy: {
          copy: "stock-status.EFM50PLUS60.modal.copy.copy",
          emoji: "Peace",
        },
        cta: "stock-status.EFM50PLUS60.modal.cta",
      },
      completeModal: {
        title: "stock-status.EFM50PLUS60.complete-modal.title",
        copy: "stock-status.EFM50PLUS60.complete-modal.copy",
        ctaModule: undefined,
      },
      iterable: {
        optIn: "62dc2774-d9ee-4bc2-baa0-4fef6eb8a08c",
        optOut: "53ae091d-4ef5-4aa6-b7ee-7ba9ba9a435a",
      },
    },
    E50PLUS60: {
      modal: {
        title: "stock-status.E50PLUS60.modal.title",
        copy: {
          copy: "stock-status.E50PLUS60.modal.copy.copy",
          emoji: "Peace",
        },
        cta: "stock-status.E50PLUS60.modal.cta",
      },
      completeModal: {
        title: "stock-status.E50PLUS60.complete-modal.title",
        copy: "stock-status.E50PLUS60.complete-modal.copy",
        ctaModule: undefined,
      },
      iterable: {
        optIn: "51f29622-b601-402f-a632-60152df3bab8",
        optOut: "025d8dc9-05ff-43b2-b351-f4c4ccb2ef87",
      },
    },
    EFTG60: {
      modal: {
        title: "stock-status.EFTG60.modal.title",
        copy: {
          copy: "stock-status.EFTG60.modal.copy",
          emoji: "Peace",
        },
        cta: "stock-status.EFTG60.modal.cta",
      },
      completeModal: {
        title: "stock-status.EFTG60.complete-modal.title",
        copy: "stock-status.EFTG60.complete-modal.copy",
        ctaModule: undefined,
      },
      iterable: {
        optIn: "93692651-71a2-4fd0-9881-ff1869443bfd",
        optOut: "4cb1db2c-9a92-499e-b6a0-869f174a91d8",
      },
    },
    CHLC: {
      modal: {
        title: "stock-status.EFTG60.modal.title",
        copy: {
          copy: "stock-status.EFTG60.modal.copy",
          emoji: "Peace",
        },
        cta: "stock-status.EFTG60.modal.cta",
      },
      completeModal: {
        title: "You’re on the waitlist",
        copy: "Congrats, you're on the waitlist",
        ctaModule: undefined,
      },
      iterable: {
        optIn: "60ef54b9-8b2f-4e93-9517-1947a5548032",
        optOut: "7b5308de-04e5-4d81-8222-735ccb3ae230",
      },
    },
  },
  preorder: {
    E50PLUS60: {
      modal: {
        title: "preorder.E50PLUS60.modal.title",
        copy: {
          copy: "preorder.E50PLUS60.modal.copy.copy",
        },
        cta: "preorder.E50PLUS60.modal.cta",
      },
      completeModal: {
        title: "preorder.E50PLUS60.complete-modal.title",
        copy: "preorder.E50PLUS60.complete-modal.copy",
        ctaModule: {
          title: "preorder.E50PLUS60.complete-modal.cta-module.title",
          copy: "preorder.E50PLUS60.complete-modal.cta-module.copy",
          cta: "preorder.E50PLUS60.complete-modal.cta-module.cta",
          url: "/refer50plus",
        },
      },
      iterable: {
        optIn: "4e6515e2-bdf4-4df1-ba32-95d39dc65caa",
        optOut: "d3ae6ec5-770f-4062-a516-e807863951d4",
      },
    },
  },
  default: {
    modal: {
      title: "Waitlist",
      copy: {
        copy: "Join the waitlist",
      },
      cta: "Join the Waitlist",
    },
    completeModal: {
      title: "You’re on the waitlist",
      copy: "Congrats, you're on the waitlist",
      ctaModule: undefined,
    },
    iterable: {
      optIn: undefined,
      optOut: undefined,
    },
  },
};
export default ModalCopy;
