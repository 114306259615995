import fetch from "../fetch";

export async function verifyAssertion(assertion, provider, provider_id) {
  const body = JSON.stringify({
    provider,
    provider_id,
    grant_type: "assertion",
    assertion,
    origin: window.location.origin,
    scope: "customer",
  });

  return fetch("oauth/token", {
    method: "POST",
    credentials: "include",
    body,
    fetchOptions: {
      addCastleRequestToken: true,
    },
  });
}
