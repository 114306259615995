import styled from "styled-components";
import intl from "../../services/intl";
import { ProductSKU } from "../../utils/product";
import Text from "../Text";
import Svg from "../Svg";
import TruemedPopupButton from "../bundle/TruemedPopupButton";

const Container = styled.div`
  z-index: 2;
  position: relative;

  &.single-sku-pdp {
    margin-right: 0;

    @media (min-width: 750px) {
      margin-right: var(--spacing-3);
    }

    @media (min-width: 1024px) {
      margin-right: var(--spacing-8);
    }
  }
`;

const Heading = styled.p.attrs({
  className: "typography-eyebrow2 font-circular",
})`
  margin-bottom: var(--spacing-1);
  color: var(--indigo-blue-60);
`;

const BenefitContainer = styled.div`
  display: flex;
  flex-direction: row;

  &:not(:last-of-type) {
    margin-bottom: var(--spacing-1);
  }
`;

const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  position: relative;
  display: flex;
  margin-right: var(--spacing-0_75);
  flex: 0 0 auto;

  svg {
    color: var(--warm);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    path,
    circle {
      stroke: currentColor;
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }
`;

type Product = {
  sku: string;
};

type Props = {
  products: Product[];
  isBundle?: boolean;
  showTruemedBenefit?: boolean;
};

const Benefits = ({
  products,
  showTruemedBenefit = false,
  isBundle = true,
}: Props) => {
  const currentLocale = intl.locale;
  const epreSkus = [ProductSKU.EPRE60, ProductSKU.EPRE60M];

  const showFsaHsaReimbursementBlock =
    !showTruemedBenefit &&
    products.some((product) => epreSkus.includes(product.sku)) &&
    ["en-US", "en-CA"].includes(currentLocale);

  const bundleAndSaveBenefit = {
    icon: "https://assets.ritual.com/icons/box-24x24.svg",
    description: "Bundle and save on your first month.",
  };

  const yourRitualYourWayBenefit = {
    icon: "https://assets.ritual.com/icons/truck.svg",
    description: isBundle
      ? "Free shipping and easy cancellation"
      : "Free shipping for subscriptions and easy cancellation",
  };

  const moneyBackGuaranteeBenefit = {
    icon: "https://assets.ritual.com/icons/star-circle-24x24.svg",
    description: "30 day, money-back guarantee",
  };

  const truemedBenefit = {
    icon: "https://assets.ritual.com/icons/piggy-bank.svg",
    description: "HSA/FSA accepted with",
  };

  const subscribeAndSaveBenefit = {
    icon: "https://assets.ritual.com/icons/sparkles-24x24.svg",
    description: "Subscribe and save on all orders",
  };

  const firstBenefit = () => {
    if (showTruemedBenefit) {
      return truemedBenefit;
    }
    return isBundle ? bundleAndSaveBenefit : subscribeAndSaveBenefit;
  };

  const benefits = [
    firstBenefit(),
    yourRitualYourWayBenefit,
    moneyBackGuaranteeBenefit,
  ];
  return (
    <Container className={!isBundle ? "single-sku-pdp" : ""}>
      <Heading>
        {Text({
          id: "product.hero.subscription-benefits.heading",
          defaultMessage: "Good Value(s)",
        })}
      </Heading>
      <div role="list">
        {benefits.map((benefit, i) => {
          return (
            <BenefitContainer key={i} role="listitem">
              <IconContainer aria-label="Benefit icon">
                <Svg url={benefit.icon} />
              </IconContainer>
              <TextContainer>
                <p
                  className="typography-body3 font-circular"
                  aria-label={`Benefit description ${benefit.description}`}
                >
                  {benefit.description}{" "}
                  {benefit === truemedBenefit && (
                    <TruemedPopupButton label={"Truemed"} />
                  )}
                </p>
              </TextContainer>
            </BenefitContainer>
          );
        })}
        {showFsaHsaReimbursementBlock && (
          <BenefitContainer role="listitem">
            <IconContainer aria-label="Benefit icon">
              <Svg url="https://assets.ritual.com/icons/piggy-bank.svg" />
            </IconContainer>
            <TextContainer>
              <p
                className="typography-body3 font-circular"
                aria-label="Benefit label Eligible For FSA/HSA Reimbursement"
              >
                Eligible For FSA/HSA reimbursement
              </p>
            </TextContainer>
          </BenefitContainer>
        )}
      </div>
    </Container>
  );
};

export default Benefits;
