import React from "react";
import styled from "styled-components";

const PlanTileWrapper = styled.div`
  display: block;
  border: 1px solid var(--indigo-blue-20);
  border-radius: var(--spacing-0_25);
  animation: fadeInBorder 300ms ease-in-out reverse forwards;

  .plan-tile__content {
    padding: var(--spacing-1);
  }

  &.selected {
    border-color: var(--indigo-blue);
    animation: fadeInBorder 300ms ease-in-out;
  }

  &:not(.basic-price) {
    animation: fadeInBorder 300ms ease-in-out reverse forwards;
  }

  &:not(.selected):hover {
    border-color: var(--indigo-blue-40);
    transition: 150ms ease-in-out border-color;
  }

  &:not(.basic-price).selected {
    animation: fadeInBorder 300ms ease-in-out;
  }

  &.selected .header-container .title,
  &.selected .price-row {
    font-weight: 500;
  }

  .radio-button {
    border: 1px solid var(--indigo-blue-20);
    min-width: 18px;
    min-height: 18px;
    border-radius: 50%;
    position: relative;
  }

  .radio-button::after {
    position: absolute;
    display: block;
    content: " ";
    height: 0;
    width: 0;
    top: 50%;
    left: 50%;
    background-color: var(--indigo-blue);
    border-radius: 50%;
    transition: all 150ms ease-in;
  }

  &.selected .radio-button {
    border-color: var(--indigo-blue);
    background-color: var(--indigo-blue);
  }

  &.selected .radio-button::after {
    background-color: var(--white);
    width: 9px;
    height: 9px;
    top: calc(50% - 9px / 2);
    left: calc(50% - 9px / 2);
  }

  &:not(.selected):hover .radio-button {
    border-color: var(--indigo-blue-40);
  }

  .header-container {
    width: 100%;
  }

  .plan-tile__header {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-1);
    color: var(--indigo-blue);
    align-items: flex-start;
  }

  .plan-tile__header.basic-price {
    align-items: center;
  }

  .plan-tile__header .label {
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: var(--spacing-1);
  }

  .plan-tile__header .subtitle {
    padding-left: calc(18px + var(--spacing-1));
  }

  .plan-tile__header .title,
  .plan-tile__header .subtitle,
  .plan-tile__header .price-row {
    display: flex;
    flex-direction: row;
  }

  .plan-tile__header .title,
  .plan-tile__header .subtitle {
    justify-content: space-between;
    font-weight: 450;
  }

  .price-row {
    align-items: center;
    gap: var(--spacing-0_25);
  }

  div.base-price {
    color: var(--indigo-blue-40) !important;
    text-decoration: line-through;
  }

  div.savings {
    color: var(--success-green) !important;
  }

  div.plan-tile-savings-container {
    display: flex;
  }

  div.plan-tile-savings-container span {
    text-align: left;
  }

  div.monthly-price {
    color: var(--indigo-blue-60) !important;
  }

  .plan-tile__benefits {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-0_5);
    border-top: 1px solid var(--indigo-blue-20);
    padding-top: var(--spacing-1);
    margin-top: var(--spacing-1);
  }

  .plan-tile__flag-label {
    text-align: center;
    background-color: var(--yellow-ochre-60);
    padding: var(--spacing-0_25) var(--spacing-0_5);
    color: var(--indigo-blue);
    border-bottom: 1px solid var(--indigo-blue-20);
  }

  &.selected .plan-tile__flag-label {
    border-bottom: 1px solid var(--indigo-blue);
  }

  @keyframes fadeIn {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeInBorder {
    from {
      border-color: var(--indigo-blue-20);
    }
    to {
      border-color: var(--indigo-blue);
    }
  }
`;

export interface PlanTileProps {
  /** Is Plan Tile Selected */
  isSelected?: boolean;
  /** Label to display at the top of the Plan Tile */
  flagLabel?: string;
  /** Content for the plan tile title slot */
  title?: React.ReactNode;
  /** Content for the base price slot */
  basePrice?: React.ReactNode;
  /** Content for the final price slot */
  finalPrice?: React.ReactNode;
  /** Content for the savings slot */
  savings?: React.ReactNode;
  /** Content for the monthly price slot */
  monthlyPrice?: React.ReactNode;
  /** Content for the benefits slot */
  benefits?: React.ReactNode;
  /** Custom class name */
  className?: string;
}

export const PlanTile: React.FC<PlanTileProps> = ({
  isSelected = false,
  flagLabel,
  title,
  basePrice,
  finalPrice,
  savings,
  monthlyPrice,
  benefits,
  className,
}) => {
  const showBenefits = Boolean(benefits);
  const showPriceDetails = Boolean(savings);
  const showMonthlyPrice = Boolean(monthlyPrice);

  const tileClassName = isSelected ? "selected" : "";
  const priceRowClassName = !showPriceDetails ? "basic-price" : "";

  return (
    <PlanTileWrapper
      className={`plan-tile ${tileClassName} ${priceRowClassName} ${className}`}
    >
      {flagLabel && (
        <div className="plan-tile__flag-label">
          <span className="typography-caption">{flagLabel}</span>
        </div>
      )}
      <div className="plan-tile__content">
        <div className={`plan-tile__header font-circular ${priceRowClassName}`}>
          <div className="header-container">
            <div className="title font-circular typography-body1">
              <div className="label">
                <span className="radio-button"></span>
                {title}
              </div>
              <div className="price-row typography-body1 font-circular">
                <div className="base-price">{basePrice}</div>
                <div className="final-price">{finalPrice}</div>
              </div>
            </div>
            {showPriceDetails && (
              <div className="subtitle typography-body3 font-circular">
                <div className="savings plan-tile-savings-container">
                  {savings}
                </div>
                {showMonthlyPrice && (
                  <div className="monthly-price">{monthlyPrice}</div>
                )}
              </div>
            )}
          </div>
        </div>
        {showBenefits && <div className="plan-tile__benefits">{benefits}</div>}
      </div>
    </PlanTileWrapper>
  );
};
