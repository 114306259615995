let translations = {
  "ritual.com": {
    "--16441": "sticker",
    "--16442": "age_range",
    "--16443": "subscription_age",
    "--16444": "would_recommend",
    "--16445": "received_product",
  },
  "dev.ritual.com": {
    "--15761": "age_range",
    "--15762": "would_recommend",
    "--15769": "sticker",
    "--15770": "subscription_age",
    "--15959": "received_product",
  },
};

const translateReview = function (domain, node) {
  Object.entries(translations[domain]).forEach(([key, newKey]) => {
    if (node.custom_fields && node.custom_fields[key]) {
      node[newKey] = node.custom_fields[key].value;
    }
  });
  return node;
};

module.exports = translateReview;
