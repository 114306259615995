import { Component } from "react";
import styled from "styled-components";
import { Icons } from "../../utils/react-svg";
import { calculateReadingTime } from "../../utils/readingTime";
import { Font, media, rem, Color } from "../../utils/style";
import intl from "../../services/intl";

const ArticleReadtimeElement = styled.span`
  ${Font.circular};
  display: block;
  color: inherit;
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  text-align: left;
  margin: 0 0 ${rem(6)};

  i {
    height: 100%;
    margin-left: 16px;

    svg {
      width: 8px;
      height: 10px;
      transform: translateY(-1px);

      g {
        fill: ${Color.ritualBlue};

        ${media.mobile`
          fill: ${(p) => (p.color ? p.color : Color.white)};
        `};
      }
    }
  }
`;

export default class ArticleReadtime extends Component {
  render() {
    const { article, className } = this.props;
    const { articleVideo } = article;

    let timeLabel = intl.t("hub.article.read-time", "{length} min read", {
      length: calculateReadingTime(article.primaryContent),
    });
    if (articleVideo) {
      timeLabel = intl.t("hub.hero.video-length", "{length} min video", {
        length: articleVideo.videoLength / 60,
      });
    }

    return (
      <ArticleReadtimeElement
        className={className}
        color={article.heroSecondaryColor}
      >
        {timeLabel}
        {article.videoLength && (
          <i>
            <Icons.Play />
          </i>
        )}
      </ArticleReadtimeElement>
    );
  }
}
