import styled from "styled-components";

// Components
import Container from "../../components/Container";
import Row from "../../components/Row";
import Text from "../../components/Text";
import MagicLink from "../MagicLink";

// Utils
import { Color, rem, responsive, Opacity } from "../../utils/style";

const Section = styled.section``;

const Heading = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0 15px;
  margin-bottom: 40px;

  ${responsive.md`
    margin-bottom: 80px;
  `}
`;

const Title = styled.div`
  div {
    height: 4px;
    width: 32px;
    background-color: ${Color.ritualBlue};
    margin-bottom: 16px;
  }

  h3 {
    font-size: ${rem(26)};
    font-weight: 500;
    letter-spacing: -0.3px;
    line-height: ${rem(36)};
  }

  ${responsive.md`
    margin-bottom: 80px;

    div {
      margin-bottom: 24px;
    }

    h3 {
      font-size: ${rem(34)};
      letter-spacing: -0.5px;
      line-height: ${rem(40)};
    }

  `}
`;

const SubCategoryLink = styled(MagicLink)`
  font-size: ${rem(26)};
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: ${rem(36)};

  &:hover {
    opacity: ${Opacity.light};
  }

  ${responsive.md`
    font-size: ${rem(34)};
    letter-spacing: -0.5px;
    line-height: ${rem(40)};
  `}
`;

const Summary = styled.div`
  padding: 0;

  div {
    height: 4px;
    width: 32px;
    background-color: ${Color.ritualBlue};
    margin-bottom: 16px;
  }

  p {
    font-size: ${rem(20)};
    line-height: ${rem(30)};
  }

  ${responsive.md`
    margin-bottom: 80px;

    div {
      margin-bottom: 24px;
    }

    p {
      font-size: ${rem(26)};
      line-height: ${rem(36)};
      letter-spacing: -0.3px;
    }
  `}
`;

const CategoryLink = styled(MagicLink)`
  border-bottom: 2px solid ${Color.ritualBlue};
  font-size: ${rem(14)};
  line-height: ${rem(24)};

  &:hover {
    opacity: ${Opacity.light};
  }

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `}
`;

const FoldHere = styled.div`
  width: 100%;
  content: "";
  border-bottom: 3px dotted ${Color.ritualBlue};
`;

const JournalSection = ({
  children,
  className,
  category,
  slug,
  title,
  showFold = true,
  summary,
}) => {
  return (
    <>
      <Section className={className}>
        <Container>
          <Row>
            <Heading>
              {title && (
                <Title>
                  <div />
                  {slug && (
                    <SubCategoryLink
                      to={`/articles/categories/${category.slug}/${slug}`}
                    >
                      {title}
                    </SubCategoryLink>
                  )}
                  {!slug && <h3>{title}</h3>}
                </Title>
              )}
              {summary && (
                <Summary className={"col-12 col-sm-6"}>
                  <div />
                  <p>{summary}</p>
                </Summary>
              )}
              {category && (
                <CategoryLink
                  to={
                    slug
                      ? `/articles/categories/${category.slug}/${slug}`
                      : `/articles/categories/${category.slug}`
                  }
                >
                  <Text
                    id={"journal.landing.view-all"}
                    defaultMessage={"View All"}
                  />
                </CategoryLink>
              )}
            </Heading>
          </Row>
        </Container>
        {children}
      </Section>
      {showFold && <FoldHere />}
    </>
  );
};

export default JournalSection;
