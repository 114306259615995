import { createSelector } from "reselect";

const outOfStock = (state) => state.outOfStock;

const isWaitlisted = createSelector(
  outOfStock,
  (_, props) => props.sku,
  (outOfStock, sku) => {
    return Boolean(outOfStock.waitlisted && outOfStock.waitlisted[sku]);
  },
);

export default {
  isWaitlisted,
};
