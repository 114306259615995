import useVariation from "../useVariation";

type ProductOfferUpsell = {
  slug: string;
  label?: string;
};

const isValidUpsells = (
  upsells: any,
): upsells is ProductOfferUpsell[] | string[] =>
  Array.isArray(upsells) &&
  upsells.every(
    (upsell) =>
      typeof upsell === "string" ||
      (typeof upsell === "object" && upsell !== null && "slug" in upsell),
  );

const useProductOfferUpsells = (
  flagName: string,
  productSku: string,
): ProductOfferUpsell[] => {
  const value = useVariation(flagName, {});
  const match = value?.[productSku];

  const upsells = isValidUpsells(match) ? match : [];

  return upsells.map((upsell) =>
    typeof upsell === "string" ? { slug: upsell } : upsell,
  );
};

export default useProductOfferUpsells;
