import styled from "styled-components";

// Utils
import { media } from "../../utils/style";

// Components
import Container from "../Container";
import Featured2UpArticle from "./Featured2UpArticle";
import Row from "../Row";
// TODO: Do we use this any more?
// import Featured2UpFact from "./Featured2UpFact";

// Styled Components
const Featured2UpRow = styled(Row)`
  margin-top: 50px;
  margin-bottom: 50px;

  ${media.tablet`
    margin-top: 0;
    margin-bottom: 0;
  `};
`;

const TwoUpBox = styled.div.attrs({ className: "col-12 col-md-6" })`
  ${media.tablet`
    &:first-child {
      margin: 30px auto 15px;
    }

    &:last-child {
      margin: 15px auto 30px;
    }
  `};
`;

const Featured2Up = ({ category, data }) => {
  const { articles } = data;

  if (!articles) return null;

  if (articles.length > 2) articles.length = 2;
  return (
    <Container>
      <Featured2UpRow>
        {articles.map((article, i) => {
          return (
            <TwoUpBox key={i}>
              <Featured2UpArticle
                article={article}
                category={category}
                key={"article-" + i}
              />
            </TwoUpBox>
          );
        })}
      </Featured2UpRow>
    </Container>
  );
};

export default Featured2Up;
