import { useSelector } from "react-redux";
import productSelectors from "../../store/product/selectors";
import styled from "styled-components";
import Text from "../Text";
import { responsive } from "../../utils/style";

const InterstitialSuccessBannerContainer = styled.div`
  background: #77a932;
  color: #fff;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 24px;

  ${responsive.sm`
    padding: 12px 40px;
  `}

  font-weight: 450;
`;

export const InterstitialSuccessBanner = ({ planAdded }) => {
  const product = useSelector((state) =>
    productSelectors.productForId(state, { id: planAdded?.productId }),
  );

  if (!product) return null;

  return (
    <InterstitialSuccessBannerContainer className="typography-body3 font-circular">
      <Text
        id="cart.interstitial-success-banner"
        defaultMessage="{name} added to cart."
        values={{
          name: product.name,
        }}
      />
    </InterstitialSuccessBannerContainer>
  );
};
