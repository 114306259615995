import { Helmet } from "react-helmet";
import GeneralTags from "./GeneralTags";
import {
  getAgeGroupFromProduct,
  getGenderFromProduct,
  getSeoCategoryFromProduct,
} from "../../utils/product";

interface ProductPageSeoProps {
  contentfulProduct: {
    path: string;
    pageTitle: string;
    pageDescription: string;
    stockStatus: string;
    socialImage: {
      file: {
        details: {
          image: {
            width: number;
            height: number;
          };
        };
        url: string;
      };
    };
  };
  ritualProduct: {
    product_id: string;
    demographics: string[];
    age_ranges: string[];
    sub_category: string;
    ritualPlans: {
      currency: string;
      billing_type: string;
      amount: number;
    }[];
  };
  locale: {
    type: string;
  };
}

const ProductPageSeo = ({
  contentfulProduct,
  ritualProduct,
  locale,
}: ProductPageSeoProps) => {
  const pagePath = contentfulProduct.path;
  const title = contentfulProduct.pageTitle;
  const description = contentfulProduct.pageDescription;
  const image = {
    url: contentfulProduct.socialImage.file.url,
    width: contentfulProduct.socialImage.file.details.image.width,
    height: contentfulProduct.socialImage.file.details.image.height,
  };

  const plan = ritualProduct.ritualPlans.find(
    (plan) =>
      plan.currency === locale.type.toLowerCase() &&
      plan.billing_type === "one_time",
  );

  const productMeta = [
    { property: "product:brand", content: "Ritual" },
    {
      property: "product:availability",
      content: contentfulProduct.stockStatus,
    },
    { property: "product:condition", content: "new" },
    {
      property: "product:retailer_item_id",
      content: ritualProduct.product_id,
    },
    { property: "og:price:amount", content: `${(plan?.amount ?? 0) / 100}` },
    { property: "og:price:currency", content: locale.type },
    {
      property: "product:price:amount",
      content: `${(plan?.amount ?? 0) / 100}`,
    },
    {
      property: "product:price:currency",
      content: locale.type,
    },
    {
      property: "product:category",
      content: getSeoCategoryFromProduct({
        subCategory: ritualProduct.sub_category,
      }),
    },
    {
      property: "robots",
      content: "max-image-preview:large",
    },
  ];

  const gender = getGenderFromProduct({
    demographics: ritualProduct.demographics,
  });
  const ageGroup = getAgeGroupFromProduct({
    ageRanges: ritualProduct.age_ranges,
  });

  if (gender) {
    productMeta.push({ property: "product:gender", content: gender });
  }

  if (ageGroup) {
    productMeta.push({ property: "product:age_group", content: ageGroup });
  }

  const meta = [
    {
      name: "image",
      content: image.url,
    },
    { property: "og:type", content: "product.item" },
    {
      property: "og:image",
      content: image.url,
    },
    {
      property: "og:image:width",
      content: image.width?.toString(),
    },
    {
      property: "og:image:height",
      content: image.height?.toString(),
    },
    {
      name: "twitter:image",
      content: image.url,
    },
    ...productMeta,
  ];

  return (
    <>
      <GeneralTags
        pagePath={pagePath}
        title={title}
        description={description}
        image={image}
        ogTitle={title}
      />
      <Helmet meta={meta} />
    </>
  );
};

export default ProductPageSeo;
