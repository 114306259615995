import styled from "styled-components";

const Wrapper = styled.div.attrs({
  className: "mobile-banner-bottom",
})`
  position: fixed;
  bottom: 0;
  width: 100vw;
  z-index: 999;
`;

const Container = styled.div`
  padding: 0 15px;
  padding-bottom: calc(24px + env(safe-area-inset-bottom));
`;

export default function MobileBannerBottom(props) {
  return (
    <Wrapper>
      <Container
        className={`mobile-banner-bottom-btn-container ${
          props.hideMobileBackground ? "hide-mobile-background" : ""
        }`}
      >
        {props.children}
      </Container>
    </Wrapper>
  );
}
