import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import * as Sentry from "@sentry/react";

// Reducers
import app from "./app/reducer";
import applyCode from "./apply-code/reducer";
import banners from "./banners/reducer";
import i18n from "./i18n/reducer";
import navigation from "./navigation/reducer";
import outOfStock from "./out-of-stock/reducer";
import pendingCode from "./pending-code/reducer";
import route from "./route/reducer";
import staticData from "./staticData/reducer";

// Normalized Store Reducers
import carts from "./cart/reducer";
import cartProducts from "./cart-product/reducer";
import coupons from "./coupon/reducer";
import promotion from "./promotion/reducer";
import promotionPlan from "./promotion-plan/reducer";
import guests from "./guest/reducer";
import plans from "./plan/reducer";
import products from "./product/reducer";
import productGroups from "./product-group/reducer";
import shippingOptions from "./shipping-option/reducer";
import subscriptions from "./subscription/reducer";
import subscriptionItems from "./subscription-item/reducer";
import invoiceItems from "./invoice-item/reducer";
import users from "./user/reducer";
import promotionIntents from "./promotion-intent/reducer";
import productOffers from "./product-offer/reducer";
import productOfferPlans from "./product-offer-plan/reducer";

// Constants
const SENTRY_ALLOWED_ACTIONS = [
  "APP_LOADED",
  "AUTH_INITIALIZED",
  "FETCH_AFTER_AUTH_START",
  "FETCH_AFTER_AUTH_SUCCESS",
  "FETCH_AFTER_AUTH_FAILURE",
  "UPDATE_CART_REPLACE_BANNER",
  "UPDATE_CART_LIMIT_BANNER",
  "UPDATE_CART_PRODUCT_OFFER_LIMIT_BANNER",
  "UPDATE_CART_WIPEOUT_BANNER",
  "UPDATE_FLYOUT_CART",
  "UPDATE_CART_FETCH_FAILED",
  "CREATE_USER",
  "UPDATE_USER",
  "REMOVE_USER",
  "CREATE_GUEST",
  "UPDATE_GUEST",
  "REMOVE_GUEST",
  "UPDATE_BEST_ELIGIBLE_PROMOTION_ID",
  "PROMOTION_REQUEST_START",
  "PROMOTION_REQUEST_SUCCESS",
  "PROMOTION_REQUEST_FAILURE",
  "UPDATE_BANNER",
  "UPDATE_APPLY_CODE_INPUT",
  "UPDATE_PENDING_CODE",
  "WAITLISTED",
  "SET_LOCALE",
  "SET_ACTIVE_LOCALES",
  "SET_STORES",
  "SET_LOCALE_SUGGESTION",
];

const rootReducer = combineReducers({
  app,
  applyCode,
  banners,
  i18n,
  navigation,
  outOfStock,
  pendingCode,
  route,
  staticData,
  // Normalized Stores
  carts,
  cartProducts,
  coupons,
  promotion,
  promotionPlan,
  guests,
  invoiceItems,
  plans,
  products,
  productGroups,
  shippingOptions,
  subscriptions,
  subscriptionItems,
  users,
  promotionIntents,
  productOffers,
  productOfferPlans,
});

export function create(initialState = {}) {
  const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension's options like name, actionsBlacklist, actionsCreators, serialize...
        })
      : compose;

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: (action) => {
      return SENTRY_ALLOWED_ACTIONS.includes(action.type) ? action : null;
    },
  });

  const enhancers = composeEnhancers(
    applyMiddleware(thunk),
    sentryReduxEnhancer,
    // other store enhancers if any
  );
  return createStore(rootReducer, initialState, enhancers);
}

const store = create({});

export function getStore() {
  return store;
}
