import { Component } from "react";

export default class ReviewSchema extends Component {
  generateSchema() {
    let { productUrl, productName, title, user, content, score } = this.props;
    return {
      "@context": "http://schema.org/",
      "@type": "Review",
      itemReviewed: {
        "@type": "Product",
        name: productName,
        url: productUrl,
      },
      name: title,
      author: {
        "@type": "Person",
        name: user.display_name,
      },
      reviewBody: content,
      reviewRating: {
        "@type": "Rating",
        ratingValue: score,
        bestRating: "5",
      },
      publisher: {
        "@type": "Organization",
        name: "Ritual",
      },
    };
  }

  render() {
    return (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(this.generateSchema()),
        }}
      />
    );
  }
}
