import styled from "styled-components";
import { responsive } from "../../utils/style";

import ScrollableOverflow from "../ScrollableOverflow";
import GridContainer from "../grid/GridContainer";
import GridColumn from "../grid/GridColumn";
import GridRow from "../grid/GridRow";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";


const ScienceTeamContainer = styled(GridContainer)``;

const Heading = styled.h3`
  margin-bottom: var(--spacing-2);

  ${responsive.sm`
    margin-bottom: var(--spacing-3);
  `}
`
const ExpertStyledComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: var(--spacing-1);
  width: 213px;

  ${responsive.sm`
    width: 168px;
  `}

  ${responsive.md`
    width: 214px;
    margin-right: var(--spacing-1_5);
  `}

  ${responsive.lg`
    width: 300px;
  `}

  p {
    white-space: pre-wrap;
  }

  p.name, p.title {
    margin-bottom: 0;
  }

  p.degrees {
    color: var(--indigo-blue-60);
  }

  p.degrees {
    margin-bottom: var(--spacing-0_5);
  }

  .expert-image {
    margin-bottom: var(--spacing-1);
    width: 100%;
    aspect-ratio: 213 / 243;

    ${responsive.sm`
      aspect-ratio: 168 / 191;
    `}

    ${responsive.md`
      aspect-ratio: 214 / 244;
    `}

    ${responsive.lg`
      aspect-ratio: 300 / 342;
    `}

    img {
      user-select: none;
      cursor: pointer;
      pointer-events: none;
    }
  }
}`;

type Expert = {
  name: string;
  title: string;
  image: any;
  titleSubhead: string;
};

type Props = {
  experts: Expert[];
};

const ExpertComponent = ({ expert }: { expert: Expert }) => {
  const {
    name,
    title,
    image,
    titleSubhead
   } = expert;

   const [expertName, ...degrees] = name.split(',');
  return (
    <ExpertStyledComponent>
      {image && <GatsbyImage
        image={
          getImage(
            image.gatsbyImageData
          ) as IGatsbyImageData
        }
        loading="eager"
        draggable={false}
        className="expert-image"
        color={image.gatsbyImageData.backgroundColor}
        alt={image.description}
      />}
      <p className="name typography-label1">{expertName}</p>
      {degrees.length > 0 && <p className="degrees typography-body2 font-circular">{degrees.join(',').trim()}</p>}
      <p className="title typography-body2">{`${title} ${titleSubhead || ""}`}</p>
    </ExpertStyledComponent>
  );
}

const ScientificTeam = (props: Props) => {
  const {
    experts
  } = props;

  const renderExpertComponents = (
    <>{experts.map((expert: Expert, index: number) => (
        <ExpertComponent
          key={index}
          expert={expert}
        />
      ))
    }</>
  )

  return (
    <ScienceTeamContainer>
      <GridRow>
        <GridColumn
          xs={{ size: 6 }}
          s={{ size: 12 }}
          m={{ size: 12 }}
          >
          <Heading className="typography-headline2">Our Scientific Advisory Board</Heading>
          <ScrollableOverflow bleedLeft={true} bleedRight={true}>
            {renderExpertComponents}
          </ScrollableOverflow>
        </GridColumn>
      </GridRow>
    </ScienceTeamContainer>
  );
};

export default ScientificTeam;
