import { Component } from "react";
import styled from "styled-components";

// Utils
import { media, responsive } from "../utils/style";

// Components
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import MagicVideo from "./MagicVideo";

// Styled Elements
const Wrapper = styled.section`
  background-color: ${(p) => p.backgroundColor};
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  padding-top: 52px;

  ${responsive.md`
    padding-top: 64px;
  `};

  width: calc(100vw - 16px);
  height: calc(100vw - 16px);

  &.short {
    height: calc((100vw - 16px) * 0.72368421052);
  }

  &.height-fit {
    height: fit-content;
  }

  &.full-width-mobile {
    ${media.mobile`
      width: 100%;
    `}
  }
  ${responsive.sm`
    width: calc(100vw - 48px);
    height: calc((100vw - 48px) * 0.52778);
    padding-left: 0px;
    padding-right: 0px;

    &.short {
      height: calc((100vw - 48px) * 0.52778);
    }

    &.height-fit {
      height: fit-contet;
    }

  `}

  ${responsive.md`
    height: calc((100vw - 48px) * 0.3883196721);

    &.short {
      height: calc((100vw - 48px) * 0.3883196721);
    }

    &.height-fit {
      height: fit-content;
    }

  `}

  ${responsive.lg`
    width: calc(100vw - 80px);
    height: calc((100vw - 80px) * 0.3888024883);
    max-width: 1920px;
    max-height: calc(1920px * 0.3888024883);

    &.short {
      height: calc((100vw - 80px) * 0.3888024883);
    }

    &.height-fit {
      height: fit-content;
      max-height: fit-content;
    }

  `}

  > .fill {
    height: 100%;

    > .row {
      height: 100%;

      > .col-12 {
        height: 100%;
      }
    }
  }
`;

const Column = styled.div`
  position: relative;
`;

const ContentArea = styled.div`
  z-index: 1;
  position: relative;
  height: 100%;
  width: 100%;
`;

export default class PageHero extends Component {
  renderImage() {
    const {
      id = "page-hero",
      imageMobile,
      imageDesktop,
      imageObjectPosition = "50% 50%",
      videoDesktop,
      videoMobile,
    } = this.props;

    const defaultStyle = {
      position: "absolute",
      left: 0,
      top: 0,
      width: "100%",
      height: "100%",
      zIndex: "0",
      userSelect: "none",
      userDrag: "none",
      pointerEvents: "none",
      touchCallout: "none",
    };

    return [
      imageMobile && !videoMobile && (
        <GatsbyImage
          id={`${id}_mobile-image`}
          key={"mobile-image"}
          className="d-sm-none"
          image={getImage(imageMobile)}
          loading="eager"
          alt={imageMobile.description || ""}
          style={defaultStyle}
          objectFit="cover"
          objectPosition={imageObjectPosition}
        />
      ),
      imageDesktop && !videoDesktop && (
        <GatsbyImage
          id={`${id}_desktop-image`}
          key={"desktop-image"}
          className="d-none d-sm-block"
          image={getImage(imageDesktop)}
          loading="eager"
          alt={imageDesktop.description || ""}
          style={defaultStyle}
        />
      ),
    ];
  }

  renderVideo() {
    const { videoDesktop, videoMobile } = this.props;

    const styles = {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "center center",
      margin: "auto",
      left: 0,
      top: 0,
      position: "absolute",
      userSelect: "none",
      pointerEvents: "all",
      userDrag: "none",
      touchCallout: "none",
    };

    const videoProps = {
      autoPlay: true,
      muted: true,
      loop: true,
      playsInline: true,
      controls: false,
      preload: "metadata",
      videoElementStyle: styles,
      videoStyle: { position: "static" },
    };

    return [
      videoMobile && (
        <MagicVideo
          {...videoProps}
          {...videoMobile}
          key={"desktop-video"}
          className="d-sm-none"
        />
      ),
      videoDesktop && (
        <MagicVideo
          {...videoProps}
          {...videoDesktop}
          key={"mobile-video"}
          className="d-none d-sm-block"
        />
      ),
    ];
  }

  render() {
    const {
      id = "page-hero",
      backgroundColor = "#ffd600",
      children,
      className,
      columnStyling = "col-12",
    } = this.props;
    return (
      <Wrapper id={id} className={className} backgroundColor={backgroundColor}>
        <div className="fill">
          <div className="row">
            <Column className={columnStyling}>
              <ContentArea id={`${id}_content`}>
                {children}
                {this.renderVideo()}
              </ContentArea>
            </Column>
          </div>
        </div>
        <div className="disable-opacity-transition">{this.renderImage()}</div>
      </Wrapper>
    );
  }
}
