import { Component, Fragment } from "react";
import styled from "styled-components";

// Animations
import gsap from "gsap";
import ScrollMagic from "scrollmagic";

//Utils
import { stripWrappingParagraphTag } from "../../utils/contentful";
import { addDisclaimerLink } from "../../utils/markdown";
import { Font, media, rem, responsive } from "../../utils/style";
import { Typography } from "../../utils/styleDesignSystem";

// Components
import { GatsbyImage } from "gatsby-plugin-image";
import Container from "../Container";
import MagicVideo from "../MagicVideo";
import Row from "../Row";
import FollowElement from "../FollowElement";

// Styled Elements
const TimelineWrapper = styled(Container).attrs({
  id: "timeline",
  role: "section",
  "aria-labelledby": "timeline-header",
})`
  margin: 0 auto 80px;
  position: relative;
  overflow: visible !important;

  ${responsive.md`
    margin: 0 auto 120px;
  `}
`;

const TimelineRow = styled(Row)`
  .timeline__desktop {
    display: none;

    @media (min-width: 750px) {
      display: block;
    }
  }

  .timeline__mobile {
    display: block;

    @media (min-width: 750px) {
      display: none;
    }
  }
`;

const ImageArea = styled.div.attrs({
  className: "col-12 col-sm-6",
})`
  position: relative;
  top: 0px;

  &.single-product {
    padding: 0;
  }
`;

const CircleWrapper = styled.div.attrs({
  className: "timeline__circle", // Required className for scrollmagic
})``;

const TimelineAsset = styled.div`
  max-width: 100%;
  margin: 0;
  z-index: 100;
  max-width: 470px;
  max-height: 470px;
  aspect-ratio: 1 / 1;

  .gatsby-image-wrapper {
    max-width: 100%;
    max-height: 100%;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }

  position: relative !important;
  width: 100%;
  top: 0;

  img {
    width: 100%;
    height: 100%;
  }
`;

const TimelineArea = styled.div.attrs({
  className: "col-12 col-sm-5 offset-sm-1",
})`
  position: relative;
  padding-right: 20px;

  ${responsive.sm`
    padding-left: 5px;
    padding-right: 5px;
  `};

  ${responsive.md`
    padding-left: 15px;
    padding-right: 20px;
  `};

  &.single-product {
    padding: 0;
  }
`;

const TimelineIntroHeader = styled.h2.attrs({
  id: "timeline-header",
})`
  ${Font.circular}
  font-size: ${rem(30)};
  line-height: ${rem(36)};
  letter-spacing: -0.4px;
  font-weight: 500;
  margin: 24px 0 16px;

  ${responsive.sm`
    margin-top: 0;
    margin-bottom: 24px;
  `};

  ${responsive.md`
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    letter-spacing: -1.4px;
  `};

  em {
    ${Font.dutch};
    font-style: italic;
  }
`;

const TimelineIntroSubhead = styled.p`
  ${Font.dutch}
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: normal;
  margin: 0 0 56px;

  ${media.mobile`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    margin-bottom: 40px;
  `}
`;

const Line = styled.div.attrs({
  className: "timeline__line",
})`
  left: calc(50% + 7px + 20px);
  height: calc(100% + 42px);
  position: absolute;
  left: 6px;
  height: calc(100% + 25px);
  width: 2px;
  background-color: #b3bbd1;

  ${media.tablet`
    left: 16px;
    height: calc(100% + 42px);
  `};
`;

const TimelineContent = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  padding-bottom: 3px;
`;

const TimelineItem = styled.div`
  padding: 0;
  position: relative;
  margin-bottom: 56px;
  padding-right: 20px;
  padding-left: 40px;
  transition: opacity 0.4s;
  box-sizing: border-box !important;
  max-width: 442px;

  ${media.mobile`
    padding-left: 40px;
    width: auto;
  `}

  opacity: 0.2;
  transition: opacity 0.4s;
`;

const LastMarker = styled.div`
  display: none;
  position: absolute;
  left: 3px;
  bottom: 0;
  width: 4px;
  height: 4px;
  border: 2px solid #b3bbd1;
  background-color: #b3bbd1;
  border-radius: 18px;
  z-index: 11;
  box-sizing: content-box !important;

  ${media.tablet`
    left: 13px;
  `};
`;

const ItemMarker = styled.span`
  position: absolute;
  left: 1px;
  width: 8px;
  height: 8px;
  border: 2px solid #ced5e1;
  background-color: #ffffff;
  border-radius: 18px;
  z-index: 11;
  box-sizing: content-box !important;

  ${media.tablet`
    left: 11px;
  `};
`;

const ItemFill = styled.span`
  position: absolute;
  left: 1px;
  width: 8px;
  height: 8px;
  background-color: #142b6f;
  border: 2px solid #142b6f;
  border-radius: 13px;
  z-index: 12;
  box-sizing: content-box !important;

  ${media.tablet`
    left: 11px;
  `};
`;

const ItemContent = styled.div`
  ${(p) =>
    p.hasMonth
      ? `
    transform: translateY(-6px);
  `
      : `
    transform: translateY(-10px);

    & > span {
      display: none;
    }
  `};
`;

const ItemMonth = styled.span`
  position: relative;
  ${Font.circular}
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-weight: 500;
  display: block;
  margin: 0 0 16px;

  ${media.mobile`
    font-size: ${rem(12)};
    line-height: ${rem(18)};
    margin-bottom: 8px;
    position: relative;
    left: 0;
    top: 0;
  `}
`;

const ItemTitle = styled.h3`
  ${Font.circular}
  font-size: ${rem(22)};
  line-height: ${rem(32)};
  letter-spacing: -0.2px;
  font-weight: 500;
  margin: 0 0 8px;

  ${media.mobile`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    letter-spacing: 0;
  `}
`;

const Description = styled.p`
  ${Font.dutch}
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: normal;
  margin: 0;

  ${media.mobile`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `}

  span {
    ${Typography.label4}
    font-weight: 500;
  }
`;

export default class Timeline extends Component {
  constructor(props) {
    super(props);
    this.elements = [];
    this.markers = [];
    this.fills = [];
    this.scene = null;
    this.imageScene = null;
    this.imageWrapper = null;
    this.image = null;

    this.elementStyles = {
      width: "470px",
      height: "470px",
      userSelect: "none",
      userDrag: "none",
      pointerEvents: "none",
      touchCallout: "none",
    };

    this.imageProps = {
      loading: "eager",
      style: {
        ...this.elementStyles,
      },
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.controller && !prevProps.controller) {
      this.initializeAnimation();
      // Initialize it, then re-initialize after fonts loaded
      this.timeout = setTimeout(() => {
        this.initializeAnimation();
      }, 150);
      window.addEventListener("resize", () => {
        this.initializeAnimation();
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", () => {
      this.initializeAnimation();
    });
    clearTimeout(this.timeout);
  }

  initializeAnimation() {
    const element = this.sceneElement;
    if (!element) return; // Prevent error when element is not mounted
    const style = window.getComputedStyle(element);
    const marginTop = parseFloat(style.marginTop);
    const marginBottom = parseFloat(style.marginBottom);
    const sceneDuration = element.offsetHeight + marginTop + marginBottom;
    // Destroy all ongoing scenes.
    if (this.scene) {
      this.scene.destroy(true);
    }

    if (this.imageScene) {
      this.imageScene.destroy(true);
    }

    if (this.itemScenes) {
      this.itemScenes.forEach((scene) => {
        scene.destroy(true);
      });
    }

    if (ScrollMagic) {
      this.scene = new ScrollMagic.Scene({
        triggerElement: this.sceneElement,
        triggerHook: 0.5,
        duration: sceneDuration,
        offset: 0,
      })
        .setTween(this.timelineTween())
        .addTo(this.props.controller);

      this.itemScenes = this.elements.map((item, i) => {
        const element = item;
        const style = window.getComputedStyle(element);
        const marginTop = parseFloat(style.marginTop);
        const marginBottom = parseFloat(style.marginBottom);
        const duration = element.offsetHeight + marginTop + marginBottom;
        return new ScrollMagic.Scene({
          triggerElement: item,
          triggerHook: 0.5,
          duration: duration,
          offset: 0,
        })
          .setTween(this.itemTween(item, i, this.elements.length))
          .addTo(this.props.controller);
      });
    }
  }

  itemTween(item, i, elementsCount) {
    var timeline = gsap.timeline();
    var marker = this.markers[i];
    var markerFill = this.fills[i];

    timeline
      .set(item, {
        css: {
          opacity: 0,
        },
      })
      .fromTo(
        marker,
        {
          css: {
            borderColor: "#ced5e1",
            scale: 1,
          },
        },
        {
          duration: 0.01,
          css: {
            borderColor: "#142b6f",
            scale: 1,
          },
        },
      )
      .set(item, {
        css: {
          opacity: 1.0,
        },
      })
      .fromTo(
        markerFill,
        {
          css: {
            scale: 1,
            opacity: 0,
          },
        },
        {
          duration: 0.01,
          css: {
            scale: 1,
            color: "#142b6f",
            opacity: 1,
          },
        },
      )
      .to(marker, { duration: 0.5 })
      .to(marker, {
        duration: 0.7,
        css: {
          scale: 1,
        },
        ease: "power1.easeOut",
      })
      .to(marker, {
        duration: 0.7,
        css: {
          scale: 1,
        },
        ease: "power1.easeIn",
      });

    if (i === elementsCount - 1) {
      timeline.set(".timeline__item__last-marker", {
        css: {
          display: "block",
        },
      });
    }
    return timeline;
  }

  timelineTween() {
    return gsap.fromTo(
      ".timeline__line",
      {
        css: {
          height: "0",
        },
        ease: "none",
      },
      {
        duration: 10,
        css: {
          height: "100%",
        },
        ease: "none",
      },
    );
  }

  renderTimelineAsset() {
    const { image, video } = this.props;

    const imageProps = {
      ...this.imageProps,
      alt: image.description,
    };

    return (
      <TimelineAsset className="timeline__image" role="img">
        {video ? (
          <MagicVideo
            {...video}
            autoPlay={true}
            controls={false}
            muted={true}
            loop={true}
            playsInline={true}
            videoStyle={{ position: "absolute" }}
            videoElementStyle={{ ...this.elementStyles, pointerEvents: "auto" }}
            aria-label="product benefit points video"
          />
        ) : (
          <GatsbyImage
            {...imageProps}
            image={image?.desktop}
            id="timeline_row_image-area_wrapper_image_desktop"
            aria-label="product benefit points image"
            role="img"
          />
        )}
      </TimelineAsset>
    );
  }

  render() {
    let { timelineHeading, timelineSubhead, data, singleProduct, image } =
      this.props;

    const imageProps = {
      ...this.imageProps,
      alt: image.description,
    };

    return (
      <TimelineWrapper>
        <TimelineRow>
          <ImageArea className={`${singleProduct ? "single-product" : ""}`}>
            <div className="timeline__desktop" style={{ height: "100%" }}>
              <FollowElement customOffset={256}>
                <CircleWrapper>{this.renderTimelineAsset()}</CircleWrapper>
              </FollowElement>
            </div>
            <div className="timeline__mobile">
              {image && (
                <GatsbyImage
                  {...imageProps}
                  style={{
                    width: "100%",
                    height: "100%",
                    paddingTop: "50%",
                  }}
                  image={image?.mobile}
                  id="timeline_row_image-area_wrapper_image_mobile"
                  aria-label="product benefit points image"
                  role="img"
                />
              )}
            </div>
          </ImageArea>

          <TimelineArea className={`${singleProduct ? "single-product" : ""}`}>
            <TimelineIntroHeader
              dangerouslySetInnerHTML={{
                __html: stripWrappingParagraphTag(
                  addDisclaimerLink(timelineHeading.childMarkdownRemark.html),
                ),
              }}
            ></TimelineIntroHeader>
            <TimelineIntroSubhead
              dangerouslySetInnerHTML={{
                __html: addDisclaimerLink(timelineSubhead),
              }}
            />

            <TimelineContent ref={(r) => (this.sceneElement = r)}>
              <Line />
              {data.map((item, i) => {
                let shortname = "point-" + i;
                return (
                  <Fragment key={shortname}>
                    <TimelineItem
                      index={i}
                      className={"timeline__item " + shortname}
                      ref={(r) => (this.elements[i] = r)}
                    >
                      <ItemMarker
                        index={i}
                        ref={(r) => (this.markers[i] = r)}
                        className={`timeline__item__marker ${shortname}-marker`}
                      />
                      <ItemFill
                        index={i}
                        ref={(r) => (this.fills[i] = r)}
                        className={`timeline__item__fill ${shortname}-marker-fill`}
                      />
                      <ItemContent index={i} hasMonth={!!item.month}>
                        <ItemMonth index={i} className="timeline__item__month">
                          {item.month}
                        </ItemMonth>
                        <ItemTitle
                          index={i}
                          dangerouslySetInnerHTML={{
                            __html: addDisclaimerLink(item.title),
                          }}
                        />
                        <Description
                          index={i}
                          dangerouslySetInnerHTML={{
                            __html: addDisclaimerLink(item.description),
                          }}
                        />
                      </ItemContent>
                    </TimelineItem>
                    {i === data.length - 1 && (
                      <LastMarker className="timeline__item__last-marker" />
                    )}
                  </Fragment>
                );
              })}
              {this.props.children}
            </TimelineContent>
          </TimelineArea>
        </TimelineRow>
      </TimelineWrapper>
    );
  }
}
