import {
  CaptionTitle,
  PriceBreakdown,
  ProductTile,
  ProductTileLarge,
  ProductTileItem,
  RBadge,
  RPrice,
} from "@ritual/essentials-for-react";
import { GatsbyImage, IGatsbyImageData, getImage } from "gatsby-plugin-image";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import cartService from "../../services/cart";
import { addProductToCart } from "../../store/cart/actions";
import {
  getFeaturedBundleName,
  getProductOfferPricing,
} from "../../utils/bundle";
import metrics from "../../utils/metrics";
import { getPropertiesForProductSku } from "../../utils/tracking/helpers";
import Currency from "../Currency";
import DiscountButton from "../bundle/DiscountButton";
import { navigate } from "../../services/navigation";
import useVariation from "../../hooks/useVariation";

const BundleProductImage = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  overflow: hidden;
`;

const IncludedImage = styled(GatsbyImage)`
  width: 64px;
  height: 64px;
`;

type Flag = {
  content: string | null;
};

type Product = {
  image: {
    gatsbyImageData: any;
    description: string;
  };
  name: {
    name: string;
  };
  shortDescription: string;
};

type Content = {
  title: string;
  slug: string;
  buildBundlePdp: boolean;
  sku: string | null;
  featuredImage: {
    gatsbyImageData: any;
    description: string;
  };
  shortDescription: string;
  ritualProductOffer: {
    product_offer_id: string;
  };
  products: Product[];
};

type Item = {
  size: string;
  content: Content;
  tileFlag: Flag;
  titleFlag: Flag | null;
};

type BundleProductTileProps = (props: {
  item: Item;
  location: string;
  currency: {
    type: string;
  };
  loading?: "lazy" | "eager";
  isBundleLandingPage: boolean;
}) => JSX.Element | null;

const BundleProductTile: BundleProductTileProps = ({
  item,
  location = "Product List Page Bundle Cards",
  currency,
  loading = "lazy",
  isBundleLandingPage,
}) => {
  const { size, content, tileFlag } = item;

  const {
    ritualProductOffer,
    featuredImage: image,
    title: productName,
    shortDescription,
    products,
    slug,
    buildBundlePdp,
  } = content;
  const dispatch = useDispatch();
  const bundleTileFlyoutFlag = useVariation("bundle-tile-flyout", true);

  const { price, futurePrice, savings } = getProductOfferPricing(
    ritualProductOffer,
    currency.type,
  );

  const handleAddBundle = (e: any) => {
    metrics.track("CTA Clicked", {
      location: location,
      title: e?.target?.innerText,
      name: getFeaturedBundleName(item.content),
    });

    dispatch(
      addProductToCart(
        {
          productOfferId: ritualProductOffer.product_offer_id,
        },
        {
          location: location,
        },
      ),
    );

    cartService.openCart(e);
  };

  const trackEvent = (title?: string) =>
    metrics.track("Product Clicked", {
      location: location,
      name: getFeaturedBundleName(item.content),
      title,
      products: products.map((p: any) => getPropertiesForProductSku(p.sku)),
    });

  const handleCardClick = (e: any) => {
    const target = e?.target;
    const containsAdd = target?.textContent?.includes("Add");

    if (!containsAdd) {
      if (buildBundlePdp || isBundleLandingPage) {
        trackEvent();
        navigate(`/bundles/${slug}`, undefined, e);
        return;
      }
    } else {
      handleAddBundle(e);
    }

    if (!bundleTileFlyoutFlag) return;
  };

  const discountButtonProps = {
    to: "/cart",
    noNavigate: true,
    onClick: handleCardClick,
    price: undefined,
    prefix: "Add",
    className: "plp-cta fullwidth",
    showDash: false,
  };

  const imageData: IGatsbyImageData | undefined =
    image && getImage(image.gatsbyImageData);

  const isLargeTile = size === "Large";
  const tileUrl =
    !isBundleLandingPage && buildBundlePdp ? `/bundles/${slug}` : undefined;
  const TileComponent = isLargeTile ? ProductTileLarge : ProductTile;
  const tileProps = isLargeTile
    ? {
        name: productName,
        handleClick: handleCardClick,
        url: tileUrl,
      }
    : {
        name: productName,
        description: shortDescription,
        handleClick: handleCardClick,
        isBaseTile: false,
        size: size?.toLowerCase(),
        isFeaturedProduct: false,
        url: tileUrl,
      };

  return (
    <TileComponent {...tileProps}>
      {imageData && (
        <span slot="product-image">
          <GatsbyImage
            image={imageData}
            alt={image.description}
            style={{
              width: "100%",
              height: "100%",
            }}
            loading={loading}
          />
        </span>
      )}
      {tileFlag && (
        <RBadge
          slot="product-image-r-badge"
          badgeColor="yellow"
          badgeWidth="wide"
        >
          {tileFlag.content}
        </RBadge>
      )}
      <div slot="product-image-cta">
        <DiscountButton {...discountButtonProps} />
      </div>

      <PriceBreakdown slot="price-breakdown" spread={true}>
        <RPrice slot="price-breakdown-final" priceClass="final">
          {/* @ts-ignore */}
          <Currency value={price} round={true} />
        </RPrice>
        {futurePrice && (
          <RPrice
            slot="price-breakdown-strikethrough"
            priceClass="strikethrough"
          >
            {/* @ts-ignore */}
            <Currency value={futurePrice} round={true} />
          </RPrice>
        )}
        {savings && (
          <CaptionTitle slot="price-breakdown-savings-caption">
            {/* @ts-ignore */}
            <Currency value={savings} round={true} /> savings
          </CaptionTitle>
        )}
      </PriceBreakdown>

      {size === "Large" &&
        products.map((product, i) => (
          <ProductTileItem
            slot="bundle-product-item"
            productTitle={product.name.name}
            productDescription={product.shortDescription}
            key={i}
          >
            <BundleProductImage slot="product-tile-item-image">
              {product?.image && (
                <IncludedImage
                  image={
                    getImage(
                      product?.image?.gatsbyImageData,
                    ) as IGatsbyImageData
                  }
                  alt={product?.image?.description}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
              )}
            </BundleProductImage>
          </ProductTileItem>
        ))}
    </TileComponent>
  );
};

export default BundleProductTile;
