import styled from "styled-components";
import Cart from "../../store/cart/model";
import {
  amountPriceTotal,
  basePriceTotal,
  formatTotal,
} from "../../utils/cart";
import Text from "../Text";
import CartProduct from "../../store/cart-product/model";
import InfoTooltip from "../InfoTooltip";
import { RecurringBillingDetails } from "../../store/cart/model";
import { Color } from "../../utils/styleDesignSystem";
import useVariation from "../../hooks/useVariation";

interface LedgerReceiptOptions {
  hideFutureCharges?: boolean;
  hideTax?: boolean;
  hideTotal?: boolean;
}

interface LedgerReceiptProps {
  activeCart?: Cart;
  activeCartProducts?: CartProduct[];
  isProcessing: boolean;
  activeCoupon?: any;
  appliedPromotion?: any;
  collapsed?: boolean;
  paymentMethod?: string | null;
  options?: LedgerReceiptOptions;
}

const ReceiptList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    justify-content: space-between;
    margin: 0 0 var(--spacing-0_5);
    padding: 0;

    &:last-child {
      margin-bottom: 0;
    }

    &.promo {
      * {
        color: var(--success-green);
      }
    }

    &.total {
      margin-bottom: var(--spacing-1);
      padding-bottom: var(--spacing-1);
      border-bottom: 1px solid var(--cool-40);

      &.hide-border {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
`;

const ReceiptListLabel = styled.span.attrs({
  className: "typography-label3",
})`
  .muted {
    color: var(--Solid-Primary-IndigoBlue-60, #62719f);
    font-weight: 450;
  }
`;

const ReceiptListValue = styled.span.attrs({
  className: "typography-body3 font-circular",
})`
  text-align: right;

  & > span {
    color: var(--indigo-blue-60);
  }
`;

const ReceiptListRecurringCharges = styled.span.attrs({
  className: "typography-body3 font-circular",
})`
  color: var(--indigo-blue-60);
`;

const RecurringChargesContainer = styled.li`
  svg {
    height: 16px;
    width: 16px;
  }
`;

const RecurringChargesCadenceLineItem = styled.li`
  margin-bottom: var(--spacing-0_25) !important;
`;

const TooltipContent = ({
  recurringBillingDetails,
}: {
  recurringBillingDetails: RecurringBillingDetails;
}) => {
  const billingKeys = Object.keys(recurringBillingDetails);

  const phrases = billingKeys
    .map((key) => {
      const amount = recurringBillingDetails[key]?.amount;
      if (!amount) {
        return "";
      }
      return `${formatTotal(amount)} every ${key} days`;
    })
    .join(" and ");
  /* fix if we add more than 2 cadences */

  return (
    <div>
      <p className="typography-caption">
        Some of the items you are purchasing will automatically renew as a
        subscription and will be charged {phrases} until you cancel. Your second
        shipment will be charged and shipped 3 days early to prevent any gaps in
        your Ritual. You can cancel anytime by visiting your account page on our
        website.
      </p>
    </div>
  );
};

const FutureRecurringCharges = ({
  activeCart,
  isProcessing,
  collapsed,
}: {
  activeCart: Cart;
  isProcessing: boolean;
  collapsed?: boolean;
}) => {
  const { recurringBillingDetails } = activeCart;

  if (
    !recurringBillingDetails ||
    Object.keys(recurringBillingDetails).length === 0
  ) {
    return null;
  }

  const billingKeys = Object.keys(recurringBillingDetails);
  const hasMultipleCharges = billingKeys.length > 1;

  const getRecurringChargeLabel = (key: string) => {
    switch (key) {
      case "30":
        return "Monthly";
      case "90":
        return "Every 3 months";
      default:
        return `Every ${key} days`; // For any other future keys
    }
  };

  return (
    <>
      {!collapsed && (
        <RecurringChargesContainer>
          <ReceiptListRecurringCharges>
            {hasMultipleCharges
              ? "This order has multiple recurring charges."
              : "This order has a recurring charge."}
          </ReceiptListRecurringCharges>
          <InfoTooltip
            content={
              <TooltipContent
                recurringBillingDetails={recurringBillingDetails}
              />
            }
            color={Color.indigoBlue60}
            hoverColor={Color.indigoBlue}
            placement="top-end"
            additionalProps={{ offset: [10, 10] }}
          />
        </RecurringChargesContainer>
      )}
      {billingKeys.map((key) => {
        const billingDetail = recurringBillingDetails[key];
        if (!billingDetail) {
          return null;
        }

        return (
          <RecurringChargesCadenceLineItem key={key}>
            <ReceiptListRecurringCharges>
              {getRecurringChargeLabel(key)}
            </ReceiptListRecurringCharges>
            <ReceiptListRecurringCharges>
              {formatTotal(billingDetail.amount, false, isProcessing, true)}
            </ReceiptListRecurringCharges>
          </RecurringChargesCadenceLineItem>
        );
      })}
    </>
  );
};

const defaultOptions = {
  hideFutureCharges: false,
  hideTax: false,
  hideTotal: false,
};

const LedgerReceipt = ({
  activeCart,
  activeCoupon,
  activeCartProducts,
  appliedPromotion,
  isProcessing,
  collapsed,
  paymentMethod,
  options = defaultOptions,
}: LedgerReceiptProps) => {
  const componentOptions = { ...defaultOptions, ...options };
  const welcomeOfferPromotionFlag = useVariation("welcome-offer-promotion", {});
  const welcomeOfferPromotionEnabled =
    welcomeOfferPromotionFlag?.slug &&
    welcomeOfferPromotionFlag?.slug === "welcome-offer-alc";
  if (!activeCart) return null;

  const hasFutureRecurringCharges =
    !!activeCart.recurringBillingDetails && !componentOptions.hideFutureCharges;

  const VATIncluded = activeCart.taxInclusive;
  const hideTax = componentOptions.hideTax || VATIncluded;

  if (collapsed) {
    return (
      <ReceiptList>
        <li>
          <ReceiptListLabel>Today's Total</ReceiptListLabel>
          <ReceiptListValue>
            {formatTotal(activeCart.total, false, isProcessing, true)}
          </ReceiptListValue>
        </li>
        {hasFutureRecurringCharges && (
          <FutureRecurringCharges
            activeCart={activeCart}
            isProcessing={isProcessing}
            collapsed={collapsed}
          />
        )}
      </ReceiptList>
    );
  }

  return (
    <ReceiptList>
      {activeCart?.giftCards?.map((giftCard) => {
        return (
          <li className="promo">
            <ReceiptListLabel>Gift Card</ReceiptListLabel>
            <ReceiptListValue>
              {formatTotal(giftCard?.amount, true, isProcessing)}
            </ReceiptListValue>
          </li>
        );
      })}
      {activeCoupon && (
        <li className="promo">
          <ReceiptListLabel>Promo: {activeCoupon.code}</ReceiptListLabel>
          <ReceiptListValue>
            {formatTotal(activeCart.discountAmount, true, isProcessing)}
          </ReceiptListValue>
        </li>
      )}
      {!welcomeOfferPromotionEnabled &&
        appliedPromotion &&
        activeCartProducts &&
        activeCartProducts.length > 0 && (
          <li className="promo">
            <ReceiptListLabel>{appliedPromotion.displayName}</ReceiptListLabel>
            <ReceiptListValue>
              {formatTotal(activeCart.discountAmount, true, isProcessing)}
            </ReceiptListValue>
          </li>
        )}
      {welcomeOfferPromotionEnabled &&
        appliedPromotion &&
        activeCartProducts &&
        activeCartProducts.length > 0 && (
          <li className="promo">
            <ReceiptListLabel>{appliedPromotion.displayName}</ReceiptListLabel>
            <ReceiptListValue>
              {formatTotal(
                basePriceTotal(activeCartProducts) -
                  amountPriceTotal(activeCartProducts),
                true,
                isProcessing,
              )}
            </ReceiptListValue>
          </li>
        )}
      {!!activeCart.productOfferDiscountAmount && (
        <li className="promo">
          <ReceiptListLabel>Bundle Discount</ReceiptListLabel>
          <ReceiptListValue>
            {formatTotal(
              activeCart.productOfferDiscountAmount,
              true,
              isProcessing,
            )}
          </ReceiptListValue>
        </li>
      )}
      <li>
        <ReceiptListLabel>Subtotal</ReceiptListLabel>
        <ReceiptListValue>
          {formatTotal(activeCart.subtotal, false, isProcessing, true)}
        </ReceiptListValue>
      </li>
      <li>
        <ReceiptListLabel>Shipping</ReceiptListLabel>
        <ReceiptListValue>
          {activeCart?.shippingAmount ? (
            formatTotal(activeCart.shippingAmount, false, isProcessing, true)
          ) : (
            <Text id="cart.receipt.free" defaultMessage="Free" />
          )}
        </ReceiptListValue>
      </li>
      {!hideTax && (
        <li>
          <ReceiptListLabel>Tax</ReceiptListLabel>
          <ReceiptListValue>
            {!!activeCart?.shippingAddress?.state ? (
              formatTotal(activeCart.totalTax, false, isProcessing, true)
            ) : (
              <span>Not yet calculated</span>
            )}
          </ReceiptListValue>
        </li>
      )}
      {!componentOptions.hideTotal && (
        <li
          className={`total ${
            componentOptions.hideFutureCharges ? "hide-border" : ""
          }`}
        >
          <ReceiptListLabel>
            Today's Total
            {VATIncluded && <span className="muted"> (VAT Included)</span>}
          </ReceiptListLabel>
          <ReceiptListValue>
            {formatTotal(activeCart.total, false, isProcessing, true)}
          </ReceiptListValue>
        </li>
      )}

      {paymentMethod && (
        <li className="total" data-testid="payment-method">
          <ReceiptListValue className="typography-body3">
            Payment ({paymentMethod})
          </ReceiptListValue>
          <ReceiptListValue>
            {formatTotal(activeCart.total, false, isProcessing, true)}
          </ReceiptListValue>
        </li>
      )}
      {hasFutureRecurringCharges && (
        <FutureRecurringCharges
          activeCart={activeCart}
          isProcessing={isProcessing}
          collapsed={collapsed}
        />
      )}
    </ReceiptList>
  );
};

export default LedgerReceipt;
