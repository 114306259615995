import { Component } from "react";
import styled from "styled-components";
import { rem, Color, media, Font } from "../../utils/style";
import MagicLink from "../MagicLink";
import Text from "../Text";
import intl from "../../services/intl";

const Wrapper = styled.div.attrs({
  className: "row",
})`
  text-align: center;
  padding-top: ${rem(160)};

  ${media.mobile`
    padding-top: ${rem(88)};
  `};
`;

const IngredientHeader = styled.h2.attrs({
  className: "text-center",
})`
  text-align: center;
  color: #002d6b;
  font-size: ${rem(66)};
  font-weight: 500;
  line-height: ${rem(76)};
  letter-spacing: ${rem(-2)};

  ${media.tablet`
    font-size: ${rem(34)};
    line-height: ${rem(40)};
    letter-spacing: ${rem(-0.5)};
  `} ${media.mobile`
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    letter-spacing: ${rem(-0.4)};
  `}

  em {
    ${Font.dutch};
    font-weight: 300;
  }
`;

const IngredientText = styled.p`
  ${Font.dutch} text-align: center;
  margin-top: ${rem(24)};
  margin-bottom: ${rem(56)};
  font-size: ${rem(18)};

  ${media.mobile`
    font-size: ${rem(16)};
    margin-top: ${rem(16)};
    margin-bottom: ${rem(24)};
  `};
`;

const Tabs = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  text-align: center;
  padding-bottom: 10px;
`;

const TabContainer = styled.div`
  display: flex;
  flex: 1;
  margin: 0 ${rem(24)};
  text-align: center;

  ${media.mobile`
    margin: 0 ${rem(20)};
  `} ${({ active }) =>
    active &&
    `
  & a {
    border-bottom: 2px solid ${Color.ritualBlue};
    span {
      opacity: 1.0;
    }
  }
  `};
`;

const Tab = styled.span`
  display: block;
  color: ${Color.ritualBlue};
  font-size: ${rem(18)};
  ${Font.circular} font-weight: 500;
  text-align: center;
  letter-spacing: 0;
  line-height: ${rem(28)};
  cursor: pointer;
  opacity: 0.5;
  margin-bottom: 2px;

  ${media.mobile`
    font-size: ${rem(16)};
  `};
`;

/* eslint-disable */
export default class IngredientsHeader extends Component {
  render(data) {
    const { locationPath, location, subheadText, productSku } = this.props;

    const headerText = intl.t(
      "ingredients.header.header",
      "Traceable Ingredients",
    );

    const mapView = location.pathname.includes("map");

    return (
      <Wrapper>
        <div className="col-12 col-sm-8 offset-sm-2">
          <IngredientHeader
            dangerouslySetInnerHTML={{
              __html: headerText,
            }}
          />
          <IngredientText>{subheadText}</IngredientText>
          <Tabs>
            <TabContainer
              active={!mapView}
              style={{ justifyContent: "flex-end" }}
            >
              <MagicLink
                to="/ingredients"
                state={productSku ? { productSku } : {}}
              >
                <Tab>
                  <Text
                    id="ingredients.header.list-view"
                    defaultMessage="List View"
                  />
                </Tab>
              </MagicLink>
            </TabContainer>
            <TabContainer
              active={mapView}
              style={{ justifyContent: "flex-start" }}
            >
              <MagicLink
                to={`/ingredients/map/${locationPath}`}
                state={productSku ? { productSku } : {}}
              >
                <Tab>
                  <Text
                    id="ingredients.header.map-view"
                    defaultMessage="Map View"
                  />
                </Tab>
              </MagicLink>
            </TabContainer>
          </Tabs>
        </div>
      </Wrapper>
    );
  }
}
