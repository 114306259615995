import { graphql } from "gatsby";
import { useState } from "react";
import { useSelector } from "react-redux";
import StickyProductNav from "../components/global/StickyProductNav";
import SingleProductDetailPage from "../components/product/templates/SingleProductDetailPage";
import { BillingType } from "../constants/plan";
import productSelectors from "../store/product/selectors";
import { navigate } from "../services/navigation";
import { getCadencePlans } from "../utils/ritualData";
import { getProductAttributesForSku } from "../utils/planToProduct";
import useIsMobileBreakpoint from "../hooks/useIsMobileBreakpoint";
import { Helmet } from "react-helmet";
import { productGroupSchema, productSchema } from "../utils/seo";
import ProductPageSeo from "../components/seo/ProductPage";
import { useEffect } from "react";
import { useLocation } from "@reach/router";

const navigateToProduct = (product) => {
  navigate(`/products/${product.slug}`);
};

const SEOLayer = (props) => {
  // We base the local on the currency passed to the page from the Gatsby Node context
  const locale = props.pageContext.currency;
  const { contentfulProduct, mostHelpful } = props.data;
  const { ritualProduct } = contentfulProduct;

  const productSchemaData = productSchema(ritualProduct.productGroup, locale);

  const productGroupSchemaData = productGroupSchema(
    contentfulProduct,
    ritualProduct,
    productSchemaData,
  );

  const reviewCountsMap = useSelector(productSelectors.reviewCountsMap);
  const reviewRatingsMap = useSelector(productSelectors.reviewRatingsMap);
  const { showReviews } = contentfulProduct;
  if (showReviews) {
    productGroupSchemaData.aggregateRating = {
      "@type": "AggregateRating",
      ratingValue: reviewRatingsMap[ritualProduct.product_id],
      reviewCount: reviewCountsMap[ritualProduct.product_id],
    };

    if (mostHelpful) {
      productGroupSchemaData.review = {
        "@type": "Review",
        name: mostHelpful.title,
        author: {
          "@type": "Person",
          name: mostHelpful.user.display_name,
        },
        reviewBody: mostHelpful.content,
        reviewRating: {
          "@type": "Rating",
          ratingValue: mostHelpful.score,
        },
      };
    }
  }

  return (
    <>
      <ProductPageSeo
        contentfulProduct={contentfulProduct}
        ritualProduct={ritualProduct}
        locale={locale}
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(productGroupSchemaData)}
        </script>
      </Helmet>
      <ProductPageTemplate {...props} />
    </>
  );
};

const ProductPageTemplate = (props) => {
  const ctfProduct = props.data.contentfulProduct;
  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [queryParamSet, setQueryParamSet] = useState(false);
  const location = useLocation();

  const ritualProduct = useSelector((state) => {
    return productSelectors.productForSku(state, ctfProduct);
  });

  const cadencePlans = getCadencePlans(
    ctfProduct.ritualProduct.ritualPlans,
    props.pageContext.currency,
    true,
  );

  // Set selectedPlanId with proper plan for Single Sku PDP template
  if (selectedPlanId === null) {
    const defaultPlan = cadencePlans.find((plan) => plan.interval_count === 30);
    setSelectedPlanId(defaultPlan.plan_id);
  }

  useEffect(() => {
    if (queryParamSet) return;

    const params = new URLSearchParams(location.search);
    const priceParam = params.get("price");
    if (priceParam) {
      const isValidPlan = cadencePlans?.some(
        (plan) => plan.plan_id === priceParam,
      );
      if (isValidPlan) {
        setSelectedPlanId(priceParam);
        setQueryParamSet(true);
      }
    }
  }, [cadencePlans, location.search, queryParamSet]);

  const productAttributesOneTime =
    getProductAttributesForSku(ctfProduct.sku, BillingType.OneTime) || {};
  const productAttributesSubscription =
    getProductAttributesForSku(ctfProduct.sku) || {};

  const productAttributes = {
    oneTime: productAttributesOneTime,
    subscription: productAttributesSubscription,
  };

  const reviewCountsMap = useSelector(productSelectors.reviewCountsMap);
  const reviewRatingsMap = useSelector(productSelectors.reviewRatingsMap);
  const isMobile = useIsMobileBreakpoint();

  return (
    <>
      <StickyProductNav
        sku={ctfProduct.sku}
        ctfProduct={ctfProduct}
        ctaLocation={"Sticky Nav"}
        showTopBar={!isMobile}
        quantity={1}
        selectedPlanId={selectedPlanId}
        cadencePlanCta={false}
        showDash={false}
        floatText={true}
        showAnimated={isMobile}
        isVisible={isMobile}
      />
      <SingleProductDetailPage
        {...props}
        productAttributes={productAttributes}
        product={ritualProduct}
        handleSelectedProduct={navigateToProduct}
        reviewRatingsMap={reviewRatingsMap}
        reviewCountsMap={reviewCountsMap}
        selectedPlanId={selectedPlanId}
        setSelectedPlanId={setSelectedPlanId}
      />
    </>
  );
};

export default SEOLayer;

export const query = graphql`
  query ProductQuery($locale: String!, $slug: String!, $productId: String!) {
    mostHelpful: highlightReview(
      reviewType: { eq: "Most Helpful" }
      destination: { eq: "pdp" }
      product_slug: { eq: $slug }
    ) {
      id
      score
      content
      title
      user {
        display_name
      }
    }
    mostCritical: highlightReview(
      reviewType: { eq: "Most Critical" }
      destination: { eq: "pdp" }
      product_slug: { eq: $slug }
    ) {
      id
      score
      content
      title
      user {
        display_name
      }
    }
    allProductReview(
      sort: { fields: [created_at], order: DESC }
      filter: { product_id: { eq: $productId } }
    ) {
      edges {
        node {
          id
          product_id
          score
          votes_up
          votes_down
          content
          title
          sentiment
          created_at
          verified_buyer
          sticker
          age_range
          subscription_age
          would_recommend
          received_product
          comment {
            id
            content
            created_at
          }
          user {
            user_id
            display_name
          }
        }
      }
    }
    contentfulProduct(node_locale: { eq: $locale }, slug: { eq: $slug }) {
      name {
        name
        childMarkdownRemark {
          html
        }
      }
      category
      showReviews
      stockStatus
      pillSectionTitle {
        childMarkdownRemark {
          html
        }
      }
      pillSectionImage {
        description
        gatsbyImageData(
          layout: CONSTRAINED
          width: 470
          height: 470
          quality: 90
        )
      }
      pillSectionAccordion {
        title
        content
      }
      summary
      headerDescription
      formulaHighlights {
        label
        value
      }
      slug
      path
      gtin
      articles {
        ...featuredArticlesFragment
        heroImage {
          file {
            url
          }
          gatsbyImageData(
            layout: CONSTRAINED
            height: 400
            width: 600
            quality: 90
            cropFocus: LEFT
            resizingBehavior: FILL
          )
          desktop: gatsbyImageData(
            layout: CONSTRAINED
            width: 470
            height: 341
            quality: 100
            cropFocus: LEFT
            resizingBehavior: FILL
          )
          mobile: gatsbyImageData(
            layout: CONSTRAINED
            width: 335
            height: 341
            quality: 100
            cropFocus: LEFT
            resizingBehavior: FILL
          )
        }
        primaryContent {
          __typename
          ... on ContentfulContentGeneric {
            contentBody: body {
              childMarkdownRemark {
                timeToRead
              }
            }
          }
        }
      }
      ingredients {
        __typename
        name
        slug
        description
        officialName
        source
        contentful_id
        supplier
        function {
          function
        }
        benefit
        nutritionFact
        productDosages {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        manufacturingLocation
        cardImage {
          title
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 500
            quality: 80
          )
        }
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 668
            quality: 80
          )
        }
        modalDescription
        modalDesktopImage {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 770
            quality: 80
          )
        }
        ingredientStoryContent {
          description
          title
          id
        }
        ingredientStoryImages {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 520
            quality: 80
          )
          description
          title
        }
        modalMobileImage {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 520
            quality: 80
          )
        }
        icons {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
      valueProps {
        name
        disclaimer
        icon {
          file {
            url
            fileName
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
      howToUse {
        instructions {
          raw
        }
        instructionsIcon {
          url
          description
        }
        tooltip {
          content
          type
        }
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 391
            quality: 90
          )
          description
        }
      }
      timelineHeading {
        childMarkdownRemark {
          html
        }
      }
      timelineSubhead
      timelinePhoto {
        title
        desktop: gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 470
          quality: 90
        )
        mobile: gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 280
          quality: 90
        )
        description
      }
      timelineVideo {
        ...videoContent
      }
      timelineData {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      designTour {
        title
        subheading
        config {
          duration
          frameCount
          fileNamePrefix
        }
        blocks {
          title
          text
        }
      }
      faq {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      ingredientsSubheader {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      somethingMissingTitle
      experts {
        name
        title
        titleSubhead
        expertise
        image {
          description
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 300
            quality: 90
          )
        }
      }
      doctorGuide {
        file {
          url
        }
      }
      heroBackgroundColor
      heroFeaturedImages {
        title
        gatsbyImageData(
          layout: CONSTRAINED
          width: 555
          quality: 90
          formats: [AUTO]
        )
        description
      }
      heroZoomImages: heroFeaturedImages {
        title
        gatsbyImageData(
          layout: CONSTRAINED
          width: 1920
          quality: 90
          formats: [AUTO]
        )
        description
      }
      pageTitle
      pageDescription
      socialImage {
        file {
          details {
            image {
              width
              height
            }
          }
          url
        }
      }
      sku
      scentSelector
      contentfulId: contentful_id
      productSubhead
      productStudyModal {
        linkText
        modalTitle {
          raw
        }
        headerImage {
          description
          gatsbyImageData
        }
        studyDescriptionImage {
          description
          gatsbyImageData(placeholder: NONE)
        }
        mobileStudyDescriptionImage {
          description
          gatsbyImageData(placeholder: NONE)
        }
        content {
          header
          subheader
          studyDescription
          studyResults {
            title
            results
          }
          secondaryStudyResults {
            title
            results
          }
          studySummary {
            title
            content
          }
          disclaimer
        }
      }
      supplementFacts {
        certifications
        servingSize
        servingSizeWeight
        servingsPerContainer
        additionalInformation {
          label
          value
          description
        }
        labels {
          dv
          dv2
        }
        nutrients {
          label
          dosage
          dv
          dosage2
          dv2
        }
        ingredients {
          label
          dosage
          dv
          dosage2
          dv2
        }
        notations {
          label
        }
        otherIngredients
        allergens {
          label
          value
          superset
        }
        notationReferences
        attributions {
          label
          superset
        }
        simpleIngredients {
          name
          dosage
        }
      }
      footnotes {
        childMarkdownRemark {
          html
        }
      }
      ...RitualProductPricingData
    }
    contentfulClinical(
      node_locale: { eq: $locale }
      product: { slug: { eq: $slug } }
    ) {
      title {
        childMarkdownRemark {
          html
        }
      }
      firstResult {
        childMarkdownRemark {
          html
        }
      }
      secondResult {
        childMarkdownRemark {
          html
        }
      }
      summaryTitle {
        childMarkdownRemark {
          html
        }
      }
      summary {
        childMarkdownRemark {
          html
        }
      }
      about {
        childMarkdownRemark {
          html
        }
      }
    }
  }

  fragment RitualProductPricingData on ContentfulProduct {
    ritualProduct {
      product_id
      sku
      in_stock
      sub_category
      name
      url_slug
      demographics
      age_ranges
      ritualPlans {
        plan_id
        amount
        billing_type
        currency
        interval
        interval_count
        in_stock
        variant_contents
        variant_description
        base_price
        number_of_months
        monthly_amount
        monthly_amount_savings
        monthly_percent_savings
      }
      productGroup {
        ritualProducts {
          name
          product_id
          url_slug
          contentfulProduct {
            path
            slug
            gtin
            socialImage {
              file {
                details {
                  image {
                    width
                    height
                  }
                }
                url
              }
            }
            heroFeaturedImages {
              file {
                details {
                  image {
                    width
                    height
                  }
                }
                url
              }
            }
          }
          ritualPlans {
            plan_id
            amount
            billing_type
            currency
            interval
            interval_count
            in_stock
            variant_contents
            variant_description
            base_price
            number_of_months
            monthly_amount
            monthly_amount_savings
            monthly_percent_savings
          }
        }
      }
    }
  }
`;
