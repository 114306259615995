import styled from "styled-components";
import { useState } from "react";
import classNames from "classnames";
import { responsive } from "../../utils/style";

import GridContainer from "../../components/grid/GridContainer";
import GridRow from "../../components/grid/GridRow";
import GridColumn from "../../components/grid/GridColumn";
import { IconPlus, IconMinus, IconArrowRight } from "../../utils/svg";
import { MagicSVGImage } from "../MagicSVGImage";
import FollowElement from "../../components/FollowElement";
import metrics from "../../utils/metrics";

const FAQsContent = styled.div`
  padding-bottom: var(--spacing-5);
  position: relative;
  z-index: 3;

  @media (min-width: 933px) {
    padding-bottom: 120px;
  }
`;

const FAQContainer = styled(GridContainer)`
  &.single-product {
    padding: 0;
  }
`;

const MobileHeading = styled.h2`
  display: block !important;
  margin-bottom: var(--spacing-2, 32px);
  font-size: 2rem;
  font-weight: 450 !important;
  line-height: 40px;
  letter-spacing: -0.01em;

  @media (min-width: 750px /* Grid S Tablet */) {
    display: none !important;
  }
`;

const DesktopHeading = styled.h2`
  display: none !important;
  margin-bottom: 0;
  font-size: 40px;
  font-weight: 450 !important;
  line-height: 40px;
  letter-spacing: -1%;

  @media (min-width: 750px /* Grid S Tablet */) {
    display: block !important;
    margin-bottom: var(--spacing-1, 16px);
  }

  @media (min-width: 933px) {
    font-size: 2.5rem;
    font-weight: !important;
    line-height: 50px;
    letter-spacing: -0.02em;
  }
`;

const FAQElements = styled.div`
  border-top: 2px solid var(--indigo-blue);
`;

const FAQElement = styled.div`
  padding-bottom: var(--spacing-2);
  margin: var(--spacing-2) 0;
  border-bottom: 1px solid var(--indigo-blue-20, #d0d5e2);
`;

const Question = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-1, 16px);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: 1.5rem;
  color: var(--indigo-blue);

  h3 {
    font-weight: 450;
    font-size: var(--spacing-1_5, 24px);
    line-height: 25px;
    margin-bottom: 0;
    text-align: left;

    @media (min-width: 933px) {
      font-size: var(--spacing-1_5, 24px);
      line-height: 30px;
    }

    ${responsive.sm`
      font-size: var(--spacing-1_25, 20px);
    `};
  }

  .icon-plus-minus {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 48px;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    color: var(--indigo-blue);
    transition: background-color 0.2s ease-in-out;

    img {
      height: 24px;
      width: 24px;
    }
  }

  &:hover {
    .icon-plus-minus {
      background-color: var(--yellow-ochre-60);
    }
  }

  [data-whatintent="touch"] &:hover {
    .icon-plus-minus {
      background-color: none;
    }
  }
`;

const Answer = styled.p`
  margin-bottom: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, padding-top 0.3s ease-in-out;

  &.closed {
    max-height: 0;
    padding-top: 0;
  }

  &.open {
    max-height: 800px;
    padding-top: var(--spacing-1_5, 24px);
  }
`;

const FAQLink = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--spacing-0_75, 12px);
  font-size: var(--spacing-0_75, 12px);

  @media (min-width: 933px) {
    font-size: var(--spacing-1, 16px);
  }

  .arrow-icon {
    height: 16px;
    width: 16px;
    padding: 24px;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out;
  }

  &:hover {
    .arrow-icon {
      background-color: var(--yellow-ochre-60);
    }
  }
`;

type FAQ = {
  question: string;
  answer: string;
};

type Props = {
  faqs: FAQ[];
  children?: React.ReactNode;
  singleProduct?: boolean;
  customOffset?: number;
};

const Plus = () => {
  return <MagicSVGImage src={IconPlus} width={24} height={24} alt="Plus" />;
};

const Minus = () => {
  return <MagicSVGImage src={IconMinus} width={24} height={24} alt="Minus" />;
};

const Arrow = () => {
  return (
    <MagicSVGImage
      src={IconArrowRight}
      width={16}
      height={16}
      alt="Arrow Right"
    />
  );
};

const FAQs = ({
  faqs,
  children,
  singleProduct = false,
  customOffset = 48,
}: Props) => {
  const [openFAQ, setOpenFAQ] = useState<number | null>(null);

  const toggleFAQ = (index: number) => {
    setOpenFAQ(openFAQ === index ? null : index);

    metrics.track("Dr. Luke QA Expanded", {
      title: faqs[index]?.question,
    });
  };

  const faqUrl = "https://help.ritual.com";
  return (
    <FAQsContent>
      <FAQContainer className={classNames({ "single-product": singleProduct })}>
        <GridRow>
          <GridColumn
            xs={{ size: 6, hide: true }}
            s={{ size: 5 }}
            m={{ size: 5 }}
          >
            <FollowElement customOffset={customOffset}>
              <DesktopHeading
                className={classNames("typography-headline2 typography-medium")}
              >
                Frequent Questions
              </DesktopHeading>
            </FollowElement>
          </GridColumn>
          <GridColumn
            xs={{ size: 0, hide: true }}
            s={{ size: 2, hide: true }}
            m={{ size: 2, hide: true }}
          ></GridColumn>
          <GridColumn xs={{ size: 6 }} s={{ size: 7 }} m={{ size: 7 }}>
            <MobileHeading
              className={classNames("typography-headline2 typography-medium")}
            >
              Frequent Questions
            </MobileHeading>
            <div>
              <FAQElements
                className={classNames({ "single-product": singleProduct })}
              >
                {faqs.map((faq, index) => (
                  <FAQElement key={index}>
                    <Question onClick={() => toggleFAQ(index)}>
                      <h3 className="typography-lead2 typography-medium">
                        {faq.question}
                      </h3>
                      <div className="icon-plus-minus">
                        {openFAQ === index ? <Minus /> : <Plus />}
                      </div>
                    </Question>
                    <Answer
                      className={`typography-body1 ${
                        openFAQ === index ? "open" : "closed"
                      }`}
                      dangerouslySetInnerHTML={{ __html: faq.answer }}
                    ></Answer>
                  </FAQElement>
                ))}
              </FAQElements>
              <FAQLink
                href={faqUrl}
                aria-label="All FAQs"
                className="typography-eyebrow1"
              >
                See All FAQS{" "}
                <div className="arrow-icon">
                  <Arrow />
                </div>
              </FAQLink>
            </div>
          </GridColumn>
        </GridRow>
      </FAQContainer>
      {children}
    </FAQsContent>
  );
};

export default FAQs;
