import { Children, Fragment } from "react";
import { useCarousel } from "../utils/useCarousel";
import styled from "styled-components";
import { Color, responsive, rem, media, Opacity } from "../utils/style";
import { Icons } from "../utils/react-svg";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

const CarouselContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
`;

const CarouselItem = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const Ol = styled.ol.attrs({
  id: "featured-articles_container_row-1_column_controls",
  className: "d-flex align-items-center justify-content-center",
})`
  z-index: 15;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 0 auto;
  padding-top: 32px;
  padding-left: 0;
`;

const CarouselSlideMarker = styled.li.attrs((p) => ({
  id: `featured-articles_container_row-1_column_controls_marker-${p.index}`,
  className: `CarouselNew__CarouselSlideMarker`,
}))`
  display: block;
  width: 7px;
  height: 7px;
  border: 1px solid ${Color.ritualBlue};
  border-radius: 50%;
  margin-right: 16px;
  position: relative;
  flex: 0 1 auto;
  cursor: pointer;
  ${(p) =>
    p.active
      ? `
    background-color: ${Color.ritualBlue};
    border-color: ${Color.ritualBlue};
  `
      : `
    background-color: ${Color.white};
    border-color: ${Color.ritualSecondaryDisabledBlue};
  `} &:last-child {
    margin-right: 0;
  }
`;

const CarouselControls = styled.div.attrs({
  id: "experts_container_row_column_controls",
})`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${rem(27)};
  padding-top: 27px;
  ${responsive.sm`
    padding-top: ${rem(43)};
  `};
`;

const CarouselButton = styled.button.attrs((p) => ({
  id: `experts_container_row_column_controls_button-${p.index}`,
}))`
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:first-child {
    margin-right: 80px;

    ${media.mobile`
      margin-right: 40px;
    `};
  }

  i {
    position: relative;
    display: block;
    cursor: pointer;
    width: 10px;
    height: 18px;

    ${media.mobile`
      width: 8px;
      height: 14px;
    `};
    svg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &:disabled {
    opacity: ${Opacity.light};
  }
`;

/*

# Primary Carousel component, utilizing the `useCarousel` hook.

The only required prop is `children`.

Optional props are `interval`, `showButtons`, `arrowButtons`, `disableCarousel`, and `slidesPresented`.
`interval` can be specified by ms and will transition slides at that interval.
`showButtons` will render circle buttons at the bottom of the carousel and can be clicked to transition slides.
`arrowButtons` will render two left and right buttons as seen on the `productPage` in the `Experts` section.
`disableCarousel` will remove the drag and carousel functionality and will instead show all slides in a grid.
`slidesPresented` can be specified by int to display any number of slides desired instead of 1 child as is by default.

# Usage

Import `<CarouselContainer />` && `<CarouselChild />` from this file. Wrap any amount of desired slides in both.

# Example:

import { CarouselContainer, CarouselChild } from 'Carousel.New';

<div>
  <CarouselContainer
    interval={int}
    showButtons={bool}
    arrowButtons={bool}
    disableCarousel={bool}
    slidesPresented={int}
  >
      {data.map((key, index) => {
        return (
          <CarouselChild>
            <div />
          </CarouselChild>
        )
      })}
  </CarouselContainer>
</div>

 */

export const CarouselContainer = ({
  children,
  interval,
  showButtons,
  arrowButtons,
  disableCarousel,
  slidesPresented = 1,
}) => {
  const slides = Children.toArray(children);
  const length = slides.length;
  const numActive = Math.min(length, slidesPresented);
  const [active, setActive, handlers, style] = useCarousel(length, interval, {
    slidesPresented: numActive,
  });
  return (
    length > 0 && (
      <Wrapper>
        <CarouselContent
          {...handlers}
          style={!disableCarousel ? style : null}
          data-testid="carousel"
        >
          {!disableCarousel && <RenderEnabledCarousel slides={slides} />}
          {disableCarousel && <RenderDisabledCarousel slides={slides} />}
        </CarouselContent>
        {showButtons && (
          <Buttons slides={slides} setActive={setActive} active={active} />
        )}
        {arrowButtons && (
          <ArrowButtons
            length={length}
            setActive={setActive}
            active={active}
            slidesPresented={slidesPresented}
          />
        )}
      </Wrapper>
    )
  );
};

export const CarouselChild = ({ children }) => (
  <CarouselItem>{children}</CarouselItem>
);

const RenderEnabledCarousel = ({ slides, beforeIndices, afterIndices }) => {
  return (
    <Fragment>
      {slides.map((slide, index) => (
        <CarouselChild key={index}>{slide}</CarouselChild>
      ))}
    </Fragment>
  );
};

const RenderDisabledCarousel = ({ slides }) => {
  return (
    <Fragment>
      {slides.map((slide, index) => (
        <CarouselChild key={index}>{slide}</CarouselChild>
      ))}
    </Fragment>
  );
};

const Buttons = ({ slides, setActive, active }) => {
  return (
    <Ol>
      {slides.map((_, index) => (
        <CarouselSlideMarker
          onClick={() => setActive(index)}
          index={index}
          key={index}
          active={active === index}
        />
      ))}
    </Ol>
  );
};

const ArrowButtons = ({ length, setActive, active, slidesPresented }) => {
  return (
    <CarouselControls>
      <CarouselButton
        disabled={active === 0}
        onClick={() => setActive(active - 1)}
        aria-label="Previous Slide"
      >
        <i>
          <Icons.CaretLeft />
        </i>
      </CarouselButton>
      <CarouselButton
        disabled={active === length - slidesPresented}
        onClick={() => setActive(active + 1)}
        aria-label="Next Slide"
      >
        <i>
          <Icons.CaretRight />
        </i>
      </CarouselButton>
    </CarouselControls>
  );
};
