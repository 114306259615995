import { useState } from "react";
import styled from "styled-components";
import FloatingInput from "../global/FloatingInput";
import { responsive, media } from "../../utils/style";
import fetchInternal from "../../utils/fetch";
import { Icons } from "../../utils/react-svg";

import RitualButton from "../global/RitualButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-1);
  padding-bottom: 0;

  ${responsive.sm`
    padding: var(--spacing-1_5);
    padding-bottom: 0;
  `};

  border-radius: var(--spacing-0_25, 4px);
  background: var(--white);
  margin-bottom: var(--spacing-1_5);
  border-radius: 4px;
`;

const Subheader = styled.p`
  margin-bottom: 0;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: var(--spacing-1_5);
  padding-bottom: var(--spacing-1_5);
  width: 100%;
  gap: var(--spacing-1);
`;

const NameInputsContainer = styled.div`
  display: flex;
  gap: var(--spacing-1);
  flex-direction: column;
  width: 100%;

  ${responsive.sm`
    flex-direction: row;
  `};
`;

const PasswordInputContainer = styled.div`
  width: 100%;
  margin-bottom: var(--spacing-0_5);
`;

const Feedback = styled.div<{ $state?: "success" | "error" }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: var(--Between-Components-Only-spacing-0_25, 4px);
  background-color: ${(props) =>
    props.$state === "success"
      ? `var(--success-green, #4c840d)`
      : `var(--error-red, #c83d1e)`};

  padding: var(--spacing-0_75);
  width: 100%;

  ${responsive.sm`
    align-items: center;
  `};

  svg {
    height: var(--spacing-1);
    width: var(--spacing-1);

    ${media.mobile`
      margin-top: var(--spacing-0_25);
    `};
  }

  p {
    color: var(--white);
    margin-left: var(--spacing-1);
    margin-bottom: 0;
  }
`;

const SlimIconContainer = styled.div`
  width: 16px;

  svg {
    margin-top: 0 !important;
  }
`;

interface AccountSetupProps {
  token: string;
  initialLastName?: string | null;
  initialFirstName?: string | null;
  authenticateAfterSetup: (email: string, password: string) => void;
}

interface APIResponse {
  data: {
    attributes: {
      email: string;
    };
  };
}

const AccountSetup: React.FC<AccountSetupProps> = ({
  token,
  initialLastName,
  initialFirstName,
  authenticateAfterSetup,
}) => {
  const [firstName, setFirstName] = useState(initialFirstName || "");
  const [lastName, setLastName] = useState(initialLastName || "");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const validForm =
    [firstName, lastName, password].every(
      (input) => input.length > 0 && input.length <= 40,
    ) && password.length >= 6;

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validForm) return;
    setSuccess(false);
    setError(false);

    try {
      const response = (await fetchInternal("users/account_setup", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        fetchOptions: {
          addCastleRequestToken: true,
        },
        body: JSON.stringify({
          token,
          password,
          first_name: firstName,
          last_name: lastName,
        }),
      })) as APIResponse;
      const email = response.data.attributes.email;
      await authenticateAfterSetup(email, password);
      setSuccess(true);
    } catch (error) {
      setError(true);
    }
  };

  return (
    <Container>
      <h2 className="typography-lead2">Finish your account setup</h2>
      <Subheader className="typography-body2 font-dutch">
        Almost there! Double-check your details & set a password to unlock
        account access.
      </Subheader>
      <Form onSubmit={handleSubmit}>
        <NameInputsContainer>
          <FloatingInput
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="First name"
            maxLength={40}
            minLength={1}
          />
          <FloatingInput
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Last name"
            maxLength={40}
            minLength={1}
          />
        </NameInputsContainer>
        <PasswordInputContainer>
          <FloatingInput
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            maxLength={40}
            minLength={6}
          />
        </PasswordInputContainer>

        <RitualButton
          disabled={!validForm || success}
          aria-disabled={!validForm || success}
          onClick={handleSubmit}
          className="fullwidth"
          isLink={false}
        >
          Create Account
        </RitualButton>

        {success && (
          <Feedback $state="success">
            <SlimIconContainer>
              <Icons.CheckSlim />
            </SlimIconContainer>
            <p className="typography-body3 font-circular">
              Your account has been created successfully — you're all set!
            </p>
          </Feedback>
        )}
        {error && (
          <Feedback $state="error">
            <Icons.AlertWarning />
            <p className="typography-body3 font-circular">
              An unexpected error occurred while setting up your account. Please
              try again or contact support if the issue persists.
            </p>
          </Feedback>
        )}
      </Form>
    </Container>
  );
};

export default AccountSetup;
