import cartService from "../../services/cart";
import metrics from "../../utils/metrics";
import ProductButton from "./ProductButton";
import CtaDetailText from "./hero/CtaDetailText";

type Props = {
  location: string;
  animated?: boolean;
  isVisible?: boolean;
  price: number;
  futurePrice?: number;
  discountPrice?: number | undefined;
  className?: string;
  selectedPlan?: any;
  product?: any;
  productButtonProps?: any;
  promotion?: any;
  textCta?: string;
  detailTextEnabled?: boolean;
};

const AddProductButton = ({
  location,
  isVisible,
  animated,
  price,
  className,
  discountPrice,
  selectedPlan,
  product,
  productButtonProps,
  textCta,
  detailTextEnabled = true,
}: Props) => {
  const handleTrackAddProduct = (e: any) => {
    metrics.track("CTA Clicked", {
      location,
      title: "Add to Cart",
    });

    cartService.openCart(e);
  };

  const simpleButtonProps = {
    actionType: "add-to-cart",
    noNavigate: false,
    product: product,
    trackCTAClick: handleTrackAddProduct,
    location: "Single Product PDP Hero",
    planType: selectedPlan?.billingType || "recurring",
    showPricing: true,
    className,
    productButtonProps: {
      showAnimated: animated,
      discountPrice,
      floatText: true,
      animatedPrice: price !== discountPrice ? price : undefined,
      cadencePlanCta: true,
      isVisible: isVisible,
    },
    ...productButtonProps,
    selectedPlan,
    textCta,
  };

  return (
    <>
      <ProductButton {...simpleButtonProps} />
      {detailTextEnabled && (
        <CtaDetailText product={product} customClass={"full-width"} />
      )}
    </>
  );
};

export default AddProductButton;
