import { useRef, useEffect, forwardRef } from "react";
import styled from "styled-components";
import classNames from "classnames/bind";

// Services
import intl from "../../../services/intl";
import { navigate } from "../../../services/navigation";

// Utils
import { responsive, rem, Opacity, Color } from "../../../utils/style";

// Components
import MagicLink from "../../MagicLink";
import ScrollableOverflow from "../../ScrollableOverflow";
import BundleRibbon from "../../bundle/BundleRibbon";

const ItemList = styled.ul`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0;

  ${responsive.sm`
    justify-content: center;
  `}

  ${(p) => p.contentStyle}
`;

const ListItem = styled.li`
  list-style: none;

  ${responsive.sm`
    position: relative;
  `}

  ${(p) => p.contentStyle}
`;

const ActiveItemIcon = styled.div`
  height: 4px;
  width: 4px;
  background-color: ${Color.ritualBlue};
  border-radius: 8px;
  margin: auto;

  ${responsive.sm`
    position: absolute;
    top: -4px;
    left: 50%;
    overflow: visible;
  `}

  ${(p) => p.contentStyle}
`;

const ItemLink = styled(MagicLink)`
  padding: 0 16px;
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  opacity: ${Opacity.light};
  position: relative;

  ${responsive.md`
    font-size: ${rem(22)};
    letter-spacing: -0.2px;
    line-height: ${rem(32)};
    padding: 0 32px;
  `}

  &.active-item {
    opacity: 1;
  }

  ${(p) => p.contentStyle}
`;

const ScrollableNav = (props) => {
  const scrollableRef = useRef(null);
  const itemRefs = useRef(new Array());
  const {
    items,
    currentItem,
    contentStyle,
    trackClick,
    shopPage = false,
  } = props;

  useEffect(() => {
    const scrollOffset = props.location?.state?.scrollOffset;

    if (scrollableRef.current && itemRefs.current.length) {
      // Scroll to the previously active index without animating. This ensures
      // that the subsequent slide transition starts from the previously
      // active item.
      if (scrollOffset) {
        scrollableRef.current.scrollToPosition(scrollOffset * -1, false);
        // return;
      }

      // If an item was previously active, animate a transition to the newly
      // active item.
      const activeIndex = items.findIndex((item) => item.key === currentItem);
      scrollableRef.current.scrollToElement(
        itemRefs.current[activeIndex],
        "center",
        scrollOffset !== "undefined",
      );
    }
  }, []);

  function isActiveItem(item) {
    return item.key === currentItem;
  }

  return (
    <nav>
      <div className="d-block d-sm-none">
        <ScrollableOverflow
          ref={scrollableRef}
          bleedLeft={true}
          bleedRight={true}
        >
          <ItemList
            contentStyle={contentStyle.listStyle}
            data-test-item-list-mobile
          >
            {items.map((item, i) => {
              return (
                <ListItemComponent
                  key={`${item.slug}-${i}-mobile`}
                  item={item}
                  activeItem={isActiveItem(item)}
                  path={item.path}
                  ref={(e) => itemRefs.current.push(e)}
                  scrollableRef={scrollableRef}
                  contentStyle={contentStyle}
                  shopPage={shopPage}
                  trackClick={trackClick}
                >
                  {shopPage && i === items.length - 1 && (
                    <BundleRibbon short={true} />
                  )}
                </ListItemComponent>
              );
            })}
          </ItemList>
        </ScrollableOverflow>
      </div>
      <div className="d-none d-sm-block">
        <ItemList
          contentStyle={contentStyle.listStyle}
          data-test-item-list-desktop
        >
          {items.map((item, i) => (
            <ListItemComponent
              key={`${item.slug}-${i}`}
              item={item}
              activeItem={isActiveItem(item)}
              path={item.path}
              contentStyle={contentStyle}
              shopPage={shopPage}
              trackClick={trackClick}
            >
              {shopPage && i === items.length - 1 && <BundleRibbon />}
            </ListItemComponent>
          ))}
        </ItemList>
      </div>
    </nav>
  );
};

const ListItemComponent = forwardRef((props, ref) => {
  const {
    item,
    activeItem,
    path,
    scrollableRef,
    contentStyle,
    shopPage,
    children,
    trackClick,
  } = props;
  const { listItemStyle, itemLinkStyle, activeItemIconStyle } = contentStyle;

  function handleClick(e) {
    e.preventDefault();

    trackClick && trackClick(item);

    navigate(`${path}`, {
      state: {
        scrollOffset: scrollableRef?.current?.getLeftPosition(),
      },
    });
  }

  return (
    <ListItem ref={ref} contenStyle={listItemStyle}>
      {!shopPage && activeItem && (
        <ActiveItemIcon contentStyle={activeItemIconStyle} />
      )}
      <ItemLink
        className={classNames({
          "active-item": activeItem,
        })}
        data-test-list-item-link
        contentStyle={itemLinkStyle}
        to={path}
        onClick={handleClick}
        title={intl.t(
          "journal.nav.category-link-title",
          `Article Category - {title}`,
          {
            title: item.title,
          },
        )}
      >
        {item.title}
        {children}
      </ItemLink>
    </ListItem>
  );
});

export default ScrollableNav;
