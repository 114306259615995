// Utils
import { getCookie } from "./cookies";
import fetch from "./fetch";
import { getStoredUser } from "./currentUser";
import * as Sentry from "@sentry/react";

export const updateUserPreferences = () => {
  const preferencesCookie = getCookie("tracking-preferences");
  const currentUser = getStoredUser();

  if (currentUser && currentUser.id) {
    fetch(`users/${currentUser.id}/tracking_preferences`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ segment_integrations: preferencesCookie }),
    }).catch((error) => Sentry.captureException(error));
  }
};
