import { debounce } from "lodash";
import metrics from "./metrics";
import { getStoredUser } from "./currentUser";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

/**
 * Creates a debounced function to identify a user with their email and marketing preferences.
 * This helps reduce the number of API calls when multiple updates happen in quick succession.
 * Will only make the identify call if a valid email format is present.
 *
 * @param debounceMs - Optional debounce delay in milliseconds, defaults to 500ms
 * @returns A debounced function that takes email and marketing preference as parameters
 */
export const createDebouncedIdentify = (debounceMs: number = 500) => {
  return debounce((email: string, marketingPreference: boolean) => {
    if (!email?.trim() || !EMAIL_REGEX.test(email)) {
      return;
    }
    const currentUser = getStoredUser();

    metrics.identify(currentUser?.id, {
      email,
      marketingPreference,
    });
  }, debounceMs);
};

/**
 * Creates a debounced function to track when a user enters their email.
 * This helps reduce the number of track events when multiple updates happen in quick succession.
 * Will only track if a valid email format is present.
 *
 * @param debounceMs - Optional debounce delay in milliseconds, defaults to 500ms
 * @returns A debounced function that takes email and location as parameters
 */
export const createDebouncedEmailTrack = (debounceMs: number = 500) => {
  return debounce((email: string, location: string) => {
    if (!email?.trim() || !EMAIL_REGEX.test(email)) {
      return;
    }

    metrics.track("Email Entered", {
      location,
    });
  }, debounceMs);
};
