import Helmet from "react-helmet";
import config from "../../utils/siteConfig";
import GeneralTags from "./GeneralTags";
import { productGroupSchema, productSchema } from "../../utils/seo";

// TODO: Define types
interface ListPageSEOProps {
  seoProps: any;
  currency: any;
}

const ListPageSEO = ({ seoProps, currency }: ListPageSEOProps) => {
  const { path, title, description, items, image } = seoProps;

  const pageUrl = `${config.siteUrl}/${path}`;
  const pageTitle = `Shop ${title} | ${config.siteTitle}`;
  const imgUrl = image || config.siteUrl + config.shareImage;

  let meta = [
    { name: "image", content: imgUrl },
    { name: "title", content: pageTitle },
    { property: "og:image", content: imgUrl },
    { name: "twitter:image", content: imgUrl },
  ];

  let breadcrumbListSchema = [
    {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": config.siteUrl,
            name: "Home",
          },
        },
        {
          "@type": "ListItem",
          position: 2,
          item: {
            "@id": pageUrl,
            name: pageTitle,
          },
        },
      ],
    },
  ];

  const allProducts = items
    .flatMap((item: any) => {
      if (item.content?.ritualProduct) return item.content;
      return null;
    })
    .filter(Boolean);

  const productGroupSchemas = allProducts.map((contentfulProduct: any) => {
    const product = contentfulProduct.ritualProduct;
    const productSchemaData = productSchema(product.productGroup, currency);
    const productGroupSchemaData = productGroupSchema(
      contentfulProduct,
      product,
      productSchemaData,
    );

    return productGroupSchemaData;
  });

  return (
    <div>
      <GeneralTags
        pagePath={path}
        title={pageTitle}
        description={description}
        image={image}
      />
      <Helmet meta={meta}>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@graph": [...breadcrumbListSchema, ...productGroupSchemas],
          })}
        </script>
      </Helmet>
    </div>
  );
};

export default ListPageSEO;
