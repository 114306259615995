import styled from "styled-components";

// Utils
import { Font, media, rem, maxLines, Color } from "../../utils/style";

// Components
import MagicLink from "../MagicLink";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ArticleReadtime from "./ArticleReadtime";
import ArticlePreviewCategory from "./ArticlePreviewCategory";

// Styled Elements
const Content = styled.div.attrs({
  className: "content",
})`
  min-height: 427px;

  ${media.notDesktop`
    min-height: 340px;
  `};
`;

const Article = styled.div`
  align-self: flex-end;
  max-width: 320px;
  z-index: 2;

  span,
  h2 {
    color: ${(p) => (p.color ? p.color : Color.ritualBlue)};

    a {
      color: inherit;
    }
  }
`;

const ArticleContent = styled(Content)`
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: ${rem(50)};
  background-color: ${(p) => p.background};

  ${media.notDesktop`
    min-height: 340px;
    padding: ${rem(24)};
  `} img {
    transition: all 0.2s ease-in-out !important;
  }

  &:hover {
    cursor: pointer;

    img {
      transform: scale(1.1);
    }
  }

  ${media.mobile`
    &:hover img {
      transform: none;
    }
  `};
`;

const ArticleTitle = styled.h2`
  ${Font.circular}
  font-size: ${rem(22)};
  line-height: ${rem(30)};
  font-weight: 400;
  text-align: left;
  letter-spacing: -0.2px;
  margin: 0;

  a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  ${media.notDesktop`
    font-size: ${rem(22)};
  `}
`;

const ArticlePreview = styled.p`
  ${Font.dutch}
  font-size: ${rem(18.4)};
  line-height: ${rem(28)};
  font-weight: normal;
  text-align: left;
  margin: ${rem(16)} 0 0;
  ${maxLines(30, 3)}

  ${media.notDesktop`
    font-size: ${rem(16)};
    line-height: ${rem(24)};
    ${maxLines(28, 3)}
    margin: ${rem(18)} 0 0;
  `}
`;

const Featured2UpArticle = ({ article, category, showPreview = false }) => {
  if (!article) return null;

  return (
    <MagicLink to={article} aria-labelledby={article.slug}>
      <ArticleContent background={article.heroBackgroundColor}>
        <GatsbyImage
          className="d-sm-none"
          image={getImage(article.heroImage.mobile)}
          color={article.heroBackgroundColor}
          alt={article.title}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: "1",
            userSelect: "none",
            userDrag: "none",
            pointerEvents: "none",
            touchCallout: "none",
          }}
        />
        <GatsbyImage
          className="d-none d-sm-block"
          image={getImage(article.heroImage.desktop)}
          color={article.heroBackgroundColor}
          alt={article.title}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: "1",
            userSelect: "none",
            userDrag: "none",
            pointerEvents: "none",
            touchCallout: "none",
          }}
        />
        <Article color={article.heroSecondaryColor}>
          {category ? (
            <ArticlePreviewCategory
              category={
                category.slug === "featured" ? article.category : category
              }
              color="#ffffff"
            />
          ) : (
            <ArticleReadtime article={article} />
          )}
          <ArticleTitle id={article.slug}>{article.title}</ArticleTitle>
          {showPreview && (
            <ArticlePreview>{article.previewText}</ArticlePreview>
          )}
        </Article>
      </ArticleContent>
    </MagicLink>
  );
};

export default Featured2UpArticle;
