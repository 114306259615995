import { useSelector } from "react-redux";
import { ActionType } from "../constants/product";
import { selectStickySearchParam } from "../store/route/selectors";
import subscriptionSelectors from "../store/subscription/selectors";
import { getBundleCartLimit } from "../utils/bundle";
import useVariation from "./useVariation";

const useStickySearchParam = (param) =>
  useSelector((state) => selectStickySearchParam(state, param));

// @todo - Move to selectors. See src/store/subscription/selectors.js
const useAvailableSubscriptions = () =>
  useSelector((state) => {
    const limit = getBundleCartLimit();
    const allSubscriptions = subscriptionSelectors.allSubscriptions(state);
    return allSubscriptions.filter((subscription) => {
      const itemQuantity = subscriptionSelectors.itemQuantityForSubscription(
        state,
        {
          id: subscription.id,
        },
      );
      return itemQuantity < limit;
    });
  });

const useShoppingIntention = (args = {}) => {
  const stickyActionType = useStickySearchParam("at");
  const stickySubscriptionId = useStickySearchParam("sid");
  const quickAddForSubscribersEnabled = useVariation("quick-add-pdp");
  const availableSubscriptions = useAvailableSubscriptions();

  const quickAddForSubscribers =
    quickAddForSubscribersEnabled &&
    availableSubscriptions.length &&
    ActionType.QuickAdd;

  const desiredActionType =
    args.actionType || stickyActionType || quickAddForSubscribers;

  const onlyAvailableSubscription =
    availableSubscriptions.length === 1 && availableSubscriptions[0];

  const subscriptionId =
    args.subscriptionId || stickySubscriptionId || onlyAvailableSubscription.id;

  const canQuickAdd =
    desiredActionType === ActionType.QuickAdd && subscriptionId;

  const canAuthedAdd = [ActionType.QuickAdd, ActionType.AuthedAdd].includes(
    desiredActionType,
  );

  const actionType =
    (canQuickAdd && ActionType.QuickAdd) ||
    (canAuthedAdd && ActionType.AuthedAdd) ||
    ActionType.AddToCart;

  return actionType !== ActionType.QuickAdd
    ? { actionType }
    : { actionType, subscriptionId };
};

export default useShoppingIntention;
