import { Component } from "react";
import styled from "styled-components";

// Components
import Row from "../Row";
import Text from "../Text";
import MagicLink from "../MagicLink";
import ReviewCTA from "./ReviewCTA";

// Utils
import { Font, Color, rem, media, Opacity } from "../../utils/style";
import metrics from "../../utils/metrics";

const StyledRow = styled(Row)`
  padding-top: 24px;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;

  ${media.mobile`
    padding-top: 15px;
  `};
`;

const Wrapper = styled.div.attrs({
  className:
    "col-12 d-flex flex-column flex-sm-row align-items-center justify-content-between",
})`
  padding-left: 0 !important;
  padding-right: 0 !important;
`;

const PaginationWrapper = styled.div.attrs({
  className: "w-100 order-0 order-sm-1 mb-4 mb-sm-0",
})``;

const NumberLink = styled.a`
  ${Font.circular};
  margin: 0 10px;
  color: ${Color.ritualBlue};
  font-size: ${rem(16)};
  line-height: ${rem(22)};
  opacity: ${Opacity.light};
  display: inline-block;
  min-width: 9px;
  transition: opacity 200ms ease-in-out;

  &.active {
    opacity: 1 !important;
    cursor: default;
    border-bottom: 2px solid ${Color.ritualBlue};
  }

  &:hover {
    opacity: ${Opacity.light};
  }
`;

const PageLink = styled(MagicLink)`
  ${Font.circular};
  color: ${Color.ritualBlue};
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  border-bottom: 2px solid ${Color.ritualBlue};
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: ${Opacity.light};
  }
`;

const Ellipsis = styled.span`
  color: ${Color.ritualBlue};
  opacity: ${Opacity.light};

  &:after {
    content: "...";
  }
`;

export default class ReviewPagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      isAlternativeVerbiageEnabled: false,
    };
  }

  reset() {
    this.setState({
      currentPage: 1,
    });
  }

  pageNumberArray(maxPage, currentPage) {
    if (maxPage <= 6) {
      return this.range(1, maxPage);
    } else if (currentPage <= 4) {
      return this.range(1, 5).concat(["...", maxPage]);
    } else if (currentPage >= maxPage - 3) {
      return [1, "..."].concat(this.range(maxPage - 4, maxPage));
    } else {
      return [1, "..."]
        .concat(this.range(currentPage - 2, currentPage + 2))
        .concat(["...", maxPage]);
    }
  }

  range(from, to) {
    return new Array(to - from + 1).fill().map((_, i) => i + from);
  }

  handleClick(pageNumber, e) {
    e.preventDefault();

    this.trackReviewPageSelected(this.state.currentPage, pageNumber);

    this.setState({
      currentPage: pageNumber,
    });
    this.props.onPageSelect(pageNumber);
  }

  trackReviewPageSelected(currentPage, nextPage) {
    const event = {
      currentPage,
      nextPage,
    };
    metrics.track("Review Page Selected", event);
  }

  getLinkOptions(pageNumber, currentPage) {
    let isCurrent = pageNumber === currentPage;
    let otherPageOptions = {
      href: "#",
      onClick: this.handleClick.bind(this, pageNumber),
    };
    return isCurrent ? { className: "active" } : otherPageOptions;
  }

  render() {
    const { totalPages, seeAllReviewsCta, productId, loggedIn } = this.props;
    const { currentPage } = this.state;

    return (
      <StyledRow>
        <Wrapper>
          <PaginationWrapper>
            {this.pageNumberArray(totalPages, currentPage).map(
              (pageNumber, i) => {
                if (pageNumber === "...") {
                  return <Ellipsis key={"ellipsis" + i} />;
                }
                const linkOptions = this.getLinkOptions(
                  pageNumber,
                  currentPage,
                );
                return (
                  <NumberLink key={"number-link" + i} {...linkOptions}>
                    {pageNumber}
                  </NumberLink>
                );
              },
            )}
          </PaginationWrapper>

          <div className="w-100 order-1 order-sm-0 d-flex justify-content-center justify-content-sm-start mt-2 mt-sm-0">
            <ReviewCTA productId={productId} loggedIn={loggedIn} />
          </div>

          <div className="w-100 order-2 d-flex justify-content-center justify-content-sm-end mt-4 mt-sm-0">
            {seeAllReviewsCta && (
              <PageLink to={"/reviews"}>
                <Text id="reviews.see-all" defaultMessage="See All Reviews" />
              </PageLink>
            )}
          </div>
        </Wrapper>
      </StyledRow>
    );
  }
}
