import styled from "styled-components";

// Components
import Text from "../Text";
import RitualButton from "../global/RitualButton";

// Utils
import { responsive } from "../../utils/style";

// Styled Components
const SwitchButtonWrapper = styled.div`
  width: 100%;

  ${responsive.md`
    width: auto;
  `}
`;

const Button = styled(RitualButton)`
  width: 100%;

  ${responsive.sm`
    width: auto;
    padding-left: 37px;
    padding-right: 37px;
  `};

  ${responsive.md`
    padding-left: 26px;
    padding-right: 26px;
  `};
`;
export default function SwitchButton(props) {
  const { plan, subscriptionId, subscriptionItemId, trackCTAClick } = props;
  const switchUrl = `${process.env.GATSBY_ACCOUNT_URL}/subscription/${subscriptionId}/items/${subscriptionItemId}/switch/confirm?pid=${plan.id}`;

  return (
    <SwitchButtonWrapper>
      <Button
        onClick={trackCTAClick}
        href={switchUrl}
        target="_self"
        rel="noreferrer"
        title={`Switch To ${plan.id}`}
        {...props}
      >
        <Text id="general.switch" defaultMessage="Switch" />
      </Button>
    </SwitchButtonWrapper>
  );
}
