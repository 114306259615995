import styled, { css } from "styled-components";

// Utils
import { rem, Font, media, Color, maxLines } from "../../utils/style";

// Components
import MagicLink from "../MagicLink";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Row from "../Row";
import Container from "../Container";
import { Icons } from "../../utils/react-svg";
import ArticleReadtime from "./ArticleReadtime";
import ArticlePreviewCategory from "./ArticlePreviewCategory";

const ArticleWrapper = styled.div`
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;

  ${media.notDesktop`
    margin-top: 30px;
    margin-bottom: 30px;
  `};

  &:hover {
    cursor: pointer;

    * {
      text-decoration: none !important;
    }

    img:not(.arrow) {
      transform: scale(1.1);
    }
  }

  ${media.mobile`
    &:hover img:not(.arrow) {
      transform: none;
    }
  `};
`;

const ArticleRow = styled(Row)`
  position: relative;
`;

const ArticleContent = styled.div`
  height: 528px;
  display: flex;
  align-items: center;
  justify-content: left;

  ${media.notDesktop`
    height: auto;
    display: block;
  `};
`;

const Article = styled.div`
  padding: 0;

  &.left {
    padding-right: 100px;

    ${media.notDesktop`
      padding: 86px 5px 40px;
    `};

    ${media.mobile`
      padding: 146px 5px 40px;
    `};
  }

  &.right {
    padding-left: 100px;

    ${media.notDesktop`
      padding: 86px 5px 40px;
    `};

    ${media.mobile`
      padding: 236px 0px 40px;
    `};
  }

  span,
  p,
  h2 {
    color: ${Color.ritualBlue};

    ${media.mobile`
      color: ${(p) => (p.color ? p.color : Color.ritualBlue)};
    `};

    a {
      color: inherit;
    }
  }
`;

const ImageArea = styled.div`
  position: absolute;
  width: calc(50% - 15px);
  ${(p) => {
    return p.align === "left"
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `;
  }};
  background-color: ${(p) => p.background};
  top: 0;
  bottom: 0;
  overflow: hidden;

  img {
    object-position: center !important;
  }

  ${media.mobile`
    left: 0;
    width: 100%;
    background-color: transparent;

    img {
      object-position: center !important;
    }
  `};
`;

const Image = styled(GatsbyImage)`
  img {
    transition: all 200ms !important;
  }
`;

const ArticleReadtimeElement = styled(ArticleReadtime)`
  margin: 0 0 ${rem(16)};

  ${media.mobile`
    margin: 0 0 ${rem(8)};
  `};
`;

const ArticleTitle = styled.h2`
  ${Font.circular};
  color: inherit;
  font-size: ${rem(34)};
  line-height: ${rem(40)};
  font-weight: 400;
  text-align: left;
  letter-spacing: -0.5px;
  margin: 0;

  ${media.notDesktop`
    font-size: ${rem(30)};
    line-height: ${rem(38)};
    letter-spacing: -0.2px;
  `};
`;

const ArticleDescription = styled.p`
  ${Font.dutch};
  color: inherit;
  font-size: ${rem(17)};
  line-height: ${rem(28)};
  font-weight: normal;
  text-align: left;
  letter-spacing: 0;
  margin: ${rem(30)} 0 0;
  ${maxLines(30, 5)};

  ${media.notDesktop`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    ${maxLines(28, 5)};
  `};

  ${media.mobile`
    display: none;
  `};
`;

const StyledIcon = styled(Icons.ArrowRight)`
  width: 18px;
  height: 8px;
  transform: translateY(-1px);
  margin-left: 8px;
  color: inherit;

  g {
    fill: ${Color.ritualBlue};

    ${media.mobile`
      fill: ${(p) => p.color};
    `};
  }
`;

const ArticleCategory = styled(ArticlePreviewCategory)`
  margin-bottom: 16px !important;
`;

const FeaturedLargeArticle = ({
  category,
  data: { articles, alignment = "left" },
}) => {
  if (!articles || !articles[0]) return null;

  const article = articles[0];
  return (
    <MagicLink to={article} aria-labelledby={article.slug}>
      <ArticleWrapper>
        <ImageArea background={article.heroBackgroundColor} align={alignment}>
          <Image
            className="article-image d-sm-none"
            image={getImage(article.heroImage.mobile)}
            loading="eager"
            color={article.heroBackgroundColor}
            alt={article.title}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
            }}
          />
          <Image
            className="article-image d-none d-sm-block"
            image={getImage(article.heroImage.desktop)}
            loading="eager"
            color={article.heroBackgroundColor}
            alt={article.title}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
            }}
          />
        </ImageArea>
        <Container>
          <ArticleRow>
            <ArticleContent
              className={
                alignment === "left"
                  ? "col-12 col-sm-6"
                  : "col-12 col-sm-6 offset-sm-6"
              }
            >
              <Article color={article.heroSecondaryColor} className={alignment}>
                {category ? (
                  <ArticleCategory
                    category={
                      category.slug === "featured" ? article.category : category
                    }
                    className="mb-4"
                  />
                ) : (
                  <ArticleReadtimeElement article={article} />
                )}
                <ArticleTitle id={article.slug}>
                  {article.title}
                  {!category && (
                    <StyledIcon
                      color={article.heroSecondaryColor}
                      className="arrow"
                    />
                  )}
                </ArticleTitle>
                <ArticleDescription>{article.previewText}</ArticleDescription>
              </Article>
            </ArticleContent>
          </ArticleRow>
        </Container>
      </ArticleWrapper>
    </MagicLink>
  );
};

export default FeaturedLargeArticle;
