import { ReactElement } from "react";
import styled from "styled-components";
import Marquee from "react-fast-marquee";

const ValuePropsTickerElement = styled.div`
  position: relative;
  overflow: visible;
`;

const TickerContainer = styled.div`
  white-space: nowrap;
  width: 100%;
  padding: var(--spacing-2, 32px) 0;
  user-select: none;
  border-top: 1px solid var(--indigo-blue-20, #d0d5e2);
  border-bottom: 1px solid var(--indigo-blue-20, #d0d5e2);
`;

const TickerWrapper = styled.div`
  z-index: 10;
  position: relative;
`;

const ValueProp = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-1_5);
  width: max-content;
  padding-left: var(--spacing-1_5);
`;

const ValuePropsTicker = ({ children }: { children?: ReactElement }) => {
  const valueProps = [
    {
      name: "Traceable Nutrients",
      iconUrl:
        "https://assets.ritual.com/icons/formula/warm/traceable-24x24.svg",
      iconAltText: "Circle with an arrow running through to the right icon",
    },
    {
      name: "Third-Party Tested (for microbes and heavy metals)",
      iconUrl: "https://assets.ritual.com/icons/formula/warm/tested-24x24.svg",
      iconAltText: "Shield icon",
    },
    {
      name: "Non-GMO",
      iconUrl: "https://assets.ritual.com/icons/formula/warm/non-gmo-24x24.svg",
      iconAltText: "Disallow icon",
    },
    {
      name: "Vegan",
      iconUrl: "https://assets.ritual.com/icons/formula/warm/vegan-24x24.svg",
      iconAltText: "Leaf sprout icon",
    },
    {
      name: "No Synthetic Colorants",
      iconUrl:
        "https://assets.ritual.com/icons/formula/warm/no-colorants-24x24.svg",
      iconAltText: "Water drop icon",
    },
    {
      name: "Traceable Nutrients",
      iconUrl:
        "https://assets.ritual.com/icons/formula/warm/traceable-24x24.svg",
      iconAltText: "Circle with an arrow running through to the right icon",
    },
    {
      name: "Third-Party Tested (for microbes and heavy metals)",
      iconUrl: "https://assets.ritual.com/icons/formula/warm/tested-24x24.svg",
      iconAltText: "Shield icon",
    },
    {
      name: "Non-GMO",
      iconUrl: "https://assets.ritual.com/icons/formula/warm/non-gmo-24x24.svg",
      iconAltText: "Disallow icon",
    },
    {
      name: "Vegan",
      iconUrl: "https://assets.ritual.com/icons/formula/warm/vegan-24x24.svg",
      iconAltText: "Leaf sprout icon",
    },
    {
      name: "No Synthetic Colorants",
      iconUrl:
        "https://assets.ritual.com/icons/formula/warm/no-colorants-24x24.svg",
      iconAltText: "Water drop icon",
    },
    {
      name: "Traceable Nutrients",
      iconUrl:
        "https://assets.ritual.com/icons/formula/warm/traceable-24x24.svg",
      iconAltText: "Circle with an arrow running through to the right icon",
    },
    {
      name: "Third-Party Tested (for microbes and heavy metals)",
      iconUrl: "https://assets.ritual.com/icons/formula/warm/tested-24x24.svg",
      iconAltText: "Shield icon",
    },
    {
      name: "Non-GMO",
      iconUrl: "https://assets.ritual.com/icons/formula/warm/non-gmo-24x24.svg",
      iconAltText: "Disallow icon",
    },
    {
      name: "Vegan",
      iconUrl: "https://assets.ritual.com/icons/formula/warm/vegan-24x24.svg",
      iconAltText: "Leaf sprout icon",
    },
    {
      name: "No Synthetic Colorants",
      iconUrl:
        "https://assets.ritual.com/icons/formula/warm/no-colorants-24x24.svg",
      iconAltText: "Water drop icon",
    }
  ];

  return (
    <TickerContainer>
      <ValuePropsTickerElement>
        <TickerWrapper>

          <Marquee speed={40} gradient={false}>
            {valueProps.map((valueProp, i) => (
              <ValueProp key={i}>
                <img src={valueProp.iconUrl} alt={valueProp.iconAltText} />
                <span className="typography-eyebrow1">{valueProp.name}</span>
              </ValueProp>
            ))}
          </Marquee>
        </TickerWrapper>
        {children}
      </ValuePropsTickerElement>
    </TickerContainer>
  );
};

export default ValuePropsTicker;
