import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import styled from "styled-components";

// Constants
import { ActionType } from "../../constants/product";
import { Status } from "../../constants/stockStatus";

// Services
import intlService from "../../services/intl";

// Components
import { RBadge } from "@ritual/essentials-for-react";
import PulseIcon from "../PulseIcon";
import Text from "../Text";
import DiscountButton from "../bundle/DiscountButton";
import RitualButton from "../global/RitualButton";
import { InterstitialSuccessBanner } from "./InterstitialSuccessBanner";

// Utils
import metrics from "../../utils/metrics";
import NameMap from "../../utils/nameMap";
import {
  getContentfulProductForPlan,
  getPlanIdForProductSku,
  getProductAttributes,
} from "../../utils/planToProduct";
import { isProductSpecificPromotion } from "../../utils/promotion";
import { Font, Opacity, rem, responsive } from "../../utils/style";

// Store
import { useDispatch, useSelector } from "react-redux";
import {
  addProductToCart,
  clearCartAndAddProducts,
} from "../../store/cart/actions";
import { productOfferPlanForId } from "../../store/product-offer-plan/selectors";
import promotionSelectors from "../../store/promotion/selectors";
import { contentfulProductBundlesForSlug } from "../../store/staticData/selectors";
import userSelectors from "../../store/user/selectors";

// Hooks
import useInterstitialUpsells from "../../hooks/useInterstitialUpsells";
import useShoppingIntention from "../../hooks/useShoppingIntention";
import useVariation from "../../hooks/useVariation";
import usePromotionDiscount from "../../hooks/usePromotionDiscount";
import { BillingType } from "../../constants/plan";

const Placeholder = styled.div`
  height: 510px;
  width: 100%;
  min-width: 280px;

  ${responsive.sm`
    height: 320px;
  `}

  ${responsive.md`
    height: 342px;
  `};
`;

const Headline = styled.div`
  display: flex;
  margin-bottom: 24px;
  text-align: left;
  align-items: center;
  padding-right: var(--spacing-1_5);

  @media (max-width: 350px) {
    display: inline-block;
  }

  .headline-container {
    flex: 1 1 0;
  }

  h2 {
    display: inline;
    margin-bottom: 0;
    font-weight: 500;
    letter-spacing: -0.2px;
    font-size: ${rem(18)};
    line-height: ${rem(28)};

    em {
      ${Font.dutch};
    }

    .highlighter {
      display: inline-block;

      div {
        z-index: -1;
      }
    }

    &:first-child {
      margin-right: ${rem(4)};
    }

    ${responsive.sm`
      line-height: ${rem(32)};
      font-size: ${rem(22)};

      &:first-child {
        margin-right: ${rem(8)};
      }
    `};
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  align-items: center;

  padding-bottom: 60px;

  ${responsive.sm`
    padding-bottom: 0;
  `}

  ${responsive.md`
    padding-bottom: 50px;
  `}
`;

const ProductImage = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: 16px;
  flex: 1 0 auto;

  img {
    height: 100%;
    width: 100%;
  }

  ${responsive.sm`
    height: 189px;
    width: 189px;
    margin-right: 32px;
    margin-bottom: 0;
  `}

  ${responsive.md`
    height: 280px;
    width: 280px;
    margin-right: 50px;
  `}

  width: 100% !important;
  height: 38vh;
  @media (min-height: 820px) {
    height: 318px;
  }
  margin: 0 auto 28px !important;

  img {
    object-fit: cover;
  }
`;

const ProductContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProductValueProps = styled.div`
  ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 24px;

    li {
      font-size: ${rem(16)};
      font-weight: 300;
      line-height: ${rem(22)};
      margin-left: 20px;
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &:before {
        color: rgb(20, 43, 111);
        opacity: ${Opacity.light};
        font-size: 16px;
        width: 20px;
        margin-left: -20px;
        display: inline-block;
        content: "\\2022";
      }
    }
  }
`;

const ButtonsContainer = styled.div`
  max-width: 375px;
  margin: auto;
  left: 24px;
  right: 24px;
  bottom: 0;
  position: fixed;
  padding: 16px 0 16px;
  ${responsive.sm`
    padding: 16px 0 32px;
  `}

  border-top: 1px solid rgba(0, 0, 0, 0.15);
  background-color: #fff;

  a {
    width: 100%;
  }

  button {
    &:first-of-type {
      margin-bottom: 16px;
    }
  }
`;

const ContinueButton = styled(RitualButton)``;

const StyledBadge = styled(RBadge)`
  position: absolute;
  z-index: 10;
  margin: 0.75rem;
`;

const authedActionTypes = [
  ActionType.AuthedAdd,
  ActionType.QuickAdd,
  ActionType.Switch,
];
const Tooltip = () => {
  const authenticated = useSelector(userSelectors.isLoggedIn);
  const shoppingIntention = useShoppingIntention();
  const homepageHelloBar = useVariation("homepage-hello-bar", {});

  if (isEmpty(homepageHelloBar) || !homepageHelloBar?.tooltip) return null;

  const authedAdd =
    authenticated || authedActionTypes.includes(shoppingIntention?.actionType);

  return (
    <PulseIcon className="cart-interstitial">
      {authedAdd
        ? homepageHelloBar?.tooltip.loggedIn
        : homepageHelloBar?.tooltip.loggedOut}
    </PulseIcon>
  );
};

export const useInterstitialData = (
  sku,
  productOfferSlug,
  productOfferPlanId,
  intervalCount = 30,
) => {
  const type = intervalCount === 0 ? BillingType.OneTime : undefined;
  const planId = sku && getPlanIdForProductSku(sku, type, intervalCount);
  const { name, price } = (planId && getProductAttributes(planId)) || {};
  const { upsellImage, heroFeaturedImages, productValueProps, stockStatus } =
    (planId && getContentfulProductForPlan(planId)) || {};

  const productOffer = useSelector((state) => {
    const bundle =
      contentfulProductBundlesForSlug(state, productOfferSlug) || {};

    const productOfferPlan =
      productOfferPlanForId(state, productOfferPlanId)?.initialPlan(state) ||
      {};

    return {
      discountPrice: productOfferPlan.amount
        ? productOfferPlan.amount / 100
        : undefined,
      discountValue: bundle.discountValue,
      flag: bundle.flag,
    };
  });

  const isValid = stockStatus === Status.InStock;

  return {
    isValid,
    product: {
      planId,
      name,
      price,
      upsellImage,
      heroFeaturedImages,
      productValueProps,
      stockStatus,
    },
    productOffer,
  };
};

const InterstitialSingleUpsell = ({ openCart, addedPlan }) => {
  const dispatch = useDispatch();
  const appliedPromotion = useSelector(promotionSelectors.appliedPromotion);
  const bestEligiblePromotion = useSelector(
    promotionSelectors.bestEligiblePromotion,
  );
  const intervalCount = addedPlan?.intervalCount || 0;
  const {
    isProcessing,
    type: upsellType,
    upsells,
  } = useInterstitialUpsells(intervalCount);
  const { sku, productOfferId, productOfferSlug, productOfferPlanId } =
    upsells?.[0] || [];
  const {
    isValid,
    product: {
      planId,
      name,
      price,
      upsellImage,
      heroFeaturedImages,
      productValueProps,
      stockStatus,
    },
    productOffer: { flag, discountPrice, discountValue },
  } = useInterstitialData(
    sku,
    productOfferSlug,
    productOfferPlanId,
    intervalCount,
  );

  const promotionDiscount = usePromotionDiscount(bestEligiblePromotion, planId);

  useEffect(() => {
    if (isProcessing) return;

    if (!isValid) openCart();

    metrics.track("Cart Interstitial Viewed", {
      variation: "single",
      product_offers: productOfferSlug ? [productOfferSlug] : [],
    });
  }, [isProcessing, isValid, openCart, productOfferSlug]);

  const productImageFlag = useVariation(
    "cart-interstitial-product-image",
    "hero-featured-image",
  );

  // TODO: If we end up with quarterly product offers, we should change this
  const isProductOfferUpsell =
    upsellType === "productOfferUpsell" && intervalCount === 30;
  const showPromotion =
    (!appliedPromotion || !isProductSpecificPromotion(appliedPromotion)) &&
    bestEligiblePromotion &&
    !isProductOfferUpsell;

  function handleClick(name) {
    const propertiesToTrack = {
      location: "Cart Interstitial",
    };

    if (isProductOfferUpsell) {
      dispatch(
        clearCartAndAddProducts([{ productOfferId }], true, propertiesToTrack),
      );
    } else {
      dispatch(addProductToCart({ planId }, propertiesToTrack));
    }

    metrics.track("CTA Clicked", {
      title: name,
      location: "Cart Interstitial",
    });

    openCart();
  }

  function handleContinueClick(e) {
    metrics.track("CTA Clicked", {
      title: "Continue to Cart",
      location: "Cart Interstitial",
    });

    return openCart(e);
  }

  const showCartImage = productImageFlag === "cart-image";
  const bottleImage = showCartImage ? upsellImage : heroFeaturedImages?.[0];
  const productName = NameMap({ name });
  const headerCopy = isProductOfferUpsell
    ? intlService.t(
        "cart.single-item-modal.add-with-discount",
        "Add {productName} and save {discountValue} on your entire bundle",
        {
          productName: productName.html,
          discountValue,
        },
      )
    : intlService.t("cart.single-item-modal.add", "Add {productName}", {
        productName: productName.html,
      });

  if (!planId || stockStatus !== Status.InStock) {
    return <Placeholder />;
  }

  return (
    <>
      <InterstitialSuccessBanner planAdded={addedPlan} />
      <Headline>
        <div className={"headline-container"}>
          <h2
            dangerouslySetInnerHTML={{
              __html: headerCopy,
            }}
          ></h2>
        </div>
      </Headline>
      <ModalBody>
        <ProductImage>
          {showCartImage && flag && (
            <StyledBadge
              slot="product-image-r-badge"
              badgeColor="yellow"
              badgeWidth="wide"
            >
              {flag}
            </StyledBadge>
          )}
          <GatsbyImage
            className=""
            image={getImage(bottleImage)}
            loading="eager"
            alt={bottleImage.description || bottleImage.title}
            style={{
              position: "absolute",
              left: 0,
              bottom: 0,
              height: "100%",
              width: "100%",
              zIndex: "1",
            }}
          />
        </ProductImage>
        <ProductContent>
          {productValueProps && (
            <ProductValueProps>
              <ul>
                {productValueProps.map((valueProp, i) => {
                  return <li key={i}>{valueProp.content}</li>;
                })}
              </ul>
            </ProductValueProps>
          )}
          <ButtonsContainer>
            {!isProductOfferUpsell && <Tooltip />}
            <DiscountButton
              prefix="Add"
              price={price}
              isLink={false}
              className={"fullwidth"}
              onClick={() => handleClick(name)}
              promotion={showPromotion && promotionDiscount}
              discountPrice={discountPrice}
            />
            <ContinueButton
              className="bordered"
              to="/cart"
              onClick={handleContinueClick}
            >
              <Text
                id="cart.single-item-modal.continue-to-cart"
                defaultMessage="Continue to Cart"
              />
            </ContinueButton>
          </ButtonsContainer>
        </ProductContent>
      </ModalBody>
    </>
  );
};

export default InterstitialSingleUpsell;
