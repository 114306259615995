import styled, { keyframes } from "styled-components";
import { PlanTileBenefit } from "@ritual/essentials-for-react";
import intl from "../../../services/intl";
import { cadencePlanDetails } from "../../../utils/ritualData";
import classNames from "classnames";
import { Icons } from "../../../utils/react-svg";
import { responsive } from "../../../utils/style";
import { useSelector } from "react-redux";
import { bestEligiblePromotion } from "../../../store/promotion/selectors";
import { getPromotionDetails } from "../../../utils/promotion";
import usePromotionDiscount from "../../../hooks/usePromotionDiscount";
import { PlanTile } from "./PlanTile";
import useVariation from "../../../hooks/useVariation";

const CadenceButton = styled.button<{ selected: boolean }>`
  border: none;
  margin: var(--spacing-0_5) 0 0;
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: "#f0f0f0";
  }
`;

const FadeInBorder = keyframes`
  from {
    border-color: var(--indigo-blue-20);
  }
  to {
    border-color: var(--indigo-blue);
  }
`;

const FadeOutBorder = keyframes`
  from {
    border-color: var(--indigo-blue);
  }
  to {
    border-color: var(--indigo-blue-20);
  }
`;

const PlanTileContainer = styled(PlanTile)`
  overflow: hidden;
  border-color: var(--indigo-blue-20);
  animation: 300ms ease-in-out reverse forwards ${FadeInBorder};

  &.one-time {
    .plan-tile__header .title {
      margin-bottom: unset;
    }
  }

  &[is-selected="false"] {
    animation: 300ms ease-in-out ${FadeOutBorder};
  }

  &[is-selected="true"] {
    animation: 300ms ease-in-out ${FadeInBorder};

    .price-row .base-price {
      font-weight: 450 !important;
    }
  }

  .plan-tile__header {
    .title {
      margin-bottom: unset;

      div.label {
        color: unset;
        margin-bottom: unset;
      }
    }
  }

  plan-tile-benefit {
    text-align: left !important;
  }

  .plan-tile-benefit-row {
    align-items: flex-end;

    ${responsive.md`
      align-items: baseline;
    `}
  }

  .benefit-icon {
    svg {
      width: 16px;
      height: 16px;

      g g {
        fill: var(--indigo-blue-60);
      }
    }

    &.benefit-icon__recolor {
      svg {
        g path,
        g circle {
          stroke: var(--indigo-blue-40);
        }
      }
    }
  }

  &.hide-benefits {
    .plan-tile__benefits {
      display: none;
    }
  }

  ${responsive.md`
    .title .label .typography-label1 {
      font-size: 1.125rem !important;
    }
  `}
`;

type Props = {
  plan: any;
  selectedPlanId: string;
  defaultPlanId: string;
  handlePlanClick: (plan: any) => void;
  index: number;
  bestValue?: boolean;
  deliveryCadenceCopyFlag?: boolean;
};

type DataProps = {
  finalPrice: string; // formatted (string with currency symbol) amount customer sees and pays
  strikePrice: string; // formatted (string with currency symbol) amount struck through
  monthlyPriceEnabled: boolean; // Display price/month
  saveBenefitEnabled: boolean; // Save X% on every future order
  bestValueEnabled: boolean; // Best Value flag for 3-month plans
  oneTimeShippingEnabled: boolean; // One-time shipping benefit
};

const SelectorOption = ({
  plan,
  selectedPlanId,
  defaultPlanId,
  handlePlanClick,
  index,
  bestValue = false,
  deliveryCadenceCopyFlag,
}: Props) => {
  const welcomeOfferPromotionFlag = useVariation("welcome-offer-promotion", {});
  const welcomeOfferPromotionEnabled =
    welcomeOfferPromotionFlag?.slug &&
    welcomeOfferPromotionFlag?.slug === "welcome-offer-alc";

  const {
    selectorHeader,
    amount,
    basePrice,
    monthlyAmount,
    monthlyPercentSavings,
    quarterlyBasePrice,
    cadenceClarity,
  } = cadencePlanDetails(plan, deliveryCadenceCopyFlag);

  const promotion = useSelector(bestEligiblePromotion);
  const promotionDiscount = usePromotionDiscount(promotion, plan.plan_id);
  const promotionDetails = getPromotionDetails(
    promotionDiscount,
    (plan?.amount || 0) / 100,
  );

  const isSelected = selectedPlanId
    ? plan.plan_id === selectedPlanId
    : plan.plan_id === defaultPlanId;
  const isOneTimePlan = plan.billing_type === "one_time";

  let data: DataProps = {
    finalPrice:
      promotionDetails?.discountPrice !== undefined
        ? intl.formatCurrency(promotionDetails.discountPrice, {
            round: true,
          })
        : amount,
    strikePrice: welcomeOfferPromotionEnabled
      ? quarterlyBasePrice || basePrice
      : amount,
    monthlyPriceEnabled: false,
    saveBenefitEnabled: true,
    bestValueEnabled: false,
    oneTimeShippingEnabled: false,
  };

  return (
    <CadenceButton
      key={index}
      selected={isSelected}
      onClick={() => handlePlanClick(plan)}
      aria-label={selectorHeader}
    >
      <PlanTileContainer
        className={
          !isSelected ||
          (isSelected && isOneTimePlan && !data.oneTimeShippingEnabled)
            ? "hide-benefits"
            : ""
        }
        isSelected={isSelected}
        flagLabel={data.bestValueEnabled ? "Best Value" : undefined}
        title={
          <span
            className={classNames("font-circular", {
              "typography-label1": isSelected,
              "typography-body1": !isSelected,
            })}
          >
            {selectorHeader}
          </span>
        }
        basePrice={
          data.strikePrice &&
          data.strikePrice !== data.finalPrice &&
          !isOneTimePlan &&
          basePrice && (
            // This is the strike-through price
            <span slot="plan-tile-base-price">{data.strikePrice}</span>
          )
        }
        finalPrice={
          amount && (
            // This is the final price
            <span slot="plan-tile-final-price">{data.finalPrice}</span>
          )
        }
        monthlyPrice={
          !isOneTimePlan &&
          monthlyAmount &&
          data.monthlyPriceEnabled && (
            <span slot="plan-tile-monthly-price">{`${monthlyAmount}/month`}</span>
          )
        }
        savings={
          !isOneTimePlan &&
          promotionDetails?.formattedValue && (
            <>
              {welcomeOfferPromotionEnabled ? (
                <span slot="plan-tile-savings">{`Subscribe and Save 20% Today`}</span>
              ) : (
                <span slot="plan-tile-savings">{`Subscribe and Save ${promotionDetails.formattedValue} Today`}</span>
              )}
            </>
          )
        }
        benefits={
          !isOneTimePlan && isSelected ? (
            <>
              <PlanTileBenefit
                slot="plan-tile-benefit"
                style={{
                  display:
                    data.saveBenefitEnabled && monthlyPercentSavings
                      ? "block"
                      : "none",
                }}
              >
                <span
                  className="benefit-icon benefit-icon__recolor"
                  slot="plan-tile-benefit-icon"
                >
                  <Icons.Sparkle />
                </span>
                <span slot="plan-tile-benefit-text">
                  Save {monthlyPercentSavings} on every future order
                </span>
              </PlanTileBenefit>
              <PlanTileBenefit slot="plan-tile-benefit">
                <span className="benefit-icon" slot="plan-tile-benefit-icon">
                  <Icons.RecurringBenefit />
                </span>
                <span slot="plan-tile-benefit-text">{cadenceClarity}</span>
              </PlanTileBenefit>
              <PlanTileBenefit slot="plan-tile-benefit">
                <span className="benefit-icon" slot="plan-tile-benefit-icon">
                  <Icons.TestedBenefit />
                </span>
                <span slot="plan-tile-benefit-text">
                  {intl.t(
                    `delivery-cadence.benefits.${
                      plan.number_of_months === 1 ? "monthly" : "non-monthly"
                    }`,
                  )}
                </span>
              </PlanTileBenefit>
            </>
          ) : (
            <PlanTileBenefit
              slot="plan-tile-benefit"
              style={{
                display:
                  isOneTimePlan && data.oneTimeShippingEnabled
                    ? "block"
                    : "none",
              }}
            >
              <span
                className="benefit-icon benefit-icon__recolor"
                slot="plan-tile-benefit-icon"
              >
                <Icons.ShippingFree />
              </span>
              <span slot="plan-tile-benefit-text">
                Shipping calculated at checkout
              </span>
            </PlanTileBenefit>
          )
        }
      ></PlanTileContainer>
    </CadenceButton>
  );
};

export default SelectorOption;
