import { createSelector } from "reselect";

const route = (state) => state.route;
const stickySearchParams = createSelector(
  route,
  (route) => route.stickySearchParams,
);

export const selectStickySearchParam = createSelector(
  stickySearchParams,
  (_, param) => param,
  (params, param) => params[param],
);
