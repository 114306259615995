import styled from "styled-components";

// Utils
import { Color, rem, Font, Opacity } from "../../utils/style";

// Services
import intl from "../../services/intl";

// Components
import MagicLink from "../MagicLink";

const PageLink = styled(MagicLink)`
  ${Font.circular};
  color: ${Color.ritualBlue};
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  border-bottom: 2px solid ${Color.ritualBlue};
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: ${Opacity.light};
  }
`;

const ReviewCTA = (props) => {
  const { productId, loggedIn, className } = props;

  const reviewUrl = `${process.env.GATSBY_ACCOUNT_URL}/reviews/${productId}`;

  const reviewLinkMessage = loggedIn
    ? intl.t("reviews.logged-in-write", "Write a Review")
    : intl.t("reviews.logged-out-write-sign-in", "Sign in to Review");

  return (
    <div className={className}>
      <PageLink href={reviewUrl}>{reviewLinkMessage}</PageLink>
    </div>
  );
};

export default ReviewCTA;
