import { useEffect, useState } from "react";
import Modal from "../MagicModal";
import styled, { css } from "styled-components";
import { finalizeTruemedPayment } from "./utils";
import { Icons } from "../../utils/react-svg";
import LoadingFlask from "../global/LoadingFlask";

interface TruemedIframeModalProps {
  truemedIframeUrl: string;
  cartId: string;
  onRequestClose: () => void;
  onSuccess: () => void;
}

const ModalStyle = css`
  padding: 0 !important;
  border-radius: var(--spacing-0_25, 4px);
  width: 95vw;
  height: 85vh;

  /* prevent the content in the iframe from getting squished on small phones */
  @media (max-width: 400px) {
    max-width: unset;
    width: 100vw !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

const IframeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  height: 100%;
`;

const LoadingState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3 {
    margin-top: var(--spacing-1_5);
  }
`;

const ErrorMessage = styled.div`
  max-width: 80%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--solid-semantic-error-red, #c83d1e);

  a {
    color: var(--solid-semantic-error-red, #c83d1e);
    text-decoration: underline;
  }

  svg {
    height: 20px;
    width: 20px;
    margin-bottom: var(--spacing-1_5);
    path {
      stroke: var(--solid-semantic-error-red, #c83d1e);
    }
  }
`;

const TruemedIframeModal = ({
  onRequestClose,
  onSuccess,
  truemedIframeUrl,
  cartId,
}: TruemedIframeModalProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const truemedOrigin = process.env.GATSBY_TRUEMED_ORIGIN;

  const handleErrors = () => {
    setError(true);
  };

  useEffect(() => {
    const onReceivedMessage = async (event: MessageEvent) => {
      const {
        origin,
        data: { success },
      } = event;
      if (origin !== truemedOrigin) {
        return;
      }

      if (success) {
        setLoading(true);
        const response = await finalizeTruemedPayment(cartId);

        if (response.success) {
          onSuccess();
        } else {
          handleErrors();
          return;
        }
      } else {
        handleErrors();
      }
    };

    window.addEventListener("message", onReceivedMessage);

    return () => {
      window.removeEventListener("message", onReceivedMessage);
    };
  }, [cartId, truemedOrigin, onSuccess]);

  return (
    <Modal
      isOpen={Boolean(truemedIframeUrl)}
      onRequestClose={onRequestClose}
      contentStyle={ModalStyle}
      aria-label="Truemed payment flow"
    >
      <IframeContainer>
        {!loading && !error && (
          <iframe
            src={truemedIframeUrl}
            title="Truemed payment"
            width="100%"
            height="100%"
            data-testid="truemed-payment-iframe"
          />
        )}
        {loading && !error && (
          <LoadingState>
            <LoadingFlask />
            <h3 className="typography-lead2 font-circular">
              Payment processing
            </h3>
          </LoadingState>
        )}
        {error && (
          <ErrorMessage>
            <Icons.AlertWarning />
            <h3 className="typography-lead2 font-circular">
              Uh oh, something went wrong
            </h3>
            <p className="typography-body1 font-circular">
              Check your email for your order confirmation or reach out to{" "}
              <a href="mailto:help@ritual.com">help@ritual.com</a>
            </p>
          </ErrorMessage>
        )}
      </IframeContainer>
    </Modal>
  );
};

export default TruemedIframeModal;
