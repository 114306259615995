import { graphql } from "gatsby";
import { createRef, Component } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import EfwCta from "../../components/clinical/EfwCta";
import ClinicalHero from "../../components/clinical/Hero";
import IngredientGraph from "../../components/clinical/IngredientGraph";
import Introduction from "../../components/clinical/Introduction";
import ScienceTeam from "../../components/clinical/ScienceTeam";
import VideoOverlay from "../../components/clinical/VideoOverlay";
import PageHero from "../../components/PageHero";
import ProductCategoryCards from "../../components/product/ProductCategoryCards";
// Components
import PageSEO from "../../components/seo/Page";
// Utils
import { Color } from "../../utils/style";

// Styled Elements
const ClinicalPageWrapper = styled.div.attrs({
  className: "mb-2 mb-md-4",
})`
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
`;

const Omega3Copy = styled.p`
  font-weight: 500 !important;

  a {
    border-bottom: 2px solid ${Color.ritualBlue};
  }
`;

export default class Clinical extends Component {
  constructor(props) {
    super(props);

    const { pageTitle, pageDescription, socialImage } =
      props.data.contentfulClinicalPage;

    this.state = {
      seo: {
        pagePath: "clinical-study",
        title: pageTitle,
        description: pageDescription,
        image: {
          url: socialImage.file.url,
          width: socialImage.file.details.image.width,
          height: socialImage.file.details.image.height,
        },
      },
    };

    this.videoRef = createRef();
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Clinical Study",
    });
  }

  openVideo() {
    if (this.videoRef) {
      this.videoRef.current.openVideo();
    }
  }

  render() {
    const {
      contentfulClinicalPage,
      vitaminD3Image,
      vitaminD3DesktopVideo,
      vitaminD3MobileVideo,
      vitaminD3MobileBenefit,
      vitaminD3DesktopImage1,
      vitaminD3DesktopImage2,
      vitaminD3DesktopImage3,
      omega3Image,
      omega3DesktopVideo,
      omega3MobileVideo,
      omega3MobileBenefit,
      omega3DesktopImage1,
      omega3DesktopImage2,
      omega3DesktopImage3,
      contentfulVideoContent,
    } = this.props.data;
    const {
      heroHeadline,
      heroImageDesktop,
      heroImageMobile,
      heroCta,
      scientists,
      efwCtaText,
      efwCtaDescription,
      efwCtaDesktopImage,
      efwCtaMobileImage,
      efwCtaMobileBackgroundImage,
      ingredientGraphContent,
      readMoreArticle,
      abstractCopy,
      abstractDocument,
      featuredArticles,
      products,
    } = contentfulClinicalPage;

    const ingredientGraphData = JSON.parse(
      ingredientGraphContent.childMarkdownRemark.rawMarkdownBody,
    );

    const ingredientD3 = {
      image: vitaminD3Image, // Image Asset
      videoDesktop: vitaminD3DesktopVideo, // Video Asset,
      videoMobile: vitaminD3MobileVideo, // Video Asset,
      backgroundImage: efwCtaMobileBackgroundImage,
      benefitsMobileImage: vitaminD3MobileBenefit,
      benefitsDesktopImages: [
        vitaminD3DesktopImage1,
        vitaminD3DesktopImage2,
        vitaminD3DesktopImage3,
      ],
      graphSectionContent: {
        ...ingredientGraphData[0],
      },
    };

    const ingredientOmega3 = {
      image: omega3Image, // Image Asset
      videoDesktop: omega3DesktopVideo, // Video Asset,
      videoMobile: omega3MobileVideo, // Video Asset,
      backgroundImage: efwCtaMobileBackgroundImage,
      benefitsMobileImage: omega3MobileBenefit,
      benefitsDesktopImages: [
        omega3DesktopImage1,
        omega3DesktopImage2,
        omega3DesktopImage3,
      ],
      graphSectionContent: {
        ...ingredientGraphData[1],
      },
    };

    return (
      <>
        <ClinicalPageWrapper>
          <PageSEO {...this.state.seo} />

          <PageHero
            backgroundColor="#FFFFFF"
            imageMobile={heroImageMobile}
            imageDesktop={heroImageDesktop}
            imageObjectPosition="50% 100%"
          >
            <ClinicalHero
              headline={heroHeadline}
              imageDesktop={heroImageDesktop}
              imageMobile={heroImageMobile}
              cta={heroCta}
              openVideo={this.openVideo.bind(this)}
            />
          </PageHero>

          <Introduction {...this.props} />

          <IngredientGraph ingredient={ingredientD3} />

          <IngredientGraph ingredient={ingredientOmega3}>
            {abstractDocument && abstractCopy && (
              <Omega3Copy>
                {abstractCopy}{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  aria-label={abstractDocument.description}
                  href={abstractDocument.file.url}
                >
                  Read the abstract
                </a>
              </Omega3Copy>
            )}
          </IngredientGraph>

          <EfwCta
            description={efwCtaDescription}
            ctaText={efwCtaText}
            desktopImage={efwCtaDesktopImage}
            mobileImage={efwCtaMobileImage}
            mobileBackgroundImage={efwCtaMobileBackgroundImage}
          />

          <ScienceTeam
            slides={scientists}
            readMoreArticle={readMoreArticle}
            featuredArticles={featuredArticles}
            products={products}
          />

          <ProductCategoryCards className="mb-7 mb-md-9" />

          <Helmet bodyAttributes={{ class: "template" }} />
        </ClinicalPageWrapper>

        <VideoOverlay
          ref={this.videoRef}
          contentfulVideoContent={contentfulVideoContent}
        />
      </>
    );
  }
}

export const query = graphql`
  fragment ingredientImageFragment on ContentfulAsset {
    description
    gatsbyImageData(
      layout: CONSTRAINED
      placeholder: NONE
      width: 570
      quality: 90
    )
  }

  fragment featuredArticlesFragment on ContentfulArticle {
    __typename
    slug
    title
    previewText
    heroBackgroundColor
    heroSecondaryColor
    category {
      slug
      title
    }
  }

  query ClinicalPageQuery($locale: String!) {
    contentfulVideoContent(
      node_locale: { eq: $locale }
      contentful_id: { eq: "3rlpajVvUQkuLwcRiw60TC" }
    ) {
      sources {
        file {
          url
          contentType
        }
        description
      }
      title
      videoLength
      captions {
        file {
          url
        }
        description
      }
    }
    vitaminD3DesktopVideo: contentfulVideoContent(
      node_locale: { eq: $locale }
      contentful_id: { eq: "5REtyX3PIlEvB08LHRQjkM" }
    ) {
      ...videoContent
    }
    vitaminD3MobileVideo: contentfulVideoContent(
      node_locale: { eq: $locale }
      contentful_id: { eq: "7kltPAVf7c80XjMNDEfmXF" }
    ) {
      ...videoContent
    }
    omega3DesktopVideo: contentfulVideoContent(
      node_locale: { eq: $locale }
      contentful_id: { eq: "1Gar0ifFTNi618tNGBHBdc" }
    ) {
      ...videoContent
    }
    omega3MobileVideo: contentfulVideoContent(
      node_locale: { eq: $locale }
      contentful_id: { eq: "71PYcEg6g0HkBB2VMWc9Fp" }
    ) {
      ...videoContent
    }
    contentfulClinicalPage(
      node_locale: { eq: $locale }
      slug: { eq: "clinical-study" }
    ) {
      slug
      heroHeadline {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      heroCta {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      heroImageDesktop {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 1920
          quality: 90
        )
      }
      heroImageMobile {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 912
          quality: 90
        )
      }
      introHeader {
        childMarkdownRemark {
          html
        }
      }
      introLink {
        childMarkdownRemark {
          html
        }
      }
      introStudyDesignContent {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      introResultsCTA
      introResultsProductLink
      introResultsHeadline {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      introResultsData {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      modalContent {
        childMarkdownRemark {
          html
        }
      }
      clinicalIcons {
        childMarkdownRemark {
          html
        }
      }
      efwCtaDescription
      efwCtaText
      efwCtaDesktopImage {
        description
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 970
          quality: 90
        )
      }
      efwCtaMobileImage {
        description
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 750
          quality: 90
        )
      }
      efwCtaMobileBackgroundImage {
        description
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 750
          quality: 90
        )
      }
      ingredientGraphContent {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      pageTitle
      pageDescription
      socialImage {
        file {
          details {
            image {
              width
              height
            }
          }
          url
        }
      }
      scientists {
        id
        name
        title
        expertise
        clinicalQuote
        bio {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 240
            quality: 100
          )
          description
        }
        clinicalImage {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 270
            quality: 100
          )
          description
        }
      }
      products {
        name {
          name
          childMarkdownRemark {
            html
          }
        }
        slug
        sku
        shortDescription
        summary
        cardImage {
          file {
            url
          }
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 1710
            quality: 90
          )
        }
        cardBackgroundColor
      }
      readMoreArticle {
        slug
      }
      abstractCopy
      abstractDocument {
        file {
          url
        }
        description
      }
      featuredArticles {
        ...featuredArticlesFragment
        heroImage {
          file {
            url
          }
          desktop: gatsbyImageData(
            layout: CONSTRAINED
            width: 470
            height: 341
            quality: 100
            cropFocus: LEFT
            resizingBehavior: FILL
          )
          mobile: gatsbyImageData(
            layout: CONSTRAINED
            width: 335
            height: 341
            quality: 100
            cropFocus: LEFT
            resizingBehavior: FILL
          )
        }
        ...expandPrimaryContent
      }
    }
    vitaminD3Image: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "5nz569oKcl6dGzHZJGNJj8" }
    ) {
      ...ingredientImageFragment
    }
    vitaminD3MobileBenefit: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "5mGHWYkmyORuEqNW7LDWZ0" }
    ) {
      ...ingredientImageFragment
    }
    vitaminD3DesktopImage1: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "36YMjgU0jz5QzglDzTB5n8" }
    ) {
      ...ingredientImageFragment
    }
    vitaminD3DesktopImage2: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "16TRYKpIgmKGtZeUs2Tnoz" }
    ) {
      ...ingredientImageFragment
    }
    vitaminD3DesktopImage3: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "35Jm63u1TKactMgz6pIvoy" }
    ) {
      ...ingredientImageFragment
    }
    omega3Image: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "Gj5bU89oJCctUNqwxhAff" }
    ) {
      ...ingredientImageFragment
    }
    omega3MobileBenefit: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "34XvIEY9sWSwwfoycrUEYr" }
    ) {
      ...ingredientImageFragment
    }
    omega3DesktopImage1: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "2uyOOWOtzw9RQNDuvXZ72g" }
    ) {
      ...ingredientImageFragment
    }
    omega3DesktopImage2: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "1BIOLlz5nzsawuKgbARTQF" }
    ) {
      ...ingredientImageFragment
    }
    omega3DesktopImage3: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "7ejKgGP2R1PlKHvFYURbZk" }
    ) {
      ...ingredientImageFragment
    }
  }
`;
