import { Component } from "react";
import styled, { css } from "styled-components";
import { Font, Color, media, responsive } from "../../utils/style";

// Components
import MagicModal from "../MagicModal";
import Text from "../Text";

const contentStyle = css`
  ${responsive.sm`
    margin: 0 15%;
  `}

  ${responsive.md`
    margin: 0 12%;
  `}
`;

const PolicyContainer = styled.div`
  padding: 0px 0px;

  ${responsive.sm`
    padding: 8px 16px;
    padding-top: 0px;
  `}

  ${responsive.md`
    padding: 8px 56px;
    padding-top: 18px;
  `}

  ${responsive.lg`
    padding: 20px 76px;
  `}
`;

const PolicyHeading = styled.h2`
  border-bottom: 2px solid ${Color.ritualBlue};
  padding-bottom: 40px;

  ${media.tablet`
    font-size: 22px;
    font-weight: 500;
    text-align: left;
    letter-spacing: -0.2px;
    line-height: 32px;
    padding-bottom: 24px;
  `};
`;

const PolicyContent = styled.div`
  ${Font.dutch} font-size: 16px;
  font-weight: normal;
  text-align: left;
  letter-spacing: 0;
  line-height: 26px;

  p {
    a:link {
      border-bottom: 1px solid ${Color.ritualBlue};
      border-width: 1px !important;
    }
  }
`;

export default class ReviewPolicyModal extends Component {
  render() {
    const { isOpen, onRequestClose } = this.props;

    return (
      <MagicModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaLabel="Read more about our reviews policy"
        contentStyle={contentStyle}
      >
        <PolicyContainer>
          <PolicyHeading>
            <Text
              id="reviews.policy.heading"
              defaultMessage="More About Our Reviews"
            />
          </PolicyHeading>
          <PolicyContent className="mt-3 mt-md-4">
            <p className="mb-0">
              <Text
                id="reviews.policy.content"
                defaultMessage={`We only publish real reviews by real customers. We’d love to post
              every review that’s submitted, but because of FDA and FTC
              regulations, there are some we can’t. Learn more about that`}
              />{" "}
              <a
                className="-underline"
                href="/reviews-policy"
                aria-label="Read our Reviews Policy"
              >
                <Text id="reviews.policy.read-more" defaultMessage="here" />
              </a>
              .
            </p>
          </PolicyContent>
        </PolicyContainer>
      </MagicModal>
    );
  }
}
