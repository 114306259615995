import styled from "styled-components";

// Utils
import { Color, rem, responsive } from "../../utils/style";

// Components
import MagicLink from "../MagicLink";

// Styled Elements
const ArticleCategoryLink = styled(MagicLink)`
  display: inline-block;
  font-size: ${rem(12)};
  line-height: ${rem(20)};
  font-weight: 500;
  color: ${(p) => p.color || Color.lessFadedBlue} !important;
  text-transform: uppercase;
  margin-bottom: 8px;

  ${responsive.sm`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `};
`;

const ArticlePreviewCategory = ({ category, color }) => {
  if (!category) return null;

  const { slug, title } = category;

  if (!slug || !title) return null;

  return (
    <ArticleCategoryLink to={`/articles/categories/${slug}`} color={color}>
      {title}
    </ArticleCategoryLink>
  );
};

export default ArticlePreviewCategory;
