import { createElement } from "react";

// Components
import Featured2Up from "../components/hub/Featured2Up";
import Featured3Up from "../components/hub/Featured3Up";
import FeaturedLargeArticle from "../components/hub/FeaturedLargeArticle";
import FeaturedQuote from "../components/hub/FeaturedQuote";
import FeaturedFullWidth from "../components/hub/FeaturedFullWidth";

const Components = {
  "2-Up": Featured2Up,
  "3-Up": Featured3Up,
  Large: FeaturedLargeArticle,
  Quote: FeaturedQuote,
  "Full-Width": FeaturedFullWidth,
};

const renderComponents = (featuredArticles, category) => {
  if (!featuredArticles) return null;

  return featuredArticles.map(function (article, i) {
    let component = Components[article.type];
    let nextArticle = featuredArticles[i + 1];
    let sameType = nextArticle ? nextArticle.type === article.type : false;
    let reactComponent = createElement(component, {
      data: article,
      category,
      sameAsNext: sameType,
      key: `featured-article_` + i,
    });
    return reactComponent;
  });
};

export default renderComponents;
