import { Children, cloneElement, Component } from "react";
import styled from "styled-components";

// We need this file imported here to ensure the cart service is initialized, otherwise things break
import cartService from "../services/cart"; // eslint-disable-line no-unused-vars

// Utils
import { currentPageData } from "../utils/currentPageData";
import { getPlanIdAndQuantityArray } from "../utils/bundle";

// Add global styles
import "../styles/index.scss";

// Shared Components across site
import Navigation from "../components/global/Navigation";
import FlyoutCart from "../components/cart/FlyoutCart";
import Banners from "../components/banners/Banners";
import Footer from "../components/global/Footer";
import SegmentConsentManager from "../components/segment/SegmentConsentManager";
import LocaleSuggester from "../components/global/LocaleSuggester";

// Store
import { connect } from "react-redux";
import { getStore } from "../store/createStore";

// Actions
import { appLoaded } from "../store/app/actions";
import {
  clearCartAndAddProducts,
  addProductsToCart,
} from "../store/cart/actions";

// Selectors
import cartProductSelectors from "../store/cart-product/selectors";
import cartSelectors from "../store/cart/selectors";
import {
  CustomerScope,
  hasScope,
  isAuthenticated,
  logout,
} from "../utils/authentication";
import CartController from "../components/CartController";

const SiteRoot = styled.div`
  padding-top: ${(p) => {
    // Account for offset and 4px loading bar at top of page
    return p.topOffset ? `${p.topOffset - 4}px` : "0";
  }} !important;
  // contain: paint; // this causes issue with flyout

  &:focus {
    outline: none;
  }
`;

export class RitualBase extends Component {
  constructor(props) {
    super(props);

    if (isAuthenticated() && !hasScope(CustomerScope)) {
      logout(false);
    }

    this.updatePageData = this.updatePageData.bind(this);

    this._bufferedOffset = null;
    this._isMounted = false;

    this.state = {
      productsToAdd: [],
    };
  }

  // Triggers on first load
  componentDidMount() {
    this._isMounted = true;
    if (typeof window !== "undefined" && "performance" in window) {
      performance.mark("application-loaded");
    }

    getStore().dispatch(appLoaded());

    this.loadCheckHeadings();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const cart = urlParams.get("cart");
    if (cart === "open") {
      cartService.openCart();
    }

    const addProductsFromQueryParam = (paramName, validIds, formatter) => {
      const param = urlParams.get(paramName);
      return param
        ? param
            .split(",")
            .filter((id) => validIds.includes(id))
            .map(formatter)
        : [];
    };

    const { validPlanIds, validProductOfferIds } = this.props;

    const productsToAdd = [
      ...addProductsFromQueryParam("pid", validPlanIds, (id) =>
        getPlanIdAndQuantityArray([id]),
      ).flat(),
      ...addProductsFromQueryParam("poid", validProductOfferIds, (id) => ({
        quantity: 1,
        productOfferId: id,
      })),
    ];

    if (productsToAdd.length) {
      this.setState({ productsToAdd });
    }
  }

  componentDidUpdate() {
    console.log("State or prop change");
  }

  componentWillUnmount() {
    window.clearInterval(this.cyberMondayInterval);
    this._isMounted = false;
  }

  handleClearProducts = () => {
    this.setState({ productsToAdd: [] });
  };

  async loadCheckHeadings() {
    if (
      process.env.GATSBY_ACTIVE_ENV === "development" &&
      process.env.GATSBY_CHECK_HEADINGS
    ) {
      const { default: checkHeadings } = await import("../utils/headings");
      checkHeadings();
    }
  }

  setOffset(height = 0) {
    if (!this._isMounted) {
      this._bufferedOffset = height;
      return;
    }

    this._bufferedOffset = null;
    this.setState({
      offset: height,
    });
  }

  updatePageData(data) {
    if (data.label) {
      currentPageData.label = data.label;
    }
  }

  renderNavigation() {
    const { location } = this.props;

    return (
      <Navigation>
        <Banners location={location} />
      </Navigation>
    );
  }

  render() {
    const {
      children,
      location,
      offset,
      showUSPNotice,
      activeCart,
      dispatchAddProductsToCart,
      isProcessing,
    } = this.props;
    const { productsToAdd } = this.state;

    const childrenWithProps = Children.map(children, (child) =>
      cloneElement(child, {
        updatePageData: this.updatePageData,
        offset,
      }),
    );

    // Strip all "/" from the beginning and end of the path string.
    const strippedPath = this.props.location.pathname.replace(/^\/+|\/+$/g, "");

    // Hiding nav on certain pages
    const pagesWithoutNav = ["checkout", "ca/checkout", "uk/checkout"];
    const hideNav = !!pagesWithoutNav.find((p) => strippedPath === p);

    // Hiding footer on certain pages
    const pagesWithoutFooter = ["checkout", "ca/checkout", "uk/checkout"];
    const hideFooter = !!pagesWithoutFooter.find((p) => strippedPath === p);
    const displaySlimFooter = children.props.pageContext.slimFooter || false;

    return (
      <>
        {!hideNav && this.renderNavigation()}

        <SiteRoot topOffset={!hideNav ? offset : 0} id="main">
          {childrenWithProps}
        </SiteRoot>

        {!hideFooter && (
          <Footer
            location={location}
            displaySlimFooter={displaySlimFooter}
            showUSPNotice={showUSPNotice}
          />
        )}
        <div id="modal-root">
          {!hideNav && <FlyoutCart />}
          <LocaleSuggester />
        </div>
        <div id="zoom-root"></div>
        <SegmentConsentManager />
        <CartController
          productsToAdd={productsToAdd}
          activeCart={activeCart}
          isProcessing={isProcessing}
          dispatchAddProductsToCart={dispatchAddProductsToCart}
          onClearProducts={this.handleClearProducts}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { navigation } = state;
  return {
    offset: navigation.offset,
    showUSPNotice: state.navigation.showFooterUSPNotice,
    cartQuantity: cartProductSelectors.activeCartProductQuantity(state),
    validPlanIds: state.plans.allIds,
    validProductOfferIds: state.productOffers.allIds,
    activeCart: cartSelectors.activeCart(state),
    isProcessing: cartSelectors.isProcessing(state),
  };
};

const componentWithState = connect(mapStateToProps, {
  dispatchClearCartAndAddProducts: clearCartAndAddProducts,
  dispatchAddProductsToCart: addProductsToCart,
})(RitualBase);

export default componentWithState;
