import { useMemo } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { Appearance, StripeElementsOptions, Stripe } from "@stripe/stripe-js";
import useVariation from "../../hooks/useVariation";

import cartSelectors from "../../store/cart/selectors";
import i18nSelectors from "../../store/i18n/selectors";
import { Color } from "../../utils/styleDesignSystem";

export const stripeAppearanceConfig: Appearance = {
  theme: "stripe",
  labels: "floating",
  variables: {
    borderRadius: "4px",
    colorDanger: `${Color.errorRed}`,
    colorPrimary: `${Color.indigoBlue}`,
    colorSuccess: `${Color.successGreen}`,
    colorText: `${Color.indigoBlue}`,
    colorWarning: `${Color.warningOrange}`,
    fontFamily: "CircularXX",
    fontSizeBase: "16px",
    fontVariantLigatures: "normal",
    fontWeightBold: "500",
    fontWeightLight: "450",
    fontWeightMedium: "500",
    fontWeightNormal: "450",
    iconHoverColor: `${Color.indigoBlue40}`,
    iconChevronDownColor: `${Color.indigoBlue}`,
    iconChevronDownHoverColor: `${Color.indigoBlue40}`,
    iconCloseColor: `${Color.indigoBlue}`,
    iconCloseHoverColor: `${Color.indigoBlue40}`,
    iconColor: `${Color.indigoBlue}`,
  },
  rules: {
    ".Input": {
      boxShadow: "none",
    },
    ".AccordionItem": {
      boxShadow: "none",
    },
  },
};

const ElementsWrapper = ({ children }: { children: React.ReactNode }) => {
  const activeCart = useSelector(cartSelectors.activeCart);
  const locale = useSelector(i18nSelectors.locale);

  const truemedPaymentMethodId = process.env.GATSBY_TRUEMED_PAYMENT_METHOD_ID;

  const cartTotal = activeCart?.total || 0;
  const truemedEnabled = useVariation("enable-hsa-fsa");
  const showTruemed =
    truemedEnabled &&
    locale === "en-US" &&
    truemedPaymentMethodId &&
    cartTotal > 0;

  const stripePromise: Promise<Stripe | null> = useMemo(
    () => loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY!),
    [],
  );

  const mode = activeCart?.requiresPaymentIntent ? "payment" : "setup";

  const elementsOptions: StripeElementsOptions = {
    mode,
    ...(mode === "payment" && { amount: activeCart?.total || 50 }),
    currency: activeCart?.currency || "usd",
    setupFutureUsage: "off_session",
    appearance: stripeAppearanceConfig,
    fonts: [
      {
        family: "CircularXX",
        src: "url(https://fonts.ritual.com/Circular/Book/css/fonts/CircularXXWeb-Book.woff2)",
      },
    ],
    ...(showTruemed && {
      customPaymentMethods: [
        {
          id: truemedPaymentMethodId,
          options: { type: "static", subtitle: "Pay with HSA/FSA" },
        },
      ],
    }),
  };

  return (
    <Elements stripe={stripePromise} options={elementsOptions}>
      {children}
    </Elements>
  );
};

export default ElementsWrapper;
