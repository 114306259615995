import bindClassNames from "classnames/bind";
import { Component } from "react";
import styled from "styled-components";

// Utils
import { getBundleCartLimit } from "../../utils/bundle";
import { getProductForContentfulId } from "../../utils/planToProduct";
import { Color, Opacity, rem, responsive, rgba } from "../../utils/style";

// Redux
import { connect } from "react-redux";
import {
  removeProductFromCart,
  updateCartProductQuantity,
} from "../../store/cart/actions";
import planSelectors from "../../store/plan/selectors";
import productSelectors from "../../store/product/selectors";

// Components
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { getStore } from "../../store/createStore";
import { productOfferForId } from "../../store/product-offer/selectors";
import Currency from "../Currency";
import MagicLink from "../MagicLink";
import StrikeText from "../global/StrikeText";
import QuantityStepper from "./QuantityStepper";
import { variation } from "../../services/launchDarkly";

// Styled Elements
export const CartItemContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${Color.white};
  padding: 16px;

  &::before {
    content: "";
    position: absolute;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-top: 1px solid ${Color.fadedGrey};
  }

  &.simplified {
    padding: 16px 0;

    &::before {
      right: 0;
      bottom: 0;
      left: 0;
      border-top: 1px solid ${Color.veryFadedGrey};
    }
  }

  ${responsive.md`
    padding: 24px;

    &::before {
      right: 24px;
      left: 24px;
    }
  `};

  &:last-child {
    &:not(.simplified) {
      border-bottom: none;
      margin-bottom: 0;

      &::before {
        display: none;
      }
    }
  }
`;

export const SectionWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 0;
  margin-right: var(--spacing-0_5);
`;

export const ImageAndSectionContainer = styled.div`
  display: flex;
`;

// Remove a11y focus from image link since title link is immediately adjacent (duplicate)
const ImageWrapper = styled(MagicLink).attrs({
  tabIndex: -1,
  "aria-hidden": "true",
})`
  position: relative;
  width: 97px;
  height: 105px;
  margin-right: var(--spacing-1);
  flex: 0 0 97px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 97px;
  height: 105px;
  margin-right: var(--spacing-1);
  flex: 0 0 97px;
  overflow: hidden;
`;

export const SubscriptionInfo = styled.p`
  color: ${rgba(Color.ritualBlue, Opacity.light)};
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 22px;
  text-wrap: nowrap;
  display: inline-flex;
  justify-content: space-between;

  ${responsive.md`
    font-size: 14px;
    line-height: 24px;
    display: inline-flex;
    justify-content: space-between;
  `}
  .savings {
    color: var(--Solid-Semantic-SuccessGreen, #4c840d);

    @media (max-width: 375px) {
      display: block;
      margin-left: 0;
    }
  }
`;

export const ProductTitleDefault = styled.p`
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  color: var(--indigo-blue);
  font-weight: 500;
  margin: 0;
  padding-bottom: 8px !important;

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(26)};

    padding-bottom: 16px !important;
  `}

  &.product-offer-title {
    font-family: var(--font-dutch);
    font-size: ${rem(12)};
    line-height: ${rem(18)};

    em {
      font-family: var(--font-circular);
      font-style: normal;
      font-size: ${rem(16)};
      line-height: ${rem(24)};
    }
  }
`;

export const ProductTitle = styled.p`
  color: var(--indigo-blue);

  span {
    display: block;
  }

  .typography-label4 {
    font-style: italic;
    color: var(--indigo-blue-60);
  }
`;

export const CartItemSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0;
  padding: 0;

  p {
    margin: 0;
    padding: 0;
  }
`;

export const CartItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  margin-top: auto;
  padding-right: var(--spacing-0_5);
  padding: 0;

  p {
    margin: 0;
    padding: 0;
    font-size: ${rem(12)};
    line-height: ${rem(20)};
  }
`;

export const ItemActions = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-left: auto;
  padding: 0;
  align-items: flex-end;

  p {
    margin: 0;
    padding: 0;
  }
`;

const QuantityWrapper = styled.div`
  margin-top: auto;
  margin-left: var(--spacing-0_5);

  span.static-qty {
    text-wrap: nowrap;
  }
`;

function getProductUrl(contentfulProduct) {
  if (!contentfulProduct) return null;

  const { contentfulId } = contentfulProduct;
  const { urlSlug } = getProductForContentfulId(contentfulId);

  return `/products/${urlSlug}`;
}

const ItemTitle = ({ contentfulProduct, productOffer, product }) => {
  if (!contentfulProduct && !productOffer) {
    return (
      <>
        <span className="typography-label3">{product.name}</span>
        <span className="typography-label4 font-dutch"></span>
      </>
    );
  }

  const label3Text = productOffer?.name || contentfulProduct?.summary;
  const label4Text = productOffer
    ? productOffer.contentfulProductBundle(getStore().getState())
        .shopDescription
    : contentfulProduct?.productSubhead;

  return (
    <>
      <span className="typography-label3">{label3Text}</span>
      <span className="typography-label4 font-dutch">{label4Text}</span>
    </>
  );
};

function getCartImage(contentfulProduct, productOffer) {
  if (!contentfulProduct && !productOffer) return null;

  const state = getStore().getState();

  if (productOffer) {
    return productOffer.contentfulProductBundle(state).shopImages?.[0];
  }

  const { cartImage } = contentfulProduct;
  return cartImage;
}

// TODO: Put in a util as no longer needed in this file but used elsewhere
export function getVariantDescription(plan, productOffer) {
  if (productOffer) {
    const productQuantity = productOffer.productQuantity(getStore().getState());

    return `${productQuantity}-Item Bundle`;
  }

  return plan && plan.variantDescription;
}

function priceDifference(currentPrice, futurePrice) {
  return (futurePrice * 100 - currentPrice * 100) / 100;
}

function getPrice(cartProduct, productOffer, plan) {
  const { productPrice, productSubtotal, subtotal, quantity } = cartProduct;
  const round = true;

  if (productOffer) {
    const price = (productPrice / 100).toFixed(2);
    const futurePrice = productOffer.futureAmount(getStore().getState());
    const futurePriceAdjusted = (futurePrice / 100).toFixed(2);

    const savings = priceDifference(price, futurePriceAdjusted);
    return (
      <>
        <div className="price">
          <StrikeText>
            <Currency value={futurePriceAdjusted} round={round} />
          </StrikeText>
          <Currency value={price} round={round} />
          <br />
          <span className="savings">
            <Currency value={savings} round={true} /> savings
          </span>
        </div>
      </>
    );
  } else if (productSubtotal !== subtotal) {
    let fullPrice = (productSubtotal / 100).toFixed(2);
    const currentPrice = (subtotal / 100).toFixed(2);
    let savings = priceDifference(currentPrice, fullPrice);

    const welcomeOfferPromotionFlag = variation("welcome-offer-promotion", {});
    const welcomeOfferPromotionEnabled =
      welcomeOfferPromotionFlag?.slug &&
      welcomeOfferPromotionFlag?.slug === "welcome-offer-alc";

    if (welcomeOfferPromotionEnabled) {
      fullPrice = (
        (plan.basePrice * plan.unitQuantity * quantity) /
        100
      ).toFixed(2);
      savings = priceDifference(currentPrice, fullPrice);
    }
    return (
      <>
        <div className="price">
          {plan?.variantDescription && (
            <p className="typography-caption">
              {plan.variantDescription.replace("Essenced", "")}
            </p>
          )}
          <StrikeText>
            <Currency value={fullPrice} round={round} />
          </StrikeText>
          <Currency value={currentPrice} round={round} />
          <br />
          <span className="savings">
            <Currency value={savings} round={true} /> savings
          </span>
        </div>
      </>
    );
  }

  return (
    <div className="price">
      {plan && plan?.variantDescription && (
        <p className="typography-caption">
          {plan.variantDescription.replace("Essenced", "")}
        </p>
      )}
      <Currency value={(productSubtotal / 100).toFixed(2)} round={round} />
    </div>
  );
}

export const CartItemComponent = class CartItem extends Component {
  constructor() {
    super();
    this.state = {
      isBundleModelOpen: false,
    };
  }

  renderImage(cartImage, productUrl, classNames, productOffer, product) {
    const image = cartImage && (
      <GatsbyImage
        image={getImage(cartImage)}
        loading="eager"
        alt={cartImage.description}
        aria_label={cartImage.description}
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: "0",
          userSelect: "none",
          userDrag: "none",
          pointerEvents: "none",
          touchCallout: "none",
        }}
      />
    );

    return (
      <ImageWrapper className={classNames} to={productUrl}>
        {image}
      </ImageWrapper>
    );
  }

  onQuantityChange(e) {
    const { cartProduct } = this.props;
    const quantity = e.target.value;

    this.props.dispatchUpdateCartProductQuantity(cartProduct.planId, quantity);
  }

  handleQuantityChange(newQuantity) {
    const { cartProduct } = this.props;
    this.props.dispatchUpdateCartProductQuantity(
      cartProduct.planId,
      newQuantity,
    );
  }

  async removeItem(cartProduct, e) {
    e.preventDefault();

    const productData = {
      planId: cartProduct.planId,
      productOfferId: cartProduct.productOfferId,
    };

    await this.props.dispatchRemoveProductFromCart(productData, true);
  }

  render() {
    const {
      cartProduct,
      contentfulProduct,
      cartQuantity,
      plan,
      product,
      productOffer,
      className,
      isFlyoutContext,
      disableQuantitySelector,
    } = this.props;

    if (!plan && !productOffer) return null;

    const cartImage = getCartImage(contentfulProduct, productOffer);

    const classNames = bindClassNames(
      className,
      "simplified",
      isFlyoutContext && "flyout",
    );

    const productUrl = productOffer
      ? `/bundles/${productOffer?.slug}`
      : getProductUrl(contentfulProduct);

    const cartLimit = getBundleCartLimit();

    return (
      <>
        <CartItemContainer className={classNames}>
          <ImageAndSectionContainer>
            {cartImage ? (
              this.renderImage(cartImage, productUrl, classNames, productOffer)
            ) : (
              <ImageContainer>
                {product && (
                  <img
                    src={product.imageUrl}
                    alt={product.name}
                    style={{
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                      top: 0,
                      height: "100%",
                      zIndex: "0",
                      userSelect: "none",
                      userDrag: "none",
                      pointerEvents: "none",
                      touchCallout: "none",
                    }}
                  />
                )}
              </ImageContainer>
            )}
            <SectionWrapper className={classNames}>
              <CartItemSection>
                <MagicLink className={classNames} to={productUrl}>
                  <ProductTitle>
                    <ItemTitle
                      contentfulProduct={contentfulProduct}
                      productOffer={productOffer}
                      product={product}
                    />
                  </ProductTitle>
                </MagicLink>
              </CartItemSection>
              <CartItemInfo className={classNames}>
                <SubscriptionInfo>
                  {getPrice(cartProduct, productOffer, plan)}
                </SubscriptionInfo>
              </CartItemInfo>
            </SectionWrapper>
            {disableQuantitySelector ? (
              <ItemActions>
                <QuantityWrapper>
                  <span className="static-qty typography-caption">
                    Qty {cartProduct.quantity}
                  </span>
                </QuantityWrapper>
              </ItemActions>
            ) : (
              <ItemActions>
                {plan ? (
                  <QuantityWrapper>
                    <QuantityStepper
                      quantity={cartProduct.quantity}
                      disableAdd={cartQuantity >= cartLimit}
                      removeAction={this.removeItem.bind(this, cartProduct)}
                      increaseAction={this.handleQuantityChange.bind(
                        this,
                        cartProduct.quantity + 1,
                      )}
                      decreaseAction={this.handleQuantityChange.bind(
                        this,
                        cartProduct.quantity - 1,
                      )}
                      productName={contentfulProduct?.summary}
                    />
                  </QuantityWrapper>
                ) : (
                  <QuantityWrapper>
                    <QuantityStepper
                      quantity={1}
                      disableAdd={cartQuantity >= cartLimit}
                      removeOnly={true}
                      removeAction={this.removeItem.bind(this, cartProduct)}
                      increaseAction={this.handleQuantityChange.bind(
                        this,
                        cartProduct.quantity + 1,
                      )}
                      decreaseAction={this.handleQuantityChange.bind(
                        this,
                        cartProduct.quantity - 1,
                      )}
                      productName={productOffer.name}
                    />
                  </QuantityWrapper>
                )}
              </ItemActions>
            )}
          </ImageAndSectionContainer>
        </CartItemContainer>
      </>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  const plan = planSelectors.planForId(state, {
    id: ownProps.cartProduct.planId,
  });

  return {
    plan,
    productOffer: productOfferForId(state, ownProps.cartProduct.productOfferId),
    product: productSelectors.productForId(state, {
      id: plan?.productId,
    }),
  };
};

export default connect(mapStateToProps, {
  dispatchRemoveProductFromCart: removeProductFromCart,
  dispatchUpdateCartProductQuantity: updateCartProductQuantity,
})(CartItemComponent);
