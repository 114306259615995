import styled from "styled-components";

// Utils
import {
  Font,
  media,
  Color,
  rem,
  maxLines,
  imageStyle,
  responsive,
} from "../../utils/style";

// Components
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import MagicLink from "../MagicLink";
import ArticleReadtime from "./ArticleReadtime";
import ArticlePreviewCategory from "./ArticlePreviewCategory";

// Styled Elements
const ArticleCardContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  white-space: normal;
`;

const Article = styled.div.attrs({ className: "" })`
  width: 340px;
  margin: 15px;

  &:hover {
    cursor: pointer;
  }

  ${media.notDesktop`
    width: 231px;
  `};

  span,
  p,
  h2 {
    ${media.mobile`
      color: ${(p) => (p.color ? p.color : Color.ritualBlue)};
    `};

    a {
      color: inherit;
    }
  }
`;

const ArticleImageWrapper = styled.div`
  height: 278px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${(p) => p.background};
  margin-bottom: 16px;

  ${media.notDesktop`
    height: 174px;
  `};

  img {
    object-position: center center !important;
    transition: all 0.2s ease-in-out !important;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  ${media.mobile`
    margin-bottom: 16px;
    &:hover img {
      transform: none;
    }
  `};

  ${responsive.md`
    margin-bottom: 24px;
  `}
`;

const ArticleTitle = styled.h2`
  ${Font.circular};
  color: ${Color.ritualBlue};
  font-size: ${rem(22)};
  line-height: ${rem(30)};
  font-weight: 400;
  text-align: left;
  margin: 0 0 ${rem(8)};

  ${media.mobile`
    font-size: ${rem(24)};
    line-height: ${rem(32)};
  `};

  a {
    font: inherit;
    color: inherit;
    text-decoration: none;
  }
`;

const ArticlePreview = styled.p`
  ${Font.dutch};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  margin: 0;
  ${maxLines(24, 2)};
`;

const Featured3Up = ({ article, category }) => {
  if (!article) return null;

  return (
    <ArticleCardContainer>
      <MagicLink to={article} aria-labelledby={article.slug}>
        <Article>
          <ArticleImageWrapper background={article.heroBackgroundColor}>
            {article.heroImage && (
              <GatsbyImage
                image={getImage(article.heroImage.mobile)}
                loading="eager"
                color={article.heroBackgroundColor}
                alt={article.title}
                style={imageStyle}
              />
            )}
          </ArticleImageWrapper>
          {category ? (
            <ArticlePreviewCategory
              category={category ? category : article.category}
            />
          ) : (
            <ArticleReadtime article={article} />
          )}
          <ArticleTitle id={article.slug}>{article.title}</ArticleTitle>
          <ArticlePreview>{article.previewText}</ArticlePreview>
        </Article>
      </MagicLink>
    </ArticleCardContainer>
  );
};

export default Featured3Up;
