import { Component } from "react";
import styled, { css } from "styled-components";
import {
  Color,
  Font,
  media,
  Opacity,
  rem,
  responsive,
  rgba,
} from "../../utils/style";
import MagicLink from "../MagicLink";
import { Icons } from "../../utils/react-svg";

const ButtonStyling = css`
  box-sizing: border-box;
  padding: 0 24px;
  border-radius: 26px;
  height: 48px;
  cursor: pointer;
  background-color: ${Color.ritualBlue};
  border: 2px solid ${Color.ritualBlue};
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;

  &,
  span,
  *:before,
  *:after {
    transition:
      color 0.2s,
      background-color 0.2s,
      border 0.2s;
  }

  path {
    stroke: ${Color.white} !important;
  }

  ${responsive.sm`
    padding: 0 var(--spacing-1_5, 24px);
  `};

  * {
    font-weight: 500;
  }

  &.bordered {
    background-color: transparent;

    path {
      stroke: ${Color.ritualBlue} !important;
    }
  }

  &.short {
    height: 40px;
  }
  ${media.mobile`
    &.xs-short {
      height: 40px;
    }
  `}

  &.fullwidth {
    width: 100%;
  }

  &.maxwidth {
    width: 100%;

    & > div {
      width: 100%;

      & > span {
        width: 100%;
      }
    }
  }

  &.fullwidth-mobile {
    ${media.mobile`
      width: 100%;
    `}
  }

  &.long-text {
    padding: 0 20px;

    ${responsive.sm`
      padding: 0 20px;
    `}
  }

  &:hover,
  &:focus {
    border-color: ${Color.ritualYellow} !important;
    background-color: ${Color.ritualYellow} !important;

    path {
      stroke: ${Color.ritualBlue} !important;
    }

    span {
      color: ${Color.ritualBlue} !important;

      &:before {
        background-color: ${Color.ritualBlue} !important;
      }
    }
  }

  &.alt-hover {
    &:hover {
      border-color: ${Color.white} !important;
      background-color: ${Color.white} !important;

      span {
        color: ${Color.ritualBlue} !important;

        &:before {
          background-color: ${Color.ritualBlue} !important;
        }
      }
    }
  }

  &.primary-cta {
    background-color: white !important;
    border: none !important;
    color: ${Color.ritualBlue} !important;

    &:hover {
      color: white !important;
      background-color: ${Color.ritualBlue} !important;

      span {
        color: white !important;
      }

      path {
        stroke: ${Color.white} !important;
      }
    }

    path {
      stroke: ${Color.ritualBlue} !important;
    }

    span {
      color: ${Color.ritualBlue} !important;
    }

    &.narrow {
      ${responsive.sm`
        padding: 0 24px;
      `}
    }
  }

  &.plp-cta {
    background-color: white !important;
    border: none !important;
    color: ${Color.ritualBlue} !important;
    padding: 12px var(--between-components-modules-spacing-1, 16px);
    height: unset;

    &:hover {
      color: white !important;
      background-color: ${Color.ritualBlue} !important;

      span {
        color: white !important;
      }

      path {
        stroke: ${Color.white} !important;
      }
    }

    path {
      stroke: ${Color.ritualBlue} !important;
    }

    span {
      color: ${Color.ritualBlue} !important;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
    }
  }

  &.yellow-cta {
    background-color: ${Color.ritualYellow} !important;
    border: none !important;
    color: ${Color.ritualBlue} !important;

    span {
      color: ${Color.ritualBlue} !important;

      &:before {
        background-color: ${Color.ritualBlue} !important;
      }
    }

    &:hover {
      color: white !important;
      background-color: ${Color.ritualBlue} !important;

      span {
        color: ${Color.white} !important;

        &:before {
          background-color: ${Color.white} !important;
        }
      }
    }

    &.disabled {
      background-color: var(--cool-40) !important;

      span {
        color: ${Color.white} !important;

        &:before {
          background-color: ${Color.white} !important;
        }
      }
    }
  }

  &.disabled {
    cursor: default;
    pointer-events: none;
    background-color: var(--cool-40) !important;
    border-color: var(--cool-40) !important;
    color: var(--indigo-blue-40) !important;

    span {
      color: var(--indigo-blue-40) !important;

      &:before {
        background-color: white !important;
      }
    }

    &:hover {
      background-color: var(--cool-40) !important;
      border: 2px solid var(--cool-40) !important;
    }
  }

  &.outlined {
    background-color: white;
    border: 1px solid ${rgba(Color.ritualBlue, 0.24)} !important;

    span {
      color: ${Color.ritualBlue};

      &:before {
        background-color: ${Color.ritualBlue} !important;
      }
    }

    &:hover {
      background-color: ${Color.ritualBlue} !important;

      span {
        color: ${Color.white} !important;

        &:before {
          background-color: ${Color.white} !important;
        }
      }
    }
  }

  &.simple-text {
    background: ${Color.white};
    border-radius: 22px;
    border: 2px solid ${Color.ritualBlue};
    color: ${Color.ritualBlue};
    font-size: ${rem(14)};
    line-height: ${rem(24)};
    text-align: center;
    padding: 4px 20px;

    span {
      color: ${Color.ritualBlue} !important;
    }

    &:hover {
      border-color: ${Color.ritualYellow} !important;
      background-color: ${Color.ritualYellow} !important;

      span {
        color: ${Color.ritualBlue} !important;

        &:before {
          background-color: ${Color.ritualBlue} !important;
        }
      }
    }
  }

  &.round-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    color: var(--indigo-blue);
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    border: none;
  }
`;

// Styled Elements
const StyledLink = styled(MagicLink)`
  ${ButtonStyling}
`;

const StyledButton = styled.button`
  ${ButtonStyling}
`;

const StyledTextWrapper = styled.div`
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.fullwidth.single-pdp {
    width: 100%;
  }
`;

const StyledText = styled.span`
  ${Font.circular}
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  color: ${Color.white};
  display: flex;
  align-items: center;
  align-content: center;
  margin: 0 !important;

  &.short {
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  }

  &.tall-text {
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  }

  .isvg {
    height: auto;
    display: flex;
  }

  &.bordered {
    color: ${Color.ritualBlue} !important;

    span {
      color: ${Color.ritualBlue} !important;

      &:before {
        background-color: ${Color.ritualBlue};
      }
    }
  }

  &.fullwidth.single-pdp {
    width: 100%;
    justify-content: center;
  }
`;

const AppendText = styled.span`
  position: relative;
  ${Font.circular}
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  color: ${Color.white};
  padding-left: 10px;
  margin: 0 0 0 8px !important;

  &.short {
    font-size: ${rem(14)};
    line-height: ${rem(24)};

    &:before {
      height: 16px;
    }
  }

  &:before {
    position: absolute;
    content: "";
    top: 50%;
    transform: translateY(-50%);
    height: 24px;
    left: 0;
    width: 1px;
    background-color: ${Color.white};
    opacity: ${Opacity.light};
  }
`;

const Arrow = styled(Icons.ArrowRightLarge)`
  width: 24px;
  height: 24px;
  margin-left: 10px;
`;

export default class RitualButton extends Component {
  render() {
    let {
      to,
      state,
      href,
      children,
      className,
      target,
      title,
      append,
      onClick,
      ariaLabel,
      id,
      disabled,
      type,
      isLink = true,
      arrow = false,
      floatText = false,
    } = this.props;

    if (disabled) {
      className = `${className || ""} disabled`;
    }

    let settings = {
      to,
      state,
      href,
      title,
      target,
      className,
      ariaLabel,
      id,
      type,
    };

    if (onClick) {
      settings.onClick = disabled ? null : onClick;
    }

    const ButtonComponent = isLink ? StyledLink : StyledButton;

    return (
      <ButtonComponent id={id} {...settings}>
        <StyledTextWrapper className={floatText ? className : ""}>
          <StyledText className={className}>{children}</StyledText>
          {append && <AppendText className={className}>{append}</AppendText>}
          {arrow && <Arrow />}
        </StyledTextWrapper>
      </ButtonComponent>
    );
  }
}
