import React from "react";
import styled from "styled-components";

const FollowContainer = styled.div`
  height: 100%; /* Matches the height of its parent */
  z-index: 100; /* Ensure it's above css background */
  position: relative;
`;

const ContentContainer = styled.div`
  position: sticky;
  width: 100%;
  top: 0;
  ${props => `top: ${props.customOffset}px;`}
`;

type Props = {
  children: React.ReactNode;
  customOffset?: number;
};

const FollowElement = ({ children, customOffset = 0 }: Props) => {
  if (!children) return null;

  return (
    <FollowContainer>
      <ContentContainer customOffset={customOffset}>{children}</ContentContainer>
    </FollowContainer>
  );
};

export default FollowElement;
