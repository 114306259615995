import { Component } from "react";
import styled from "styled-components";

// Services
import intl from "../../services/intl";
import cartService from "../../services/cart";
import { navigate } from "../../services/navigation";

// Utils
import { responsive } from "../../utils/style";

// Redux
import { connect } from "react-redux";
import userSelectors from "../../store/user/selectors";

// Actions
import { addProductToCart } from "../../store/cart/actions";

// Components
import DiscountButton from "./DiscountButton";

const ButtonWrapper = styled.div`
    width: 100%;

    ${responsive.md`
    width: auto;
  `};
`;

const Button = styled(DiscountButton)`
    width: 100%;

    ${responsive.sm`
    width: auto;
    padding-left: 37px;
    padding-right: 37px;
  `};

    ${responsive.md`
    padding-left: 26px;
    padding-right: 26px;
  `};
`;

export const AuthedAddButtonComponent = class AuthedAddButton extends Component {
  handleClick(e) {
    const { dispatchAddProductToCart, plan, propertiesToTrack, trackCTAClick } =
      this.props;

    if (trackCTAClick) trackCTAClick(e);

    dispatchAddProductToCart({ planId: plan.id }, propertiesToTrack);

    if (!this.props.isLoggedIn) {
      navigate("/login", { state: { redirect: "/cart" } });
    } else {
      cartService.openCart(e);
    }
  }

  render() {
    const { isLoggedIn, plan, promotion } = this.props;
    const toLocation = isLoggedIn ? "/cart" : "/login";

    const price = plan.amount / 100;

    return (
      <ButtonWrapper>
        <Button
          to={toLocation}
          noNavigate={true}
          onClick={this.handleClick.bind(this)}
          prefix={intl.t("general.discount-button-prefix", "Add")}
          price={price}
          promotion={promotion}
          {...this.props}
        />
      </ButtonWrapper>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: userSelectors.isLoggedIn(state),
  };
};

export default connect(mapStateToProps, {
  dispatchAddProductToCart: addProductToCart,
})(AuthedAddButtonComponent);
