import { useCallback } from "react";
import styled, { css } from "styled-components";
import cartService from "../../services/cart";
import { responsive } from "../../utils/style";
import MagicModal from "../MagicModal";
import InterstitialSingleUpsell from "./InterstitialSingleUpsell";

const contentStyleSlideout = css`
  padding: 69px 24px 32px 24px;
  width: 100%;

  ${responsive.sm`
    padding-top: 69px;
  `}
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 375px;
  margin: auto;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  padding-bottom: 140px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const modalCloseButtonStyle = css`
  top: 69px;
  right: 24px;

  ${responsive.sm`
    right: 40px;
  `}
`;

const useOpenCart = (beforeCallbackFn) =>
  useCallback(
    (e) => {
      beforeCallbackFn();
      cartService.openCart(e);
    },
    [beforeCallbackFn],
  );

const InterstitialFlyout = ({ isOpen, onRequestClose, addedPlan }) => {
  const openCart = useOpenCart(onRequestClose);

  return (
    <MagicModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentStyle={contentStyleSlideout}
      modalCloseButtonStyle={modalCloseButtonStyle}
      className="vertical-scroll"
      slideOut={true}
    >
      <ModalContainer>
        <InterstitialSingleUpsell openCart={openCart} addedPlan={addedPlan} />
      </ModalContainer>
    </MagicModal>
  );
};

export default InterstitialFlyout;
