import styled from "styled-components";
import classNames from "classnames";
import { useEffect, useState, useCallback } from "react";
import { responsive } from "../../utils/style";
import metrics from "../../utils/metrics";
import { IGatsbyImageData } from "gatsby-plugin-image";
import {
  SupplementFactsType,
  ValuePropType,
  ProductStudy,
  HowToUse,
  ContentfulImage,
} from "../../utils/productTypes";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

import {
  QuickView,
  QuickViewHeader,
  RCloseButton,
} from "@ritual/essentials-for-react";
import FocusTrap from "focus-trap-react";
import TabGroupPanel from "../bundle/TabGroupPanel";
import HowToUsePanel, { ImageType } from "./HowToUsePanel";
import SupplementFactsPanel from "./SupplementFactsPanel";
import ClinicalStudyPanel from "./ClinicalStudyPanel";

type Image = ImageType;

type Product = {
  sku: string;
  pageDescription: string;
  category: string;
  name: {
    name: string;
  };
  supplementFacts: SupplementFactsType;
  valueProps: ValuePropType[];
  howToUse: HowToUse;
  productStudyModal: ProductStudy;
  heroZoomImages: ContentfulImage[];
  heroFeaturedImages: ContentfulImage[];
  timelineHeading: string;
  timelineSubhead: string;
  timelinePhoto: IGatsbyImageData;
  timelineVideo: IGatsbyImageData;
  timelineData: {
    childMarkdownRemark: {
      rawMarkdownBody: string;
    };
  };
};

type BundleItem = {
  name?: string;
  products: Product[];
  heroImage?: Image;
};

export type FlyoutPanelProps = {
  item: BundleItem;
  product: Product;
  open: boolean;
  openIndex?: string;
  hideTabs?: boolean;
  closeQuickView: () => void;
};

const PanelWrapper = styled.div`
  .quick-view-content-wrapper {
    background-color: var(--warm-20);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: unset;
  }

  &.single-product-pdp {
    padding: var(--spacing-2);

    .quick-view-content-wrapper {
      background-color: var(--white);
    }
  }

  &.no-quick-view-footer .quick-view-item-list {
    height: 100%;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.no-overlay overlay-box {
    display: none;
  }

  .quick-view-header {
    padding: var(--spacing-1_5, 24px) var(--spacing-1_5, 24px) 0;
    border-bottom: unset !important;

    .icon-close {
      width: 24px;
      height: 24px;
    }

    div.title {
      margin-bottom: unset;
    }

    .title-wrapper {
      padding-bottom: var(--spacing-1_5, 24px);

      div.label {
        margin-bottom: unset !important;
      }
    }

    .caption-wrapper {
      border-bottom: 2px solid var(--indigo-blue);
    }
  }

  .quick-view-footer {
    display: none;
    border-top: unset !important;
  }

  ${responsive.sm`
    .quick-view-item-list {
      overflow-x: hidden;
    }

    .nav-flyout.left {
      min-width: 455px !important;
    }
  `}
`;

const PanelTitle = styled.div.attrs({
  className: "typography-headline2",
})`
  font-weight: 450;
  line-height: 40px;
  letter-spacing: -0.01em;
  font-size: var(--spacing-2, 32px);
  padding-bottom: var(--spacing-2);

  ${responsive.sm`
    font-size: var(--spacing-2_5, 40px);
  `}

  p {
    margin-bottom: unset;
    i {
      font-family: var(--font-dutch);
      font-weight: 400;
    }
  }
`;

const PanelContent = styled.div`
  margin: 0 var(--spacing-1_5, 24px);
`;

const FlyoutPanel = ({
  item,
  open,
  openIndex,
  product,
  hideTabs = false,
  closeQuickView,
}: FlyoutPanelProps) => {
  if (!item || !product) return null;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [selectedProduct, setSelectProduct] = useState<Product>(product);

  // Bundle flyout specific
  const productLabels = item?.products?.map(
    (product: Product) => product.name.name,
  );

  const selectProduct = (productLabel: string) => {
    const product = item?.products.find(
      (product: Product) => product.name.name === productLabel,
    ) as Product;
    setSelectProduct(product);

    metrics.track("Flyout Tab Toggled", {
      title: productLabel,
      location: openIndex,
    });
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape" && open) {
        closeQuickView();
      }
    },
    [open, closeQuickView],
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const initPageOverflow =
    typeof document !== "undefined" ? document.body?.style?.overflow : null;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [initialOverflow] = useState(initPageOverflow);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (initialOverflow === "hidden") return;

    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open, initialOverflow]);

  const renderHowToUse = <HowToUsePanel selectedProduct={selectedProduct} />;
  const renderSupplementFacts = <SupplementFactsPanel item={selectedProduct} />;
  const renderStudyModal = <ClinicalStudyPanel productData={selectedProduct} />;

  const isClinicalStudy =
    openIndex !== "Supplement Facts" && openIndex !== "How to Use";

  const clinicalStudyTitle =
    selectedProduct.productStudyModal?.modalTitle?.raw &&
    documentToHtmlString(
      JSON.parse(selectedProduct.productStudyModal.modalTitle.raw),
    );
  const panelTitle = isClinicalStudy ? clinicalStudyTitle : openIndex;

  const renderPanelContent = () => {
    switch (openIndex) {
      case "How to Use":
        return renderHowToUse;
      case "Supplement Facts":
        return renderSupplementFacts;
      default:
        return selectedProduct.productStudyModal && renderStudyModal;
    }
  };

  return (
    <FocusTrap
      active={open}
      focusTrapOptions={{
        allowOutsideClick: true,
        initialFocus: "#product-flyout-panel",
        fallbackFocus: "#product-flyout-panel",
        tabbableOptions: {
          getShadowRoot: true,
        },
      }}
    >
      <PanelWrapper
        id="product-flyout-panel"
        className={classNames({
          "no-quick-view-footer": true,
          "single-product-pdp": hideTabs,
        })}
      >
        <QuickView open={open} closeQuickView={closeQuickView}>
          <QuickViewHeader slot="quick-view-header">
            <PanelTitle
              slot="title"
              dangerouslySetInnerHTML={{
                __html: panelTitle as string,
              }}
            />
            <RCloseButton
              slot="quick-view-header-close"
              handleClose={closeQuickView}
            ></RCloseButton>

            {!hideTabs && (
              <div slot="quick-view-header-caption">
                <TabGroupPanel
                  selectedLabel={selectedProduct.name.name}
                  labels={productLabels}
                  selectTabCallback={selectProduct}
                />
              </div>
            )}
          </QuickViewHeader>

          <PanelContent slot="quick-view-items">
            {renderPanelContent()}
          </PanelContent>
        </QuickView>
      </PanelWrapper>
    </FocusTrap>
  );
};

export default FlyoutPanel;
