import { Component } from "react";
import styled from "styled-components";

// Components
import PulseIcon from "./animation/PulseIcon";

// Utils
import { Color, rem } from "../utils/style";

const PulseIconWrapper = styled.div`
  padding: 8px;
  background-color: ${Color.ritualLightYellow};
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  font-weight: 300;
  font-size: ${rem(14)};
  line-height: ${rem(20)};
  font-style: normal;
  width: 100%;
  justify-content: center;

  &.cart-interstitial {
    margin-bottom: 16px;
    padding: 16px;
  }

  span {
    font-weight: 300;
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    color: ${Color.ritualBlue};
    margin-left: 8px;
  }
`;

export default class PulseIconComponent extends Component {
  render() {
    let { children, className } = this.props;

    return (
      <PulseIconWrapper className={className}>
        <PulseIcon />
        <span>{children}</span>
      </PulseIconWrapper>
    );
  }
}
