import styled from "styled-components";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import MagicLink from "../MagicLink";

// Utils
import {
  Font,
  media,
  Color,
  rem,
  maxLines,
  imageStyle,
  responsive,
} from "../../utils/style";

// Styled Elements
const ArticleCardContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  flex: 1 0 0;

  @media (min-width: 933px) {
    flex: 0 0 calc(33.333% - ((var(--spacing-1_5) * 2) / 3));
    width: calc(33.333% - ((var(--spacing-1_5) * 2) / 3));
  }
`;

const ArticleComponent = styled.div`
  width: 269px;
  margin-right: var(--spacing-1);

  @media (min-width: 933px) {
    width: 100%;
    margin-right: 0;
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:hover {
    cursor: pointer;
  }

  &.last-element {
    margin-right: 0;
  }

  span,
  p,
  h2 {
    ${media.mobile`
      color: ${(p: any) => (p.color ? p.color : Color.ritualBlue)};
    `};

    a {
      color: inherit;
    }
  }
`;

const ArticleImageWrapper = styled.div<{ background: string }>`
  height: 201px;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: ${(p: any) => p.background};
  margin-bottom: 16px;

  @media (min-width: 933px) {
    height: unset;
    aspect-ratio: 1.465;

    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  img {
    object-position: center center !important;
    transition: all 0.2s ease-in-out !important;
  }

  ${responsive.md`
    margin-bottom: 24px;
  `}
`;

const ArticleCategory = styled.p`
  text-transform: uppercase;
  color: var(--indigo-blue-60) !important;
  margin-bottom: var(--spacing-0_75);

  ${responsive.md`
    margin-bottom: var(--spacing-0_5);
  `}
`;

const ArticleTitle = styled.p`
  text-align: left;
  margin-bottom: var(--spacing-0_75);

  a {
    font: inherit;
    color: inherit;
    text-decoration: none;
  }

  ${responsive.md`
    margin-bottom: var(--spacing-0_5);
  `}
`;

const ArticlePreview = styled.p`
  ${Font.dutch};
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  margin: 0;
  ${maxLines(24, 2)};
`;

// eslint-disable-next-line @typescript-eslint/no-redeclare
type Article = {
  slug: string;
  heroBackgroundColor: string;
  heroImage: {
    gatsbyImageData: IGatsbyImageData;
  };
  title: string;
  previewText: string;
  category: {
    title: string;
  };
};

type Props = {
  article: Article;
  className?: string;
};

const BundleFeaturedArticle = ({ article, className }: Props) => {
  if (!article) return null;

  return (
    <ArticleCardContainer>
      <MagicLink to={article} aria-labelledby={article.slug}>
        <ArticleComponent className={className}>
          <ArticleImageWrapper background={article.heroBackgroundColor}>
            {article.heroImage && (
              <GatsbyImage
                image={
                  getImage(
                    article.heroImage.gatsbyImageData,
                  ) as IGatsbyImageData
                }
                loading="eager"
                color={article.heroBackgroundColor}
                alt={article.title}
                style={imageStyle as object}
              />
            )}
          </ArticleImageWrapper>
          {article.category && (
            <ArticleCategory className="typography-eyebrow1">
              {article.category.title}
            </ArticleCategory>
          )}
          <ArticleTitle
            className="typography-label1 font-circular"
            id={article.slug}
          >
            {article.title}
          </ArticleTitle>
          <ArticlePreview className="typography-body1">
            {article.previewText}
          </ArticlePreview>
        </ArticleComponent>
      </MagicLink>
    </ArticleCardContainer>
  );
};

export default BundleFeaturedArticle;
