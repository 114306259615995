import { useSelector } from "react-redux";
import cartProductSelectors from "../store/cart-product/selectors";
import cartSelectors from "../store/cart/selectors";
import { allProductOffersByCurrentStore } from "../store/product-offer/selectors";
import productSelectors from "../store/product/selectors";
import staticDataSelectors, {
  contentfulProductBundlesForSlug,
} from "../store/staticData/selectors";
import useProductOfferUpsells from "./launchdarkly/useProductOfferUpsells";
import useVariation from "./useVariation";

const useProductRecommendation = () => {
  const isProcessing = useSelector(cartSelectors.isProcessing);
  const activeCart = useSelector(cartSelectors.activeCart);

  const skus = isProcessing
    ? undefined
    : activeCart?.productRecommendations || [];

  return { isProcessing, skus };
};

const useProductFromCart = () =>
  useSelector((state) => {
    const [cartProduct] = cartProductSelectors.activeCartProducts(state);
    if (!cartProduct) return {};

    const product = cartProduct.product(state);
    if (!product) return {};

    return product;
  });

const useCtfProductOfferUpsell = (sku) =>
  useSelector((state) => {
    if (!sku) return {};

    const ctfProduct = staticDataSelectors.contentfulProductForSku(state, {
      sku,
    });

    const productOfferSlug = ctfProduct.upsellProductOffer?.slug;

    const ctfProductBundle = contentfulProductBundlesForSlug(
      state,
      productOfferSlug,
    );

    return ctfProductBundle ? ctfProductBundle.slug : undefined;
  });

const useCombinedProductOfferUpsells = (sku) => {
  const ctfUpsellSlug = useCtfProductOfferUpsell(sku);
  const ldUpsells = useProductOfferUpsells("product-offer-upsells", sku);

  return ldUpsells.length ? ldUpsells : [{ slug: ctfUpsellSlug }];
};

const useUpsellMissingProducts = (productId, upsells) =>
  useSelector((state) =>
    upsells
      .map((upsell) => {
        if (!upsell?.slug) return false;

        const productOffer = allProductOffersByCurrentStore(state).find(
          (offer) => offer.slug === upsell.slug,
        );

        if (!productOffer) return false;

        const productOfferPlan = productOffer
          .productOfferPlans(state)
          .find(
            (productOfferPlan) =>
              productOfferPlan.initialPlan(state).productId !== productId,
          );

        const plan = productOfferPlan.initialPlan(state);

        const product = productSelectors.productForId(state, {
          id: plan.productId,
        });

        return {
          sku: product.sku,
          productOfferId: productOffer.id,
          productOfferPlanId: productOfferPlan.id,
          productOfferSlug: productOffer.slug,
          label: upsell.label,
        };
      })
      .filter(Boolean),
  );

const useInterstitialProductOfferUpsells = () => {
  const { id: productId, sku } = useProductFromCart();

  const upsells = useCombinedProductOfferUpsells(sku);
  const upsellsWithKeys = useUpsellMissingProducts(productId, upsells);

  return upsellsWithKeys;
};

const useInterstitialUpsells = (intervalCount) => {
  const recommendationUpsells = useProductRecommendation();
  const productOfferUpsells = useInterstitialProductOfferUpsells();

  const enableUpsell = useVariation("cart-interstitial-upsell", {
    productOfferUpsell: false,
    productRecommendation: true,
  });

  const useProductOfferUpsellSku =
    enableUpsell.productOfferUpsell &&
    productOfferUpsells.length &&
    intervalCount === 30;

  return useProductOfferUpsellSku
    ? {
        isProcessing: false,
        type: "productOfferUpsell",
        upsells: productOfferUpsells,
      }
    : {
        isProcessing: recommendationUpsells.isProcessing,
        type: "productRecommendation",
        upsells: recommendationUpsells.skus?.map((sku) => ({ sku })),
      };
};

export default useInterstitialUpsells;
