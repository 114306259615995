import MagicArrowCarousel from "../MagicArrowCarousel";
import Container from "../Container";
import Row from "../Row";

import FeaturedArticleCard from "./FeaturedArticleCard";

const Featured3Up = ({ data: { articles }, category }) => {
  if (!articles) return null;

  return (
    <Container>
      <Row>
        <MagicArrowCarousel arrowsEnabled={false} dotsEnabled={true}>
          {articles.map((article, i) => (
            <FeaturedArticleCard
              key={i}
              article={article}
              category={category}
            />
          ))}
        </MagicArrowCarousel>
      </Row>
    </Container>
  );
};

export default Featured3Up;
