import { graphql, StaticQuery } from "gatsby";
import { Component } from "react";
import styled from "styled-components";

// Services
import intl from "../../services/intl";

// Utils
import { iterablePost } from "../../utils/iterable";
import metrics from "../../utils/metrics";
import NameMap from "../../utils/nameMap";
import { Icons } from "../../utils/react-svg";
import { emailRegex } from "../../utils/regex";
import ModalCopy from "../../utils/stockStatus";
import { Color, Font, rem, responsive } from "../../utils/style";
import Text from "../Text";

// Components
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import RitualButton from "../global/RitualButton";
import MagicModal from "../MagicModal";

// Store
import { connect } from "react-redux";
import intlService from "../../services/intl";
import { setWaitlistedForSku } from "../../store/out-of-stock/actions";

// Styled Elements
const ModalContent = styled.div`
  max-width: 100%;

  ${responsive.sm`
    max-width: 421px;
    width: 420px;
  `};

  ${responsive.md`
    max-width: 614px;
    width: 566px;
  `};

  ${responsive.lg`
    max-width: 570px;
  `};

  > h2 {
    ${Font.circular};
    font-size: ${rem(22)};
    line-height: ${rem(32)};
    letter-spacing: -0.2px;
    font-weight: 500;
    padding: 0 50px 24px 0;
    border-bottom: 2px solid ${Color.ritualBlue};
    margin: 0 0 16px;

    ${responsive.md`
      font-size: ${rem(30)};
      line-height: ${rem(36)};
      letter-spacing: -0.4px;
    `};

    &.no-border {
      display: flex;
      align-items: center;
      border-bottom: none;
      padding: 0;
      margin-bottom: 8px;

      ${responsive.md`
        margin-bottom: 24px;
      `};

      svg {
        width: 20px;
        height: 15px;
        margin-right: 8px;

        ${responsive.md`
          width: 26px;
          height: 20px;
          margin-right: 16px;
        `};
      }
    }
  }

  p {
    ${Font.dutch};
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    font-weight: normal;
    margin: 0 0 16px !important;
    padding: 0;

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    `};

    svg {
      height: 22px;
      width: auto;
      transform: translateY(-2px);
    }
  }
`;

const EmailForm = styled.form`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  label:first-of-type {
    ${Font.circular};
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    font-weight: 300;
    padding: 0;
    margin: 0 0 8px;
  }

  input {
    &[type="email"] {
      width: 100%;
      height: 44px;
      border: 1px solid ${Color.ritualBlue};
      margin: 0 0 32px;
      padding-left: 16px;
      color: ${Color.ritualBlue};

      ${responsive.md`
        max-width: 370px;
      `};
    }
  }
`;

const WaitlistButton = styled.button`
  box-sizing: border-radius;
  padding: 0 30px;
  border-radius: 26px;
  height: 50px;
  cursor: pointer;
  background-color: ${Color.ritualBlue};
  border: 2px solid ${Color.ritualBlue};
  margin: 0;
  transition: all 0.2s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
  width: 100%;

  ${responsive.sm`
    width: auto;
  `};

  span {
    ${Font.circular}
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    font-weight: 500;
    color: ${Color.white};
    transition: all 0.2s;
    display: flex;
    align-items: center;
    align-content: center;
  }

  &:disabled,
  &[disabled] {
    opacity: 0.24;
  }
`;

const WaitListLabel = styled.label`
  margin-top: 32px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  input {
    display: none;
  }

  span {
    ${Font.circular};
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    font-weight: 300;
    margin: 0;
    padding: 0;

    ${responsive.md`
      font-size: ${rem(16)};
      line-height: ${rem(22)};
    `};
  }
`;

const CheckBox = styled.i`
  width: 20px;
  height: 20px;
  border: 1px solid ${Color.ritualBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  transform: translateY(1px);
  flex: 0 0 auto;
`;

const ReferFriend = styled.div`
  padding: 24px 20px 132px 20px;
  background-color: rgb(242, 241, 245);
  position: relative;

  ${responsive.sm`
    padding: 32px;
  `};

  ${responsive.md`
    padding: 40px 56px;
  `};

  ${responsive.lg`
    padding: 40px;
  `};
`;

const ReferFriendContent = styled.div`
  position: relative;
  z-index: 100;
  text-align: left !important;

  > h3 {
    ${Font.circular};
    font-size: ${rem(22)};
    line-height: ${rem(32)};
    letter-spacing: -0.2px;
    font-weight: 500;
    color: ${Color.ritualBlue};
    margin: 0 0 8px;
    text-align: inherit;

    ${responsive.sm`
      margin-bottom: 16px;
    `};

    ${responsive.md`
      font-size: ${rem(30)};
      line-height: ${rem(36)};
      letter-spacing: -0.4px;
    `};
  }

  > p {
    ${Font.dutch};
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    font-weight: normal;
    margin: 0 0 32px;
    padding: 0;
    text-align: inherit;

    ${responsive.sm`
      margin-bottom: 40px;
    `};

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    `};
  }

  ${responsive.sm`
    h3, p {
      max-width: 280px;
    }
  `};
`;

const ReferFriendImage = styled(GatsbyImage)`
  position: absolute !important;
  width: 100px;
  height: 100px;
  bottom: 16px;
  left: 20px;

  ${responsive.sm`
    width: 100px;
    height: 100px;
    bottom: 24px;
    left: unset;
    right: 24px;
  `};

  ${responsive.md`
    width: 150px;
    height: 150px;
    bottom: 40px;
    right: 40px;
  `};
`;

export class OutOfStockModal extends Component {
  constructor(props) {
    super(props);

    const { product } = props;

    // Copy used for Modal
    let modalConfig;
    if (product.stockStatus !== "in stock") {
      modalConfig =
        ModalCopy?.[product.stockStatus]?.[product.sku] || ModalCopy.default;
    }

    this.state = {
      modalConfig,
      disabled: true,
      email: "",
      newsletter: false,
      productName: NameMap(product.name).plain,
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleNewsletterChange = this.handleNewsletterChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleEmailChange(e) {
    let email = e.target.value.toLowerCase();
    let emailPass = emailRegex.test(email);

    this.setState({
      email: email,
      disabled: !emailPass,
    });
  }

  handleNewsletterChange(e) {
    this.setState({ newsletter: e.target.checked });
  }

  handleSubmit(e) {
    e.preventDefault();
    let { disabled, email, productName, newsletter, modalConfig } = this.state;

    if (disabled) return;

    this.setState({
      error: false,
    });

    let publicIdString = modalConfig.iterable.optOut;
    if (newsletter) {
      publicIdString = modalConfig.iterable.optIn;
    }

    iterablePost(publicIdString, email).then(() => {
      this.props.dispatchSetWaitlistedForSku(this.props.product.sku);

      metrics.track("CTA Clicked", {
        title: productName,
        location: "Waitlist Modal Email Submitted",
      });

      if (newsletter) {
        metrics.track("Newsletter Opt-In");
      }
    });
  }

  renderWaitlistModal() {
    const { data, onRequestClose, waitlisted } = this.props;
    const { email, disabled, newsletter, modalConfig } = this.state;

    const modalCopy = modalConfig?.modal;
    const confirmationCopy = modalConfig?.completeModal;
    const ctaModule = confirmationCopy?.ctaModule;
    const confirmationImage = intl.cf(data.confirmationImages.nodes, true);
    const Emoji = Icons[modalCopy.copy.emoji];
    if (waitlisted) {
      return (
        <>
          <h2 className="no-border">
            <Icons.CheckRounded />
            <Text id={confirmationCopy.title} />
          </h2>
          <p>
            <Text id={confirmationCopy.copy} />
          </p>
          {ctaModule && (
            <ReferFriend>
              <ReferFriendContent>
                <h3>
                  <Text id={ctaModule.title} />
                </h3>
                <p>
                  <Text id={ctaModule.copy} />
                </p>
                <RitualButton
                  onClick={onRequestClose.bind(this)}
                  to={ctaModule.url}
                >
                  <Text id={ctaModule.cta} />
                </RitualButton>
              </ReferFriendContent>
              <ReferFriendImage
                image={getImage(confirmationImage)}
                loading="eager"
                fadeIn={false}
                alt={confirmationImage.description}
                style={{
                  userSelect: "none",
                  userDrag: "none",
                  pointerEvents: "none",
                  touchCallout: "none",
                }}
              />
            </ReferFriend>
          )}
        </>
      );
    }
    return (
      <>
        <h2>
          <Text id={modalCopy.title} />
        </h2>
        <p>
          <span
            dangerouslySetInnerHTML={{
              __html: intlService.t(modalCopy.copy.copy),
            }}
          />{" "}
          {Emoji && <Emoji />}
        </p>
        <EmailForm onSubmit={this.handleSubmit}>
          <label htmlFor="out-of-stock-email">
            <input
              id="out-of-stock-email"
              type="email"
              value={email}
              onChange={this.handleEmailChange}
            />
            <Text id="product.oos.email-label" defaultMessage="Your Email" />
          </label>
          <WaitlistButton
            type="submit"
            disabled={disabled}
            aria-label="submit-waitlist-form"
          >
            <span>
              <Text id={modalCopy.cta} />
            </span>
          </WaitlistButton>
          <WaitListLabel htmlFor="confirm-newsletter-signup">
            <input
              type="checkbox"
              id="confirm-newsletter-signup"
              value={newsletter}
              onChange={this.handleNewsletterChange}
            />
            <CheckBox>{newsletter && <Icons.CheckRounded />}</CheckBox>
            <span>
              <Text
                id="product.oos.email-confirm"
                defaultMessage="Yes, I’d like to receive emails from Ritual."
              />
            </span>
          </WaitListLabel>
        </EmailForm>
      </>
    );
  }

  render() {
    const { isOpen, onRequestClose, product } = this.props;

    return (
      <MagicModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        ariaLabel={`Product is ${product.stockStatus}`}
      >
        <ModalContent>{this.renderWaitlistModal()}</ModalContent>
      </MagicModal>
    );
  }
}

const componentQuery = graphql`
  query OutOfStockModalQuery {
    confirmationImages: allContentfulAsset(
      filter: { contentful_id: { eq: "52ujqOgnOhNSyZNyel0YZT" } }
    ) {
      nodes {
        node_locale
        description
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: DOMINANT_COLOR
          width: 150
          quality: 100
        )
      }
    }
  }
`;

export const ConnectedOutOfStockModal = connect(null, {
  dispatchSetWaitlistedForSku: setWaitlistedForSku,
})(OutOfStockModal);

export default (
  props, // eslint-disable-line import/no-anonymous-default-export
) => (
  <StaticQuery
    query={componentQuery}
    render={(data) => <ConnectedOutOfStockModal {...props} data={data} />}
  />
);
