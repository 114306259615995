import intlService from "../services/intl";
import siteConfig from "./siteConfig";

interface ProductSEOData {
  name: string;
  product_id: string;
  url_slug: string;
  variant_description: string | null;
  contentfulProduct: {
    path: string;
    slug: string;
    gtin: string;
    socialImage: {
      file: {
        details: {
          image: {
            width: number;
            height: number;
          };
        };
        url: string;
      };
    };
    heroFeaturedImages: {
      file: {
        details: {
          image: {
            width: number;
            height: number;
          };
        };
        url: string;
      };
    }[];
  };
  ritualPlans: {
    plan_id: string;
    amount: number;
    billing_type: string;
    currency: string;
    interval: string;
    interval_count: number;
    in_stock: boolean;
    variant_contents: string;
    variant_description: string;
    base_price: number;
    number_of_months: number;
    monthly_amount: number;
    monthly_amount_savings: number;
    monthly_percent_savings: number;
  }[];
}

interface ProductGroupData {
  ritualProducts: ProductSEOData[];
}

const countryTransitTime = {
  CA: 10,
  US: 4,
  GB: 7,
};

const intervalMap = {
  30: "Monthly",
  90: "3-month",
};

const WELCOME_OFFER_DISCOUNT = 0.8;

export const countryCode = (currency: string) => {
  switch (currency) {
    case "CAD":
      return "CA";
    case "GBP":
      return "GB";
    case "USD":
    default:
      return "US";
  }
};

export const createShippingDetails = (plan: any) => {
  return {
    "@type": "OfferShippingDetails",
    shippingRate: {
      "@type": "MonetaryAmount",
      value: plan.billing_type === "recurring" ? 0 : 6.95,
      currency: plan.currency?.toUpperCase(),
    },
    deliveryTime: {
      "@type": "ShippingDeliveryTime",
      businessDays: {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: [
          "https://schema.org/Monday",
          "https://schema.org/Tuesday",
          "https://schema.org/Wednesday",
          "https://schema.org/Thursday",
          "https://schema.org/Friday",
        ],
      },
      handlingTime: {
        "@type": "QuantitativeValue",
        minValue: 0,
        maxValue: 1,
        unitCode: "d",
      },
      transitTime: {
        "@type": "QuantitativeValue",
        minValue: 1,
        maxValue:
          (plan.currency &&
            countryTransitTime[countryCode(plan.currency?.toUpperCase())]) ||
          7,
        unitCode: "d",
      },
    },
    shippingDestination: {
      "@type": "DefinedRegion",
      addressCountry: countryCode(plan.currency?.toUpperCase()),
    },
  };
};

export const createOffer = (plan: any, productUrl: string) => {
  const isSubscription = plan.billing_type === "recurring";

  if (!isSubscription && !plan.interval_count) {
    return {
      "@type": "Offer",
      name: `One-Time Purchase - ${plan.variant_description}`,
      price: plan.amount / 100,
      priceCurrency: plan.currency?.toUpperCase(),
      availability: plan.in_stock
        ? "https://schema.org/InStock"
        : "https://schema.org/OutOfStock",
      url: productUrl + "?price=" + plan.plan_id,
      shippingDetails: createShippingDetails(plan),
    };
  }

  return {
    "@type": "Offer",
    name: `${
      intervalMap[plan?.interval_count as keyof typeof intervalMap]
    } Subscription - ${plan.variant_description}`,
    price: plan.amount / 100,
    priceCurrency: plan.currency?.toUpperCase(),
    availability: plan.in_stock
      ? "https://schema.org/InStock"
      : "https://schema.org/OutOfStock",
    url: productUrl + "?price=" + plan.plan_id,
    priceSpecification: [
      {
        "@type": "UnitPriceSpecification",
        price: plan.amount / 100,
        priceCurrency: plan.currency?.toUpperCase(),
        priceType: "Subscription",
        billingDuration: `P${plan.interval_count}D`,
      },
      // Hardcoded 20% off welcome offer
      {
        "@type": "UnitPriceSpecification",
        price: (plan.amount * WELCOME_OFFER_DISCOUNT) / 100,
        priceCurrency: plan.currency?.toUpperCase(),
        priceType: "SalePrice",
        validFrom: new Date().toISOString(),
        eligibleTransactionVolume: {
          "@type": "PriceSpecification",
          priceComponentType: "FirstPurchaseOnly",
        },
      },
    ],
    shippingDetails: createShippingDetails(plan),
  };
};

/**
 * Generates SEO data for a group of products based on the provided locale.
 *
 * @param {ProductGroupData} productGroup - The group of products to generate SEO data for.
 * @param {Object} locale - The locale information, including currency type.
 * @returns {Array<Object>} An array of SEO data objects for each product in the group.
 */
export const productSchema = (productGroup: ProductGroupData, locale: any) => {
  const localeData = productGroup.ritualProducts.map(
    (product: ProductSEOData) => {
      const variant_description =
        product.ritualPlans[0]?.variant_description || null;
      return {
        ...product,
        variant_description,
        ritualPlans: product.ritualPlans.filter(
          (plan) => plan.currency === locale.type.toLowerCase(),
        ),
      };
    },
  );

  return localeData
    .map((product: ProductSEOData) => {
      if (!product.contentfulProduct) return null;

      const localizedPath = intlService.localizePath(
        product.contentfulProduct.path,
      );
      const startsWithSlash = localizedPath.startsWith("/");
      const productUrl = `${siteConfig.siteUrl}${
        startsWithSlash ? "" : "/"
      }${localizedPath}`;

      return {
        "@type": "Product",
        name: `${product.name} - ${product.variant_description}`,
        sku: product.product_id,
        mpn: product.product_id,
        gtin: product.contentfulProduct?.gtin,
        url: productUrl,
        image: product.contentfulProduct?.heroFeaturedImages.map((image) => {
          return {
            "@type": "ImageObject",
            url: "https:" + image.file.url,
            width: image.file.details.image.width,
            height: image.file.details.image.height,
          };
        }),
        offers: {
          "@type": "AggregateOffer",
          lowPrice:
            product.ritualPlans.reduce(
              (acc, plan) => (plan.amount < acc ? plan.amount : acc),
              Infinity,
            ) / 100,
          highPrice:
            product.ritualPlans.reduce(
              (acc, plan) => (plan.amount > acc ? plan.amount : acc),
              0,
            ) / 100,
          priceCurrency: locale.type,
          offerCount: product.ritualPlans.length,
          offers: product.ritualPlans
            .map((plan) => createOffer(plan, productUrl))
            .filter(Boolean),
        },
      };
    })
    .filter(Boolean);
};

interface ContentfulProductProps {
  headerDescription: string;
  socialImage: {
    file: {
      details: {
        image: {
          width: number;
          height: number;
        };
      };
      url: string;
    };
  };
}

export const productGroupSchema = (
  contentfulProduct: ContentfulProductProps,
  ritualProduct: ProductSEOData,
  productSchemaData: ReturnType<typeof productSchema>,
) => {
  const socialImageUrl = "https:" + contentfulProduct.socialImage.file.url;
  return {
    "@context": "https://schema.org",
    "@type": "ProductGroup",
    name: ritualProduct.name,
    description: contentfulProduct.headerDescription,
    brand: {
      "@type": "Brand",
      name: "Ritual",
    },
    image: {
      "@type": "ImageObject",
      url: socialImageUrl,
      width: contentfulProduct.socialImage.file.details.image.width,
      height: contentfulProduct.socialImage.file.details.image.height,
    },
    hasVariant: productSchemaData,
    hasMerchantReturnPolicy: {
      "@type": "MerchantReturnPolicy",
      merchantReturnLink: "https://ritual.com/return-policy",
      applicableCountry: "US",
      description: "30 Money-Back Guarantee without Product Return",
      merchantReturnDays: 30,
      returnFees: 0,
    },
  };
};
