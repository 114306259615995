import { Component } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

// Utils
import { rem, responsive, Color } from "../utils/style";

// Components
import Helmet from "react-helmet";
import PageSEO from "../components/seo/Page";
import Row from "../components/Row";
import Container from "../components/Container";
import MapboxMap from "../components/ingredients/MapboxMap";
import IngredientsHeader from "../components/ingredients/IngredientsHeader";
import IngredientsMapSidebar from "../components/ingredients/IngredientsMapSidebar";
import OtherIngredientsModal from "../components/ingredients/OtherIngredientsModal";
import RitualButton from "../components/global/RitualButton";
import ArticlePreview from "../components/hub/ArticlePreview";
import ProductCategoryCards from "../components/product/ProductCategoryCards";
import StickyProductNav from "../components/global/StickyProductNav";
import Text from "../components/Text";

const Wrapper = styled.div.attrs({})`
  border-top: 1px solid ${Color.fadedGrey};
  margin-top: ${rem(24)};
  flex-direction: column-reverse;
  display: flex;
  overflow: hidden;

  ${responsive.sm`
    min-height: ${rem(550)};
    flex-direction: row;
    overflow: hidden;
    margin-top: ${rem(40)};
  `}

  ${responsive.md`
    min-height: ${rem(784)};
  `}
`;

const OtherIngredientsColumn = styled.div.attrs({
  className: "col-12",
})`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 40px;
  padding-top: 24px;
  padding-left: 20px;
  padding-right: 20px;

  ${responsive.sm`
    padding-left: 0;
    padding-right: 0;
  `}

  &::before {
    content: "";
    width: 32px;
    height: 4px;
    background-color: ${Color.ritualBlue};
    position: absolute;
    top: 0;
    left: calc(50% - 16px);
  }
`;

const OtherIngredientsSubheader = styled.p`
  margin: 0 0 24px;
  font-weight: 500;
  text-align: center !important;

  width: 100%;
  letter-spacing: 0px;
  font-size: ${rem(18)};
  line-height: ${rem(28)};

  ${responsive.sm`
    width: 460px;
  `}

  ${responsive.md`
    width: 570px;
    letter-spacing: -0.2px;
    font-size: ${rem(22)};
    line-height: ${rem(32)};
  `}
`;

const ProductCategoryCardsContainer = styled.div`
  width: 100vw;
  max-width: 100%;
  overflow: hidden;

  h3 {
    font-size: ${rem(24)};
    letter-spacing: -0.28px;
    line-height: ${rem(34)};
    text-align: center;
    margin-bottom: 24px;

    ${responsive.md`
      font-size: ${rem(40)};
      letter-spacing: -1.17px;
      line-height: ${rem(54)};
      margin-bottom:  56px;
    `};
  }
`;

export default class IngredientsMap extends Component {
  constructor(props) {
    super(props);
    const { locationPath } = props.pageContext;
    const { pageDescription } =
      props.data.allContentfulIngredientsPage.edges[0].node;

    this.state = {
      activeItemIndex: -1,
      modalOpen: false,
      seo: {
        pageSEO: true,
        description: pageDescription,
        pagePath: `ingredients/map/${locationPath}`,
        title: this.getPageTitle(props.pageContext),
      },
    };

    this.onItemClick = this.onItemClick.bind(this);
    this.setActiveItemIndex = this.setActiveItemIndex.bind(this);
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Ingredient Map",
    });

    const ingredients = this.props.data.allContentfulIngredient.edges;
    const { activeItemIndex } = this.state;

    if (
      activeItemIndex > -1 ||
      window.innerWidth > 749 ||
      ingredients.length === 1
    ) {
      this.setActiveItemIndex(0);
    }
  }

  onItemClick(evt, index) {
    this.setActiveItemIndex(index);
  }

  setActiveItemIndex(index) {
    const { activeItemIndex } = this.state;

    this.setState({
      activeItemIndex: activeItemIndex !== index ? index : null,
    });
  }

  getPageTitle({ locationGroup, locations }) {
    let pageTitle = "";
    const ingredients = locations[locationGroup].map((loc) => loc.name);

    if (ingredients.length === 3) {
      pageTitle = `${ingredients[0]}, ${ingredients[1]}, and ${ingredients[2]}`;
    } else if (ingredients.length === 2) {
      pageTitle = `${ingredients[0]} and ${ingredients[1]}`;
    } else {
      pageTitle = ingredients[0];
    }

    return `${pageTitle} from ${locationGroup} | Ritual`;
  }

  openIngredientsModal() {
    this.setState({
      modalOpen: true,
    });
  }

  closeIngredientsModal() {
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    const { activeItemIndex, modalOpen } = this.state;
    const ingredients = this.props.data.allContentfulIngredient.edges;
    const locations = this.props.pageContext.locations;
    const locationPath = this.props.pageContext.locationPath;
    const locationGroup = this.props.pageContext.locationGroup;
    const contentfulIngredientsPage =
      this.props.data.allContentfulIngredientsPage.edges[0].node;
    const { subheadText, otherIngredients, articles } =
      contentfulIngredientsPage;
    const { location } = this.props;
    const article = articles[0];

    const productSku = location.state ? location.state.productSku : "";

    return (
      <>
        {productSku && (
          <StickyProductNav
            sku={productSku}
            ctaLocation={"Ingredient Product Cta"}
            scrollOffset={150}
            showLearnMore={true}
            showTopBar={true}
          />
        )}
        <Container>
          <PageSEO {...this.state.seo} />
          <Helmet bodyAttributes={{ class: "template" }}>
            <link
              href="https://api.tiles.mapbox.com/mapbox-gl-js/v0.49.0/mapbox-gl.css"
              rel="stylesheet"
            />
          </Helmet>
          <IngredientsHeader
            subheadText={subheadText}
            location={location}
            locationPath={locationPath}
            productSku={productSku}
          />
        </Container>
        <Wrapper>
          <IngredientsMapSidebar
            activeItemIndex={activeItemIndex}
            onItemClick={this.onItemClick}
            ingredients={ingredients}
            locationGroup={locationGroup}
          />
          <MapboxMap locationGroup={locationGroup} locations={locations} />
        </Wrapper>

        {/** Other Ingredients */}
        <Row
          className="mt-5 mt-7"
          style={{
            width: "100vw",
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          <OtherIngredientsColumn>
            <OtherIngredientsSubheader>
              {otherIngredients.subheader}
            </OtherIngredientsSubheader>
            <RitualButton
              className="bordered"
              isLink={false}
              onClick={this.openIngredientsModal.bind(this)}
            >
              <Text
                id="templates.ingredient-map.see-other"
                defaultMessage="See Other Ingredients"
              />
            </RitualButton>
          </OtherIngredientsColumn>
        </Row>

        <div className="mt-7 mt-md-9 mb-7 mb-md-9">
          <ArticlePreview article={article} className="mobile-gutter" />
        </div>
        <ProductCategoryCardsContainer className="mb-7 mb-md-9">
          <h3>
            <Text
              id="category-card.section.heading"
              defaultMessage="Find Your Ritual"
            />
          </h3>
          <ProductCategoryCards />
        </ProductCategoryCardsContainer>
        <OtherIngredientsModal
          isOpen={modalOpen}
          onRequestClose={this.closeIngredientsModal.bind(this)}
          title={otherIngredients.title}
          subheader={otherIngredients.subheader}
          ingredients={otherIngredients.ingredients}
        />
      </>
    );
  }
}

export const pageQuery = graphql`
  query IngredientsMapQuery(
    $locale: String!
    $locationGroup: String!
    $ingredientsListPageId: String!
  ) {
    allContentfulIngredient(
      filter: {
        node_locale: { eq: $locale }
        locationGroup: { eq: $locationGroup }
      }
    ) {
      edges {
        node {
          __typename
          name
          slug
          description
          image {
            thumbnail: gatsbyImageData(
              layout: CONSTRAINED
              placeholder: DOMINANT_COLOR
              width: 56
              quality: 90
            )
          }
          locationGroup
          productDosages {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          productClassification
        }
      }
    }
    allContentfulProduct(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          ...AllContentfulProductFragment
        }
      }
    }
    allContentfulIngredientsPage(
      filter: {
        node_locale: { eq: $locale }
        contentful_id: { eq: $ingredientsListPageId }
      }
    ) {
      edges {
        node {
          ...IngredientsPageFragment
        }
      }
    }
  }
`;
