import { Component } from "react";
import styled from "styled-components";
import { rem, Font, Color, responsive } from "../../utils/style";

const StyledInputButton = styled.input`
  box-sizing: border-radius;
  padding: 0 24px;
  border-radius: 26px;
  height: 50px;
  cursor: pointer;
  background-color: ${Color.ritualBlue};
  border: 2px solid ${Color.ritualBlue};
  margin: 0;
  transition: all 0.2s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;

  ${Font.circular}
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  color: ${Color.white};
  transition: all 0.2s;
  display: flex;
  align-items: center;
  align-content: center;

  &.short {
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  }

  ${responsive.sm`
    padding: 0 30px;
  `};

  * {
    font-weight: 500;
  }

  &.bordered {
    background-color: transparent;
  }

  &.short {
    height: 40px;
  }

  &.fullwidth {
    width: 100%;
  }

  &:hover {
    border-color: ${Color.ritualYellow} !important;
    background-color: ${Color.ritualYellow} !important;

    color: ${Color.ritualBlue} !important;

    &:before {
      background-color: ${Color.ritualBlue} !important;
    }
  }

  &.alt-hover {
    &:hover {
      border-color: ${Color.white} !important;
      background-color: ${Color.white} !important;

      span {
        color: ${Color.ritualBlue} !important;

        &:before {
          background-color: ${Color.ritualBlue} !important;
        }
      }
    }
  }

  [data-whatintent="mouse"][data-whatinput="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &.primary-cta {
    background-color: white !important;
    border: none !important;
    color: ${Color.ritualBlue} !important;
    &:hover {
      color: white !important;
      background-color: ${Color.ritualBlue} !important;

      span {
        color: white !important;
      }
    }

    span {
      color: ${Color.ritualBlue} !important;
    }
  }

  &.yellow-cta {
    background-color: ${Color.ritualYellow} !important;
    border: none !important;
    color: ${Color.ritualBlue} !important;

    span {
      color: ${Color.ritualBlue} !important;
    }

    &:hover {
      color: white !important;
      background-color: ${Color.ritualBlue} !important;

      span {
        color: white !important;
      }
    }
  }

  &.disabled {
    opacity: 0.24;
    cursor: default;
    pointer-events: none;

    &:hover {
      background-color: ${Color.ritualBlue} !important;
      border: 2px solid ${Color.ritualBlue} !important;

      span {
        color: white !important;

        &:before {
          background-color: white !important;
        }
      }
    }
  }
`;

export default class RitualSubmitButton extends Component {
  render() {
    let { name, type, value, id, className, disabled, title } = this.props;

    if (disabled) {
      className = `${className || ""} disabled`;
    }

    return (
      <StyledInputButton
        id={id}
        name={name}
        type={type}
        value={value}
        className={className}
        title={title}
      />
    );
  }
}
