import styled, { css } from "styled-components";
import { graphql, useStaticQuery } from "gatsby";

// Utils
import { responsive, rem, Opacity, Color } from "../../utils/style";
import intl from "../../services/intl";

// Components
import Container from "../Container";
import Row from "../Row";
import ScrollableNav from "../global/navigation/ScrollableNav";

const JournalContainer = styled(Container)`
  width: calc(100% - 30px);
  margin-left: 15px;
  overflow: visible !important;
  padding-left: 0;
  padding-right: 0;

  ${responsive.sm`
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    overflow: hidden;
  `}
`;

const JournalNavRow = styled(Row)`
  margin-left: 0;
  margin-right: 0;
`;
const JournalNavContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 32px;
  padding-bottom: 24px;

  ${responsive.sm`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  `}

  ${responsive.md`

    margin-top: 64px;
    padding-bottom: 56px;
  `}
`;

const BottomBorder = styled.div`
  width: 100%;
  border-bottom: 1px solid ${Color.fadedGrey};
`;

const listStyle = css`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-bottom: 0;
  padding-left: 0;

  ${responsive.sm`
  padding-left: 0;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  flex: 1 1 auto;
`}
`;

const listItemStyle = css`
  list-style: none;

  div {
    margin-bottom: 4px;
  }

  ${responsive.sm`
  margin-top: 4px;
  margin-bottom: 4px;
  position: relative;
`}
`;

const itemLinkStyle = css`
  padding: 0 8px;
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  opacity: ${Opacity.light};
  position: relative;

  &:hover {
    opacity: 1;
  }

  ${responsive.md`
  font-size: ${rem(22)};
  letter-spacing: -0.2px;
  line-height: ${rem(32)};
  padding: 0 16px;
`}

  &.active-category {
    opacity: 1;

    &:hover {
      opacity: ${Opacity.light};
    }
  }
`;

const activeItemIconStyle = css`
  margin-bottom: 4px;
`;

const JournalNav = (props) => {
  const { currentCategory } = props;
  const data = useStaticQuery(query);
  const currentLocale = intl.locale;
  const categorySections = data.allContentfulJournalLanding.nodes.find(
    (node) => node.node_locale === currentLocale,
  );

  let categories = categorySections.categorySections.map(
    (category) => category.category,
  );

  categories.unshift({ title: "All", slug: null });

  categories.forEach((category) => {
    category.key = category.slug;
    category.path = category.slug
      ? `/articles/categories/${category.slug}`
      : "/articles/";
  });

  const contentStyle = {
    listStyle,
    listItemStyle,
    itemLinkStyle,
    activeItemIconStyle,
  };

  return (
    <>
      <JournalContainer>
        <JournalNavRow>
          <JournalNavContainer>
            <ScrollableNav
              items={categories}
              currentItem={currentCategory}
              contentStyle={contentStyle}
            />
          </JournalNavContainer>
          <BottomBorder />
        </JournalNavRow>
      </JournalContainer>
    </>
  );
};

export default JournalNav;

export const query = graphql`
  query JournalNavQuery {
    allContentfulJournalLanding {
      nodes {
        categorySections {
          category {
            title
            slug
            node_locale
          }
        }
        node_locale
      }
    }
  }
`;
