import styled from "styled-components";
import { useState } from "react";
import { RButton } from "@ritual/essentials-for-react";
import ScrollableOverflow from "../ScrollableOverflow";

export type TabGroupProps = {
  selectedLabel: string;
  labels?: string[];
  selectTabCallback: (label: string) => void;
};

const TabGroupWrapper = styled.div`
  display: block;
  padding: 0 0 var(--spacing-2, 32px) 0;

  .r-button.tab.white-bg:not(.active) button {
    background: var(--white);
  }

  @media (min-width: 993px) {
    padding-top: var(--spacing-1, 16px);
  }
`;

const ScrollableWrapper = styled(ScrollableOverflow)`
  padding: 2px;

  .scrollable-overflow-draggable-content {
    gap: var(--spacing-0_5, 8px);
  }
`;

const TabGroupPanel = ({
  selectedLabel,
  labels,
  selectTabCallback,
}: TabGroupProps) => {
  const [selectedTab, setSelectedTab] = useState<string>(selectedLabel);

  const handleSelectTab = (label: string) => {
    setSelectedTab(label);
    selectTabCallback(label);
    focusOnScroll();
  };

  const focusOnScroll = () => {
    setTimeout(() => {
      const targetElement = document.querySelector(
        "span.active-label",
      ) as Element;
      targetElement &&
        targetElement.scrollIntoView({ behavior: "smooth", inline: "nearest" });
    }, 100);
  };

  return (
    <TabGroupWrapper>
      <ScrollableWrapper bleedRight={true} bleedLeft={true}>
        {labels &&
          labels.map((label: string) => {
            const activeLabel = selectedTab === label;
            return (
              <span
                tabIndex={0}
                key={`${label}-tab`}
                role="button"
                title={label}
                className={activeLabel ? "active-label" : ""}
                onClick={() => handleSelectTab(label)}
                onKeyDown={(e: any) =>
                  e.key === "Enter" ? handleSelectTab(label) : null
                }
              >
                <RButton
                  buttonClass={`tab white-bg typography-body3 font-circular ${
                    activeLabel ? "active" : ""
                  }`}
                >
                  {label}
                </RButton>
              </span>
            );
          })}
      </ScrollableWrapper>
    </TabGroupWrapper>
  );
};

export default TabGroupPanel;
