import { Component } from "react";
import styled from "styled-components";
import { Font, Color, rem, media, responsive } from "../../utils/style";
import ReviewStars from "./ReviewStars";
import Text from "../Text";

const Wrapper = styled.div.attrs({
  className: "d-flex flex-column align-items-center",
})`
  margin-top: 0;
  ${responsive.md`
    margin-top: 6px;
  `}
`;

const StarWrapper = styled.div.attrs({
  className: "mb-3",
})`
  .ReviewStars--star {
    width: 24px;
    height: 26px;
    margin-right: ${rem(3)};

    ${media.mobile`
      margin-right: ${rem(2)};
    `} &:last-child {
      margin-right: 0;
    }
  }
`;

const Rating = styled.h2.attrs({
  className: "m-0",
})`
  ${Font.circular}
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  color: ${Color.ritualBlue};
`;

export default class CompactAggregatedStarRating extends Component {
  render() {
    let { score } = this.props;
    let roundedScore = Math.round(score * 10) / 10;

    return (
      <Wrapper>
        <StarWrapper>
          <ReviewStars score={score} />
        </StarWrapper>
        <Rating>
          <Text
            id="reviews.agg-star.rating"
            defaultMessage="{score} out of 5.0 stars"
            values={{ score: roundedScore.toFixed(1) }}
          />
        </Rating>
      </Wrapper>
    );
  }
}
