import styled, { css } from "styled-components";
import { Icons } from "../../utils/react-svg";
import TruemedPopupButton from "./TruemedPopupButton";
import { responsive } from "../../utils/style";

const narrowWidth = (...args: [TemplateStringsArray, ...any[]]) => css`
  @media (750px <= width <= 960px), (max-width: 375px) {
    ${css(...args)};
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Container = styled(Column)`
  margin-top: var(--spacing-1_5);

  ${responsive.lg`
    flex-direction: row;
  `};

  ${narrowWidth`
    flex-direction: column;
  `}
`;

const Row = styled(Column)`
  flex-direction: row;
`;

const HeadAndLogo = styled(Row)``;

const TopRow = styled(Row)`
  ${narrowWidth`
    flex-direction: column;
  `}
`;

const Head = styled.span`
  font-weight: 500;
`;
const SubHead = styled.span`
  font-weight: 450;

  ${responsive.lg`
    margin-right: var(--spacing-0_5);
  `};
`;
const Logo = styled(Column)`
  margin-left: var(--spacing-0_5);
  margin-right: var(--spacing-0_5);
  ${narrowWidth`
    margin-right: 0;
  `}
`;

const TruemedWidget = () => (
  <Container>
    <TopRow>
      <HeadAndLogo>
        <Head
          className="typography-body3 font-circular"
          role="heading"
          aria-level={1}
        >
          Pay with HSA/FSA
        </Head>
        <Logo>
          <Icons.TruemedLogo
            data-testid="truemed-logo"
            aria-label="Truemed Logo"
          />
        </Logo>
      </HeadAndLogo>

      <SubHead
        className="typography-body3 font-circular"
        role="heading"
        aria-level={2}
      >
        Use your pre-tax dollars
      </SubHead>
    </TopRow>
    <Row>
      <TruemedPopupButton />
    </Row>
  </Container>
);

export default TruemedWidget;
