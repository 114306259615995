import { Component } from "react";
import styled from "styled-components";
import { rem, Font, responsive, Color, media } from "../../utils/style";
import { calculateReadingTime } from "../../utils/readingTime";
import MagicLink from "../MagicLink";
import Text from "../Text";
import { Icons } from "../../utils/react-svg";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ArticlePreviewContainer = styled.div.attrs({
  className: "container",
})`
  ${media.mobile`
    &.mobile-gutter {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
  `}

  ${responsive.lg`
    max-width: 100%;
  `}
`;

const ArticlePreviewTitleColumn = styled.div.attrs({
  className: "col-12 col-sm-8 offset-sm-2",
})`
  &::before {
    content: "";
    width: 32px;
    height: 4px;
    background-color: ${Color.ritualBlue};
    position: absolute;
    top: 0;
    left: calc(50% - 16px);
  }

  > * {
    ${Font.circular};
    font-size: ${rem(22)};
    line-height: ${rem(32)};
    letter-spacing: -0.2px;
    margin: 28px auto 40px;

    ${responsive.sm`
      text-align: center !important;
      margin-bottom: 56px;
    `};

    ${responsive.md`
      max-width: 614px;
    `};

    ${responsive.lg`
      max-width: 770px;
    `};
  }
`;

const ArticleLink = styled(MagicLink)`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;

  ${responsive.sm`
    flex-direction: row;
  `};

  img {
    transition: all 0.2s ease-in-out !important;
  }

  &:hover {
    cursor: pointer;

    img {
      transform: scale(1.1);
    }
  }
`;

const ArticlePreviewImage = styled.div`
  width: 100%;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  img {
    max-width: 100%;
    width: 100%;
  }

  overflow: hidden;
  background-color: ${(p) => p.backgroundColor};
  display: flex;
  position: relative;
  align-items: center;

  ${responsive.sm`
    flex: 0 0 auto;
    height: 232px;
    width: 232px;
  `};

  ${responsive.md`
    height: 240px;
    width: 240px;
  `}

  ${responsive.lg`
    height: 300px;
    width: 300px;
  `};
`;

const ArticlePreviewContent = styled.div`
  padding: 40px 24px;
  background-color: ${Color.ritualGrey};
  width: 100%;

  ${responsive.sm`
    width: 400px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  `};

  ${responsive.md`
    padding: 40px 48px;
    width: 534px;
  `};

  ${responsive.lg`
    padding: 40px 56px;
    width: 670px;
  `};
`;

const ArticleReadtime = styled.span`
  display: flex;
  align-items: center;
  ${Font.circular};
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  font-weight: 500;
  text-align: left;
  margin: 0 0 8px;
`;

const ArticleArrow = styled(Icons.ArrowRight)`
  width: 17px;
  height: 8px;
  margin-left: 16px;
`;

const ArticleTitle = styled.h2`
  ${Font.circular};
  font-size: ${rem(20)};
  line-height: ${rem(30)};
  color: ${Color.ritualBlue};
  margin: 0 0 8px;

  ${responsive.md`
    font-size: ${rem(24)};
    line-height: ${rem(32)};
    letter-spacing: -0.22px;
  `};
`;

const ArticleSummary = styled.p`
  ${Font.dutch};
  font-weight: normal;
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  margin: 0;
  color: ${Color.ritualBlue};

  ${responsive.md`
    font-size: ${rem(18)};
    line-height: ${rem(28)};
  `};
`;

export default class ArticlePreview extends Component {
  render() {
    let { children, article, className } = this.props;
    let readTime = calculateReadingTime(article.primaryContent);
    return (
      <ArticlePreviewContainer className={`container ${className}`}>
        {children && (
          <div className="row">
            <ArticlePreviewTitleColumn>{children}</ArticlePreviewTitleColumn>
          </div>
        )}

        <div className="row">
          <div className="col-12 col-lg-10 offset-lg-1 article-link-container">
            <ArticleLink to={article} ariaLabel={article.title}>
              <ArticlePreviewImage
                backgroundColor={article.heroBackgroundColor}
              >
                <GatsbyImage
                  image={getImage(article.heroImage)}
                  color={article.heroBackgroundColor}
                  alt={article.title}
                  style={{
                    userSelect: "none",
                    objectFit: "cover",
                    objectPosition: "center",
                    height: "100%",
                    width: "100%",
                    userDrag: "none",
                    pointerEvents: "none",
                    touchCallout: "none",
                  }}
                />
              </ArticlePreviewImage>
              <ArticlePreviewContent>
                <ArticleReadtime>
                  <Text
                    id="hub.hero.reading-time"
                    defaultMessage="{readTime} min read"
                    values={{ readTime }}
                  />
                  <ArticleArrow />
                </ArticleReadtime>
                <ArticleTitle>{article.title}</ArticleTitle>
                <ArticleSummary>{article.previewText}</ArticleSummary>
              </ArticlePreviewContent>
            </ArticleLink>
          </div>
        </div>
      </ArticlePreviewContainer>
    );
  }
}
