import styled from "styled-components";
import { Helmet } from "react-helmet";

const TruemedLearnMore = styled.button`
  text-decoration: underline;
  color: var(--Solid-Primary-IndigoBlue-60);
`;

const TruemedPopupButton = ({ label = "Learn more" }: { label?: string }) => (
  <>
    <Helmet>
      <script
        defer
        src="https://static.truemed.com/widgets/ritual-pdp-widget.min.js"
      ></script>
    </Helmet>
    <TruemedLearnMore
      role="button"
      className="typography-body3 font-circular"
      onClick={() => window?.Truemed?.showModal()}
      aria-label="Learn more about Truemed HSA/FSA payments"
    >
      {label}
    </TruemedLearnMore>
  </>
);

export default TruemedPopupButton;
