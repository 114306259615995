import styled from "styled-components";
import { Color } from "../../utils/style";

const ThirdPartyAuthButton = styled.button`
  border-radius: 25px;
  border: 2px solid #000;
  height: 50px;
  background: transparent;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  transition: color 0.2s, background-color 0.2s, border 0.2s;

  &[disabled] {
    opacity: 0.24;

    &:hover {
      cursor: initial;
    }
  }

  &:not(:disabled):hover {
    border-color: ${Color.ritualYellow};
    background-color: ${Color.ritualYellow};
  }

  span {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 26px;
    margin-left: 8px;
    text-align: left;
  }
`;

export default ThirdPartyAuthButton;
