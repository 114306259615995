import { createRef, useRef, useState } from "react";
import { graphql } from "gatsby";

// Components
import PageSEO from "../../components/seo/Page";
import TraceabilityHero from "../../components/traceability/TraceabilityHero";
import TraceabilitySection from "../../components/traceability/TraceabilitySection";
import TraceabilityTabs from "../../components/traceability/TraceabilityTabs";
import Background from "../../components/traceability/Background";

// Utils
import Justified from "../../components/traceability/contentTypes/Justified";

const MadeTraceable = ({ data }) => {
  const [indexVisible, setIndexVisible] = useState(0);

  const {
    pageTitle,
    pageDescription,
    heroHeadline,
    heroSubheadline,
    heroParagraph,
    heroImageDesktop,
    heroImageMobile,
    heroBackgroundImage,
    section01Title,
    section01Tabs,
    section02Title,
    section02Tabs,
    section03Title,
    ingredients,
    section03Tabs,
    cotField,
  } = data.contentfulTraceabilityLandingPage;

  const traceabilityContent = [
    {
      sectionTitle: section01Title,
      tabs: section01Tabs,
    },
    {
      sectionTitle: section02Title,
      tabs: section02Tabs,
    },
    {
      sectionTitle: section03Title,
      tabs: section03Tabs,
    },
  ];

  const seo = {
    pagePath: "made-traceable",
    title: pageTitle,
    description: pageDescription,
  };

  const refsMap = ["hero", ...traceabilityContent];
  const refs = useRef(refsMap.map(() => createRef()));

  return (
    <>
      <PageSEO seo={seo}></PageSEO>
      <div
        className="pb-8"
        style={{
          maxWidth: "1700px",
          margin: "0 auto",
          overflow: "hidden",
        }}
      >
        <div ref={refs.current[0]}>
          <TraceabilityHero
            heroHeadline={heroHeadline}
            heroSubheadline={heroSubheadline}
            heroParagraph={heroParagraph}
            heroImageMobile={heroImageMobile}
            heroImageDesktop={heroImageDesktop}
            heroBackgroundImage={heroBackgroundImage}
            indexVisible={indexVisible}
          />
          <Justified
            className="mt-5"
            content={cotField}
            key={`content-${cotField.id}`}
          />
        </div>

        {traceabilityContent.map((section, index) => {
          return (
            <div ref={refs.current[index + 1]}>
              <TraceabilitySection
                section={section}
                index={index}
                key={`section-${index}`}
              >
                <TraceabilityTabs
                  sectionTitle={section.sectionTitle}
                  sectionNumber={index + 1}
                  tabs={section.tabs}
                  ingredients={ingredients}
                />
              </TraceabilitySection>
            </div>
          );
        })}

        <Background
          refs={refs}
          indexVisible={indexVisible}
          setIndexVisible={setIndexVisible}
        />
      </div>
    </>
  );
};

export default MadeTraceable;

export const query = graphql`
  query TraceabilityQuery($locale: String!) {
    contentfulTraceabilityLandingPage(
      node_locale: { eq: $locale }
      contentful_id: { eq: "3wcFX97vRD0CarpZMdIInS" }
    ) {
      heroImageDesktop {
        description
        gatsbyImageData(
          layout: CONSTRAINED
          width: 339
          quality: 100
          placeholder: NONE
        )
      }
      heroImageMobile {
        description
        gatsbyImageData(
          layout: CONSTRAINED
          width: 575
          quality: 100
          placeholder: NONE
        )
      }
      heroSubheadline
      heroParagraph {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      pageDescription
      pageTitle
      heroHeadline {
        childMarkdownRemark {
          html
        }
      }
      heroBackgroundImage {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: NONE
          width: 1920
          quality: 100
        )
      }
      cotField {
        id
        title
        sunAlignment
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 1366
            quality: 50
          )
        }
        imageAlignment
        offset
        eyebrow
      }
      section01Title
      section01Tabs {
        title
        content {
          id
          title
          sunAlignment
          text {
            childMarkdownRemark {
              html
            }
          }
          eyebrow
          imageAlignment
          data {
            stats {
              description
              stat
              source
            }
            bullets
            link {
              url
              text
            }
          }
          type
          offset
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: DOMINANT_COLOR
              width: 1366
              quality: 50
            )
          }
        }
      }
      section02Title
      section02Tabs {
        title
        content {
          id
          title
          sunAlignment
          text {
            childMarkdownRemark {
              html
            }
          }
          eyebrow
          imageAlignment
          data {
            stats {
              description
              stat
              source
            }
            bullets
            link {
              url
              text
            }
          }
          type
          offset
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: DOMINANT_COLOR
              width: 1366
              quality: 50
            )
          }
        }
      }
      section03Title
      section03Tabs {
        title
        content {
          id
          title
          sunAlignment
          text {
            childMarkdownRemark {
              html
            }
          }
          eyebrow
          imageAlignment
          data {
            stats {
              description
              stat
              source
            }
            bullets
            link {
              url
              text
            }
          }
          type
          offset
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: DOMINANT_COLOR
              width: 1366
              quality: 50
            )
          }
        }
      }
      ingredients {
        location {
          lat
          lon
        }
        manufacturingLocation
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: DOMINANT_COLOR
            width: 120
          )
        }
      }
    }
  }
`;
