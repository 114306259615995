import castle from "../services/castle";

const API_URL = process.env.GATSBY_RITUAL_API_URL + `/api/v1`;

class FetchInstance {
  constructor() {
    this.accessToken = "";
  }

  async fetchInternal(url, options = {}, retryCount = 0, retryInterval = 5000) {
    await this._updateHeaders(options);

    return new Promise((resolve, reject) => {
      const attemptFetch = (retriesLeft) => {
        fetch(`${API_URL}/${url}`, options)
          .then((response) => {
            if (response.ok) {
              if (response.status === 204) {
                resolve();
              } else {
                response.json().then((data) => {
                  resolve(data);
                });
              }
              return;
            }

            const status = response.status;
            if (!retriesLeft || this._isClientError(status)) {
              try {
                response
                  .json()
                  .then((data) =>
                    reject({
                      status: response.status,
                      ...data,
                    }),
                  )
                  .catch(() => {
                    reject(response);
                  });
              } catch (e) {
                reject(response);
              }
            } else if (this._isRetryable(status)) {
              setTimeout(() => {
                attemptFetch(retriesLeft - 1);
              }, retryInterval);
            } else {
              try {
                response.json().then((data) =>
                  reject({
                    status: response.status,
                    ...data,
                  }),
                );
              } catch (e) {
                reject(response);
              }
            }
          })
          .catch((error) => {
            if (retriesLeft > 0) {
              setTimeout(() => {
                attemptFetch(retriesLeft - 1);
              }, retryInterval);
            } else {
              reject(error);
            }
          });
      };

      attemptFetch(retryCount);
    });
  }

  setAccessToken(accessToken) {
    this.accessToken = accessToken;
  }

  async _updateHeaders(options = {}) {
    const headers = {
      "Content-Type": "application/json",
    };

    if (this.accessToken) {
      headers["Authorization"] = `Bearer ${this.accessToken}`;
    }

    if (options.fetchOptions?.addCastleRequestToken) {
      const requestToken = await castle.createRequestToken();
      headers["X-Castle-Request-Token"] = requestToken;
      delete options.fetchOptions.addCastleRequestToken;
    }

    options.headers = { ...headers, ...options.headers };
  }

  _isClientError(status) {
    return status >= 400 && status < 500 && status !== 429 && status !== 423;
  }

  _isServerError(status) {
    return status >= 500;
  }

  _isRetryable(status) {
    return status === 423 || status === 429 || status >= 500;
  }
}

let fetchInstance = new FetchInstance();

export function setAccessToken(accessToken) {
  fetchInstance.setAccessToken(accessToken);
}

export function reset() {
  fetchInstance = new FetchInstance();
}

export default function fetchInternal(url, options, retryCount, retryInterval) {
  return fetchInstance.fetchInternal(url, options, retryCount, retryInterval);
}
