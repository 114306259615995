import { navigate } from "../services/navigation";
import intl from "../services/intl";
import { navigateTo } from "./navigate";

// Redirects to new location and replaces last item in browser history
// Also checks for external vs internal redirection
export async function redirectReplaceHistory(input = "/") {
  const redirectLocation = intl.localizePath(input);
  if (redirectLocation.startsWith("https")) {
    await navigateTo(redirectLocation, { replace: true });
  } else {
    window.history.replaceState({}, "Ritual", redirectLocation);
    navigate(redirectLocation);
  }
}
