import intlService from "../services/intl";
import NameMap from "./nameMap";
import { variation } from "../services/launchDarkly";
import intl from "../services/intl";


type Currency = {
  type: string;
};

type PlanOptions = {
  billing_type?: string;
  interval_count?: number;
};

const defaultOptions: PlanOptions = {
  billing_type: "recurring",
  interval_count: 30,
};

export type Plan = {
  plan_id: string;
  billing_type: string;
  currency: string;
  interval_count: number;
  amount: number;
  in_stock: boolean;
  variant_contents?: string;
  variant_description?: string;
};

export const findDefaultPlan = (
  plans: Plan[],
  currency: Currency,
  options?: PlanOptions,
) => {
  const unifiedOptions = { ...defaultOptions, ...options };
  return plans.find(
    (p: any) =>
      p.billing_type === unifiedOptions.billing_type &&
      p.currency === currency.type.toLowerCase() &&
      p.interval_count === unifiedOptions.interval_count,
  );
};

// getCadencePlans - returns the regular plan
// getCadencePlanData - returns a map of the plan data
// cadencePlanDetailText - returns the text for the plan detail

export const cadencePlanDetails = (
  plan: any,
  deliveryCadenceCopyFlag?: boolean,
) => {
  const oneTimeHeader = deliveryCadenceCopyFlag
    ? "Buy once"
    : "One-time purchase";
  const months = plan.interval_count / 30;
  const newHeader =
    months < 2 ? `Monthly delivery` : `${months}-month delivery`;
  const recurringHeader = deliveryCadenceCopyFlag
    ? newHeader
    : `${months}-month plan`;

  const selectorHeader =
    plan.billing_type === "one_time" ? oneTimeHeader : recurringHeader;
  const amount = intl.formatCurrency(plan.amount / 100, { round: true });
  const basePrice = intl.formatCurrency(plan.base_price / 100, { round: true });
  const quarterlyBasePrice =
    plan.interval_count === 90
      ? intl.formatCurrency((plan.base_price / 100) * 3, { round: true })
      : null;
  const monthlyAmount = intl.formatCurrency(plan.monthly_amount / 100, {
    round: true,
  });
  const monthlyAmountSavings = intl.formatCurrency(
    plan.monthly_amount_savings / 100,
    { round: true },
  );
  const monthlyPercentSavings = `${plan.monthly_percent_savings}%`;
  const numberOfMonths = plan.interval_count ? plan.interval_count / 30 : 0;
  const cadenceClarity =
    numberOfMonths && numberOfMonths > 1
      ? `${numberOfMonths} month supply, delivered every ${numberOfMonths} months`
      : `${numberOfMonths} month supply, delivered monthly`;

  return {
    amount,
    selectorHeader,
    basePrice,
    quarterlyBasePrice,
    monthlyAmount,
    monthlyAmountSavings,
    monthlyPercentSavings,
    cadenceClarity,
  };
};

export const getCadencePlans = (
  plans: Plan[],
  currency: Currency,
  showOutOfStock?: Boolean,
) => {
  const excludedPlanIdsVariation = variation("excluded-plan-ids", {
    planIds: [],
  });
  const excludedPlanIds = excludedPlanIdsVariation
    ? excludedPlanIdsVariation.planIds
    : [];

  return plans
    .filter((p: Plan) => (showOutOfStock ? true : p.in_stock)) // Filter plans that are not in stock
    .filter((p: Plan) => p.currency === currency.type.toLowerCase()) // Filter based on the currency
    .filter((p: Plan) => !excludedPlanIds.includes(p.plan_id)) // filter plans that are in the excludedPlanIds array
    .sort((a, b) => {
      // Sort by `billing_type` so that "one_time" comes first
      if (a.billing_type === "one_time" && b.billing_type !== "one_time") {
        return -1;
      }
      if (a.billing_type !== "one_time" && b.billing_type === "one_time") {
        return 1;
      }

      // If both have the same billing_type, sort by `interval_count`
      return a.interval_count - b.interval_count;
    })
    .map((p: any) => {
      return {
        plan_id: p.plan_id,
        billing_type: p.billing_type,
        currency: p.currency,
        interval: p.interval,
        interval_count: p.interval_count,
        amount: p.amount,
        in_stock: p.in_stock,
        variant_contents: p.variant_contents,
        variant_description: p.variant_description,
        base_price: p.base_price,
        monthly_amount: p.monthly_amount,
        monthly_amount_savings: p.monthly_amount_savings,
        monthly_percent_savings: p.monthly_percent_savings,
        number_of_months: p.number_of_months,
      };
    });
};

type ContentfulProduct = {
  ritualProduct: {
    sku: string;
    ritualPlans: Plan[];
    product_id: string;
    sub_category: string;
    url_slug: string;
  };
  slug: string;
  path: string;
  name: {
    name: string;
  };
  socialImage: {
    file: {
      url: string;
    };
  };
};

export type ProductAttributes = {
  id: string;
  category: string;
  variant: string;
  variant_contents: string;
  variant_description: string;
  sku: string;
  name: string;
  currency: string;
  price: number;
  quantity: number;
  slug: string;
  urlSlug: string;
  url: string;
  image_url: string;
  billing_type: string;
};

export const getProductAttributes = (
  product: ContentfulProduct,
  currency: Currency,
  quantity = 1,
) => {
  const { ritualProduct, slug, path, name, socialImage } = product;
  const { sku, ritualPlans, product_id, sub_category, url_slug } =
    ritualProduct;
  const plan = findDefaultPlan(ritualPlans, currency);
  const {
    plan_id,
    amount,
    variant_contents,
    variant_description,
    billing_type,
  } = plan as Plan;

  let productAttributes = {
    id: product_id,
    category: sub_category,
    variant: plan_id,
    variant_contents: variant_contents,
    variant_description: variant_description,
    sku: sku,
    name: NameMap(name).plain,
    currency: currency.type.toUpperCase(),
    price: amount / 100,
    quantity,
    slug: slug,
    urlSlug: url_slug,
    url: `${process.env.GATSBY_URL}${intlService.localizePath(`/${path}`)}`,
    image_url: `https:${socialImage.file.url}`,
    billing_type: billing_type,
  };
  return productAttributes as ProductAttributes;
};
