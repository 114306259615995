import { Component } from "react";

// Services
import cartService from "../../services/cart";
import intl from "../../services/intl";
import { variation } from "../../services/launchDarkly";

// Components
import Text from "../Text";
import DiscountButton from "../bundle/DiscountButton";
import DiscountButtonAnimated from "../bundle/DiscountButtonAnimated";
import InterstitialFlyout from "../cart/InterstitialFlyout";
import RitualButton from "../global/RitualButton";

// Store
import { connect } from "react-redux";
import cartProductSelectors from "../../store/cart-product/selectors";
import { addProductToCart, addProductsToCart } from "../../store/cart/actions";
import styled from "styled-components";

const OneTimeCTA = styled.button.attrs({
  className: "typography-label2",
})`
  appearance: none;
  background: none;
  border: none;
  color: var(--indigo-blue);
  cursor: pointer;
  text-align: center;
  display: block;
  margin: 0;
  padding: 0;
  border-bottom: 2px solid var(--indigo-blue);
  transition:
    color 0.2s,
    border-color 0.2s;

  &:hover {
    color: var(--indigo-blue-60);
    border-color: var(--indigo-blue-60);
  }
`;

export class CheckoutButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cartInterstitialModalOpen: false,
      cartInterstitialVariation: null,
    };

    this.closeCartInterstitialModal =
      this.closeCartInterstitialModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  closeCartInterstitialModal() {
    this.setState({
      cartInterstitialModalOpen: false,
    });
  }

  handleClick(e) {
    // Prevent the href of the CheckoutButton link from transitioning to cart.
    // The transition should only happen if the cart interstitial is not
    // displayed.
    e.preventDefault();

    const {
      trackCTAClick,
      dispatchAddProductToCart,
      dispatchAddProductsToCart,
      quantity,
      plan,
    } = this.props;

    trackCTAClick(e);

    const isCartIntersitialEligible = this._isCartIntersitialEligible();

    const currentPlanId = plan.id;

    // Do not show cart interstitial if multiples of the same product are being added at one time
    if (quantity && quantity > 1) {
      dispatchAddProductsToCart([{ planId: currentPlanId, quantity }]);
    }

    this.props.onAddToCart && this.props.onAddToCart();

    dispatchAddProductToCart(
      { planId: currentPlanId },
      {
        location: this.props.location || "Checkout Button",
      },
    ).then(() => {
      // Once the product add is complete, ensure there's one product in the
      // cart. If not, close the modal and open the cart.
      if (
        isCartIntersitialEligible &&
        this.props.activeCartProductPlanQuantity !== 1
      ) {
        this.setState(
          {
            cartInterstitialModalOpen: false,
          },
          () => cartService.openCart(e),
        );
      }
    });

    if (isCartIntersitialEligible) {
      this.setState({
        cartInterstitialModalOpen: true,
      });
    } else {
      cartService.openCart(e);
    }
  }

  _isCartIntersitialEligible() {
    const {
      enableCartInterstitial = true,
      activeCartProductPlanQuantity,
      plan,
    } = this.props;

    // Check the number of products in the cart before adding the new product.
    const isCartEmptyAtTimeOfClick = activeCartProductPlanQuantity === 0;

    const quarterlyInterstitialFlag = variation("quarterly-interstitial");

    if (
      !enableCartInterstitial ||
      !isCartEmptyAtTimeOfClick ||
      (plan.intervalCount === 90 && !quarterlyInterstitialFlag)
    ) {
      return false;
    }

    const cartInterstitialVariation = variation("cart-interstitial", "control");

    // Once we check the variation, we should properly set the state.
    this.setState({
      cartInterstitialVariation,
    });

    // Whether the cart interstitial should be displayed.
    return cartInterstitialVariation !== "control";
  }

  render() {
    let {
      className,
      quantity = 1,
      href,
      prefix = intl.t("general.button-add-cart"),
      promotion,
      plan,
      showPricing = true,
      showAnimated = false,
      showDash = true,
      floatText = false,
      isVisible = false,
      textCta,
    } = this.props;

    const { cartInterstitialModalOpen, cartInterstitialVariation } = this.state;

    if (!plan || (!plan.id && !plan.plan_id)) {
      return (
        <RitualButton className={className} disabled>
          <Text id="general.unavailable" defaultMessage="Unavailable" />
        </RitualButton>
      );
    }

    const { amount, basePrice, unitQuantity } = plan;

    const price = amount / 100;

    const welcomeOfferPromotionFlag = variation("welcome-offer-promotion", {});
    const welcomeOfferPromotionEnabled =
      welcomeOfferPromotionFlag?.slug &&
      welcomeOfferPromotionFlag?.slug === "welcome-offer-alc";
    const basePriceAmount = (basePrice * unitQuantity) / 100;

    if (textCta) {
      return (
        <>
          <OneTimeCTA onClick={this.handleClick}>{textCta}</OneTimeCTA>

          {/** Cart Interstitial Modal */}
          {cartInterstitialVariation === "single-item" && (
            <InterstitialFlyout
              addedPlan={plan}
              isOpen={cartInterstitialModalOpen}
              onRequestClose={this.closeCartInterstitialModal}
            />
          )}
        </>
      );
    }

    return (
      <>
        {showAnimated ? (
          <DiscountButtonAnimated
            isLink={false}
            onClick={this.handleClick}
            prefix={prefix}
            price={price}
            className={className}
            promotion={promotion}
            showDash={showDash}
            showPricing={showPricing}
            isVisible={isVisible}
            floatText={floatText}
            basePrice={
              welcomeOfferPromotionEnabled ? basePriceAmount : price * quantity
            }
          />
        ) : (
          <DiscountButton
            isLink={false}
            onClick={this.handleClick}
            prefix={prefix}
            price={price * quantity}
            className={className}
            href={href}
            promotion={promotion}
            showPricing={showPricing}
            showDash={showDash}
            floatText={floatText}
            basePrice={
              welcomeOfferPromotionEnabled ? basePriceAmount : price * quantity
            }
          />
        )}

        {/** Cart Interstitial Modal */}
        {cartInterstitialVariation === "single-item" && (
          <InterstitialFlyout
            addedPlan={plan}
            isOpen={cartInterstitialModalOpen}
            onRequestClose={this.closeCartInterstitialModal}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    activeCartProductPlanQuantity:
      cartProductSelectors.activeCartProductPlanQuantity(state),
  };
};

export default connect(mapStateToProps, {
  dispatchAddProductToCart: addProductToCart,
  dispatchAddProductsToCart: addProductsToCart,
})(CheckoutButton);
