import styled from "styled-components";
import { IGatsbyImageData, GatsbyImage } from "gatsby-plugin-image";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import * as SVGIcons from "../../utils/svg";
import FlavorIcon from "./FlavorIcon";
import { ContentfulProduct, HowToUse } from "../../utils/productTypes";

import GridRow from "../grid/GridRow";
import GridColumn from "../grid/GridColumn";
import { MagicSVGImage } from "../MagicSVGImage";

export type ImageType = {
  gatsbyImageData: IGatsbyImageData;
  description: string;
};

type HowToUsePanelProps = {
  selectedProduct: ContentfulProduct;
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2, 32px);
  margin-top: var(--spacing-2, 32px);

  .image-container {
    aspect-ratio: 327 / 229;

    .gatsby-image-wrapper {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (min-width: 750px) {
    .image-container {
      aspect-ratio: 391 / 274;
    }
  }
`;

const UsageTab = styled.div.attrs({
  className: "typography-lead2",
})`
  color: var(--indigo-blue);
  display: flex;
  flex-direction: row;
  gap: var(--spacing-1, 16px);
`;

const Instructions = styled.span`
  p {
    margin-bottom: unset;
  }
`;


const FlavorIconExtend = styled(FlavorIcon)`
  position: unset;

  img {
    width: 56px;
    height: 56px;
  }
`;

const HowToUsePanel = ({ selectedProduct }: HowToUsePanelProps) => {
  const { howToUse } = selectedProduct;
  const { instructions, instructionsIcon, tooltip, image } = howToUse as HowToUse;

  const parsedInstructions =
    instructions?.raw &&
    documentToHtmlString(JSON.parse(instructions?.raw));

  const showTooltipIcon = () => {
    switch (tooltip?.type) {
      case "scent":
        if (selectedProduct.sku === "SLPC") {
          return (
            <MagicSVGImage
              width={56}
              height={56}
              alt={`${SVGIcons.EssenceVanilla}`}
              src={SVGIcons.EssenceVanilla}
            />
          );
        } else {
          return <FlavorIconExtend className={""} sku={selectedProduct.sku} />;
        }
      case "protein":
        return (
          <MagicSVGImage
            width={56}
            height={56}
            alt={`${SVGIcons.EssenceVanilla}`}
            src={SVGIcons.EssenceVanilla}
          />
        );
      case "fertility":
        return (
          <MagicSVGImage
            width={56}
            height={56}
            alt={`${SVGIcons.FlavorLemon}`}
            src={SVGIcons.FlavorLemon}
          />
        );
      default:
        return null;
    }
  };

  return (
    <GridRow>
      <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
        <ContentContainer>
          <UsageTab>
            {instructionsIcon && (
              <MagicSVGImage
                width={56}
                height={70}
                alt={instructionsIcon.description}
                src={instructionsIcon.url}
              />
            )}
            <Instructions
              dangerouslySetInnerHTML={{
                __html: parsedInstructions,
              }}
            />
          </UsageTab>
          {tooltip && (
            <UsageTab>
              {showTooltipIcon()}
              <span
                className="tooltip-content typography-lead2"
                dangerouslySetInnerHTML={{
                  __html: tooltip?.content,
                }}
              />
            </UsageTab>
          )}
          {image && (
            <div className="image-container">
              <GatsbyImage
                image={image?.gatsbyImageData}
                alt={image?.description}
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </div>
          )}
        </ContentContainer>
      </GridColumn>
    </GridRow>
  );
};

export default HowToUsePanel;
