import { Children } from "react";

import styled from "styled-components";
import {
  CaptionTitle,
  PriceBreakdown,
  RBadge,
  RPrice,
} from "@ritual/essentials-for-react";
import Currency from "../Currency";
import ProbioticBlend from "../product/ProbioticBlend";
import ObgynClaim from "../product/ObgynClaim";
import TruemedWidget from "./TruemedWidget";

const FlagContainer = styled.div`
  margin-bottom: var(--spacing-0_5);
`;

const HeroContent = styled.div`
  margin-bottom: var(--spacing-3);
  position: relative;
  z-index: 10;
`;

const Title = styled.h1.attrs({ className: "typography-headline2" })`
  margin-bottom: var(--spacing-0_5);
`;

const Subhead = styled.p.attrs({ className: "typography-label1-dutch" })`
  color: var(--indigo-blue-60);
  border-bottom: 2px solid var(--indigo-blue);
  padding-bottom: var(--spacing-1_5);
  margin-bottom: var(--spacing-1_5);
`;

const Pricing = styled.div`
  margin-bottom: var(--spacing-1_5);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  price-breakdown {
    flex: 1 0;
  }
`;

const RPriceCustom = styled(RPrice).attrs({
  className: "typography-lead1",
})`
  letter-spacing: -0.25px !important;
`;

const PromoSavings = styled.div.attrs({
  className: "typography-body2 font-circular",
})`
  color: var(--success-green);
`;

type PromotionDetails = {
  formattedValue?: string;
  percentOff?: number;
};

type Props = {
  title: string;
  description: string;
  heroDescription: string;
  price: number;
  futurePrice?: number;
  savings?: number;
  children: React.ReactNode;
  showProbioticBlend?: boolean;
  showPrenatalBadge?: boolean;
  showPromoSavings?: boolean;
  hidePriceBreakdown?: boolean;
  promotionDetails?: PromotionDetails;
  subscriptionPromotionDetails?: PromotionDetails;
  showSubscriptionOfferFlag?: boolean;
  showTruemedWidget?: boolean;
};

const Hero = ({
  title,
  description,
  heroDescription,
  price,
  futurePrice,
  savings,
  children,
  hidePriceBreakdown,
  showProbioticBlend,
  showPrenatalBadge,
  showPromoSavings = false,
  promotionDetails,
  subscriptionPromotionDetails,
  showSubscriptionOfferFlag,
  showTruemedWidget,
}: Props) => {
  const ctaButton = Children.toArray(children).find((child: any) =>
    child.key.includes("add-product-button"),
  );
  const scentSelector = Children.toArray(children).find((child: any) =>
    child.key.includes("scent-selector"),
  );
  const cadenceSelector = Children.toArray(children).find((child: any) =>
    child.key.includes("cadence-selector"),
  );
  const cadenceDropdown = Children.toArray(children).find((child: any) =>
    child.key.includes("cadence-dropdown"),
  );
  const alcOption = Children.toArray(children).find((child: any) =>
    child.key.includes("alc-option"),
  );
  const freeShipping = Children.toArray(children).find((child: any) =>
    child.key.includes("free-shipping"),
  );

  return (
    <HeroContent>
      <Title>{title}</Title>
      <Subhead>{description.replace("-", "\u2011")}</Subhead>

      {showProbioticBlend && (
        <ProbioticBlend customClass="single-product-hero" />
      )}

      {showPrenatalBadge && <ObgynClaim />}

      <p
        className="typography-body1 font-circular"
        style={{
          marginBottom: "var(--spacing-1_5)",
        }}
        dangerouslySetInnerHTML={{ __html: heroDescription }}
      ></p>

      {scentSelector}

      {(showPromoSavings || !hidePriceBreakdown) && (
        <Pricing>
          {!hidePriceBreakdown && (
            <PriceBreakdown priceClass="fullspread">
              <RPriceCustom
                slot="price-breakdown-final"
                priceClass="final large"
              >
                {Currency({ value: price, round: true })}
              </RPriceCustom>
              {futurePrice && (
                <RPriceCustom
                  slot="price-breakdown-strikethrough"
                  priceClass="strikethrough large"
                >
                  {Currency({ value: futurePrice, round: true })}
                </RPriceCustom>
              )}
              {savings && !showPromoSavings && (
                <CaptionTitle
                  slot="price-breakdown-savings-caption"
                  captionClass="typography-body2 font-circular"
                >
                  {Currency({ value: savings, round: true })} savings
                </CaptionTitle>
              )}
            </PriceBreakdown>
          )}
          {showPromoSavings && futurePrice && (
            <PromoSavings>
              Save ${Math.floor(futurePrice - price)} on first delivery
            </PromoSavings>
          )}
        </Pricing>
      )}

      {cadenceSelector}
      {showSubscriptionOfferFlag && (
        <FlagContainer>
          <RBadge badgeColor="yellow" badgeWidth="wide">
            Limited Time:{" "}
            <span className="font-dutch font-italic">
              Subscribe & Save {subscriptionPromotionDetails?.formattedValue}
            </span>
          </RBadge>
        </FlagContainer>
      )}
      {cadenceDropdown}
      {ctaButton}
      {alcOption}
      {freeShipping}
      {showTruemedWidget && <TruemedWidget />}
    </HeroContent>
  );
};

export default Hero;
