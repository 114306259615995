import styled from "styled-components";
import ScrollableOverflow from "../ScrollableOverflow";
import GridContainer from "../../components/grid/GridContainer";
import GridRow from "../../components/grid/GridRow";
import GridColumn from "../../components/grid/GridColumn";
import BundleFeaturedArticle from "./BundleFeaturedArticle";

const BundleFeaturedArticlesContainer = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  margin-bottom: var(--spacing-5);
  max-width: 100%;
  overflow: hidden;

  @media (min-width: 933px) {
    padding-bottom: var(--spacing-6);
  }
`;

const StyledGridContainer = styled(GridContainer)`
  row-gap: 0 !important;
`;

const ArticlesTitle = styled.h2`
  margin-bottom: 0;
  padding-bottom: var(--spacing-2);

  @media (min-width: 933px) {
    padding-bottom: var(--spacing-3);
  }

  font-size: 32px;
  line-height: 40px;

  @media (min-width: 933px) {
    font-size: 40px;
    line-height: 50px;
    width: 100%;
  }
`

const MobileView = styled.div`
  @media (min-width: 933px) {
    display: none;
  }
`;

const DesktopView = styled.div`
  // display for MD/LG
  display: none;

  @media (min-width: 933px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

const DesktopContainer = styled.div`
  @media (min-width: 933px) {
    display: flex;
    gap: var(--spacing-1_5);
    width: 100%;
  }
`;

const ScrollableWrapper = styled(ScrollableOverflow)`
  padding: 2px;
`;

type Article = {
};

type Props = {
  articles: Article[];
};

const BundleFeaturedArticles = ({ articles }: Props) => {
  const renderBundleArticles = (
    <>{articles.map((article: any, index: number) => (
        <BundleFeaturedArticle
          key={index}
          article={article}
          className={`${index === articles.length - 1 ? "last-element" : ""}`}
        />
      ))
    }</>
  )
  return (
    <BundleFeaturedArticlesContainer>
      <StyledGridContainer>
        <GridRow>
          <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
            <ArticlesTitle className="typography-headline2 mb-0">Articles from the Journal</ArticlesTitle>
            <MobileView>
              <ScrollableWrapper bleedLeft={true} bleedRight={true}>
                {renderBundleArticles}
              </ScrollableWrapper>
            </MobileView>
            <DesktopView>
              <DesktopContainer>
                {renderBundleArticles}
              </DesktopContainer>
            </DesktopView>
          </GridColumn>
        </GridRow>
      </StyledGridContainer>
    </BundleFeaturedArticlesContainer>
  );
};

export default BundleFeaturedArticles;
