import { Icons } from "../../utils/react-svg";
import styled, { keyframes } from "styled-components";

const bubblePop = keyframes`
  from {transform: scale(0)};
  to {transform: scale(0.85)};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  svg {
    &.bubble {
      transform: scale(0);
      animation: ${bubblePop} 1.1s linear infinite;
      margin-bottom: 2px;

      &.bubble1 {
        margin-left: 8px;
        animation-delay: 0.4s;
      }

      &.bubble2 {
        margin-left: -5px;
      }
    }
  }
`;

const LoadingFlask = () => {
  return (
    <Container>
      <Icons.Bubble className="bubble bubble1" />
      <Icons.Bubble className="bubble bubble2" />
      <Icons.Flask />
    </Container>
  );
};

export default LoadingFlask;
