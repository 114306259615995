import { Component } from "react";
import styled from "styled-components";

// Utils
import { Color, responsive, rem, Font, Opacity } from "../../utils/style";
import { Icons } from "../../utils/react-svg";

// Components
import Row from "../Row";
import Container from "../Container";
import Text from "../Text";
import intl from "../../services/intl";

// Styled Elements
const BarrierBusterContainer = styled.div.attrs({
  className: "col-12 col-sm-10 offset-sm-1",
})`
  display: flex;
  flex-direction: column;

  padding: 56px 20px;

  h2 {
    font-size: ${rem(22)};
    font-weight: 500;
    letter-spacing: -0.2px;
    line-height: ${rem(32)};
    text-align: center;
    margin-bottom: 40px;

    ${responsive.md`
      font-size: ${rem(30)};
      letter-spacing: -0.4px;
      line-height: ${rem(36)};
      margin-bottom: 56px;
    `}
  }

  ${responsive.sm`
    padding: 56px 0 80px;
  `}

  ${responsive.md`
    padding: 80px 0;
  `}
`;

const Grid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
`;

const BusterDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  padding-bottom: 40px;

  &:last-of-type {
    padding-bottom: 0;
  }

  ${responsive.sm`
    width: 160px;

    margin-right: 50px;

    &:nth-of-type(3n) {
      margin-right: 0;
    }

    &:nth-last-of-type(-n+3) {
      padding-bottom: 0;
    }
  `}

  ${responsive.md`
    width: 214px;
    margin-right: 66px;
    padding-bottom: 56px;
  `}

  ${responsive.lg`
    width: 270px;
    margin-right: 80px;
  `}

  svg {
    width: 32px;
    height: 32px;

    ${responsive.md`
      width: 40px;
      height: 40px;
    `}
  }

  h3 {
    text-align: center;
  }
`;

const BusterTitle = styled.h3`
  ${Font.circular};
  color: ${Color.ritualBlue};
  font-size: ${rem(16)};
  font-weight: 500;
  letter-spacing: 0px;
  line-height: ${rem(26)};
  text-align: center !important;
  margin-top: 12px;
  margin-bottom: 8px;

  ${responsive.md`
    margin-top: 20px;
    margin-bottom: 16px;
    font-size: ${rem(20)};
    line-height: ${rem(30)};
  `}
`;

const BusterText = styled.p`
  ${Font.circular};
  color: rgba(20, 43, 111, ${Opacity.light});
  font-size: ${rem(14)};
  font-weight: 300;
  letter-spacing: 0px;
  line-height: ${rem(20)};
  text-align: center;
  margin-bottom: 0;
  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(22)};
  `}
`;

function Buster({ title, icon, body }) {
  let Icon = Icons[icon];
  return (
    <BusterDiv>
      {Icon && <Icon />}
      <BusterTitle>{title}</BusterTitle>
      <BusterText>{body}</BusterText>
    </BusterDiv>
  );
}

export default class BarrierBusters extends Component {
  render() {
    const barrierBusters =
      intl.unformattedTranslation("authenticate.barrier-busters.data") || [];

    return (
      <Container>
        <Row>
          <BarrierBusterContainer>
            <h2>
              <Text
                id="authenticate.barrier-busters.heading"
                defaultMessage="Making healthy habits easy"
              ></Text>
            </h2>

            <Grid>
              {barrierBusters.map((key, index) => {
                return (
                  <Buster
                    key={index}
                    title={key.title}
                    icon={key.icon}
                    body={key.body}
                  />
                );
              })}
            </Grid>
          </BarrierBusterContainer>
        </Row>
      </Container>
    );
  }
}
