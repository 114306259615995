import { useSelector } from "react-redux";
import { BillingType } from "../../constants/plan";
import { ActionType } from "../../constants/product";
import { Status } from "../../constants/stockStatus";
import usePromotionDiscount from "../../hooks/usePromotionDiscount";
import useShoppingIntention from "../../hooks/useShoppingIntention";
import useVariation from "../../hooks/useVariation";
import { bestEligiblePromotion } from "../../store/promotion/selectors";
import AuthedAddButton from "../bundle/AuthedAddButton";
import QuickAddButton from "../bundle/QuickAddButton";
import CheckoutButton from "./CheckoutButton";
import OutOfStockButton from "./OutOfStockButton";
import SwitchButton from "./SwitchButton";
import planSelectors from "../../store/plan/selectors";

export default function ProductButton(props) {
  const {
    product,
    quantity = 1,
    trackCTAClick,
    productButtonProps = {
      cadencePlanCta: false,
    },
    location,
    planType = BillingType.Recurring,
    showPricing = true,
    selectedPlan,
    className,
    prefix = null,
    textCta,
  } = props;

  const shoppingIntention = useShoppingIntention({
    actionType: props.actionType,
    subscriptionId: productButtonProps.subscriptionId,
  });

  const planForSku = useSelector((state) =>
    planSelectors.planForProductSku(state, {
      sku: product.sku,
      type: planType,
    }),
  );

  const nySaleEnabled = useVariation("ny-sale-25-front-end");

  const subscriptionId =
    props.actionType === ActionType.Switch
      ? productButtonProps.subscriptionId
      : shoppingIntention.subscriptionId;

  const { subscriptionItemId } = productButtonProps;

  const validSwitch =
    props.actionType === ActionType.Switch &&
    subscriptionId &&
    subscriptionItemId;

  const invalidSwitch =
    props.actionType === ActionType.Switch &&
    !(subscriptionId && subscriptionItemId);

  const actionType =
    (validSwitch && ActionType.Switch) ||
    (invalidSwitch && ActionType.AuthedAdd) ||
    shoppingIntention.actionType;

  const plan = selectedPlan || planForSku;

  const promotion = useSelector(bestEligiblePromotion);
  const promotionDiscount = usePromotionDiscount(promotion, plan.id);

  if (!plan || product.stockStatus !== Status.InStock) {
    return (
      <OutOfStockButton
        className={className || "fullwidth-mobile"}
        product={product}
        plan={plan}
        {...productButtonProps}
      />
    );
  }

  // Exclude evergreen Welcome Offer promotion pricing
  // and just show the regular slope pricing for the product
  // Note: Disabled for NY Sale
  if (productButtonProps.cadencePlanCta && !nySaleEnabled) {
    return (
      <CheckoutButton
        className={className || "fullwidth-mobile"}
        product={product}
        quantity={quantity}
        trackCTAClick={trackCTAClick}
        {...productButtonProps}
        prefix={productButtonProps.prefix || prefix}
        location={location}
        plan={plan}
        showPricing={showPricing}
        textCta={textCta}
      />
    );
  }

  if (actionType === ActionType.AddToCart || quantity > 1) {
    return (
      <CheckoutButton
        className={className || "fullwidth-mobile"}
        product={product}
        quantity={quantity}
        trackCTAClick={trackCTAClick}
        {...productButtonProps}
        prefix={productButtonProps.prefix || prefix}
        location={location}
        plan={plan}
        showPricing={showPricing}
        promotion={promotionDiscount}
        textCta={textCta}
      />
    );
  }

  const CustomerButton =
    (actionType === ActionType.Switch && SwitchButton) ||
    (actionType === ActionType.QuickAdd && QuickAddButton) ||
    AuthedAddButton;

  return (
    <CustomerButton
      {...productButtonProps}
      plan={plan}
      subscriptionId={subscriptionId}
      subscriptionItemId={subscriptionItemId}
      trackCTAClick={trackCTAClick}
      promotion={promotionDiscount}
      location={location}
      prefix={productButtonProps.prefix || prefix}
      showPricing={showPricing}
    />
  );
}
