import { useState } from "react";
import GoogleAuthScript from "./GoogleAuthScript";
import { verifyAssertion } from "../../utils/authentication/assertion";
import { handleAuthenticationResponse } from "../../utils/authenticate";
import { redirectReplaceHistory } from "../../utils/redirect";
import Text from "../Text";
import styled from "styled-components";
import { Color, rem } from "../../utils/style";
import { SIGN_IN, SIGN_UP } from "./ThirdPartyAuth";
import { trackSignIn, trackSignUp } from "../../utils/tracking/authentication";
import ThirdPartyAuthButton from "./ThirdPartyAuthButton";
import { isWebView } from "../../utils/user-agent";
import { MagicSVGImage } from "../MagicSVGImage";
import { GoogleLogo } from "../../utils/svg";
import * as Sentry from "@sentry/react";

const ErrorMessage = styled.p`
  font-weight: 300;
  letter-spacing: 0;
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  padding: 4px 0;
  color: ${Color.ritualRed};
  margin: -18px 0 0 0;
  text-align: center;
`;

const defaultMessage = {
  SIGN_UP: "Sign up with Google",
  SIGN_IN: "Sign in with Google",
};

function GoogleAuthButton(props) {
  const [googleCodeClient, setGoogleCodeClient] = useState(undefined);
  const [hasError, setHasError] = useState(false);
  const [hasScriptLoaded, setHasScriptLoaded] = useState(false);

  const { redirect, marketingPreference } = props;

  async function googleCodeClientCallback(response) {
    if (response.error === "access_denied") {
      return;
    }

    const assertion = {
      authorization: response,
    };
    try {
      const authenticationToken = await verifyAssertion(
        assertion,
        "google",
        process.env.GATSBY_GOOGLE_CLIENT_ID,
      );
      await handleAuthenticationResponse(authenticationToken);

      if (props.buttonType === SIGN_UP) {
        trackSignUp("provider:google", { marketingPreference });
      }

      if (props.buttonType === SIGN_IN) {
        trackSignIn("provider:google");
      }

      redirectReplaceHistory(redirect);
    } catch (e) {
      setHasError(true);
    }
  }

  function loaded() {
    if (window?.google?.accounts?.oauth2?.initCodeClient) {
      try {
        const client = window.google.accounts.oauth2.initCodeClient({
          client_id: process.env.GATSBY_GOOGLE_CLIENT_ID,
          scope: "profile email",
          ux_mode: "popup",
          callback: googleCodeClientCallback,
        });
        setGoogleCodeClient(client);
        setHasScriptLoaded(true);
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  }

  return (
    <>
      <GoogleAuthScript asyncScriptOnLoad={loaded} />
      <ThirdPartyAuthButton
        disabled={!hasScriptLoaded || isWebView()}
        onClick={() => {
          setHasError(false);
          googleCodeClient.requestCode();
        }}
        type={"button"}
      >
        <MagicSVGImage width={18} height={18} alt={"Google"} src={GoogleLogo} />
        <span>
          <Text
            defaultMessage={defaultMessage[props.buttonType]}
            id={`authenticate.google-auth-button-${props.buttonType}`}
          />
        </span>
      </ThirdPartyAuthButton>
      {hasError && (
        <ErrorMessage aria-live="assertive">
          <Text
            id={"authenticate.create.error"}
            defaultMessage={"Something went wrong, try again"}
          />
          {/**/}
        </ErrorMessage>
      )}
    </>
  );
}

export default GoogleAuthButton;
