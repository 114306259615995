import { useStripe } from "@stripe/react-stripe-js";
import { PaymentIntent, SetupIntent } from "@stripe/stripe-js";
import { useEffect, useState } from "react";

const removeQueryParams = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const useStripeReturnedIntent = () => {
  const stripe = useStripe();
  const [paymentIntent, setPaymentIntent] = useState<
    PaymentIntent | undefined
  >();
  const [setupIntent, setSetupIntent] = useState<SetupIntent | undefined>();

  const searchParams = new URLSearchParams(
    typeof window === "undefined" ? undefined : window.location.search,
  );
  const paymentIntentClientSecret = searchParams.get(
    "payment_intent_client_secret",
  );
  const setupIntentClientSecret = searchParams.get(
    "setup_intent_client_secret",
  );
  const isReturn = !!paymentIntentClientSecret || !!setupIntentClientSecret;

  useEffect(() => {
    if (!stripe || !isReturn) {
      return;
    }

    if (paymentIntentClientSecret) {
      stripe.retrievePaymentIntent(paymentIntentClientSecret).then((result) => {
        removeQueryParams();
        setPaymentIntent(result.paymentIntent);
      });
    }

    if (setupIntentClientSecret) {
      stripe.retrieveSetupIntent(setupIntentClientSecret).then((result) => {
        removeQueryParams();
        setSetupIntent(result.setupIntent);
      });
    }
  }, [stripe, isReturn, paymentIntentClientSecret, setupIntentClientSecret]);

  return { isReturn, paymentIntent, setupIntent };
};

export default useStripeReturnedIntent;
