import styled from "styled-components";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { default as intl } from "../../../services/intl";
import metrics from "../../../utils/metrics";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { responsive } from "../../../utils/style";
import Hero from "../../bundle/Hero";
import Footnotes from "../../Footnotes";
import Reviews from "../Reviews";
import usePromotionDiscount from "../../../hooks/usePromotionDiscount";
import useVariation from "../../../hooks/useVariation";
import { getPromotionDetails } from "../../../utils/promotion";
import { bestEligiblePromotion } from "../../../store/promotion/selectors";
import productSelectors from "../../../store/product/selectors";
import { getCadencePlans } from "../../../utils/ritualData";
import { trackProductViewed } from "../../../utils/tracking/product";
import MostHelpfulReviews from "../../reviews/MostHelpfulReviews";
import AggregatedStarRating from "../../reviews/AggregatedStarRating";
import { AccordionLabel, RButton } from "@ritual/essentials-for-react";
import GridColumn from "../../grid/GridColumn";
import GridContainer from "../../grid/GridContainer";
import GridRow from "../../grid/GridRow";
import AddProductButton from "../AddProductButton";
import ProductHeroCarousel from "../../../components/product/ProductHeroCarousel";
import Benefits from "../../../components/subscription/Benefits";
import FlyoutPanel from "../FlyoutPanel";
import CadenceSelector from "../CadenceSelector";
import { MagicSVGImage } from "../../../components/MagicSVGImage";
import { IconPlus, IconMinus } from "../../../utils/svg";
import Timeline from "../../product/Timeline";
import FAQsComponent from "../../bundle/FAQs";
import BundleFeaturedArticles from "../../bundle/BundleFeaturedArticles";
import ScrollMagicController from "../../ScrollMagicController";
import ValuePropsTicker from "../../plp/ValuePropsTicker";
import ScentSelector from "../ScentSelector";
import ScrollAnimation from "../ScrollAnimation";
import IngredientsOverviewSection from "../IngredientsOverviewSection";
import IngredientsOverview from "../IngredientsOverview";
import Row from "../../Row";
import Container from "../../Container";
import FollowElement from "../../FollowElement";

import {
  SupplementFactsType,
  ValuePropType,
  HowToUse,
  ProductStudy,
} from "../../../utils/productTypes";
import ScientificTeam from "../ScientificTeam";
import { getPlanForId } from "../../../utils/planToProduct";
import PillSection from "../PillSection";
import NameMap from "../../../utils/nameMap";

const Background = styled.div`
  border-bottom: 1px solid transparent;
  position: relative;
  width: 100vw;
  max-width: 100%;
`;

const TopRadialGradient = styled.span`
  display: none;

  ${responsive.sm`
    display: block;
  `};

  width: 603px;
  height: 603px;
  border-radius: 50%;
  z-index: -1;

  position: absolute;
  top: 304px;
  right: -315px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 230, 102, 0.6) 17.67%,
    rgba(255, 230, 102, 0) 100%
  );

  ${responsive.md`
    width: 733px;
    height: 733px;

    top: 402px;
    right: -386px;
  `};
`;

const BottomRadialGradient = styled(TopRadialGradient)`
  z-index: 1;
  display: block;
  width: 500px;
  height: 500px;
  top: 0;
  right: calc(500px * -0.5);

  ${responsive.md`
    display: block;
    width: 800px;
    height: 800px;
    top: calc(800px * -0.5);
    right: calc(800px * -0.5);
  `};
`;

const GradientFAQ = styled(BottomRadialGradient)`
  z-index: 1;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 214, 0, 0.8) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  top: 0;
  left: calc(500px * -0.5);

  ${responsive.sm`
    top: 0;
    left: calc(500px * -0.5);
  `}

  ${responsive.md`
    top: calc(500px * -0.2);
    left: calc(500px * -0.8);
  `}

   ${responsive.lg`
    top: calc(500px * -0.25);
    left: calc(500px* -0.8);
  `}
`;

const RightRadialGradient = styled(TopRadialGradient)`
  z-index: -1;
  display: block;

  width: 500px;
  height: 500px;
  top: calc(500px * -0.5);
  right: calc(500px * -0.5);

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(255, 214, 0, 0.8) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  ${responsive.md`
    display: block;
    width: 800px;
    height: 800px;

    top: calc(800px * -0.5) - 40px;
    right: calc(800px * -0.5) - 40px;
  `};
`;

const HeroContainer = styled(Container)`
  max-width: unset;
  padding-bottom: var(--spacing-5, 80px);

  .container {
    max-width: 875px;
  }

  ${responsive.sm`
    overflow: visible !important;
    padding: 0 var(--spacing-2, 32px) var(--spacing-5, 80px);
  `}

  ${responsive.lg`
    padding-left: var(--spacing-3);
  `}

  ${responsive.xxl`
    padding-left: var(--spacing-6, 96px);
  `}
`;

const HeroRow = styled(Row)`
  flex-wrap: nowrap !important;
`;

const HeroColumn = styled.div`
  padding: 0 var(--spacing-1_5, 24px);

  @media (min-width: 786px /* Grid S Tablet */) {
    padding-right: 0 !important;
    padding-left: unset;
  }

  ${responsive.md`
    padding-top: 0;
    padding-left: var(--spacing-3, 48px);
  `}

  ${responsive.lg`
    padding-top: 0;
    padding-left: var(--spacing-3);
  `}
`;

const HeroImageColumn = styled(HeroColumn)`
  padding-left: unset;
  width: 50vw;

  ${responsive.lg`
    margin-left: -32px;
    width: calc(50vw + 32px);
  `}
`;

const Spacer = styled.div`
  height: 84px;
  padding-top: 0;

  ${responsive.md`
    height-top: 70px;
  `}

  @media (min-width: 750px /* Grid S Tablet */) {
    padding-top: var(--spacing-3);
  }

  &.desktop {
    display: none;

    @media (min-width: 750px /* Grid S Tablet */) {
      display: block;
    }
  }

  &.mobile {
    display: block;
    @media (min-width: 750px /* Grid S Tablet */) {
      display: none;
    }
  }
`;

const FlyoutMenus = styled(AccordionLabel)`
  outline: none;
  &:first-of-type {
    border-top: 1px solid var(--indigo-blue);
  }

  &:hover {
    cursor: pointer;
    .r-button.primary button {
      background-color: var(--yellow-ochre-60);
    }
  }
`;

const FlyoutMenuWrapper = styled.div`
  margin: var(--spacing-3) -24px;

  accordion-label {
    border-color: var(--solid-neutral-cool-40, #eaeef0);
    padding: var(--spacing-1_5, 24px);

    &:first-of-type {
      border-top: 1px solid var(--solid-neutral-cool-40, #eaeef0) !important;
    }

    ${responsive.sm`
      padding: var(--spacing-1_5, 24px) 0;
    `}
  }

  ${responsive.sm`
    margin: var(--spacing-3) 0;
    padding: unset;
  `}
`;

const HeroWrapper = styled.div`
  padding-top: var(--spacing-1_5, 24px);

  ${responsive.sm`
    margin-right: var(--spacing-4, 64px);
  `}

  @media (min-width: 786px /* Grid S Tablet */) {
    padding-top: 0;
    margin-right: var(--spacing-5, 80px);
  }

  ${responsive.lg`
    margin-right: 100px;
  `}
`;

const ImageArea = styled.div.attrs({
  id: "single-product-hero_container_image",
})`
  order: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  overflow: visible;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${responsive.sm`
    order: 1;
    justify-content: start;
    padding-left: 0;
    padding-bottom: 0;
    overflow: hidden;
  `}

  ${responsive.md`
    justify-content: space-between;
  `}
`;

const CarouselWrapper = styled.div`
  position: relative;
  padding-top: 16px;
  width: 100vw;

  ${responsive.sm`
    padding-top: 0;
  `}
`;

const ArticlesWrapper = styled.div`
  width: 100vw;
  max-width: 100%;
  overflow: hidden;

  div[data-element="BundleFeaturedArticlesContainer"] {
    padding-bottom: 40px;
  }
`;

const ScientificTeamWrapper = styled.div`
  position: relative;
  width: 100vw;
  max-width: 100%;
  overflow: hidden;
`;

const FAQWrapper = styled.div`
  position: relative;
`;

const ValuePropsTickerSpacer = styled.div`
  margin-bottom: var(--spacing-5);

  @media (min-width: 750px) {
    margin-bottom: var(--spacing-6);
  }

  &.no-margin {
    margin-bottom: 0;

    @media (min-width: 750px) {
      margin-bottom: 0;
    }
  }
`;

const IngredientsOverviewWrapper = styled.div``;

const FollowElementContentWrapper = styled.div`
  width: 50vw;
  overflow: hidden;
`;

type ProductAttributes = {
  billing_type: string;
  category: string;
  currency: string;
  id: string;
  image_url: string;
  name: string;
  price: number;
  quantity: number;
  sku: string;
  slug: string;
  url: string;
  urlSlug: string;
  variant: string;
  variant_contents: string;
  variant_description: string;
  promotion?: any;
};

type Product = {
  sku: string;
  productGroupId: string;
  name: { name: string };
};

type ContentfulImage = {
  title: string;
  description: string;
  gatsbyImageData: IGatsbyImageData;
};

type FAQMarkdown = {
  childMarkdownRemark: {
    rawMarkdownBody: string;
  };
};

type FAQ = {
  question: string;
  answer: string;
};

type ContentfulProduct = {
  sku: string;
  name: { name: string };
  pageDescription: string;
  category: string;
  heroZoomImages: ContentfulImage[];
  heroFeaturedImages: ContentfulImage[];
  timelineHeading: string;
  timelineSubhead: string;
  timelinePhoto: IGatsbyImageData;
  timelineVideo: IGatsbyImageData;
  timelineData: {
    childMarkdownRemark: {
      rawMarkdownBody: string;
    };
  };
  supplementFacts: SupplementFactsType;
  valueProps: ValuePropType[];
  howToUse: HowToUse;
  faq: FAQMarkdown;
  productStudyModal: ProductStudy;
  ritualProduct: any;
  experts: any;
  slug: string;
  articles: any;
  designTour: any;
  ingredientsSubheader: any;
  ingredients: any;
  footnotes: any;
  showReviews: boolean;
  stockStatus: string;
  pillSectionTitle: string;
  pillSectionImage: ContentfulImage;
  pillSectionAccordion: any[];
  productSubhead: string;
  scentSelector?: boolean;
  headerDescription: string;
};

type Props = {
  product: Product;
  data: {
    mostHelpful: any;
    mostCritical: any;
    allProductReview: any;
    contentfulProduct: ContentfulProduct;
  };
  selectedProduct: ContentfulProduct;
  productAttributes: ProductAttributes;
  showCarousel: boolean;
  reviewRatingsMap: any;
  reviewCountsMap: any;
  pageContext: any;
  handleSelectedProduct?: (product: Product) => {};
  selectedPlanId: string;
  setSelectedPlanId?: (planId: string) => {};
};

const SingleProductDetailPage = (props: Props) => {
  const {
    data,
    product,
    showCarousel = true,
    handleSelectedProduct,
    pageContext,
    selectedPlanId,
    setSelectedPlanId = () => {},
    reviewRatingsMap,
    reviewCountsMap,
  } = props;
  const { productId } = pageContext;
  const productRating = {
    reviewScore: reviewRatingsMap[productId],
    reviewCount: reviewCountsMap[productId],
  };
  const productUrl = `${process.env.GATSBY_URL}/products/${data.contentfulProduct.slug}`;
  const contentfulReviews = data.allProductReview.edges.map(
    (e: { node: any }) => e.node,
  );
  const simpleName = NameMap(product)?.plain;
  const { mostHelpful, mostCritical, contentfulProduct } = data;
  const {
    timelineHeading,
    timelineSubhead,
    timelinePhoto,
    timelineVideo,
    timelineData,
    name: { name: productName },
    category,
    heroZoomImages,
    heroFeaturedImages,
    sku,
    productStudyModal,
    ritualProduct,
    faq,
    experts,
    articles,
    designTour,
    ingredientsSubheader,
    ingredients,
    footnotes,
    showReviews,
    scentSelector,
    pillSectionTitle,
    pillSectionImage,
    pillSectionAccordion,
    productSubhead,
    howToUse = null,
    headerDescription,
  } = contentfulProduct;

  const nySaleEnabled = useVariation("ny-sale-25-front-end") as
    | boolean
    | undefined;

  let cadencePlans = getCadencePlans(
    ritualProduct.ritualPlans,
    pageContext.currency,
  );
  const showTruemedPdp = useVariation("truemed-pdp-display");
  const showTruemedWidget = showTruemedPdp === "widget";
  const showTruemedBenefit = showTruemedPdp === "benefits";

  const defaultPlan = cadencePlans.find(
    (plan) => plan.plan_id === selectedPlanId,
  );

  const [selectedPlan, setSelectedPlan] = useState(defaultPlan);
  const planForCta = getPlanForId(selectedPlanId);

  const price = (selectedPlan?.amount || 0) / 100;
  const bestPromotion = useSelector(bestEligiblePromotion);

  // Selected subscription
  const promotion = usePromotionDiscount(bestPromotion, selectedPlan?.plan_id);
  const promotionDetails = getPromotionDetails(promotion, price);

  // Default monthly subscription
  const subscriptionPlan = cadencePlans.find(
    (plan) => plan.interval_count === 30,
  );
  const subscriptionPromotion = usePromotionDiscount(
    bestPromotion,
    subscriptionPlan?.plan_id,
  );
  const subscriptionPromotionDetails = getPromotionDetails(
    subscriptionPromotion,
    subscriptionPlan?.amount,
  );

  const promotionDiscountPrice = promotionDetails?.discountPrice;
  const currentPrice = promotionDiscountPrice || price;
  const savings = price - currentPrice;
  const showSavingsPrice = savings > 0;
  const showScientificTeam = experts && experts.length > 0;
  const showFeaturedArticles = articles && articles.length;
  const hideCTAPrice = true;
  const hasWindow = typeof window !== "undefined";

  const [scrollRef, setScrollRef] = useState(null);
  const [isMobile, setIsMobile] = useState(
    hasWindow && window.innerWidth < 750,
  );
  const handleResize = () => {
    const windowSize = window.innerWidth;
    setIsMobile(windowSize < 750);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const updatedPlan = cadencePlans.find(
      (plan) => plan.plan_id === selectedPlanId,
    );
    setSelectedPlan(updatedPlan);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlanId]);

  useEffect(() => {
    let additionalProperties = {
      interval: selectedPlan?.interval || null,
      interval_count: selectedPlan?.interval_count || null,
      unit_quantity: 1,
      variant_options: {},
    };

    // es-lint-disable-next-line
    additionalProperties.variant_options = [];
    additionalProperties.variant_options = cadencePlans.map((plan) => ({
      plan_id: plan.plan_id,
      billing_type: plan.billing_type,
      interval: plan.interval,
      interval_count: plan.interval_count,
      amount: plan.amount,
      unit_quantity: 1,
    }));
    trackProductViewed(selectedPlan?.plan_id, additionalProperties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const timeline = JSON.parse(timelineData.childMarkdownRemark.rawMarkdownBody);

  const faqs: FAQ[] = JSON.parse(faq.childMarkdownRemark.rawMarkdownBody);

  // Scent Selector
  const allProducts = useSelector(productSelectors.allProducts) as [];
  const currentProduct = allProducts.find((p: Product) => p.sku === sku) as any;
  const productGroups = allProducts.filter(
    (p: Product) => p.productGroupId === currentProduct.productGroupId,
  );

  const scentSelectorSkus = ["EPRE60", "EPRE60-M", "EPOST60", "EPOST60-C"];
  const showSelector =
    scentSelector && scentSelectorSkus.includes(sku) && productGroups.length;
  const [openFlyout, setOpenFlyout] = useState(false);
  const [flyoutIndex, showFlyoutAtIndex] = useState<string | undefined>();

  const handleClick = (index: string) => {
    setOpenFlyout(true);
    showFlyoutAtIndex(index);

    metrics.track("Flyout Viewed", {
      location: "Single Sku PDP",
      name: index,
    });

    return;
  };

  const onCloseFlyout = () => {
    setOpenFlyout(false);
    setTimeout(() => {
      showFlyoutAtIndex(undefined);
    }, 300);
  };

  const flyoutMenus = [
    intl.t("product.supplement-facts.heading", "Supplement Facts"),
    howToUse && intl.t("product.protein.how-to-use.heading", "How to Use"),
    productStudyModal && productStudyModal.linkText,
  ];

  const isProtein = category.includes("Protein");
  const isMultivitamin =
    ritualProduct.sub_category === "Multivitamin" && sku !== "FERTL";
  const isCholineOrOmega3 = sku === "CHLC" || sku === "OM3C";

  const hasPillSection =
    pillSectionTitle && pillSectionImage && pillSectionAccordion;

  const isOOS = contentfulProduct.stockStatus !== "in stock";

  const showPlanSelector = cadencePlans.length > 1 && !isOOS;

  const productButtonProps = {
    prefix: "Add to Cart",
    showPricing: !hideCTAPrice,
  };

  return (
    <Background>
      {!isMobile && <Spacer className="desktop" />}
      <HeroContainer>
        <Spacer className="mobile" />
        {isMobile && (
          <ImageArea>
            <CarouselWrapper>
              <ProductHeroCarousel
                sku={sku}
                trimmed={!showCarousel}
                zoomEnabled={showCarousel}
                images={heroFeaturedImages}
                zoomImages={heroZoomImages}
                options={{
                  bleedLeft: false,
                  bleedRight: false,
                }}
              />
            </CarouselWrapper>
          </ImageArea>
        )}
        <HeroRow>
          <HeroColumn className={isMobile ? "col-12" : "col-6"}>
            <HeroWrapper>
              <Hero
                title={productName}
                description={productSubhead}
                heroDescription={headerDescription}
                price={currentPrice}
                futurePrice={showSavingsPrice ? price : undefined}
                hidePriceBreakdown={showPlanSelector || isOOS}
                showProbioticBlend={sku === "SYN30"}
                showPrenatalBadge={sku === "EPRE60" || sku === "EPRE60-M"}
                showPromoSavings={!showPlanSelector && showSavingsPrice}
                promotionDetails={promotionDetails}
                subscriptionPromotionDetails={subscriptionPromotionDetails}
                showSubscriptionOfferFlag={false}
                showTruemedWidget={showTruemedWidget}
              >
                {showSelector && (
                  <ScentSelector
                    key={"scent-selector"}
                    fullWidth={true}
                    simpleLabel={true}
                    onClick={handleSelectedProduct}
                    products={productGroups}
                    selectedProduct={product}
                  />
                )}
                {showPlanSelector && (
                  <CadenceSelector
                    key={"cadence-selector"}
                    cadencePlans={cadencePlans}
                    selectedPlanId={selectedPlanId}
                    setSelectedPlanId={setSelectedPlanId}
                  />
                )}

                <AddProductButton
                  key={"add-product-button"}
                  productButtonProps={productButtonProps}
                  location="Single Product PDP HERO"
                  price={price}
                  className="fullwidth single-pdp"
                  product={contentfulProduct ? contentfulProduct : product}
                  selectedPlan={planForCta}
                  discountPrice={
                    nySaleEnabled && promotionDiscountPrice !== null
                      ? promotionDiscountPrice
                      : undefined
                  }
                  promotion={nySaleEnabled ? promotion : undefined}
                  detailTextEnabled={false}
                />
              </Hero>
              {showTruemedBenefit && (
                <Benefits
                  products={[product]}
                  isBundle={false}
                  showTruemedBenefit={showTruemedBenefit}
                />
              )}
              <FlyoutMenuWrapper>
                {flyoutMenus.map((menuLabel, index) => {
                  if (!menuLabel) return null;
                  return (
                    <FlyoutMenus
                      key={index}
                      onClick={() => handleClick(menuLabel)}
                      tabIndex={-1}
                      aria-expanded={flyoutIndex === menuLabel}
                    >
                      <span
                        slot="accordion-label"
                        aria-label={`${menuLabel} menu`}
                      >
                        {menuLabel}
                      </span>
                      <RButton
                        slot="accordion-icon-button"
                        buttonClass="round-icon primary typography-body3 font-circular"
                        role="button"
                        onKeyDown={(event) => {
                          if (event.key === "Enter" || event.key === " ") {
                            handleClick(menuLabel);
                          }
                        }}
                      >
                        <MagicSVGImage
                          src={
                            flyoutIndex && flyoutIndex === menuLabel
                              ? IconMinus
                              : IconPlus
                          }
                          alt={`${
                            flyoutIndex && flyoutIndex === menuLabel
                              ? "Minus"
                              : "Plus"
                          }`}
                          width={24}
                          height={24}
                        />
                      </RButton>
                    </FlyoutMenus>
                  );
                })}
              </FlyoutMenuWrapper>
            </HeroWrapper>
            {!showTruemedBenefit && (
              <Benefits products={[product]} isBundle={false} />
            )}
          </HeroColumn>

          {!isMobile && (
            <HeroImageColumn>
              <FollowElement customOffset={132}>
                <FollowElementContentWrapper>
                  <ProductHeroCarousel
                    sku={sku}
                    trimmed={!showCarousel}
                    zoomEnabled={showCarousel}
                    images={heroFeaturedImages}
                    zoomImages={heroZoomImages}
                  />
                </FollowElementContentWrapper>
              </FollowElement>
            </HeroImageColumn>
          )}
        </HeroRow>
      </HeroContainer>

      <GridContainer>
        <GridRow>
          <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
            <Timeline
              timelineHeading={timelineHeading}
              timelineSubhead={timelineSubhead}
              image={timelinePhoto}
              video={timelineVideo}
              data={timeline}
              controller={scrollRef}
              singleProduct={true}
            />

            <ScrollMagicController
              ref={(r) => {
                r && setScrollRef(r.controller);
              }}
            />
          </GridColumn>
        </GridRow>
      </GridContainer>

      <GridRow>
        <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
          <ValuePropsTickerSpacer
            className={hasPillSection && isCholineOrOmega3 ? "no-margin" : ""}
          >
            <ValuePropsTicker />
          </ValuePropsTickerSpacer>
        </GridColumn>
      </GridRow>

      <GridRow>
        <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
          <ScrollAnimation
            key={"design-tour"}
            controller={scrollRef}
            data={designTour}
          />
        </GridColumn>
      </GridRow>

      {hasPillSection && isCholineOrOmega3 && (
        <GridRow>
          <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
            <PillSection
              pillSectionTitle={pillSectionTitle}
              pillSectionImage={pillSectionImage}
              pillSectionAccordion={pillSectionAccordion}
            />
          </GridColumn>
        </GridRow>
      )}

      <GridRow>
        <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
          {isMultivitamin ? (
            <IngredientsOverview
              key={"multivitamin-ingredients-overview"}
              openFlyout={handleClick}
              product={contentfulProduct}
              selectedPlanId={selectedPlanId}
              ingredients={ingredients}
              ingredientsSubheader={ingredientsSubheader}
              somethingMissingTitle={
                "Notice something missing? We left out Calcium and Vitamin C for a reason."
              }
            />
          ) : (
            <IngredientsOverviewWrapper>
              <IngredientsOverviewSection
                product={contentfulProduct}
                productType={sku}
                ingredients={ingredients}
                ingredientsSubheader={ingredientsSubheader}
                showQualityOverQuantity={isProtein}
                showOtherIngredients={isProtein}
                openFlyout={handleClick}
                isMobile={isMobile}
              />
            </IngredientsOverviewWrapper>
          )}
        </GridColumn>
      </GridRow>

      {hasPillSection && !isCholineOrOmega3 && (
        <GridRow>
          <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
            <PillSection
              pillSectionTitle={pillSectionTitle}
              pillSectionImage={pillSectionImage}
              pillSectionAccordion={pillSectionAccordion}
            />
          </GridColumn>
        </GridRow>
      )}

      <GridContainer>
        <GridRow>
          <GridColumn xs={{ size: 6 }} s={{ size: 12 }} m={{ size: 12 }}>
            <FAQWrapper>
              <FAQsComponent
                faqs={faqs}
                singleProduct={true}
                customOffset={120}
              />
              <GradientFAQ />
            </FAQWrapper>
          </GridColumn>
        </GridRow>
      </GridContainer>

      {showFeaturedArticles && (
        <ArticlesWrapper>
          <BundleFeaturedArticles articles={articles}></BundleFeaturedArticles>
        </ArticlesWrapper>
      )}

      {showScientificTeam && (
        <ScientificTeamWrapper>
          <RightRadialGradient />
          <ScientificTeam experts={experts} />
        </ScientificTeamWrapper>
      )}

      {showReviews &&
        !!productRating?.reviewScore &&
        !!contentfulReviews?.length && (
          <Reviews
            reviews={contentfulReviews}
            productUrl={productUrl}
            productName={simpleName}
            productId={productId}
            seeAllReviewsCta={true}
            isProductPage={true}
            {...productRating}
          >
            <AggregatedStarRating
              score={productRating.reviewScore}
              totalReviews={productRating.reviewCount}
              productId={productId}
            />
            {mostHelpful && mostCritical && (
              <MostHelpfulReviews
                mostHelpful={mostHelpful}
                mostCritical={mostCritical}
              />
            )}
          </Reviews>
        )}

      <Footnotes footnotes={footnotes} />

      <FlyoutPanel
        openIndex={flyoutIndex}
        open={openFlyout}
        item={{
          products: [contentfulProduct],
        }}
        product={contentfulProduct}
        closeQuickView={onCloseFlyout}
        hideTabs={true}
      />
    </Background>
  );
};

export default SingleProductDetailPage;
