import { Component, Fragment } from "react";
import styled from "styled-components";
import { Color } from "../../utils/style";
import Text from "../Text";
import GoogleAuthButton from "./GoogleAuthButton";
import AppleAuthButton from "./AppleAuthButton";
import ThirdPartyAuthButton from "./ThirdPartyAuthButton";

const LoginSeparator = styled.div`
  height: 20px;
  color: ${Color.lessFadedBlue};
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  line-height: 20px;
  margin: -8px 0 8px;
`;

const RitualFormToggleButton = styled(ThirdPartyAuthButton)`
  background: ${Color.ritualBlue};
  color: #fff;
  margin-bottom: 0;

  &:hover {
    color: ${Color.ritualBlue};
  }
`;

const ritualAuthButtonTextDefaultMessage = {
  SIGN_IN: "Sign in with Email",
  SIGN_UP: "Sign up with Email",
};

class ThirdPartyAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ritualAuthButtonClicked: false,
    };
  }

  render() {
    const { redirect, marketingPreference } = this.props;

    return (
      <Fragment>
        <AppleAuthButton
          buttonType={this.props.buttonType}
          redirect={redirect}
          marketingPreference={marketingPreference}
        />
        <GoogleAuthButton
          buttonType={this.props.buttonType}
          redirect={redirect}
          marketingPreference={marketingPreference}
        />
        {this.props.buttonType !== SIGN_IN && (
          <LoginSeparator>
            <Text id={"authenticate.or"} defaultMessage={"or"} />
          </LoginSeparator>
        )}

        {this.shouldShowRitualAuthButton() && (
          <RitualFormToggleButton
            type={"button"}
            onClick={() => {
              this.setState({
                ritualAuthButtonClicked: true,
              });
              this.props.onRitualFormToggleClicked();
            }}
          >
            <Text
              id={`authenticate.ritual-form-toggle-button-${this.props.buttonType}`}
              defaultMessage={
                ritualAuthButtonTextDefaultMessage[this.props.buttonType]
              }
            />
          </RitualFormToggleButton>
        )}
      </Fragment>
    );
  }

  shouldShowRitualAuthButton() {
    if (this.props.buttonType === SIGN_IN) {
      return false;
    }

    return !this.state.ritualAuthButtonClicked;
  }
}

export const SIGN_IN = "sign-in";
export const SIGN_UP = "sign-up";

export default ThirdPartyAuth;
