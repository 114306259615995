import { PaymentIntent, SetupIntent } from "@stripe/stripe-js";
import { useEffect } from "react";
import useStripeReturnedIntent from "./useStripeReturnedIntent";

export type StripePaymentSuccessHandler = () => void;

export type StripePaymentErrorHandler = (
  error: PaymentIntent.LastPaymentError | SetupIntent.LastSetupError,
) => void;

const useStripeReturnedIntentHandler = (
  onPaymentSuccess: StripePaymentSuccessHandler,
  onPaymentError: StripePaymentErrorHandler,
) => {
  const { isReturn, paymentIntent, setupIntent } = useStripeReturnedIntent();

  const status = paymentIntent?.status || setupIntent?.status || "";
  const isConsideredSuccessful = ["succeeded", "processing"].includes(status);

  useEffect(() => {
    if (isConsideredSuccessful) {
      onPaymentSuccess();
    }
  }, [onPaymentSuccess, isConsideredSuccessful]);

  const error =
    paymentIntent?.last_payment_error || setupIntent?.last_setup_error || "";

  useEffect(() => {
    if (error) {
      onPaymentError(error);
    }
  }, [onPaymentError, error]);

  const isCompleted = Boolean(!isReturn || paymentIntent || setupIntent);

  return isCompleted;
};

export default useStripeReturnedIntentHandler;
