import { useState } from "react";

// Store
import { useSelector } from "react-redux";
import outOfStockSelectors from "../../store/out-of-stock/selectors";

// Constants
import { Status } from "../../constants/stockStatus";

// Components
import RitualButton from "../global/RitualButton";
import Text from "../Text";
import OutOfStockModal from "./OutOfStockModal";

// Services
import intl from "../../services/intl";

// Utils
import metrics from "../../utils/metrics";
import NameMap from "../../utils/nameMap";
import ModalCopy from "../../utils/stockStatus";

export default function OutOfStockButton(props) {
  let { className } = props;
  const { product, plan, statusCopy = {}, buttonLocation } = props;
  const productName = NameMap(product.name).plain;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const waitlisted = useSelector((state) =>
    outOfStockSelectors.isWaitlisted(state, { sku: product.sku }),
  );

  function getOutOfStockCopy(stockStatus) {
    if (statusCopy[stockStatus]) return statusCopy[stockStatus];

    switch (stockStatus) {
      case Status.Preorder:
        return className.includes("plp-cta")
          ? {
              label: intl.t(
                "product.checkout.join-waitlist",
                "Join the Waitlist",
              ),
            }
          : {
              label: intl.t("product.checkout.coming-soon", "Coming Soon"),
              append: intl.t(
                "product.checkout.join-waitlist",
                "Join the Waitlist",
              ),
            };
      case Status.OutOfStock:
        return className.includes("single-pdp")
            ? {
              label: `${intl.t("product.checkout.out-of-stock", "Out of Stock")} — ${intl.t("product.checkout.join-waitlist", "Join the Waitlist")}`,
              } 
            : {
              label: intl.t("product.checkout.sold-out", "Sold Out"),
              append: intl.t("product.checkout.join-waitlist", "Join the Waitlist"),
              };
      default:
        return null;
    }
  }

  function closeOutOfStockModal() {
    metrics.track("Modal Dismissed", {
      title: "Waitlist Modal",
    });

    setIsModalOpen(false);
  }

  async function waitlistCTAClick(e) {
    e.preventDefault();
    if (!waitlisted) {
      setIsModalOpen(true);

      metrics.track("CTA Clicked", {
        title: productName,
        location: "Join Waitlist",
      });
    }
  }

  if (!plan || !plan.id) {
    return (
      <RitualButton className={className} disabled>
        <Text id="general.unavailable" defaultMessage="Unavailable" />
      </RitualButton>
    );
  }

  const stockStatus = product?.stockStatus;
  if (!stockStatus || stockStatus === Status.InStock) {
    return null;
  }

  let outOfStockCopy = getOutOfStockCopy(stockStatus);
  const shouldShowOutOfStockWaitlist = !!ModalCopy[stockStatus]?.[product.sku];

  // If submitted form and not "in stock", display updated copy on button
  if (waitlisted) {
    const waitedlistedCopy = statusCopy[stockStatus]?.waitlisted || {
      label: outOfStockCopy.label,
      append: intl.t("product.checkout.waitlisted", "Waitlisted ✓"),
    };

    outOfStockCopy = waitedlistedCopy;
    className += " disabled";
  }

  return (
    <>
      <RitualButton
        className={className}
        onClick={waitlistCTAClick}
        to="/cart"
        target="_self"
        disabled={!shouldShowOutOfStockWaitlist}
        append={
          shouldShowOutOfStockWaitlist && buttonLocation !== "PLP"
            ? outOfStockCopy.append
            : null
        }
      >
        {buttonLocation === "PLP" ? "Join the Waitlist" : outOfStockCopy.label}
      </RitualButton>

      {shouldShowOutOfStockWaitlist && (
        <OutOfStockModal
          isOpen={isModalOpen}
          onRequestClose={closeOutOfStockModal}
          product={product}
          waitlisted={waitlisted}
        />
      )}
    </>
  );
}
