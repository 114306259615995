import { Component } from "react";
import styled from "styled-components";
import { Color, rem, media } from "../../utils/style";
import { Icons } from "../../utils/react-svg";

const StarWrapper = styled.div.attrs({
  className: "d-flex m-0 p-0",
})``;

const Star = styled.div.attrs({
  className: "position-relative ReviewStars--star",
})`
  width: 100%;
  height: 100%;
  display: block;
  margin-right: ${rem(5)};

  ${media.mobile`
    margin-right: ${rem(2)};
  `} &:last-child {
    margin-right: 0;
  }

  svg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.star--full {
    path {
      fill: ${Color.ritualYellow};
    }
  }

  &.star--empty {
    path {
      fill: ${Color.ritualYellow};
      opacity: 0.24;
    }
  }
`;

export default class ReviewStars extends Component {
  constructor(props) {
    super(props);
    this.state = {
      starTypes: {
        empty: {
          svg: "StarFull",
          class: "star--empty",
        },
        thirdYellow: {
          svg: "StarThirdYellow",
          class: "star--third--yellow",
        },
        halfYellow: {
          svg: "StarHalfYellow",
          class: "star--half--yellow",
        },
        threeQuartersYellow: {
          svg: "StarThreeQuartersYellow",
          class: "star--three-quarters--yellow",
        },
        full: {
          svg: "StarFull",
          class: "star--full",
        },
      },
    };
  }

  renderStarRating() {
    let { score } = this.props;
    let { starTypes } = this.state;

    let stars = new Array(5).fill(starTypes.empty);
    let fullStars = Math.floor(score);
    let roundedScore = Math.round(score * 10) / 10;
    let partialStars = roundedScore - fullStars;

    stars = stars.map((s, i) => {
      if (i < fullStars) {
        return starTypes.full;
      } else if (i === fullStars && partialStars > 0) {
        switch (true) {
          case partialStars >= 0.9:
            return starTypes.full;
          case partialStars >= 0.6:
            return starTypes.threeQuartersYellow;
          case partialStars >= 0.4:
            return starTypes.halfYellow;
          case partialStars >= 0.2:
            return starTypes.thirdYellow;
          default:
            return s;
        }
      } else {
        return s;
      }
    });
    return stars.map((star, index) => {
      let NewVar = Icons[star.svg];

      return (
        <Star className={star.class} key={index}>
          <NewVar index={index} key={index} alt={`Review ratings star ${index + 1}`}/>
        </Star>
      );
    });
  }

  render() {
    return <StarWrapper>{this.renderStarRating()}</StarWrapper>;
  }
}
