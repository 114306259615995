import { Component } from "react";
import styled from "styled-components";

// Redux
import { handleAuthenticationResponse } from "../../utils/authenticate";
import { redirectReplaceHistory } from "../../utils/redirect";
import { createAppleAuthScript } from "./AppleAuthScript";
import { rem, Color } from "../../utils/style";
import Text from "../Text";
import { verifyAssertion } from "../../utils/authentication/assertion";
import { SIGN_IN, SIGN_UP } from "./ThirdPartyAuth";
import { trackSignIn, trackSignUp } from "../../utils/tracking/authentication";
import ThirdPartyAuthButton from "./ThirdPartyAuthButton";
import { MagicSVGImage } from "../MagicSVGImage";
import { AppleLogo } from "../../utils/svg";

const AppleButton = styled(ThirdPartyAuthButton)`
  span {
    margin-left: 7px;
  }
`;

const Other = styled.div`
  color: green;
`;

const ErrorMessage = styled.p`
  font-weight: 300;
  letter-spacing: 0;
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  padding: 4px;
  color: ${Color.ritualRed};
  margin: -18px 0 0 0;
  text-align: center;
`;

async function getAppleAuthData() {
  try {
    return await window.AppleID.auth.signIn();
  } catch (e) {
    // https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple#3331292
    // Currently, the only error code returned is user_cancelled_authorize. The framework returns this error code when the user clicks the Cancel button.
  }
}

const defaultMessage = {
  SIGN_UP: "Sign up with Apple",
  SIGN_IN: "Sign in with Apple",
};

export default class AppleAuthButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: false,
      hasScriptLoaded: false,
    };

    this.handleAppleIDSignInSuccess =
      this.handleAppleIDSignInSuccess.bind(this);
    this.loaded = this.loaded.bind(this);
  }

  async handleAppleIDSignInSuccess() {
    const { redirect, marketingPreference } = this.props;
    this.setState({
      showError: false,
    });

    try {
      const data = await getAppleAuthData();

      if (data) {
        const authentication = await verifyAssertion(
          data,
          "apple",
          process.env.GATSBY_APPLE_CLIENT_ID,
        );
        await handleAuthenticationResponse(authentication);

        if (this.props.buttonType === SIGN_UP) {
          trackSignUp("provider:apple", {
            marketingPreference,
          });
        }

        if (this.props.buttonType === SIGN_IN) {
          trackSignIn("provider:apple");
        }

        redirectReplaceHistory(redirect);
      }
    } catch (e) {
      this.setState({
        showError: true,
      });
    }
  }

  loaded() {
    const clientId = process.env.GATSBY_APPLE_CLIENT_ID;
    if (!clientId) return;

    window.AppleID.auth.init({
      clientId,
      scope: "name email",
      redirectURI: `${process.env.GATSBY_URL}/callback`,
      usePopup: true, //or false defaults to false
    });

    this.setState({
      hasScriptLoaded: true,
    });
  }

  render() {
    const AppleAuthScript = createAppleAuthScript();
    return (
      <>
        <AppleAuthScript asyncScriptOnLoad={this.loaded} />
        <AppleButton
          disabled={!this.state.hasScriptLoaded}
          type={"button"}
          onClick={this.handleAppleIDSignInSuccess}
        >
          <MagicSVGImage width={18} height={18} alt={"Apple"} src={AppleLogo} />
          <span>
            <Text
              defaultMessage={
                defaultMessage[defaultMessage[this.props.buttonType]]
              }
              id={`authenticate.apple-auth-button-${this.props.buttonType}`}
            />
          </span>
        </AppleButton>
        {this.state.showError && (
          <ErrorMessage aria-live="assertive">
            <Text
              id={"authenticate.create.error"}
              defaultMessage={"Something went wrong, try again"}
            />
          </ErrorMessage>
        )}
      </>
    );
  }
}
