import intl from "../services/intl";
import cartSelectors from "../store/cart/selectors";
import { getStore } from "../store/createStore";
import { getPlanForId } from "./planToProduct";

export function formatTotal(
  amountInCents = 0,
  deduction = false,
  isProcessing = false,
  postfix = false,
) {
  if (isProcessing) return <>&mdash;</>;
  const amountInDollars = (amountInCents / 100) * (deduction ? -1 : 1);
  return intl.formatCurrency(amountInDollars, { postfix });
}

export function getCartTraits() {
  const state = getStore().getState();
  const cart = cartSelectors.activeCart(state);

  if (!cart) return {};

  const cartTraits = {
    cart_total: cart?.total / 100,
    cart_tax: cart?.totalTax / 100,
    cart_subtotal: cart?.subtotal / 100,
    cart_coupon: cart?.discountCode,
    cart_currency: cart?.currency,
    cart_discount_amount: cart?.discountAmount / 100,
    cart_id: cart?.id,
  };
  return cartTraits;
}

export function basePriceTotal(activeCartProducts) {
  return (
    activeCartProducts?.reduce((total, cartProduct) => {
      const { planId } = cartProduct;
      // Only include products that have a planId
      if (!planId) return total;
      const plan = getPlanForId(planId);
      if (!plan) return total;
      return (
        total + (plan.basePrice * plan.unitQuantity || 0) * cartProduct.quantity
      );
    }, 0) || 0
  );
}

export function amountPriceTotal(activeCartProducts) {
  return (
    activeCartProducts?.reduce((total, cartProduct) => {
      // Only include products that have a planId
      if (!cartProduct.planId) return total;
      return total + cartProduct.subtotal;
    }, 0) || 0
  );
}
    
