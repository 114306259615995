import { useState, useEffect } from "react";
import styled from "styled-components";
import metrics from "../../../utils/metrics";
import intlService from "../../../services/intl";
import FloatingInput from "../../global/FloatingInput";
import { Icons } from "../../../utils/react-svg";
import { setCookie } from "../../../utils/cookies";
interface SurveyOption {
  key: string;
  type: "simple" | "accordion" | "text";
  label: string;
  options?:
    | string[]
    | {
        heading: string;
        defaultText: string;
        submit: string;
        placeholder?: string;
      };
  other?: boolean;
  secondaryView?: boolean;
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
`;

const Modal = styled.div`
  background: var(--warm-20);
  overflow-y: auto;
  padding: 0 0 var(--spacing-1_5);
  height: 100%;
  width: 100%;
  position: relative;

  @media (min-width: 750px) {
    width: 90%;
    height: unset;
    max-width: 770px;
    max-height: 75vh;
  }

  h2 {
    padding: 0 var(--spacing-2);
    margin: var(--spacing-2) 0;
  }
`;

const CloseButton = styled.button`
  appearance: none;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: var(--spacing-1_5);
  right: var(--spacing-1_5);
  width: 24px;
  height: 24px;
`;

const Option = styled.div`
  padding: 16px 40px;
  border-bottom: 1px solid #e7e7e7;

  &:first-of-type {
    border-top: 1px solid #e7e7e7;
  }

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

const OptionSet = styled.div`
  padding: 24px 0 0;

  > div {
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }

    label {
      margin: 0;
      font-weight: 300;
      width: 100%;
      cursor: pointer;
      padding: 0px var(--spacing-1_5);
    }

    input[type="radio"] {
      display: none;
    }
  }
`;

const Accordion = styled.div``;

const Button = styled.button`
  appearance: none;
  outline: none;
  box-shadow: none;
  border: none;
  background: none;
  padding: 0;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;

  color: var(--indigo-blue);
  font-family: "LL Circular";
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 26px; /* 162.5% */

  &.text-input-padding {
    margin-bottom: var(--spacing-1);
  }
`;

const shuffleArray = (array: any[]) => {
  const doctorOptionIndex = array.findIndex(
    (option) => option.key === "doctor",
  );
  const otherOptionIndex = array.findIndex((option) => option.key === "other");
  const doctorOption = array.splice(doctorOptionIndex, 1)[0];
  const otherOption = array.splice(otherOptionIndex - 1, 1)[0]; // Adjust index after removing doctorOption

  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }

  array.push(doctorOption, otherOption);
};

const trackSurveyOptionSelected = (
  response: string,
  secondaryResponse: string,
  length: number,
) => {
  metrics.track("Post Purchase Survey", {
    survey_title: intlService.t("post-purchase-survey.how-did-hear"),
    survey_response: response,
    survey_secondary_response: secondaryResponse,
    total: length,
    version: "v2024-Q4",
  });
  setCookie("rit_survey", "true", {
    domain: process.env.GATSBY_COOKIE_DOMAIN,
  });
};

const PostPurchaseSurvey = () => {
  const [textInput, setTextInput] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [openAccordions, setOpenAccordions] = useState<{
    [key: string]: boolean;
  }>({});

  const surveyOptions: SurveyOption[] = [
    {
      key: "instagram-facebook",
      type: "accordion",
      label: "Instagram / Facebook",
      options: ["Influencer / Someone I follow", "Ritual ad"],
    },
    {
      key: "tiktok",
      type: "accordion",
      label: "TikTok",
      options: ["Influencer / Someone I follow", "Ritual ad"],
    },
    {
      key: "pinterest",
      type: "accordion",
      label: "Pinterest",
      options: ["Influencer / Someone I follow", "Ritual ad"],
    },
    {
      key: "youtube",
      type: "accordion",
      label: "YouTube",
      options: ["Influencer / Someone I follow", "Ritual ad"],
    },
    {
      key: "podcast-radio",
      type: "simple",
      label: "Podcast / Radio",
    },
    {
      key: "mobile-gaming-app",
      type: "simple",
      label: "Mobile Game App",
    },
    {
      key: "reddit",
      type: "accordion",
      label: "Reddit",
      options: ["Reddit Ad", "Reddit Post"],
    },
    {
      key: "online-search-online-publication",
      type: "accordion",
      label: "Online Search / Online Publication",
      options: ["Google Sponsored Ad", "Online Article"],
    },
    {
      key: "friend-family",
      type: "simple",
      label: "Friend / Family",
    },
    {
      key: "doctor",
      type: "text",
      label: "Doctor Recommendation",
      options: {
        heading: "Doctor Name",
        defaultText: "Doctor Name",
        submit: "Submit",
      },
      secondaryView: true,
    },
    {
      key: "other",
      type: "text",
      label: intlService.t(
        "post-purchase-survey.additional-question.other.primary",
      ),
      options: {
        heading: "Other",
        defaultText: "Other",
        submit: "Submit",
      },
      secondaryView: true,
    },
  ];

  useEffect(() => {
    shuffleArray(surveyOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOptionClick = (
    response: string,
    secondaryResponse: string = "",
  ) => {
    trackSurveyOptionSelected(
      response,
      secondaryResponse,
      surveyOptions.length,
    );
    setIsOpen(false);
  };

  const handleTextSubmit = (response: string) => {
    trackSurveyOptionSelected(response, textInput, surveyOptions.length);
    setIsOpen(false);
  };

  const toggleAccordion = (key: string) => {
    setOpenAccordions((prev) => ({
      [key]: !prev[key],
    }));
  };

  if (!isOpen) return null;

  return (
    <Overlay>
      <Modal>
        <CloseButton
          aria-label="Close survey"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <Icons.Close24 />
        </CloseButton>
        <h2 className="typography-headline2">
          {intlService.t("post-purchase-survey.how-did-hear")}
        </h2>
        {surveyOptions.map((option, index) => (
          <Option key={index}>
            {option.type === "simple" && (
              <div
                role="button"
                tabIndex={0}
                onClick={() => handleOptionClick(option.label)}
                onKeyPress={(e) => {
                  if (e.key === "Enter" || e.key === " ") {
                    handleOptionClick(option.label);
                  }
                }}
                className="typograpghy-body1"
              >
                {option.label}
              </div>
            )}
            {option.type === "accordion" && (
              <Accordion>
                <Button
                  onClick={() => toggleAccordion(option.key)}
                  aria-expanded={openAccordions[option.key] || false}
                  aria-controls={`accordion-content-${option.key}`}
                  className="typograpghy-body1"
                >
                  {option.label}
                  <span>
                    {openAccordions[option.key] ? (
                      <Icons.MinusBase />
                    ) : (
                      <Icons.PlusBase />
                    )}
                  </span>
                </Button>
                {openAccordions[option.key] &&
                  option.options &&
                  Array.isArray(option.options) && (
                    <OptionSet
                      id={`accordion-content-${option.key}`}
                      role="region"
                    >
                      {option.options.map((subOption, subIndex) => (
                        <div key={subIndex}>
                          <input
                            type="radio"
                            id={`${option.key}-${subIndex}`}
                            name={option.key}
                            value={subOption}
                            onClick={() =>
                              handleOptionClick(option.label, subOption)
                            }
                          />
                          <label htmlFor={`${option.key}-${subIndex}`}>
                            {subOption}
                          </label>
                        </div>
                      ))}
                      {option.other && (
                        <div>
                          <input
                            type="radio"
                            id={`${option.key}-other`}
                            name={option.key}
                            value="other"
                            onClick={() =>
                              handleOptionClick(
                                option.label,
                                intlService.t("post-purchase-survey.other"),
                              )
                            }
                          />
                          <label htmlFor={`${option.key}-other`}>
                            {intlService.t("post-purchase-survey.other")}
                          </label>
                        </div>
                      )}
                    </OptionSet>
                  )}
              </Accordion>
            )}
            {option.type === "text" && (
              <>
                <Button
                  onClick={() => {
                    toggleAccordion(option.key);
                    if (!openAccordions[option.key]) {
                      setTextInput("");
                    }
                  }}
                  aria-expanded={openAccordions[option.key] || false}
                  aria-controls={`accordion-content-${option.key}`}
                  className={`typograpghy-body1 ${
                    openAccordions[option.key] ? "text-input-padding" : ""
                  }`}
                >
                  {option.label}
                  <span>
                    {" "}
                    {openAccordions[option.key] ? (
                      <Icons.MinusBase />
                    ) : (
                      <Icons.PlusBase />
                    )}
                  </span>
                </Button>
                {openAccordions[option.key] && (
                  <FloatingInput
                    placeholder={
                      typeof option.options === "object" &&
                      !Array.isArray(option.options)
                        ? option.options.defaultText
                        : ""
                    }
                    value={textInput}
                    onChange={(e) => setTextInput(e.target.value)}
                    buttonText={
                      typeof option.options === "object" &&
                      !Array.isArray(option.options)
                        ? option.options.submit
                        : ""
                    }
                    onClick={() => handleTextSubmit(option.label)}
                  />
                )}
              </>
            )}
          </Option>
        ))}
      </Modal>
    </Overlay>
  );
};

export default PostPurchaseSurvey;
